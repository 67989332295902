import { transformCentsToDollars } from '@Components/form-general/transformers'
import {
  defaultFormData,
  defaultSpecialGameInputRow,
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData,
  SpecialGameInputRow,
  ProgressiveReceiptDetails,
  WeeklyBeanoReceiptField
} from '@Components/weekly-beano-form/step-2/constants'
import {
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormResponse
} from '../../types'
import {
  DollarizedBeanoReceiptsDetails,
  DollarizedSpecialGameReceipt
} from '../../types-dollarized'

const getSpecialGameRowFromResponse = (
  specialGames: DollarizedSpecialGameReceipt[]
): SpecialGameInputRow[] => {
  const odds: DollarizedSpecialGameReceipt[] = []
  const evens: DollarizedSpecialGameReceipt[] = []
  specialGames.forEach((specialGame, i) => {
    i % 2 ? evens.push(specialGame) : odds.push(specialGame)
  })
  const rowDetails: SpecialGameInputRow[] = odds.map(
    (oddGame, i): SpecialGameInputRow => ({
      oddPriceInDollars: oddGame.priceInDollars,
      oddNumCards: oddGame.numCards,
      evenPriceInDollars:
        evens[i]?.priceInDollars ??
        defaultSpecialGameInputRow.evenPriceInDollars,
      evenNumCards:
        evens[i]?.numCards ?? defaultSpecialGameInputRow.evenNumCards
    })
  )
  return rowDetails
}

const getProgressiveGameFromResponse = (
  specialGame: DollarizedSpecialGameReceipt
): ProgressiveReceiptDetails => ({
  [WeeklyBeanoReceiptField.numCards]: specialGame.numCards,
  [WeeklyBeanoReceiptField.pricePerCardInDollars]: specialGame.priceInDollars
})

const defaultSpecialReceipt: DollarizedSpecialGameReceipt = {
  numCards: 0,
  priceInDollars: 0
}

export const transformWeeklyBeanoResponseToStepTwoData = (
  response:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoStepTwoFormData => {
  if (!response.beanoReceiptsDetails) {
    return defaultFormData
  }
  const beanoReceiptsDetailsInDollars: DollarizedBeanoReceiptsDetails =
    transformCentsToDollars(response.beanoReceiptsDetails)

  const progressiveDetailsOne: DollarizedSpecialGameReceipt =
    beanoReceiptsDetailsInDollars.progressiveGame1ReceiptDetails ??
    defaultSpecialReceipt
  const progressiveDetailsTwo: DollarizedSpecialGameReceipt =
    beanoReceiptsDetailsInDollars.progressiveGame2ReceiptDetails ??
    defaultSpecialReceipt

  return {
    ...defaultFormData,
    [WeeklyBeanoStepTwoField.admissionsNumCards]:
      beanoReceiptsDetailsInDollars.admissionCardsDetails.quantity ?? 0,
    [WeeklyBeanoStepTwoField.admissionsCardPrice]:
      beanoReceiptsDetailsInDollars.admissionCardsDetails.priceInDollars ?? 0,
    [WeeklyBeanoStepTwoField.admissionsNumCardsXRegular]:
      beanoReceiptsDetailsInDollars.admissionCardsDetails.xRegQuantity ?? 0,
    [WeeklyBeanoStepTwoField.admissionsCardPriceXRegular]:
      beanoReceiptsDetailsInDollars.admissionCardsDetails.xRegPriceInDollars ??
      0,
    [WeeklyBeanoStepTwoField.bonusAdmissionsNumCards]:
      beanoReceiptsDetailsInDollars.bonusCardsAdmissionsDetails?.quantity ?? 0,
    [WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice]:
      beanoReceiptsDetailsInDollars.bonusCardsAdmissionsDetails
        .priceInDollars ?? 0,
    [WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular]:
      beanoReceiptsDetailsInDollars.bonusCardsAdmissionsDetails.xRegQuantity ??
      0,
    [WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular]:
      beanoReceiptsDetailsInDollars.bonusCardsAdmissionsDetails
        .xRegPriceInDollars ?? 0,
    [WeeklyBeanoStepTwoField.saleOfBeanoSupplies]:
      beanoReceiptsDetailsInDollars.beanoSuppliesSalesInDollars,
    [WeeklyBeanoStepTwoField.otherReceiptsDescription]:
      beanoReceiptsDetailsInDollars.otherReceiptsDescription ?? '',
    [WeeklyBeanoStepTwoField.otherReceiptsAmount]:
      beanoReceiptsDetailsInDollars.otherReceiptsInDollars ?? 0,
    [WeeklyBeanoStepTwoField.wtaGameReceiptDetails]:
      getSpecialGameRowFromResponse(
        beanoReceiptsDetailsInDollars.wtaGameReceiptDetails
      ),
    [WeeklyBeanoStepTwoField.otherSpecialReceiptsDetails]:
      getSpecialGameRowFromResponse(
        beanoReceiptsDetailsInDollars.otherSpecialReceiptsDetails
      ),
    [WeeklyBeanoStepTwoField.allProgressive]: [
      getProgressiveGameFromResponse(progressiveDetailsOne),
      getProgressiveGameFromResponse(progressiveDetailsTwo)
    ]
  }
}
