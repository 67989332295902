import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { AnnualBeanoOtherDisbursements } from './annual-beano-other-disbursement-ui'
import { disbursementRows } from './constants'
import { DisbursementRow } from './disbursement-row-ui'
import './styles.scss'

export type AnnualBeanoDisbursementsProps = {
  totalNetProfitOrLossDollars: number
  totalDisbursementsDollars: number
  baseDisbursementsTotalDollars: number
}

const ROOT_CLASS = 'annual-beano-disbursements'

export const AnnualBeanoDisbursements = (
  props: AnnualBeanoDisbursementsProps
) => {
  return (
    <section className={ROOT_CLASS}>
      <FormSubsection type="primary">
        <h3>5. Total Disbursements for the Year</h3>
        <div className={getBlockClass(ROOT_CLASS, 'fields')}>
          {disbursementRows.map((row, index) => (
            <DisbursementRow {...row} key={`${ROOT_CLASS}-row-${index}`} />
          ))}
          <div className={getBlockClass(ROOT_CLASS, 'fields-total')}>
            <ReadOnlyField
              value={getDollarString(props.baseDisbursementsTotalDollars)}
              noMargin
            />
          </div>
        </div>
        <AnnualBeanoOtherDisbursements />
        <div className={getBlockClass(ROOT_CLASS, 'total')}>
          <h4>6. Total Disbursements</h4>
          <ReadOnlyField
            noMargin
            value={getDollarString(props.totalDisbursementsDollars)}
          />
        </div>
      </FormSubsection>
    </section>
  )
}
