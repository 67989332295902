export type Optional<T> = T | undefined

export type RemappedKeys<
  T,
  SuffixToChange extends string,
  TargetSuffix extends string
> = {
  [K in keyof T as K extends `${infer S}${SuffixToChange}`
    ? `${S}${TargetSuffix}`
    : K]: T[K] extends Array<infer U>
    ? Array<RemappedKeys<U, SuffixToChange, TargetSuffix>>
    : T[K] extends Record<string, any>
    ? RemappedKeys<T[K], SuffixToChange, TargetSuffix>
    : T[K] extends Optional<Record<string, any>>
    ? RemappedKeys<Optional<T[K]>, SuffixToChange, TargetSuffix>
    : T[K]
}

export const getObjectKeys = <T extends Record<string, any>>(obj: T) =>
  Object.keys(obj) as Array<keyof T>

export const areEqualObjects = <T extends Record<string, any>>(
  obj1: T,
  obj2: T
): boolean => {
  const areEqual = getObjectKeys(obj1).every((key) => {
    const value1 = obj1[key]
    const value2 = obj2[key]
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      return areEqualObjects(value1, value2)
    }
    return value1 === value2
  })
  return areEqual
}

export const getNestedArraysWithLength = <T>(
  arr: T[],
  subarrayLength: number
): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += subarrayLength) {
    result.push(arr.slice(i, i + subarrayLength))
  }
  return result
}

/**
 * Recursively transformers the values and keys of all keys for which keyMatcher returns true.
 * Values are transformed using valueTransformer, keys are transformed via keyTransformer.
 */
export const transformObject = <SourceData extends Record<string, any>>(
  obj: SourceData,
  keyMatcher: (key: string, obj: any) => boolean,
  keyTransformer: (key: string) => string,
  valueTransformer: (value: any) => any
) => {
  const transformedData: Record<string, any> = {}
  getObjectKeys(obj).forEach((key) => {
    const stringKey = String(key)
    const value = obj[stringKey]
    if (Array.isArray(value)) {
      transformedData[stringKey] = value.map((arrayItem) => {
        if (typeof arrayItem === 'object' && arrayItem !== null) {
          return transformObject(
            arrayItem,
            keyMatcher,
            keyTransformer,
            valueTransformer
          )
        }
        return arrayItem
      })
    } else if (typeof value === 'object' && value !== null) {
      transformedData[stringKey] = transformObject(
        value,
        keyMatcher,
        keyTransformer,
        valueTransformer
      )
    } else if (keyMatcher(stringKey, obj)) {
      transformedData[keyTransformer(stringKey)] = valueTransformer(value)
    } else {
      transformedData[stringKey] = value
    }
  })
  return transformedData
}
