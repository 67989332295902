import { Optional } from './util'

export const getBlockClass = (rootClass: string, blockClass: string) =>
  `${rootClass}__${blockClass}`

export const getModifierClass = (
  rootClass: string,
  modifierClass?: string,
  predicate = true
) => (predicate && modifierClass ? `${rootClass}--${modifierClass}` : undefined)

export const joinClasses = (classes: Optional<string>[]): string =>
  Array.from(new Set(classes)).filter(Boolean).join(' ')

export const ComponentHelpers = {
  getBlockClass,
  getModifierClass
}
