import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { PrizeDistributionDetails } from './prize-distribution-details/prize-distribution-details'
import { ProgressiveGamesSummary } from './progressive-games/progressive-games-ui'
import { DoorPrizes } from './door-prizes/door-prizes-ui'
import { WinnerTakeAll } from './winner-take-all/winner-take-all-ui'
import { BASE_ROOT_CLASS } from './constants'
import { GrandTotalCashPrizes } from '@Components/form-general/grand-total-cash-prizes/grand-total-cash-prizes-ui'
import './styles.scss'

export type Props = {
  progressiveOneProceedsInDollars: number
  progressiveTwoProceedsInDollars: number
  beanoGrandTotalCashPrizesInDollars: number
  numWinnerTakeAllSections: number
  date: string
}

export const SummaryOfBeanoPrizes = (props: Props) => {
  return (
    <div className={BASE_ROOT_CLASS}>
      <div className={getBlockClass(BASE_ROOT_CLASS, 'contents')}>
        <h3>2. Summary of Beano Prizes</h3>
        {props.numWinnerTakeAllSections > 0 && <WinnerTakeAll />}
        <ProgressiveGamesSummary
          progressiveOneProceedsInDollars={
            props.progressiveOneProceedsInDollars
          }
          progressiveTwoProceedsInDollars={
            props.progressiveTwoProceedsInDollars
          }
        />
        <PrizeDistributionDetails />
        <DoorPrizes date={props.date} />
      </div>
      <GrandTotalCashPrizes
        totalCashPrizeDollars={props.beanoGrandTotalCashPrizesInDollars}
      />
    </div>
  )
}
