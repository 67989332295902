import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { INPUT_ROOT_CLASS } from './constants'
import { InputBaseError, Props as InputErrorProps } from './input-base-error-ui'
import { InputBase, InputBaseProps } from './input-base-ui'
import { InputBaseLabel, InputLabelProps } from './input-label-ui'

type InputUIErrorProps = Omit<InputErrorProps, 'error'>
type InputUILabelProps = Omit<InputLabelProps, 'htmlFor' | 'labelText'>

export type InputProps = InputUIErrorProps &
  InputUILabelProps &
  InputBaseProps & {
    labelText?: string
    error?: boolean | string
  }

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div className={INPUT_ROOT_CLASS}>
        {props.labelText && (
          <InputBaseLabel
            disabled={props.disabled}
            error={props.error}
            htmlFor={props.name}
            required={props.required}
            labelText={props.labelText}
            className={props.className}
            labelSupplement={props.labelSupplement}
          />
        )}
        <div className={getBlockClass(INPUT_ROOT_CLASS, 'base-wrapper')}>
          {props.moneyField && (
            <span className={getBlockClass(INPUT_ROOT_CLASS, 'dollar-sign')}>
              $
            </span>
          )}
          <InputBase
            ref={ref}
            error={props.error}
            onBlur={props.onBlur}
            onChange={props.onChange}
            type={props.type}
            name={props.name}
            disabled={props.disabled}
            className={props.className}
            required={props.required}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            moneyField={props.moneyField}
          />
        </div>
        {props.error && typeof props.error === 'string' && (
          <InputBaseError error={props.error} className={props.className} />
        )}
      </div>
    )
  }
)
