import * as React from 'react'
import dayjs from 'dayjs'
import { getBlockClass } from '@Helpers/components'
import './styles.scss'
import { DayFormat } from '@Services/dayjs/constants'

export type CasinoSignAndSubmitLegalProps = {
  dateOfOccassion: dayjs.Dayjs
}

const ROOT_CLASS = 'casino-sign-and-submit-legal'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const CLAUSE_CLASS = getBlockClass(ROOT_CLASS, 'clause')

export const CasinoSignAndSubmitLegal = (
  props: CasinoSignAndSubmitLegalProps
) => (
  <div className={ROOT_CLASS}>
    <h4 className={HEADER_CLASS}>
      IMPORTANT - PLEASE READ THE FOLLOWING PARAGRAPHS:
    </h4>
    <div className={getBlockClass(ROOT_CLASS, 'clauses')}>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>A. Gross proceeds derived from a Casino Function</b> are the total
          receipts from the sale of all tickets or other evidences of chance for
          the disposal (by means of chance) of awards permitted under Section 7A
          of Chapter 271 of the General Laws.
        </p>
      </div>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>B.</b> Any organization conducting a Casino Function{' '}
          <b>MUST FILE</b> a return and PAY a tax of 5% of the{' '}
          <b>GROSS PROCEEDS</b> within <b>TEN DAYS</b> after each Casino
          Function. See Section 7A of the General Laws, Chapter 271.
        </p>
      </div>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>C.</b> Adequate books and records must be maintained and kept to
          substantiate compliance with the 5% gross proceeds tax. For tax
          auditing purposes, such books and records should be preserved and
          available for a period of four years from the close of the calendar
          year to which the records apply.
        </p>
      </div>
    </div>
    <h4 className={HEADER_CLASS}>THIS DECLARATION MUST BE COMPLETED</h4>
    <p>
      I declare, subject to the penalties of perjury, that I was the member in
      charge of the casino function conducted by the licensee herein on the{' '}
      <b>{props.dateOfOccassion.format(DayFormat.dayWithOrdinal)}</b> day of{' '}
      <b>{props.dateOfOccassion.format(DayFormat.fullMonthAndYear)}.</b> That I
      personally supervised said function, that I have read the foregoing
      statement, and that this statement is true to the best of my knowledge and
      belief.
    </p>
  </div>
)
