import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { FormProps } from '@Components/form-general/types'
import { useFormErrorModal } from '@Hooks/use-form-error-modal'
import {
  CasinoFormStep,
  getInitialState,
  isCompleteCasinoForm
} from './form-state-reducer/shape'
import { prettyPrint } from '@Helpers/misc'
import { CasinoFormReducer } from './form-state-reducer/reducer'
import {
  casinoFormAction,
  CasinoFormPayload,
  CasinoTotal
} from './form-state-reducer/actions'
import { CASINO_FORM_NUM_STEPS } from './constants'
import { CasinoFormSubmissionBody } from './api/types'
import { CasinoFormStepOne } from './step-1/casino-form-step-one-ui'
import { CasinoFormStepTwo } from './step-2/casino-step-two-ui'
import { CasinoFormStepThree } from './step-3/casino-step-three-ui'
import { CasinoSignAndSubmit } from './sign-and-submit/casino-sign-and-submit'
import { CasinoSubmitFields } from './sign-and-submit/constants'
import { CasinoStepOneField } from './step-1/constants'
import { transformCasinoFormToSubmissionBody } from './api/transformers/casino-submission-transformer'

export type CasinoFormProps = FormProps<CasinoFormSubmissionBody>

const ROOT_CLASS = 'raffle-form'
const today = dateUtil()

export const CasinoForm = (props: CasinoFormProps) => {
  const [state, dispatch] = React.useReducer(
    CasinoFormReducer,
    getInitialState(props.initialValues)
  )

  const handleSubmit = async () => {
    if (!isCompleteCasinoForm(state)) {
      console.error(
        'Casino Form state is not complete for submission',
        prettyPrint(state)
      )
      return
    }
    await props.onSubmit(
      transformCasinoFormToSubmissionBody(
        props.organizationDetails.idNumber,
        state
      )
    )
  }
  const { SubmissionErrorModal } = useFormErrorModal({
    error: props.error,
    component: true,
    handleSubmit: handleSubmit
  })

  const onBackClick = () =>
    dispatch(casinoFormAction.changeFormStep(state.currentStep - 1))

  const updateFormData = <Step extends CasinoFormStep>(
    step: Step,
    payload: CasinoFormPayload[Step],
    totals?: CasinoTotal<Step>
  ) => dispatch(casinoFormAction.updateFormStep(step, payload, totals))

  React.useEffect(() => {
    if (state.currentStep > CASINO_FORM_NUM_STEPS) {
      handleSubmit()
      onBackClick()
    }
  }, [state.currentStep, onBackClick])

  const getFormBody = () => {
    switch (state.currentStep) {
      case 1:
        return (
          <CasinoFormStepOne
            organizationDetails={props.organizationDetails}
            backOffice={props.backOffice}
            initialValues={state[CasinoFormStep.StepOne]}
            onSubmit={(data) => updateFormData(CasinoFormStep.StepOne, data)}
          />
        )
      case 2:
        return (
          <CasinoFormStepTwo
            backOffice={props.backOffice}
            initialValues={state[CasinoFormStep.StepTwo]}
            onBackClick={onBackClick}
            onSubmit={(data, totals) =>
              updateFormData(CasinoFormStep.StepTwo, data, totals)
            }
          />
        )
      case 3:
        return (
          <CasinoFormStepThree
            backOffice={props.backOffice}
            initialValues={state[CasinoFormStep.StepThree]}
            onBackClick={onBackClick}
            onSubmit={(data, totals) =>
              updateFormData(CasinoFormStep.StepThree, data, totals)
            }
            netProfitInDollars={state.totals.netProfitInDollars}
          />
        )
      case 4:
      default:
        return (
          <CasinoSignAndSubmit
            backOffice={props.backOffice}
            initialValues={state[CasinoFormStep.Submit]}
            onBackClick={onBackClick}
            onSubmit={(data) => updateFormData(CasinoFormStep.Submit, data)}
            date={
              state[CasinoFormStep.Submit]?.[CasinoSubmitFields.date] ??
              today.format(DayFormat.monthDayYearDoubleDigit)
            }
            dateOfOccasion={dateUtil(
              state[CasinoFormStep.StepOne]?.[CasinoStepOneField.occasionDate]
            )}
          />
        )
    }
  }

  return (
    <section className={ROOT_CLASS}>
      {SubmissionErrorModal}
      {getFormBody()}
    </section>
  )
}
