import { getBlockClass } from '@Helpers/components'
import {
  DollarizedBeanoExpendituresDetails,
  DollarizedBeanoReceiptsDetails,
  DollarizedWeeklyBeanoFormBody
} from '../api/types-dollarized'

export const BASE_ROOT_CLASS = 'weekly-beano-form-step-two'
export const CARD_GROUP_CLASS = getBlockClass(BASE_ROOT_CLASS, 'card-group')

export enum WeeklyBeanoReceiptField {
  oddNumCards = 'oddNumCards',
  oddPriceInDollars = 'oddPriceInDollars',
  evenNumCards = 'evenNumCards',
  evenPriceInDollars = 'evenPriceInDollars',

  numCards = 'numCards',
  pricePerCardInDollars = 'pricePerCardInDollars'
}

export enum WeeklyBeanoStepTwoField {
  // Section 1
  admissionsNumCards = 'admissionsNumCards',
  admissionsCardPrice = 'admissionsCardPrice',
  admissionsNumCardsXRegular = 'admissionsNumCardsXRegular',
  admissionsCardPriceXRegular = 'admissionsCardPriceXRegular',
  // Section 2
  bonusAdmissionsNumCards = 'bonusAdmissionsNumCards',
  bonusAdmissionsCardPrice = 'bonusAdmissionsCardPrice',
  bonusAdmissionsNumCardsXRegular = 'bonusAdmissionsNumCardsXRegular',
  bonusAdmissionsCardPriceXRegular = 'bonusAdmissionsCardPriceXRegular',

  // Section 3
  wtaGameReceiptDetails = 'wtaGameReceiptDetails',
  allProgressive = 'allProgressive',
  otherSpecialReceiptsDetails = 'otherSpecialReceiptsDetails',

  // Section 4
  saleOfBeanoSupplies = 'saleOfBeanoSupplies',

  // Section 5
  otherReceiptsDescription = 'otherReceiptsDescription',
  otherReceiptsAmount = 'otherReceiptsAmount'
}

export type SpecialGameInputRow = {
  oddNumCards: number
  oddPriceInDollars: number
  evenNumCards: number
  evenPriceInDollars: number
}

export type ProgressiveReceiptDetails = {
  [WeeklyBeanoReceiptField.numCards]: number
  [WeeklyBeanoReceiptField.pricePerCardInDollars]: number
}

export const defaultProgressiveReceiptDetails: ProgressiveReceiptDetails = {
  [WeeklyBeanoReceiptField.numCards]: 0,
  [WeeklyBeanoReceiptField.pricePerCardInDollars]: 0
}

export type SpecialGameField =
  | WeeklyBeanoStepTwoField.wtaGameReceiptDetails
  | WeeklyBeanoStepTwoField.otherSpecialReceiptsDetails
  | WeeklyBeanoStepTwoField.allProgressive

export enum WeeklyBeanoStepTwoFieldLabels {
  numCards = 'No. of Cards',
  pricePerCard = 'Price per Card',
  description = 'Description',
  amount = 'Amount'
}

export type WeeklyBeanoStepTwoTotals = Pick<
  DollarizedBeanoReceiptsDetails,
  'specialGamesReceiptsTotalInDollars'
> &
  Pick<DollarizedWeeklyBeanoFormBody, 'grossBeanoReceiptsInDollars'> &
  Pick<DollarizedBeanoExpendituresDetails, 'mslcTaxPaidInDollars'> & {
    progressiveOneProceedsInDollars: number
    progressiveTwoProceedsInDollars: number
  }

export type WeeklyBeanoStepTwoFormData = {
  [WeeklyBeanoStepTwoField.admissionsNumCards]: number
  [WeeklyBeanoStepTwoField.admissionsCardPrice]: number
  [WeeklyBeanoStepTwoField.admissionsNumCardsXRegular]: number
  [WeeklyBeanoStepTwoField.admissionsCardPriceXRegular]: number
  [WeeklyBeanoStepTwoField.bonusAdmissionsNumCards]: number
  [WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice]: number
  [WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular]: number
  [WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular]: number
  [WeeklyBeanoStepTwoField.otherSpecialReceiptsDetails]: SpecialGameInputRow[]
  [WeeklyBeanoStepTwoField.wtaGameReceiptDetails]: SpecialGameInputRow[]
  [WeeklyBeanoStepTwoField.allProgressive]: [
    ProgressiveReceiptDetails,
    ProgressiveReceiptDetails
  ]
  [WeeklyBeanoStepTwoField.saleOfBeanoSupplies]: number
  [WeeklyBeanoStepTwoField.otherReceiptsDescription]: string
  [WeeklyBeanoStepTwoField.otherReceiptsAmount]: number
}

export type WeeklyBeanoStepTwoStaticData = Omit<
  WeeklyBeanoStepTwoFormData,
  'allGames' | 'allWinnerTakeAll' | 'allProgressive'
>

export const defaultSpecialGameInputRow: SpecialGameInputRow = {
  [WeeklyBeanoReceiptField.oddNumCards]: 0,
  [WeeklyBeanoReceiptField.oddPriceInDollars]: 0,
  [WeeklyBeanoReceiptField.evenNumCards]: 0,
  [WeeklyBeanoReceiptField.evenPriceInDollars]: 0
}

export const defaultFormData: WeeklyBeanoStepTwoFormData = {
  [WeeklyBeanoStepTwoField.admissionsNumCards]: 0,
  [WeeklyBeanoStepTwoField.admissionsCardPrice]: 0,
  [WeeklyBeanoStepTwoField.admissionsNumCardsXRegular]: 0,
  [WeeklyBeanoStepTwoField.admissionsCardPriceXRegular]: 0,
  [WeeklyBeanoStepTwoField.bonusAdmissionsNumCards]: 0,
  [WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice]: 0,
  [WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular]: 0,
  [WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular]: 0,
  [WeeklyBeanoStepTwoField.otherSpecialReceiptsDetails]: [
    defaultSpecialGameInputRow
  ],
  [WeeklyBeanoStepTwoField.wtaGameReceiptDetails]: [defaultSpecialGameInputRow],
  [WeeklyBeanoStepTwoField.allProgressive]: [
    defaultProgressiveReceiptDetails,
    defaultProgressiveReceiptDetails
  ],
  [WeeklyBeanoStepTwoField.saleOfBeanoSupplies]: 0,
  [WeeklyBeanoStepTwoField.otherReceiptsDescription]: '',
  [WeeklyBeanoStepTwoField.otherReceiptsAmount]: 0
}
