import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { getCleanNumber, sumList } from '@Helpers/numbers'
import { CasinoOverShortSelection } from '../api/types'
import {
  CasinoStepTwoField,
  CasinoStepTwoFormData,
  CasinoStepTwoTotals
} from './constants'

export const getCashPrizeSubtotal = (
  numPrizesValue?: number,
  dollarPrizeValue?: number
) => getCleanNumber(numPrizesValue) * getCleanNumber(dollarPrizeValue)

export const getCasinoGrandTotalCashPrizes = (
  formData: CasinoStepTwoFormData
) => {
  const doorPrizeOneSubtotal = getCashPrizeSubtotal(
    formData.cashDoorPrize1?.numPrizes,
    formData.cashDoorPrize1?.amountInDollars
  )
  const doorPrizeTwoSubtotal = getCashPrizeSubtotal(
    formData.cashDoorPrize2?.numPrizes,
    formData.cashDoorPrize2?.amountInDollars
  )
  const rafflePrizeOneSubtotal = getCashPrizeSubtotal(
    formData.cashRafflePrize1?.numPrizes,
    formData.cashRafflePrize1?.amountInDollars
  )
  const rafflePrizeTwoSubtotal = getCashPrizeSubtotal(
    formData.cashRafflePrize2?.numPrizes,
    formData.cashRafflePrize2?.amountInDollars
  )
  const merchPrizesSubtotal = sumList([
    formData.merchandiseDoorPrize?.valueInDollars,
    formData.merchandiseRafflePrize?.valueInDollars
  ])
  return sumList([
    doorPrizeOneSubtotal,
    doorPrizeTwoSubtotal,
    rafflePrizeOneSubtotal,
    rafflePrizeTwoSubtotal,
    merchPrizesSubtotal
  ])
}

export const getCasinoGrossReceipts = (formData: CasinoStepTwoFormData) => {
  const basicReceipts = sumList([
    formData[CasinoStepTwoField.admissionTicketsInDollars],
    formData[CasinoStepTwoField.raffleTicketsInDollars],
    formData[CasinoStepTwoField.gamingTablesInDollars],
    formData[CasinoStepTwoField.otherReceiptsInDollars]
  ])
  let overShortValueDollars = 0
  const overShortSelection = formData[CasinoStepTwoField.overShortSelection]
  if (overShortSelection) {
    const overShortRawNumber = getCleanNumber(
      formData[CasinoStepTwoField.overShortInDollars]
    )
    overShortValueDollars =
      overShortSelection === CasinoOverShortSelection.over
        ? overShortRawNumber
        : -overShortRawNumber
  }
  return basicReceipts + overShortValueDollars
}

const getCasinoTotalTaxDueDollars = (
  penaltyAndInterestInDollars: number,
  taxDueInDollars: number
) => getCleanNumber(penaltyAndInterestInDollars) + taxDueInDollars

const getOtherExpensesTotal = (formData: CasinoStepTwoFormData) =>
  sumList(
    formData.otherExpenditures.map((expenditure) => expenditure.amountInDollars)
  )

const getTotalExpendituresInDollars = (
  formData: CasinoStepTwoFormData,
  grandTotalCashPrizesInDollars: number,
  totalTaxDueInDollars: number
) => {
  const chipRedemptionsDollars =
    formData[CasinoStepTwoField.chipRedemptionsInDollars]
  const equipmentDollars = formData.equipmentSuppliesFee.amountInDollars
  const hallDollars = formData.hallRentalFee.amountInDollars
  return sumList([
    grandTotalCashPrizesInDollars,
    totalTaxDueInDollars,
    chipRedemptionsDollars,
    equipmentDollars,
    hallDollars,
    getOtherExpensesTotal(formData)
  ])
}

export const getCasinoStepTwoTotals = (
  formData: CasinoStepTwoFormData
): CasinoStepTwoTotals => {
  const grossReceiptsInDollars = getCasinoGrossReceipts(formData)
  const taxDueInDollars = calculateTaxPaidDollars(grossReceiptsInDollars)
  const grandTotalCashPrizesInDollars = getCasinoGrandTotalCashPrizes(formData)
  const totalTaxDueInDollars = getCasinoTotalTaxDueDollars(
    formData[CasinoStepTwoField.penaltyAndInterestInDollars],
    taxDueInDollars
  )
  const totalExpendituresInDollars = getTotalExpendituresInDollars(
    formData,
    grandTotalCashPrizesInDollars,
    totalTaxDueInDollars
  )
  const netProfitInDollars = grossReceiptsInDollars - totalExpendituresInDollars
  return {
    grandTotalCashPrizesInDollars,
    grossReceiptsInDollars,
    totalExpendituresInDollars,
    netProfitInDollars,
    taxDueInDollars, // 5% auto-calculated
    totalTaxDueInDollars // taxDueInDollars + penalty and interest
  }
}
