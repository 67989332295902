import * as React from 'react'
import { getBlockClass } from '@Helpers/components'

const ROOT_CLASS = 'raffle-submit-legal'
const LEGAL_CAPS_CLASS = getBlockClass(ROOT_CLASS, 'legal-caps')
const CLAUSE_CLASS = getBlockClass(ROOT_CLASS, 'clause')

export const RaffleSubmitLegal = () => (
  <article className={ROOT_CLASS}>
    <h2 className={LEGAL_CAPS_CLASS}>
      IMPORTANT - PLEASE READ THE FOLLOWING PARAGRAPHS:
    </h2>
    <div className={getBlockClass(ROOT_CLASS, 'clauses')}>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>A. Gross proceeds derived from a Raffle</b> are the total receipts
          from the sale of all tickets certain among which entitle holders to
          prizes, as determined by chance after the sale.
        </p>
      </div>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>B. Gross proceeds derived from a Bazaar</b> are the total receipts
          from the sale of all tickets or other evidences of chance for the
          disposal (by means of Chance) of awards permitted under Section 7A of
          Chapter 271 of the General Laws.
        </p>
      </div>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>C.</b> Any organization conducting a Raffle or Bazaar{' '}
          <b>MUST FILE</b> a return and <b>PAY</b> a tax of <b>5%</b> of the{' '}
          <b>GROSS PROCEEDS</b>
          within <b>TEN DAYS</b> after each Raffle or Bazaar. See Section 7A of
          the General Laws, Chapter 271 as inserted by Chapter 810 of the Acts
          of 1968 and amended by Chapter 219 of the Acts of 1977.
        </p>
      </div>
      <div className={CLAUSE_CLASS}>
        <p>
          <b>D.</b> Adequate books and records must be maintained and kept to
          substantiate compliance with the 5% gross proceeds tax. For tax
          auditing purposes, such books and records should be preserved and
          available for a period of three years after each return is filed.
        </p>
      </div>
    </div>
    <h2 className={LEGAL_CAPS_CLASS}>
      I DECLARE UNDER THE PENALTIES OF PERJURY THAT THIS RETURN HAS BEEN
      EXAMINED BY ME AND TO THE BEST OF MY KNOWLEDGE AND BELIEF IS A TRUE,
      CORRECT AND COMPLETE RETURN
    </h2>
  </article>
)
