import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { useInfoModal } from '@Hooks/use-info-modal'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import {
  BASE_ROOT_CLASS,
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFormData
} from './constants'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import './styles.scss'

export type Props = {
  totalDepositForOccasionInDollars: number
}

const ROOT_CLASS = getBlockClass(BASE_ROOT_CLASS, 'total-deposit')
const MODAL_HEADER = 'Total Deposit for this Occasion'
const MODAL_ARIA_LABEL = 'Total Deposit for this Occasion Information'
const MODAL_BODY =
  'This is calculated by adding the gross beano receipts, CGT profits and game bank, and then deducting the prizes paid.'

export const TotalDepositForOccasion = (props: Props) => {
  const { registerNumber, watch, setValue } =
    useFormContextPlus<WeeklyBeanoStepFourFormData>()
  const { InfoModal, openModal } = useInfoModal({
    header: MODAL_HEADER,
    ariaLabel: MODAL_ARIA_LABEL,
    body: MODAL_BODY
  })
  const value = watch(WeeklyBeanoStepFourField.totalDepositForOccasionInDollars)

  React.useEffect(() => {
    setValue(
      WeeklyBeanoStepFourField.totalDepositForOccasionInDollars,
      props.totalDepositForOccasionInDollars as never,
      { shouldValidate: true }
    )
  }, [props.totalDepositForOccasionInDollars, setValue])

  return (
    <>
      {InfoModal}
      <FormSubsection type="primary">
        <div className={ROOT_CLASS}>
          <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
            <h3 className={getBlockClass(ROOT_CLASS, 'header')}>
              7. Total Deposit for this Occasion
            </h3>
            <ButtonQuestionIcon onClick={openModal} />
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'fields')}>
            <Input
              {...registerNumber(
                ' ',
                WeeklyBeanoStepFourField.totalDepositForOccasionInDollars
              )}
              className={getBlockClass(ROOT_CLASS, 'input')}
              moneyField
            />
            <ReadOnlyField
              label="Total"
              value={getDollarString(value)}
              alignRight
            />
          </div>
        </div>
      </FormSubsection>
    </>
  )
}
