import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  WeeklyBeanoStepOneFields,
  WeeklyBeanoStepOneFormData
} from '../constants'
import { getBlockClass } from '@Helpers/components'
import { StartEndTimeFieldLabel } from '@Components/form-general/start-and-end-times/constants'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getEndTime, getStartTime } from './helpers'
import './styles.scss'

export type TimesAndNumPlayersProps = {
  occasionTime: string // 'AFTERNOON' | 'EVENING'
}

const ROOT_CLASS = 'times-and-num-players'

export const TimesAndNumPlayers = (props: TimesAndNumPlayersProps) => {
  const { registerRequiredNumber } =
    useFormContextPlus<WeeklyBeanoStepOneFormData>()

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'times')}>
        <ReadOnlyField
          label={StartEndTimeFieldLabel.startTime}
          value={getStartTime(props.occasionTime)}
        />
        <ReadOnlyField
          label={StartEndTimeFieldLabel.endTime}
          value={getEndTime(props.occasionTime)}
        />
      </div>
      <Input
        {...registerRequiredNumber(
          StartEndTimeFieldLabel.numPlayers,
          WeeklyBeanoStepOneFields.numPlayers,
          {
            min: {
              value: 1,
              message: `${StartEndTimeFieldLabel.numPlayers} must be at least 1`
            }
          }
        )}
      />
    </div>
  )
}
