import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import {
  AnnualBeanoStepOneFieldLabels,
  AnnualBeanoStepOneFields,
  AnnualBeanoStepOneFormData
} from '../constants'
import { Input } from '@Components/input-base/input-ui'
import './styles.scss'

const ROOT_CLASS = 'annual-beano-event-details'

export const AnnualBeanoEventDetails = () => {
  const {
    registerNumber,
    registerRequiredNumber,
    formState: { errors }
  } = useFormContextPlus<AnnualBeanoStepOneFormData>()
  return (
    <article className={ROOT_CLASS}>
      <h2>Event Details</h2>
      <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
        <Input
          {...registerNumber(
            AnnualBeanoStepOneFieldLabels.numOccasions,
            AnnualBeanoStepOneFields.numOccasions,
            {
              min: {
                value: 0,
                message: `${AnnualBeanoStepOneFieldLabels.numOccasions} must be at least 0`
              }
            }
          )}
          error={errors[AnnualBeanoStepOneFields.numOccasions]?.message}
        />
        <Input
          {...registerRequiredNumber(
            AnnualBeanoStepOneFieldLabels.averageNumPeoplePerOccasion,
            AnnualBeanoStepOneFields.averageNumPeoplePerOccasion,
            {
              min: {
                value: 1,
                message: `${AnnualBeanoStepOneFieldLabels.averageNumPeoplePerOccasion} must be at least 1`
              }
            }
          )}
          error={
            errors[AnnualBeanoStepOneFields.averageNumPeoplePerOccasion]
              ?.message
          }
        />
      </div>
    </article>
  )
}
