import {
  casinoStepTwoDefaultFormData,
  CasinoStepTwoFormData
} from '@Components/casino-form/step-2/constants'
import { DollarizedData } from '@Components/form-general/types'
import { CasinoFormSubmissionBody } from '../../types'

export const transformCasinoStepTwoInitialValues = (
  submissionBodyInDollars: DollarizedData<CasinoFormSubmissionBody>
): CasinoStepTwoFormData => {
  const { receiptsDetails, expendituresDetails, summaryOfPrizes } =
    submissionBodyInDollars
  return {
    ...casinoStepTwoDefaultFormData,
    ...receiptsDetails,
    ...summaryOfPrizes,
    chipRedemptionsInDollars: expendituresDetails.chipRedemptionsInDollars,
    penaltyAndInterestInDollars:
      expendituresDetails.penaltyAndInterestInDollars,
    totalTaxDue: {
      checkNumber: expendituresDetails.totalTaxDue.checkNumber,
      amountInDollars: expendituresDetails.totalTaxDue.amountInDollars
    },
    equipmentSuppliesFee: expendituresDetails.equipmentSuppliesFee,
    hallRentalFee: expendituresDetails.hallRentalFee,
    otherExpenditures: []
  }
}
