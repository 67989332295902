import { DollarizedData } from '@Components/form-general/types'
import { Disbursement } from '../api/types'
import {
  DollarizedDispositionOfAndAccountingForNetProfitsDetails,
  DollarizedTotalDisbursementDetails,
  DollarizedUnexpendedNetProfitDetails,
  DollarizedWeeklyBeanoFormBody
} from '../api/types-dollarized'

export const BASE_ROOT_CLASS = 'weekly-beano-form-step-four'

export enum WeeklyBeanoStepFourField {
  interestInDollars = 'interestInDollars',
  gameBankInDollars = 'gameBankInDollars',
  cgtProceedsInDollars = 'cgtProceedsInDollars',
  nonBeanoCgtProceedsInDollars = 'nonBeanoCgtProceedsInDollars',
  othersInDollars = 'othersInDollars',
  unexpendedNetProfitInDollars = 'unexpendedNetProfitInDollars',
  endingCarryOverInDollars = 'endingCarryOverInDollars',
  disbursements = 'disbursements',
  prizesPaidInDollars = 'prizesPaidInDollars',

  balancePerSpecialBeanoCheckbookInDollars = 'balancePerSpecialBeanoCheckbookInDollars',
  balancePerSpecialBeanoSavingsAcctInDollars = 'balancePerSpecialBeanoSavingsAcctInDollars',
  balanceOfCertificateOfDepositInDollars = 'balanceOfCertificateOfDepositInDollars',

  totalDepositForOccasionInDollars = 'totalDepositForOccasionInDollars'
}

export enum WeeklyBeanoStepFourFieldLabel {
  amount = 'Amount',
  checkNumber = 'Check No.',
  cgtProceeds = 'CGT Proceeds',
  payee = 'Payee',
  description = 'Description',
  date = 'Date',
  endingCarryover = 'Ending Carryover',
  interest = 'Interest',
  gameBank = 'Game Bank',
  grandTotalCashPrizes = 'Grand Total Cash Prizes',
  nonBeanoCGTProceeds = 'Non-Beano CGT Proceeds',
  others = 'Others',
  unexpendedNetProfit = `Schedule 2, Item 6 of Last Week's Report`,

  balancePerSpecialBeanoCheckbook = 'Balance per Special Beano Checkbook',
  balancePerSpecialBeanoSavings = 'Balance per Special Beano Savings Account',
  balanceOfCertificateDeposit = 'Balance of Certificate Deposit'
}

export type DisbursementDetail = DollarizedData<Disbursement>

export const defaultDisbursement: DisbursementDetail = {
  date: '',
  checkNumber: '',
  payee: '',
  description: '',
  amountInDollars: 0
}

export type WeeklyBeanoStepFourTotals = Pick<
  DollarizedWeeklyBeanoFormBody,
  | 'fundsAvailableForDisbursementInDollars'
  | 'totalDisbursementsInDollars'
  | 'totalDepositForOccasionInDollars' // used as a default, not to be submitted
> & {
  cgTicketNetProceedsInDollars: number
  expectedUnexpendedNetProfitInDollars: number
  netProfitInDollars: number // actually from step three, but repeated in step four
} & Pick<
    DollarizedDispositionOfAndAccountingForNetProfitsDetails,
    'otherIncomeInDollars'
  >

export type WeeklyBeanoStepFourFormData = DollarizedUnexpendedNetProfitDetails &
  DollarizedTotalDisbursementDetails &
  Omit<
    DollarizedDispositionOfAndAccountingForNetProfitsDetails,
    'otherIncomeInDollars' | 'netBeanoProfitInDollars'
  > &
  Pick<DollarizedWeeklyBeanoFormBody, 'totalDepositForOccasionInDollars'>

export const defaultFormData: WeeklyBeanoStepFourFormData = {
  [WeeklyBeanoStepFourField.disbursements]: [defaultDisbursement],
  [WeeklyBeanoStepFourField.unexpendedNetProfitInDollars]: 0,
  [WeeklyBeanoStepFourField.nonBeanoCgtProceedsInDollars]: 0,
  [WeeklyBeanoStepFourField.interestInDollars]: 0,
  [WeeklyBeanoStepFourField.gameBankInDollars]: 0,
  [WeeklyBeanoStepFourField.othersInDollars]: 0,
  [WeeklyBeanoStepFourField.prizesPaidInDollars]: 0,
  [WeeklyBeanoStepFourField.endingCarryOverInDollars]: 0,
  [WeeklyBeanoStepFourField.cgtProceedsInDollars]: 0,
  [WeeklyBeanoStepFourField.balancePerSpecialBeanoCheckbookInDollars]: 0,
  [WeeklyBeanoStepFourField.balancePerSpecialBeanoSavingsAcctInDollars]: 0,
  [WeeklyBeanoStepFourField.balanceOfCertificateOfDepositInDollars]: 0,
  [WeeklyBeanoStepFourField.totalDepositForOccasionInDollars]: 0
}

export const getWeeklyBeanoFourDefaultFormData = (
  defaultGrandTotalCashPrizeInDollars: number,
  initialValues?: WeeklyBeanoStepFourFormData
): WeeklyBeanoStepFourFormData => ({
  ...defaultFormData,
  ...initialValues,
  [WeeklyBeanoStepFourField.prizesPaidInDollars]:
    defaultGrandTotalCashPrizeInDollars
})
