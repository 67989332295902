import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { useInfoModal } from '@Hooks/use-info-modal'
import { CasinoStepThreeFieldLabel, CasinoStepThreeTotals } from '../constants'
import { getBlockClass } from '@Helpers/components'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

export type SpecialCasinoCheckingBalanceProps = Pick<
  CasinoStepThreeTotals,
  'specialCasinoCheckingBalanceInDollars'
>

const ROOT_CLASS = 'special-casino-balance'
const MODAL_HEADER = 'Updated Balance in Special Casino Checking Account'
const MODAL_BODY =
  'This is calculated by deducting the Total Disbursement from the Total Funds Available for Disbursement.'

export const SpecialCasinoCheckingBalance = (
  props: SpecialCasinoCheckingBalanceProps
) => {
  const { InfoModal, openModal } = useInfoModal({
    header: MODAL_HEADER,
    ariaLabel: MODAL_HEADER,
    body: MODAL_BODY
  })
  const displayValue = React.useMemo(
    () => getDollarString(props.specialCasinoCheckingBalanceInDollars),
    [props.specialCasinoCheckingBalanceInDollars]
  )
  return (
    <>
      {InfoModal}
      <FormSubsection type="primary">
        <div className={ROOT_CLASS}>
          <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
            <h4 className={getBlockClass(ROOT_CLASS, 'header')}>
              5. Updated Balance in Special Casino Checking Account
            </h4>
            <ButtonQuestionIcon onClick={openModal} />
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'fields')}>
            <ReadOnlyField
              label={CasinoStepThreeFieldLabel.amount}
              value={displayValue}
            />
            <ReadOnlyField value={displayValue} />
          </div>
        </div>
      </FormSubsection>
    </>
  )
}
