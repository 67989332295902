import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { getBlockClass } from '@Helpers/components'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import { ModalInfo } from '@Components/modal-info/modal-info-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { InputBaseError } from '@Components/input-base/input-base-error-ui'
import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel,
  AnnualBeanoStepThreeFormData
} from '../constants'
import './styles.scss'

export type AnnualBeanoBalanceInAccountsProps = {
  expectedTotalBalanceDollars: number
  error?: string
}

const ROOT_CLASS = 'annual-beano-balance-in-accounts'

const lastDayLastYear = dateUtil().month(11).date(31).subtract(1, 'year')
const lastDayLastYearFormatted = lastDayLastYear.format(
  DayFormat.fullMonthDateAndYear
)

export const AnnualBeanoBalanceInAccounts = (
  props: AnnualBeanoBalanceInAccountsProps
) => {
  const { registerNumber } = useFormContextPlus<AnnualBeanoStepThreeFormData>()
  const [infoModalOpen, setInfoModalOpen] = React.useState<boolean>(false)
  const closeInfoModal = () => setInfoModalOpen(false)
  const hasError = Boolean(props.error)

  return (
    <>
      <ModalInfo
        isOpen={infoModalOpen}
        ariaLabel="Total Balance in Special Beano Account Information"
        header={`Total Balance in Special Beano Account (${lastDayLastYearFormatted})`}
        body="This is calculated by deducting the Total Disbursements from the Total Funds Available for Disbursements. This amount must also equal the sum of the Balances per Special Beano Checkbook and Savings Account."
        buttonText="Okay"
        onButtonClick={closeInfoModal}
        handleClose={closeInfoModal}
      />
      <div className={ROOT_CLASS}>
        <FormSubsection type="primary">
          <div className={getBlockClass(ROOT_CLASS, 'top-level-info')}>
            <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
              <h4 className={getBlockClass(ROOT_CLASS, 'header')}>
                7. Total Balance in Special Beano Bank Account(s)
              </h4>
              <p
                className={getBlockClass(ROOT_CLASS, 'header-supplement')}
              >{`(as of ${lastDayLastYearFormatted})`}</p>
              <ButtonQuestionIcon onClick={() => setInfoModalOpen(true)} />
            </div>
            <ReadOnlyField
              value={getDollarString(props.expectedTotalBalanceDollars)}
              noMargin
            />
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
            <Input
              {...registerNumber(
                AnnualBeanoStepThreeFieldLabel.balancePerSpecialBeanoCheckbook,
                AnnualBeanoStepThreeField.specialBeanoCheckbookBalanceInDollars
              )}
              error={hasError}
              moneyField
            />
            <Input
              {...registerNumber(
                AnnualBeanoStepThreeFieldLabel.balancePerSpecialBeanoSavings,
                AnnualBeanoStepThreeField.specialBeanoSavingsBalanceInDollars
              )}
              error={hasError}
              moneyField
            />
          </div>
          <InputBaseError
            error={props.error}
            className={getBlockClass(ROOT_CLASS, 'error')}
          />
        </FormSubsection>
      </div>
    </>
  )
}
