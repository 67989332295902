import * as React from 'react'
import { transformWeeklyBeanoResponseToFormState } from '../api/transformers'
import { WeeklyBeanoFormBody, WeeklyBeanoFormBodyResponse } from '../api/types'
import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import { WeeklyBeanoFormReducer } from '../form-state-reducer/reducer'
import { WeeklyBeanoFormStep } from '../form-state-reducer/shape'
import { WeeklyBeanoSubmit } from '../sign-and-submit/weekly-beano-submit-ui'
import { WeeklyBeanoStepOne } from '../step-1/weekly-beano-one-ui'
import { WeeklyBeanoStepTwo } from '../step-2/weekly-beano-two-ui'
import { WeeklyBeanoStepThree } from '../step-3/weekly-beano-three-ui'
import { WeeklyBeanoStepFour } from '../step-4/weekly-beano-four-ui'
import { getEventDate } from '../sign-and-submit/constants'
import { getNumWinnerTakeAllSections } from '../step-2/helpers'
import './styles.scss'

export type WeeklyBeanoFormPrintProps = {
  dateString: string
  organizationDetails: OrganizationDetailsProps
  formData: WeeklyBeanoFormBody | WeeklyBeanoFormBodyResponse
  occasionTime: string
}

const noBackClick = () => {}
const noSubmit = (formData: any) => {}

const ROOT_CLASS = 'weekly-beano-print'

export const WeeklyBeanoFormPrint = React.forwardRef<
  HTMLElement,
  WeeklyBeanoFormPrintProps
>((props, ref) => {
  const [state] = React.useReducer(
    WeeklyBeanoFormReducer,
    transformWeeklyBeanoResponseToFormState(props.formData)
  )

  const eventDate = React.useMemo(
    () => getEventDate(props.dateString),
    [props.dateString]
  )

  return (
    <section ref={ref} className={ROOT_CLASS}>
      <WeeklyBeanoStepOne
        {...props.organizationDetails}
        dateString={props.dateString}
        initialValues={state[WeeklyBeanoFormStep.StepOne]}
        onSubmit={noSubmit}
        occasionTime={props.occasionTime}
        printMode
      />
      <WeeklyBeanoStepTwo
        initialValues={state[WeeklyBeanoFormStep.StepTwo]}
        onSubmit={noSubmit}
        onBackClick={noBackClick}
        printMode
      />
      <WeeklyBeanoStepThree
        date={props.dateString}
        initialValues={state[WeeklyBeanoFormStep.StepThree]}
        numWinnerTakeAllSections={getNumWinnerTakeAllSections(
          state[WeeklyBeanoFormStep.StepTwo]
        )}
        onSubmit={noSubmit}
        grossBeanoReceiptsInDollars={state.totals.grossBeanoReceiptsInDollars}
        onBackClick={noBackClick}
        printMode
        mslcTaxPaidInDollars={state.totals.mslcTaxPaidInDollars}
        progressiveOneProceedsInDollars={
          state.totals.progressiveOneProceedsInDollars
        }
        progressiveTwoProceedsInDollars={
          state.totals.progressiveTwoProceedsInDollars
        }
      />
      <WeeklyBeanoStepFour
        onSubmit={noSubmit}
        initialValues={state[WeeklyBeanoFormStep.StepFour]}
        netProfitOrLossInDollars={state.totals.netProfitInDollars}
        defaultGrandTotalCashPrizeInDollars={
          state.totals.beanoGrandTotalCashPrizesInDollars
        }
        grossBeanoReceiptsInDollars={state.totals.grossBeanoReceiptsInDollars}
        onBackClick={noBackClick}
        printMode
      />
      <WeeklyBeanoSubmit
        onBackClick={noBackClick}
        initialValues={state[WeeklyBeanoFormStep.Submit]}
        onSubmit={noSubmit}
        eventDate={eventDate}
        printMode
      />
    </section>
  )
})
