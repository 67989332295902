import { CasinoFormSubmissionBody } from '../api/types'

export enum CasinoStepOneField {
  raffleId = 'raffleId',
  occasionDate = 'occasionDate',
  occasionDay = 'occasionDay',
  occasionStartTime = 'occasionStartTime',
  occasionEndTime = 'occasionEndTime',
  numPlayers = 'numPlayers'
}

export enum CasinoStepOneFieldLabel {
  occasionDate = 'Date of Occasion',
  occasionDay = 'Day of Occasion',
  occasionStartTime = 'Start Time',
  occasionEndTime = 'End Time',
  numPlayers = 'Number of Players'
}

export type CasinoStepOneFormData = Pick<
  CasinoFormSubmissionBody,
  'occasionDate' | 'occasionStartTime' | 'occasionEndTime' | 'numPlayers'
>
