import * as React from 'react'
import { Button, ButtonColor, ButtonFill } from '@Components/button/button-ui'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

export type Props = {
  currentStep: number
  totalSteps: number
  formId: string
  nextDisabled?: boolean
  onBackClick?: () => void
  onNextClick?: () => void
  backOffice?: true
}

const ROOT_CLASS = 'form-traversal-buttons'

export const FormTraversalButtons = (props: Props) => {
  const nextButtonText =
    props.currentStep === props.totalSteps ? 'Submit' : 'Next'
  const showPreviousButton = props.currentStep > 1
  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'one-button', !showPreviousButton)
      ])}
    >
      {showPreviousButton && (
        <Button
          style={ButtonFill.outlined}
          htmlProps={{ onClick: props.onBackClick, type: 'button' }}
          color={props.backOffice ? ButtonColor.white : ButtonColor.blue}
        >
          Back
        </Button>
      )}
      <Button
        htmlProps={{
          type: 'submit',
          form: props.formId,
          disabled: props.nextDisabled,
          onClick: props.onNextClick
        }}
        style={ButtonFill.filled}
        color={props.backOffice ? ButtonColor.teal : ButtonColor.blue}
      >
        {nextButtonText}
      </Button>
    </div>
  )
}
