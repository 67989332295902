import {
  transformCentsToDollars,
  transformDollarsToCents
} from '@Components/form-general/transformers'
import { RaffleFormActivityTypeField } from '@Components/raffle-form/activity-type/constants'
import {
  CompleteRaffleFormState,
  RaffleFormStep
} from '@Components/raffle-form/form-state-reducer/shape'
import { RaffleStepOneField } from '@Components/raffle-form/step-1/constants'
import {
  defaultRaffleOtherExpenditure,
  RaffleStepThreeField
} from '@Components/raffle-form/step-3/constants'
import { RaffleFormResponse, RaffleFormSubmissionBody } from '../types'
import { transformRaffleResponseToStepOneAndType } from './initial-values/raffle-initial-values-step-1-and-type'
import { transformRaffleStepThreeToSubmission } from './submission/transform-raffle-step-three-to-submission'
import { transformRaffleStepTwoToSubmission } from './submission/transform-raffle-step-two-to-submission'

export const getRaffleStateFromInitialValues = (
  initialValues: RaffleFormResponse
): CompleteRaffleFormState => {
  const initialValuesInDollars = transformCentsToDollars(initialValues)
  return {
    ...transformRaffleResponseToStepOneAndType(initialValues),
    [RaffleFormStep.StepTwo]: {
      ...initialValuesInDollars.receiptsDetails,
      ...initialValuesInDollars.taxDetails
    },
    [RaffleFormStep.StepThree]: {
      [RaffleStepThreeField.totalPrizesAwardedInDollars]:
        initialValuesInDollars.expensesDetails.totalPrizesAwardedInDollars ?? 0,
      [RaffleStepThreeField.totalPrizesAwardedDescription]:
        initialValuesInDollars.expensesDetails.totalPrizesAwardedDescription ??
        '',
      [RaffleStepThreeField.taxPaidCheckNumber]:
        initialValuesInDollars.expensesDetails.taxPaid.checkNumber ?? '',
      [RaffleStepThreeField.otherExpenses]: initialValuesInDollars
        .expensesDetails.otherExpenses.length
        ? initialValuesInDollars.expensesDetails.otherExpenses
        : [defaultRaffleOtherExpenditure]
    },
    [RaffleFormStep.Submit]: {
      ...initialValuesInDollars.formPreparationDetails
    },
    totals: {
      totalTaxDueInDollars: initialValuesInDollars.totalTaxDueInDollars,
      grossReceiptsInDollars: initialValuesInDollars.grossReceiptsInDollars,
      netProfitInDollars: initialValuesInDollars.netProfitInDollars,
      totalExpensesInDollars: initialValuesInDollars.totalExpensesInDollars,
      baseTaxInDollars: initialValuesInDollars.taxDetails.baseTaxInDollars,
      totalTicketSalesInDollars:
        initialValuesInDollars.expensesDetails.totalPrizesAwardedInDollars
    },
    currentStep: 1
  }
}

export const getRaffleSubmissionBodyFromState = (
  raffleFormState: CompleteRaffleFormState,
  raffleId: string
): RaffleFormSubmissionBody => {
  const totalsInCents = transformDollarsToCents(raffleFormState.totals)
  return {
    raffleId: raffleId,
    activityType:
      raffleFormState[RaffleFormStep.ActivityType][
        RaffleFormActivityTypeField.activityType
      ],
    occasionDate:
      raffleFormState[RaffleFormStep.StepOne][RaffleStepOneField.occasionDate],
    orgFid: raffleFormState[RaffleFormStep.StepOne][RaffleStepOneField.orgFid],
    ...transformRaffleStepTwoToSubmission(
      raffleFormState[RaffleFormStep.StepTwo],
      totalsInCents
    ),
    ...transformRaffleStepThreeToSubmission(
      raffleFormState[RaffleFormStep.StepThree],
      totalsInCents
    ),
    formPreparationDetails: {
      memberRole: raffleFormState[RaffleFormStep.Submit].memberRole,
      phoneNumber: raffleFormState[RaffleFormStep.Submit].phoneNumber,
      residenceAddress: raffleFormState[RaffleFormStep.Submit].residenceAddress,
      city: raffleFormState[RaffleFormStep.Submit].city,
      signature: raffleFormState[RaffleFormStep.Submit].signature,
      email: raffleFormState[RaffleFormStep.Submit].email,
      date: raffleFormState[RaffleFormStep.Submit].date
    }
  }
}
