import * as React from 'react'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { getDollarString } from '@Helpers/currency'
import { getBlockClass } from '@Helpers/components'
import { BASE_ROOT_CLASS } from './constants'
import './styles.scss'

export type Props = {
  fundsAvailableForDisbursementInDollars: number
}

export const TotalFundsForDisbursement = (props: Props) => (
  <FormSummary>
    <div className={getBlockClass(BASE_ROOT_CLASS, 'total-funds')}>
      <FormSummary.Title>
        Total Funds Available for Disbursement
      </FormSummary.Title>
    </div>
    <FormSummary.Total>
      {getDollarString(props.fundsAvailableForDisbursementInDollars)}
    </FormSummary.Total>
  </FormSummary>
)
