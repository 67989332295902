import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  CasinoStepTwoField,
  CasinoStepTwoFieldLabel,
  CasinoStepTwoFormData
} from '../constants'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { InputRadio } from '@Components/input-radio/input-radio-ui'
import { CasinoOverShortSelection } from '@Components/casino-form/api/types'
import { getDollarString } from '@Helpers/currency'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { CasinoReceiptFieldSupplement, CasinoReceiptProps } from './constants'
import { InputBaseLabel } from '@Components/input-base/input-label-ui'
import { InputBaseError } from '@Components/input-base/input-base-error-ui'
import './styles.scss'
import { getRequiredLabel } from '@Helpers/test-util'
import { getCleanNumber } from '@Helpers/numbers'

const ROOT_CLASS = 'casino-receipt'
const OPTION_CLASS = getBlockClass(ROOT_CLASS, 'option')
const OPTION_TEXT_CLASS = getBlockClass(ROOT_CLASS, 'option-text')
const FIELD_INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'field-inputs')
const requiredConfig = {
  required: {
    value: true,
    message: `Short/Over selection is required`
  }
}

const CasinoReceiptOverShort = () => {
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContextPlus<CasinoStepTwoFormData>()
  const checkedValue = watch(CasinoStepTwoField.overShortSelection)

  return (
    <div className={getBlockClass(ROOT_CLASS, 'over-short')}>
      <InputBaseLabel
        labelText={getRequiredLabel(CasinoStepTwoFieldLabel.selectOverUnder)}
      />
      <div className={getBlockClass(ROOT_CLASS, 'over-short-options')}>
        <InputRadio
          {...register(CasinoStepTwoField.overShortSelection, requiredConfig)}
          value={CasinoOverShortSelection.short}
          id={`CasinoOverShortSelection-${CasinoOverShortSelection.short}`}
          checked={checkedValue === CasinoOverShortSelection.short}
          className={OPTION_CLASS}
        >
          <p className={OPTION_TEXT_CLASS}>Short</p>
        </InputRadio>
        <InputRadio
          {...register(CasinoStepTwoField.overShortSelection, requiredConfig)}
          value={CasinoOverShortSelection.over}
          id={`CasinoOverShortSelection-${CasinoOverShortSelection.over}`}
          checked={checkedValue === CasinoOverShortSelection.over}
          className={OPTION_CLASS}
        >
          <p className={OPTION_TEXT_CLASS}>Over</p>
        </InputRadio>
      </div>
      <InputBaseError
        error={errors[CasinoStepTwoField.overShortSelection]?.message}
      />
    </div>
  )
}

export const CasinoReceipt = (props: CasinoReceiptProps) => {
  const { watch, registerNumber, register } =
    useFormContextPlus<CasinoStepTwoFormData>()

  const amountValue: number = getCleanNumber(watch(props.amountFieldName))
  const isOverShort =
    props.fieldSupplement === CasinoReceiptFieldSupplement.overShort
  const overShortSelection: CasinoOverShortSelection = watch(
    CasinoStepTwoField.overShortSelection
  )

  const displayValue = React.useMemo(() => {
    let amount = amountValue
    if (isOverShort) {
      if (overShortSelection === CasinoOverShortSelection.short) {
        amount = -amountValue
      } else if (!overShortSelection) {
        amount = 0
      }
    }
    return getDollarString(amount)
  }, [isOverShort, overShortSelection, amountValue])

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'labels')}>
        <h4>{props.headerBoldText}</h4>
        {props.headerSupplement && (
          <i className={getBlockClass(ROOT_CLASS, 'label-supplement')}>
            {props.headerSupplement}
          </i>
        )}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'field')}>
        <div
          className={joinClasses([
            FIELD_INPUTS_CLASS,
            getModifierClass(
              FIELD_INPUTS_CLASS,
              'with-supplement',
              props.fieldSupplement !== undefined
            )
          ])}
        >
          <Input
            moneyField
            {...registerNumber(
              CasinoStepTwoFieldLabel.amount,
              props.amountFieldName
            )}
          />
          {isOverShort && <CasinoReceiptOverShort />}
          {props.fieldSupplement ===
            CasinoReceiptFieldSupplement.description && (
            <Input
              {...register(CasinoStepTwoField.otherReceiptsDescription)}
              labelText={CasinoStepTwoFieldLabel.description}
            />
          )}
        </div>
        <ReadOnlyField
          value={displayValue}
          label={props.readOnlyLabel ? 'Total' : undefined}
          noMargin={props.readOnlyLabel ? false : true}
          alignRight
        />
      </div>
    </div>
  )
}
