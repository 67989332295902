import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormStepProps } from '@Components/form-general/types'
import { RAFFLE_DISPLAY_NUM_STEPS, RAFFLE_NUM_STEPS } from '../constants'
import { RaffleSubmitFormData } from './constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { RaffleSubmitLegal } from './raffle-submit-legal'
import { RaffleSubmitFields } from './raffle-submit-fields'
import './styles.scss'

export type RaffleSubmitProps = FormStepProps<RaffleSubmitFormData> & {
  date: string
  submitDisabled?: boolean
}

const ROOT_CLASS = 'raffle-submit'
const FORM_ID = ROOT_CLASS
const FORM_STEP = RAFFLE_NUM_STEPS

export const RaffleSubmit = (props: RaffleSubmitProps) => {
  const methods = useForm<RaffleSubmitFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })

  const handleSubmit = async (
    data: RaffleSubmitFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit({ ...data, date: props.date })
  }

  return (
    <>
      <FormCard
        title={'Sign & Submit'}
        currentStep={RAFFLE_DISPLAY_NUM_STEPS}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
      >
        <div className={FORM_ID}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              id={FORM_ID}
              className={FORM_ID}
            >
              <RaffleSubmitLegal />
              <RaffleSubmitFields date={props.date} />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={RAFFLE_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid || props.submitDisabled}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
