import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import {
  OrganizationDetails,
  OrganizationDetailsProps
} from '@Components/organization-details/organization-details-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormStepOneProps } from '@Components/form-general/types'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { ANNUAL_BEANO_NUM_STEPS } from '../constants'
import { AnnualBeanoStepOneFormData } from './constants'
import { AnnualBeanoEventDetails } from './event-details/event-details-ui'
import './styles.scss'

export type AnnualBeanoStepOneProps = {
  organizationDetails: OrganizationDetailsProps
} & FormStepOneProps<AnnualBeanoStepOneFormData>

const FORM_ID = 'annual-beano-step-one'
const FORM_STEP = 1
const ROOT_CLASS = FORM_ID

export const AnnualBeanoStepOne = (props: AnnualBeanoStepOneProps) => {
  const methods = useForm<AnnualBeanoStepOneFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  return (
    <>
      <FormCard currentStep={FORM_STEP} totalSteps={ANNUAL_BEANO_NUM_STEPS}>
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(props.onSubmit)} id={FORM_ID}>
              <OrganizationDetails {...props.organizationDetails} />
              <AnnualBeanoEventDetails />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={ANNUAL_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
        />
      )}
    </>
  )
}
