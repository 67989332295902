import { transformDollarsToCents } from '@Components/form-general/transformers'
import { CentsifiedData } from '@Components/form-general/types'
import {
  CompleteWeeklyBeanoFormState,
  WeeklyBeanoFormStep
} from '@Components/weekly-beano-form/form-state-reducer/shape'
import {
  WeeklyBeanoSubmitField,
  WeeklyBeanoSubmitFormData
} from '@Components/weekly-beano-form/sign-and-submit/constants'
import { WeeklyBeanoStepFourFormData } from '@Components/weekly-beano-form/step-4/constants'
import {
  DeclarationDetails,
  DispositionOfAndAccountingForNetProfitsDetails,
  UnexpendedNetProfitDetails,
  WeeklyBeanoFormBodySubmission
} from '../../types'

export type StepFourBodyData = Pick<
  WeeklyBeanoFormBodySubmission,
  | 'dispositionOfAndAccountingForNetProfitsDetails'
  | 'fundsAvailableForDisbursementInCents'
  | 'totalDisbursementDetails'
  | 'totalDisbursementsInCents'
  | 'unexpendedNetProfitDetails'
  | 'unexpendedNetProfitInCents'
  | 'totalDepositForOccasionInCents'
  | 'declarationDetails'
>

const getDispositionOfAndAccountingForNetProfitsDetails = (
  stateInCents: CentsifiedData<CompleteWeeklyBeanoFormState>
): DispositionOfAndAccountingForNetProfitsDetails => {
  const stepFourInCents = stateInCents[WeeklyBeanoFormStep.StepFour]

  const result: DispositionOfAndAccountingForNetProfitsDetails = {
    unexpendedNetProfitInCents: stepFourInCents.unexpendedNetProfitInCents,
    netBeanoProfitInCents: stateInCents.totals.netProfitInCents,
    endingCarryOverInCents: stepFourInCents.endingCarryOverInCents,
    prizesPaidInCents: stateInCents.totals.beanoGrandTotalCashPrizesInCents,
    nonBeanoCgtProceedsInCents: stepFourInCents.nonBeanoCgtProceedsInCents,
    cgtProceedsInCents: stepFourInCents.cgtProceedsInCents,
    interestInCents: stepFourInCents.interestInCents,
    gameBankInCents: stepFourInCents.gameBankInCents,
    othersInCents: stepFourInCents.othersInCents,
    otherIncomeInCents: stateInCents.totals.otherIncomeInCents
  }
  return result
}

const getUnexpendedNetProfitsDetails = (
  formData: CentsifiedData<WeeklyBeanoStepFourFormData>
): UnexpendedNetProfitDetails => ({
  balancePerSpecialBeanoCheckbookInCents:
    formData.balancePerSpecialBeanoCheckbookInCents,
  balancePerSpecialBeanoSavingsAcctInCents:
    formData.balancePerSpecialBeanoSavingsAcctInCents,
  balanceOfCertificateOfDepositInCents:
    formData.balanceOfCertificateOfDepositInCents
})

const getDeclarationDetails = (
  submitData: WeeklyBeanoSubmitFormData
): DeclarationDetails => ({
  memberRole: submitData[WeeklyBeanoSubmitField.memberRole],
  phoneNumber: submitData[WeeklyBeanoSubmitField.phoneNumber],
  signature: submitData[WeeklyBeanoSubmitField.signature],
  date: submitData.date,
  email: submitData[WeeklyBeanoSubmitField.email]
})

export const transformStepFourData = (
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): StepFourBodyData => {
  const stateInCents = transformDollarsToCents(weeklyBeanoState)
  const stepFourInCents = stateInCents[WeeklyBeanoFormStep.StepFour]
  const { fundsAvailableForDisbursementInCents, totalDisbursementsInCents } =
    stateInCents.totals
  return {
    dispositionOfAndAccountingForNetProfitsDetails:
      getDispositionOfAndAccountingForNetProfitsDetails(stateInCents),
    fundsAvailableForDisbursementInCents: fundsAvailableForDisbursementInCents,
    totalDisbursementDetails: {
      disbursements: stepFourInCents.disbursements
    },
    totalDisbursementsInCents,
    unexpendedNetProfitDetails: getUnexpendedNetProfitsDetails(stepFourInCents),
    unexpendedNetProfitInCents:
      fundsAvailableForDisbursementInCents - totalDisbursementsInCents,
    totalDepositForOccasionInCents:
      stepFourInCents.totalDepositForOccasionInCents,
    declarationDetails: getDeclarationDetails(
      weeklyBeanoState[WeeklyBeanoFormStep.Submit]
    )
  }
}
