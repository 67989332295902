import { AnnualBeanoSubmissionBody } from '../api/types'

export enum AnnualBeanoStepOneFields {
  averageNumPeoplePerOccasion = 'averageNumPeoplePerOccasion',
  numOccasions = 'numOccasions'
}

export enum AnnualBeanoStepOneFieldLabels {
  averageNumPeoplePerOccasion = 'Average No. of Players per Event',
  numOccasions = 'Number of Events'
}

export type AnnualBeanoStepOneFormData = Pick<
  AnnualBeanoSubmissionBody,
  'numOccasions' | 'averageNumPeoplePerOccasion'
>
