import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Helpers/components'
import { INPUT_ROOT_CLASS } from './constants'

export type InputLabelProps = {
  disabled?: boolean
  error?: string | boolean
  htmlFor?: string
  required?: boolean
  labelText: string
  className?: string
  labelSupplement?: JSX.Element
}

const ROOT_CLASS = getBlockClass(INPUT_ROOT_CLASS, 'label')

export const InputBaseLabel = (props: InputLabelProps) => {
  const errorClass = getModifierClass(ROOT_CLASS, 'error', Boolean(props.error))
  const disabledClass = getModifierClass(
    ROOT_CLASS,
    'disabled',
    Boolean(props.disabled)
  )
  const requiredClass = getModifierClass(
    ROOT_CLASS,
    'required',
    Boolean(props.required)
  )
  return (
    <span className={getBlockClass(ROOT_CLASS, 'container')}>
      <label
        htmlFor={props.htmlFor}
        id={props.htmlFor ? `${props.htmlFor}-label` : undefined}
        className={joinClasses([
          ROOT_CLASS,
          errorClass,
          disabledClass,
          requiredClass,
          props.className
        ])}
      >{`${props.labelText} ${props.required ? '*' : ''}`}</label>
      {props.labelSupplement}
    </span>
  )
}
