import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { DynamicCardInputs } from '../card-inputs/card-inputs-dynamic-ui'
import { getProgressiveGameTotal } from '../helpers'
import {
  defaultProgressiveReceiptDetails,
  WeeklyBeanoReceiptField,
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import './styles.scss'

const ROOT_CLASS = 'weekly-beano-progressive-games'
const PROGRESSIVE_SECTION_CLASS = getBlockClass(ROOT_CLASS, 'game')
const CARD_INPUT_CLASS = getBlockClass(ROOT_CLASS, 'card-input')

export const WeeklyBeanoProgressiveGames = () => {
  const { getSubtotalDisplay } = useDynamicFields<
    WeeklyBeanoStepTwoFormData,
    WeeklyBeanoStepTwoField.allProgressive
  >({
    name: WeeklyBeanoStepTwoField.allProgressive,
    defaultData: defaultProgressiveReceiptDetails,
    getSubtotal: getProgressiveGameTotal
  })
  return (
    <div className={ROOT_CLASS}>
      <div className={PROGRESSIVE_SECTION_CLASS}>
        <div className={CARD_INPUT_CLASS}>
          <h4>Progressive Game #1</h4>
          <DynamicCardInputs
            type={WeeklyBeanoStepTwoField.allProgressive}
            numCardsfieldName={WeeklyBeanoReceiptField.numCards}
            pricePerCardFieldName={
              WeeklyBeanoReceiptField.pricePerCardInDollars
            }
            id={`${WeeklyBeanoStepTwoField.allProgressive}.0`}
            index={0}
          />
        </div>
        <ReadOnlyField value={getSubtotalDisplay(0)} />
      </div>
      <div className={PROGRESSIVE_SECTION_CLASS}>
        <div className={CARD_INPUT_CLASS}>
          <h4>Progressive Game #2</h4>
          <DynamicCardInputs
            type={WeeklyBeanoStepTwoField.allProgressive}
            numCardsfieldName={WeeklyBeanoReceiptField.numCards}
            pricePerCardFieldName={
              WeeklyBeanoReceiptField.pricePerCardInDollars
            }
            id={`${WeeklyBeanoStepTwoField.allProgressive}.1`}
            index={1}
          />
        </div>
        <ReadOnlyField value={getSubtotalDisplay(1)} />
      </div>
    </div>
  )
}
