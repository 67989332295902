import { getCleanNumber, sumList } from '@Helpers/numbers'
import {
  DollarizedRaffleStepThreeTotals,
  RaffleStepThreeField,
  RaffleStepThreeFormData
} from './constants'

const getOtherExpensesTotal = (formData: RaffleStepThreeFormData) => {
  const otherExpensesAmounts = formData[RaffleStepThreeField.otherExpenses].map(
    (expense) => expense.amountInDollars
  )
  return sumList(otherExpensesAmounts)
}

export const getRaffleStepThreeTotals = (
  formData: RaffleStepThreeFormData,
  grossReceiptsInDollars: number,
  totalTaxDueInDollars: number
): DollarizedRaffleStepThreeTotals => {
  const otherExpensesTotal = getOtherExpensesTotal(formData)
  const totalExpensesInDollars =
    getCleanNumber(formData[RaffleStepThreeField.totalPrizesAwardedInDollars]) +
    getCleanNumber(totalTaxDueInDollars) +
    otherExpensesTotal
  const netProfitInDollars = grossReceiptsInDollars - totalExpensesInDollars

  return {
    totalExpensesInDollars,
    netProfitInDollars
  }
}
