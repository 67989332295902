import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { getCleanNumber } from '@Helpers/numbers'
import { FundsForDisbursementSection } from './funds-for-dispersment-section'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFieldLabel,
  WeeklyBeanoStepFourFormData
} from '../constants'
import './styles.scss'

export const UnexpectedNetProfit = React.memo(() => {
  const { registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepFourFormData>()
  const unexpendedNetProfitValue = watch(
    WeeklyBeanoStepFourField.unexpendedNetProfitInDollars
  )
  return (
    <FundsForDisbursementSection
      totalDollars={getCleanNumber(unexpendedNetProfitValue)}
      header="1. Unexpended Net Profit"
      includeTotalTitle
    >
      <Input
        {...registerNumber(
          WeeklyBeanoStepFourFieldLabel.unexpendedNetProfit,
          WeeklyBeanoStepFourField.unexpendedNetProfitInDollars
        )}
        type="number"
        moneyField
      />
    </FundsForDisbursementSection>
  )
})
