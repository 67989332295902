import * as React from 'react'

type Props = {
  height?: number
  width?: number
}

export const IconQuestionCircleOutline = ({
  height = 32,
  width = 32
}: Props) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Info</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/help/dark" fill="#0F1821" fillRule="nonzero">
          <g id="Group-15" transform="translate(0.000000, -1.000000)">
            <path
              d="M16.3930095,1.5 C24.6206305,1.5 31.2857143,8.2185392 31.2857143,16.5 C31.2857143,24.781667 24.6207303,31.5 16.3930095,31.5 C12.6157234,31.5 9.05561262,30.0759758 6.32838301,27.5563433 C5.71989548,26.9941739 5.68234721,26.0451689 6.24451661,25.4366814 C6.806686,24.8281939 7.75569094,24.7906456 8.36417847,25.352815 C10.5429753,27.3657615 13.3786116,28.5 16.3930095,28.5 C22.9584985,28.5 28.2857143,23.1301432 28.2857143,16.5 C28.2857143,9.8700526 22.958388,4.5 16.3930095,4.5 C9.8275622,4.5 4.5,9.87012163 4.5,16.5 C4.5,17.5125736 4.62411992,18.5077036 4.86690022,19.4699727 C5.0695615,20.2732285 4.58268343,21.0886854 3.77942762,21.2913467 C2.9761718,21.494008 2.16071488,21.0071299 1.95805359,20.2038741 C1.65486351,19.0021685 1.5,17.7605522 1.5,16.5 C1.5,8.21858732 8.165341,1.5 16.3930095,1.5 Z"
              id="Stroke-1-Copy-5"
              transform="translate(16.392857, 16.500000) rotate(5.000000) translate(-16.392857, -16.500000) "
            ></path>
            <path
              d="M17.6279297,19.5527344 C17.6279297,18.9583333 17.6959635,18.4910482 17.8320313,18.1508789 C17.968099,17.8107096 18.2545573,17.4723307 18.6914063,17.1357422 C19.4934896,16.6129557 20.1612956,15.9648438 20.6948242,15.1914062 C21.2283529,14.4179688 21.4951172,13.5764974 21.4951172,12.6669922 C21.4951172,11.2705078 21.0385742,10.1658529 20.1254883,9.35302734 C19.2124023,8.54020182 17.953776,8.13378906 16.3496094,8.13378906 C14.860026,8.13378906 13.6515299,8.49902344 12.7241211,9.22949219 C11.7967122,9.95996094 11.34375,11.0126953 11.3652344,12.3876953 L11.3652344,12.3876953 L11.3867188,12.4521484 L14.4375,12.4521484 C14.4446615,11.8434245 14.6326497,11.3815104 15.0014648,11.0664062 C15.3702799,10.7513021 15.8196615,10.59375 16.3496094,10.59375 C16.9941406,10.59375 17.4900716,10.7799479 17.8374023,11.1523438 C18.1847331,11.5247396 18.3583984,12.0403646 18.3583984,12.6992188 C18.3583984,13.2936198 18.2062174,13.8307292 17.9018555,14.3105469 C17.5974935,14.7903646 17.1910807,15.2379557 16.6826172,15.6533203 C15.8160807,16.3193359 15.237793,16.8850911 14.9477539,17.3505859 C14.6577148,17.8160807 14.5091146,18.5501302 14.5019531,19.5527344 L14.5019531,19.5527344 L17.6279297,19.5527344 Z M17.6494141,24 L17.6494141,21.3144531 L14.4912109,21.3144531 L14.4912109,24 L17.6494141,24 Z"
              id="?"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
