// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-add{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-column-gap:12px;-moz-column-gap:12px;column-gap:12px;font-family:\"Roboto Slab\",serif}", "",{"version":3,"sources":["webpack://./src/components/button-add/styles.scss","webpack://./src/styles/_constants.scss"],"names":[],"mappings":"AAEA,YACE,mBAAA,CAAA,oBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qCAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,wBAAA,CAAA,0BAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,eAAA,CACA,+BCHY","sourcesContent":["@use 'src/styles/constants' as *;\n\n.button-add {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 12px;\n  font-family: $roboto-slab;\n}\n","@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');\n\n// Typefaces\n$roboto-slab: 'Roboto Slab', serif;\n$roboto: 'Roboto', sans-serif;\n\n// Common Styles\n$default-border-radius: 6px;\n$default-box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.3);\n$default-container-padding: 8px 12px;\n$input-flex-gap: 4px;\n$default-form-section-max-width: 584px;\n\n// Colors\n$primary-teal: #58c2b7;\n$primary-navy: #173e67;\n$light-teal: #d8eae8;\n$secondary-teal: #a7ddd7;\n$separator-green: #1e7e74;\n$button-green: #7fcbc4;\n$input-border-color: #aab3be;\n$almost-black: #121921;\n$error-red: #ff0000;\n$back-office-blue: #1e4b75;\n$back-office-primary-border: #69dbdb;\n$back-office-primary-fill: #059f9f;\n\n// Breakpoints\n$breakpoint-mobile: 640px;\n$breakpoint-tablet: 720px;\n$breakpoint-desktop: 960px;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
