import * as React from 'react'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

export type GrandTotalCashPrizesProps = {
  totalCashPrizeDollars: number
}

const ROOT_CLASS = 'grand-total-cash-prizes'

export const GrandTotalCashPrizes = (props: GrandTotalCashPrizesProps) => {
  return (
    <FormSummary>
      <div className={ROOT_CLASS}>
        <FormSummary.Title>Grand Total Cash Prizes</FormSummary.Title>
      </div>
      <FormSummary.Total>
        {getDollarString(props.totalCashPrizeDollars)}
      </FormSummary.Total>
    </FormSummary>
  )
}
