import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { DeclarationDetails } from '../api/types'

export const BASE_ROOT_CLASS = 'weekly-beano-submit'

export enum WeeklyBeanoSubmitField {
  memberRole = 'memberRole',
  phoneNumber = 'phoneNumber',
  signature = 'signature',
  email = 'email'
}

export type WeeklyBeanoSubmitFormData = DeclarationDetails

export const getEventDate = (dateString: string) => {
  try {
    return dateUtil(dateString, DayFormat.monthDayYear)
  } catch {
    console.error(
      'Could not parse dateString in mm/dd/yyyy format. dateString: ',
      dateString
    )
    return dateUtil()
  }
}
