import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  CasinoStepThreeField,
  CasinoStepThreeFieldLabel,
  CasinoStepThreeFormData
} from '../constants'
import './styles.scss'

export type CasinoDisbursementProps = {
  index: number
  id: string
}

const ROOT_CLASS = 'casino-disbursement'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')
const SPLIT_COLUMN_CLASS = getBlockClass(ROOT_CLASS, 'split-column')

export const CasinoDisbursement = (props: CasinoDisbursementProps) => {
  const { registerDate, registerNumber, register } =
    useFormContextPlus<CasinoStepThreeFormData>()
  const baseFieldName =
    `${CasinoStepThreeField.disbursements}.${props.index}` as const
  return (
    <div className={ROOT_CLASS}>
      <b className={getBlockClass(ROOT_CLASS, 'header')}>New Disbursement</b>
      <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
        <div className={ROW_CLASS}>
          <div className={SPLIT_COLUMN_CLASS}>
            <Input
              {...registerDate(
                CasinoStepThreeFieldLabel.date,
                `${baseFieldName}.date`
              )}
            />
            <Input
              labelText={CasinoStepThreeFieldLabel.checkNumber}
              {...register(`${baseFieldName}.checkNumber`)}
            />
          </div>
          <Input
            labelText={CasinoStepThreeFieldLabel.payee}
            {...register(`${baseFieldName}.payee`)}
          />
        </div>
        <div className={ROW_CLASS}>
          <Input
            labelText={CasinoStepThreeFieldLabel.description}
            {...register(`${baseFieldName}.description`)}
          />
          <Input
            moneyField
            {...registerNumber(
              CasinoStepThreeFieldLabel.amount,
              `${baseFieldName}.amountInDollars`
            )}
          />
        </div>
      </div>
    </div>
  )
}
