import {
  FieldValues,
  UseFormProps,
  RegisterOptions,
  FieldPath
} from '@Hooks/use-form-plus'

const getDefaultUseFormOptions = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  props?: UseFormProps<TFieldValues, TContext>
): UseFormProps<TFieldValues, TContext> => ({
  mode: 'onBlur',
  ...props
})

const getRequiredFieldOptions = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>
>(
  fieldName: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
): RegisterOptions<TFieldValues, TFieldName> => ({
  required: {
    value: true,
    message: `${fieldName} is required.`
  },
  ...options
})

export const ReactHookFormHelpers = {
  getDefaultUseFormOptions,
  getRequiredFieldOptions
}
