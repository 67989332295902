import { transformCentsToDollars } from '@Components/form-general/transformers'
import { DollarizedData } from '@Components/form-general/types'
import {
  defaultDisbursement,
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFormData
} from '@Components/weekly-beano-form/step-4/constants'
import { getDollarsFromCents } from '@Helpers/currency'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import {
  defaultDispositionOfAndAccountingForNetProfitsDetails,
  defaultTotalDisbursementDetails,
  defaultUnexpendedNetProfitDetails
} from '../../default-responses'
import {
  Disbursement,
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormResponse
} from '../../types'

const getWeeklyBeanoDisbursements = (
  dollarizedDisbursements: DollarizedData<Disbursement>[]
): DollarizedData<Disbursement>[] =>
  dollarizedDisbursements.map((disbursement) => ({
    ...defaultDisbursement,
    ...disbursement,
    date: disbursement.date
      ? dateUtil(disbursement.date).format(DayFormat.monthDayYearDoubleDigit)
      : defaultDisbursement.date
  }))

export const transformWeeklyBeanoResponseToStepFourData = (
  response:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoStepFourFormData => {
  const disbursementsDetails =
    response.dispositionOfAndAccountingForNetProfitsDetails ??
    defaultDispositionOfAndAccountingForNetProfitsDetails
  const disbursementsDetailsInDollars =
    transformCentsToDollars(disbursementsDetails)
  const unexpendedNetProfitDetailsInDollars = transformCentsToDollars(
    response.unexpendedNetProfitDetails ?? defaultUnexpendedNetProfitDetails
  )
  const totalDisbursementDetailsInDollars = transformCentsToDollars(
    response.totalDisbursementDetails ?? defaultTotalDisbursementDetails
  )
  totalDisbursementDetailsInDollars.disbursements = getWeeklyBeanoDisbursements(
    totalDisbursementDetailsInDollars.disbursements
  )

  return {
    [WeeklyBeanoStepFourField.disbursements]: totalDisbursementDetailsInDollars
      .disbursements.length
      ? totalDisbursementDetailsInDollars.disbursements
      : [defaultDisbursement],
    ...disbursementsDetailsInDollars,
    ...unexpendedNetProfitDetailsInDollars,
    [WeeklyBeanoStepFourField.totalDepositForOccasionInDollars]:
      getDollarsFromCents(response.totalDepositForOccasionInCents)
  }
}
