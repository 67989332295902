import {
  FieldValues,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn
} from 'react-hook-form'
import {
  RegisterParams,
  UseFormPlusRegisterReturn,
  UseFormPlusRegisters
} from './registers'

const getUseFormPlusMethods = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  methods: UseFormReturn<TFieldValues, TContext>
) => {
  return {
    registerDate: (
      fieldLabel: string,
      ...params: RegisterParams<TFieldValues>
    ): UseFormPlusRegisterReturn =>
      UseFormPlusRegisters.registerDate(methods, fieldLabel, ...params),
    registerRequired: (
      fieldLabel: string,
      ...params: RegisterParams<TFieldValues>
    ): UseFormPlusRegisterReturn =>
      UseFormPlusRegisters.registerRequired(methods, fieldLabel, ...params),
    registerNumber: (
      fieldLabel: string,
      ...params: RegisterParams<TFieldValues>
    ): UseFormPlusRegisterReturn =>
      UseFormPlusRegisters.registerNumber(methods, fieldLabel, ...params),
    registerRequiredNumber: (
      fieldLabel: string,
      ...params: RegisterParams<TFieldValues>
    ): UseFormPlusRegisterReturn =>
      UseFormPlusRegisters.registerRequiredNumber(
        methods,
        fieldLabel,
        ...params
      ),
    registerEmail: (
      fieldLabel: string,
      ...params: RegisterParams<TFieldValues>
    ): UseFormPlusRegisterReturn =>
      UseFormPlusRegisters.registerEmail(methods, fieldLabel, ...params),
    ...methods
  }
}

export const useFormPlus = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  props?: UseFormProps<TFieldValues>
) => getUseFormPlusMethods(useForm<TFieldValues, TContext>(props))

export const useFormContextPlus = <
  TFieldValues extends FieldValues = FieldValues
>() => getUseFormPlusMethods(useFormContext<TFieldValues>())

export * from './use-dynamic-fields'
export * from 'react-hook-form'
