import * as React from 'react'
import { FormProvider } from '@Hooks/use-form-plus'
import { useFormPlus } from '@Hooks/use-form-plus'
import { FormStepProps } from '@Components/form-general/types'
import { RAFFLE_DISPLAY_NUM_STEPS, RAFFLE_NUM_STEPS } from '../constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { NetProfitOrLoss } from '@Components/form-general/net-profit-or-loss/net-profit-or-loss-ui'
import { getRaffleStepThreeTotals } from './helpers'
import { RaffleBasicExpenditures } from './raffle-basic-expenditures-ui'
import { RaffleOtherExpenses } from './other-expenses/raffle-other-expenses-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import {
  defaultRaffleStepThreeFormData,
  DollarizedRaffleStepThreeTotals,
  RaffleStepThreeFormData
} from './constants'
import './styles.scss'

export type RaffleStepThreeProps = FormStepProps<
  RaffleStepThreeFormData,
  DollarizedRaffleStepThreeTotals
> & {
  grossReceiptsInDollars: number
  totalTaxDueInDollars: number
}

const ROOT_CLASS = 'raffle-step-three'
const FORM_ID = ROOT_CLASS
const FORM_STEP = 3

export const RaffleStepThree = (props: RaffleStepThreeProps) => {
  const methods = useFormPlus<RaffleStepThreeFormData>({
    defaultValues: {
      ...defaultRaffleStepThreeFormData,
      ...props.initialValues
    }
  })
  const totals = getRaffleStepThreeTotals(
    methods.watch(),
    props.grossReceiptsInDollars,
    props.totalTaxDueInDollars
  )
  const handleSubmit = async (
    data: RaffleStepThreeFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit(data, totals)
  }
  return (
    <>
      <FormCard
        currentStep={FORM_STEP}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
        title="Schedule 2: Computation of Net Profit or Loss"
      >
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <h3>B. Expenditures</h3>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <FormSubsection type="primary">
                <RaffleBasicExpenditures
                  totalTaxDueInDollars={props.totalTaxDueInDollars}
                />
                <RaffleOtherExpenses />
                <div
                  className={getBlockClass(ROOT_CLASS, 'total-expenditures')}
                >
                  <h2>Total Expenses</h2>
                  <ReadOnlyField
                    noMargin
                    value={getDollarString(totals.totalExpensesInDollars)}
                  />
                </div>
              </FormSubsection>
              <NetProfitOrLoss
                grossReceiptsDollars={props.grossReceiptsInDollars}
                totalExpendituresDollars={totals.totalExpensesInDollars}
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={RAFFLE_NUM_STEPS}
          formId={FORM_ID}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
