import * as React from 'react'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { DynamicElementProps } from '@Components/form-general/types'
import {
  PrizeSummaryDynamicField,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import './styles.scss'

type PrizeDetailAmountField =
  | WeeklyBeanoStepThreeField.spGamePrize
  | WeeklyBeanoStepThreeField.goodNeighborPrize

type PrizeDetailNumGamesField =
  | WeeklyBeanoStepThreeField.spGameNumGames
  | WeeklyBeanoStepThreeField.goodNeighborNumGames

type StaticPrizeDistributionField = {
  type: 'static'
  header: string
  numGamesFieldName: PrizeDetailNumGamesField
  prizeFieldName: PrizeDetailAmountField
  disclaimer?: string
}

type DynamicPrizeDistributionField = {
  type: 'dynamic'
  header?: string
  headerFirstOnly?: string
  name: PrizeSummaryDynamicField
} & DynamicElementProps

export type PrizeDistributionSectionProps = {
  field: DynamicPrizeDistributionField | StaticPrizeDistributionField
}

const ROOT_CLASS = 'prize-distribution-details-section'
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')

const isStaticField = (
  field: PrizeDistributionSectionProps['field']
): field is StaticPrizeDistributionField => field.type === 'static'

export const PrizeDistributionSection = (
  props: PrizeDistributionSectionProps
) => {
  const { registerNumber, register, watch } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const baseFieldName = isStaticField(props.field)
    ? ''
    : (`${props.field.name}.${props.field.index}` as const)
  const numGamesFieldName = isStaticField(props.field)
    ? props.field.numGamesFieldName
    : (`${baseFieldName}.numGames` as const)
  const prizeInDollarsFieldName = isStaticField(props.field)
    ? props.field.prizeFieldName
    : (`${baseFieldName}.prizeInDollars` as const)

  const isOtherGames =
    props.field.type === 'dynamic' &&
    props.field.name === WeeklyBeanoStepThreeField.otherGames

  return (
    <div className={ROOT_CLASS}>
      <div
        key={props.field.header}
        className={getBlockClass(ROOT_CLASS, 'section-row')}
      >
        {props.field.header !== undefined && <h4>{props.field.header}</h4>}
        <div className={getBlockClass(ROOT_CLASS, 'input-row')}>
          <div
            className={joinClasses([
              INPUTS_CLASS,
              getModifierClass(INPUTS_CLASS, 'three-column', isOtherGames)
            ])}
          >
            {isOtherGames && (
              <Input
                {...register(`${baseFieldName}.gameName` as any)}
                labelText={WeeklyBeanoStepThreeFieldLabel.description}
              />
            )}
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.numGames,
                numGamesFieldName as any
              )}
              type="number"
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.prizeAwarded,
                prizeInDollarsFieldName as any
              )}
              moneyField
              type="number"
            />
          </div>
          <ReadOnlyField
            value={getDollarString(watch(prizeInDollarsFieldName as any))}
            className={getBlockClass(ROOT_CLASS, 'total')}
          />
        </div>
      </div>
    </div>
  )
}
