import { DollarizedData } from '@Components/form-general/types'

export enum CasinoOverShortSelection {
  over = 'OVER',
  short = 'SHORT'
}

export type CasinoReceiptsDetails = {
  admissionTicketsInCents: number
  raffleTicketsInCents: number
  gamingTablesInCents: number
  overShortInCents: number
  overShortSelection: CasinoOverShortSelection
  otherReceiptsInCents: number
  otherReceiptsDescription: string
}

export type CashPrizeDetails = {
  numPrizes: number
  amountInCents: number
}

export type CasinoPrizeDetails = {
  description?: string
  valueInCents: number
}

export type CasinoSummaryOfPrizes = {
  cashDoorPrize1: CashPrizeDetails
  cashDoorPrize2: CashPrizeDetails
  cashRafflePrize1: CashPrizeDetails
  cashRafflePrize2: CashPrizeDetails
  merchandiseDoorPrize: CasinoPrizeDetails
  merchandiseRafflePrize: CasinoPrizeDetails
}

export type CasinoPaymentDetails = {
  amountInCents: number
  checkNumber?: string
}

export type EquipmentSuppliesFeeDetails = {
  amountInCents: number
  checkNumber: string
  nameOfFirm: string
}

export type HallRentalFeeDetails = {
  amountInCents: number
  checkNumber?: string
  nameOfHall?: string
  hallAddress?: string
}

export type CasinoOtherExpendituresDetails = {
  checkNumber?: string
  payee?: string
  description?: string
  amountInCents: number
}

export type CasinoExpendituresDetail = {
  chipRedemptionsInCents: number
  taxDueInCents: number
  penaltyAndInterestInCents: number
  totalTaxDue: CasinoPaymentDetails
  equipmentSuppliesFee: EquipmentSuppliesFeeDetails
  hallRentalFee: HallRentalFeeDetails
  otherExpenditures: CasinoOtherExpendituresDetails[]
}

export type CasinoDispositionOfAndAccountForNetProfitDetails = {
  prevSpecialCasinoCheckingBalanceInCents: number
  netProfitInCents: number
  otherIncomeInCents: number
  otherIncomeDescription?: string
}

export type DisbursementDetails = {
  date: string
  checkNumber?: string
  payee: string
  description?: string
  amountInCents: number
}

export type CasinoTotalDisbursementDetails = {
  disbursements: DisbursementDetails[]
}

export type CasinoDeclarationDetails = {
  memberRole: string
  phoneNumber: string
  signature: string
  date: string
  email: string
}

export type CasinoFormSubmissionBody = {
  raffleId: string
  occasionDate: string
  occasionDay: string
  occasionStartTime: string
  occasionEndTime: string
  numPlayers: number
  receiptsDetails: CasinoReceiptsDetails
  grossReceiptsInCents: number
  summaryOfPrizes: CasinoSummaryOfPrizes
  grandTotalCashPrizesInCents: number
  expendituresDetails: CasinoExpendituresDetail
  totalExpendituresInCents: number
  netProfitInCents: number
  dispositionOfAndAccountingForNetProfitsDetails: CasinoDispositionOfAndAccountForNetProfitDetails
  fundsAvailableForDisbursementInCents: number
  totalDisbursementDetails: CasinoTotalDisbursementDetails
  totalDisbursementsInCents: number
  specialCasinoCheckingBalanceInCents: number
  declarationDetails: CasinoDeclarationDetails
}

export type CasinoFormBodyResponse = CasinoFormSubmissionBody & {
  taxFormId: string
  submissionDate: string
}

export type CasinoFormGetResponse = {
  taxForm: CasinoFormBodyResponse
}

export type DollarizedCasinoFormSubmissionBody =
  DollarizedData<CasinoFormSubmissionBody>
