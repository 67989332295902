import { getDollarString } from '@Helpers/currency'
import { sumList } from '@Helpers/numbers'
import { GetValueFunction } from '@Services/react-hook-form/constants'
import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFormData
} from '../constants'

const isValidTotalBalance = (
  expectedTotalBalance: number,
  specialBeanoCheckbookBalanceInDollars?: number,
  specialBeanoSavingsBalanceInDollars?: number
) => {
  const currentBalance = sumList([
    specialBeanoCheckbookBalanceInDollars ?? 0,
    specialBeanoSavingsBalanceInDollars ?? 0
  ])
  return currentBalance === expectedTotalBalance
}

const matchesExpectedTotalBalance = (
  expectedTotalBalance: number,
  getValues: GetValueFunction<AnnualBeanoStepThreeFormData>
) => {
  const [specialBeanoCheckbookValue, specialBeanoSavingsValue] = getValues([
    AnnualBeanoStepThreeField.specialBeanoCheckbookBalanceInDollars,
    AnnualBeanoStepThreeField.specialBeanoSavingsBalanceInDollars
  ])
  const isValid = isValidTotalBalance(
    expectedTotalBalance,
    specialBeanoCheckbookValue,
    specialBeanoSavingsValue
  )
  return {
    isValid,
    errorMessage: isValid
      ? undefined
      : `Total Balance should equal ${getDollarString(expectedTotalBalance)}.`
  }
}

export const AnnualBeanoTotalBalanceValidation = {
  isValidTotalBalance,
  matchesExpectedTotalBalance
}
