import * as React from 'react'
import { FieldValues, Path } from '@Hooks/use-form-plus'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

type IncomeFieldInput<FieldName extends string, FieldLabel extends string> = {
  name: FieldName
  label: FieldLabel
}

export type InputNumberAndTextProps<
  FormData extends FieldValues,
  FieldName extends Path<FormData>,
  FieldLabel extends string
> = {
  header: string
  headerSupplement?: string
  numberField: IncomeFieldInput<FieldName, FieldLabel>
  textField?: IncomeFieldInput<FieldName, FieldLabel>
  subtotalValue: 'watch' | string
  subtotalHeader?: string
}

const ROOT_CLASS = 'input-number-and-text'

export const InputNumberAndText = <
  FormData extends FieldValues,
  FieldName extends Path<FormData>,
  FieldLabel extends string
>(
  props: InputNumberAndTextProps<FormData, FieldName, FieldLabel>
) => {
  const { registerNumber, register, watch } = useFormContextPlus<FormData>()
  const displayValue =
    props.subtotalValue === 'watch'
      ? getDollarString(watch(props.numberField.name))
      : props.subtotalValue

  return (
    <article className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
        <h4>{props.header}</h4>
        {props.headerSupplement && (
          <p className={getBlockClass(ROOT_CLASS, 'header-supplement')}>
            {props.headerSupplement}
          </p>
        )}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
          <Input
            {...registerNumber(props.numberField.label, props.numberField.name)}
            moneyField
          />
          {props.textField && (
            <Input
              {...register(props.textField.name)}
              labelText={props.textField.label}
            />
          )}
        </div>
        <ReadOnlyField
          noMargin={!Boolean(props.subtotalHeader)}
          alignRight
          value={displayValue}
          label={props.subtotalHeader}
        />
      </div>
    </article>
  )
}
