import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import {
  WeeklyBeanoStepTwoFieldLabels,
  WeeklyBeanoStepTwoFormData,
  WeeklyBeanoStepTwoStaticData
} from '../constants'
import { CARD_INPUTS_ROOT_CLASS } from './constants'
import './styles.scss'

export type CardInputsProps = {
  numCardsfieldName: keyof WeeklyBeanoStepTwoStaticData
  pricePerCardFieldName: keyof WeeklyBeanoStepTwoStaticData
}

export const StaticCardInputs = (props: CardInputsProps) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  return (
    <div className={CARD_INPUTS_ROOT_CLASS}>
      <Input
        {...registerNumber(
          WeeklyBeanoStepTwoFieldLabels.numCards,
          props.numCardsfieldName
        )}
      />
      <Input
        {...registerNumber(
          WeeklyBeanoStepTwoFieldLabels.pricePerCard,
          props.pricePerCardFieldName
        )}
        moneyField
      />
    </div>
  )
}
