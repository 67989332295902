import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFieldLabel,
  WeeklyBeanoStepFourFormData
} from '../constants'
import './styles.scss'

export type Props = {
  index: number
  id: string
}

const ROOT_CLASS = 'disbursement'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')

export const Disbursement = (props: Props) => {
  const {
    formState: { errors },
    register,
    registerNumber,
    registerDate
  } = useFormContextPlus<WeeklyBeanoStepFourFormData>()
  const baseFieldName =
    `${WeeklyBeanoStepFourField.disbursements}.${props.index}` as const
  return (
    <div className={ROOT_CLASS}>
      <div className={ROW_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'row-group')}>
          <Input
            {...registerDate(
              WeeklyBeanoStepFourFieldLabel.date,
              `${baseFieldName}.date`
            )}
            error={
              errors[WeeklyBeanoStepFourField.disbursements]?.[props.index]
                ?.date?.message
            }
            placeholder="mm/dd/yyyy"
          />
          <Input
            {...register(`${baseFieldName}.checkNumber`)}
            labelText={WeeklyBeanoStepFourFieldLabel.checkNumber}
          />
        </div>
        <Input
          {...register(`${baseFieldName}.payee`)}
          labelText={WeeklyBeanoStepFourFieldLabel.payee}
        />
      </div>
      <div className={ROW_CLASS}>
        <Input
          {...register(`${baseFieldName}.description`)}
          labelText={WeeklyBeanoStepFourFieldLabel.description}
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.amount,
            `${baseFieldName}.amountInDollars`
          )}
          moneyField
        />
      </div>
    </div>
  )
}
