import * as React from 'react'
import { useFieldArray, useWatch } from '@Hooks/use-form-plus'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { getCleanNumber } from '@Helpers/numbers'
import { getDollarString } from '@Helpers/currency'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { ProgressiveProfitToLicensee } from './division-of-progressive-receipts-ui'
import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { ProgressiveBank } from './progressive-bank-ui'
import {
  FormPrizeSummaryProgressiveGameDetails,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import './styles.scss'
import { getLicenseeProfitInDollars } from './constants'

type ProgressiveGamesSummaryProps = {
  progressiveOneProceedsInDollars: number
  progressiveTwoProceedsInDollars: number
}

type SectionProps = {
  sectionNumber: 1 | 2
  proceedsFromReceiptsInDollars: number
}

const ROOT_CLASS = 'weekly-beano-step-three-progressive-games'

const ProgressiveGamesSection = (props: SectionProps) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const { fields } = useFieldArray<WeeklyBeanoStepThreeFormData>({
    name: WeeklyBeanoStepThreeField.progressiveGames
  })
  const index = props.sectionNumber - 1
  const thisField = fields[index]
  const thisValue: FormPrizeSummaryProgressiveGameDetails =
    useWatch<WeeklyBeanoStepThreeFormData>({
      name: WeeklyBeanoStepThreeField.progressiveGames
    })[index]

  const prizesPaidDollars = getCleanNumber(thisValue.prizesPaidInDollars)
  const taxPaidInDollars = calculateTaxPaidDollars(
    props.proceedsFromReceiptsInDollars
  )
  const profitToLicenseeDollars = getLicenseeProfitInDollars(
    props.proceedsFromReceiptsInDollars,
    taxPaidInDollars,
    prizesPaidDollars
  )

  return (
    <div key={thisField.id} className={getBlockClass(ROOT_CLASS, 'section')}>
      <p
        className={getBlockClass(ROOT_CLASS, 'section-header')}
      >{`Progressive #${props.sectionNumber}`}</p>
      <div className={getBlockClass(ROOT_CLASS, 'section-content')}>
        <div className={getBlockClass(ROOT_CLASS, 'row-with-total')}>
          <div className={getBlockClass(ROOT_CLASS, 'input-row')}>
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.numCalls,
                `${WeeklyBeanoStepThreeField.progressiveGames}.${index}.numCalls`
              )}
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.numGames,
                `${WeeklyBeanoStepThreeField.progressiveGames}.${index}.numGames`
              )}
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.prizesPaid,
                `${WeeklyBeanoStepThreeField.progressiveGames}.${index}.prizesPaidInDollars`
              )}
              moneyField
            />
          </div>
          <ReadOnlyField
            value={getDollarString(prizesPaidDollars)}
            className={getBlockClass(ROOT_CLASS, 'total')}
          />
        </div>
        {prizesPaidDollars > 0 && (
          <div className={getBlockClass(ROOT_CLASS, 'subsection-container')}>
            <ProgressiveBank fieldIndex={index} />
            <ProgressiveProfitToLicensee
              fieldIndex={index}
              taxPaidInDollars={taxPaidInDollars}
              totalPrizePaidInDollars={prizesPaidDollars}
              profitToLicenseeInDollars={profitToLicenseeDollars}
              proceedsFromReceiptsInDollars={
                props.proceedsFromReceiptsInDollars
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const ProgressiveGamesSummary = (
  props: ProgressiveGamesSummaryProps
) => {
  return (
    <FormSubsection type="secondary">
      <h4 className={getBlockClass(ROOT_CLASS, 'header')}>Progressive Games</h4>
      <ProgressiveGamesSection
        sectionNumber={1}
        proceedsFromReceiptsInDollars={props.progressiveOneProceedsInDollars}
      />
      <ProgressiveGamesSection
        sectionNumber={2}
        proceedsFromReceiptsInDollars={props.progressiveTwoProceedsInDollars}
      />
    </FormSubsection>
  )
}
