import * as React from 'react'
import { PrizeDistributionSection } from './prize-distribution-section'
import { PrizeDistributionDynamicSection } from './prize-distribution-dynamic-section-ui'
import { WeeklyBeanoStepThreeField } from '../../constants'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { PrizeDistributionGame } from './prize-distribution-game-names'
import { PrizeDistributionBreakage } from './prize-distribution-breakage'
import './styles.scss'

const ROOT_CLASS = 'prize-distribution-details'

export const PrizeDistributionDetails = () => {
  return (
    <div className={ROOT_CLASS}>
      <FormSubsection type="secondary">
        <PrizeDistributionSection
          field={{
            type: 'static',
            header: PrizeDistributionGame.spGame,
            numGamesFieldName: WeeklyBeanoStepThreeField.spGameNumGames,
            prizeFieldName: WeeklyBeanoStepThreeField.spGamePrize
          }}
        />
      </FormSubsection>
      <FormSubsection type="secondary">
        <PrizeDistributionSection
          field={{
            type: 'static',
            header: `${PrizeDistributionGame.goodNeighbor} *`,
            numGamesFieldName: WeeklyBeanoStepThreeField.goodNeighborNumGames,
            prizeFieldName: WeeklyBeanoStepThreeField.goodNeighborPrize,
            disclaimer:
              '* Good Neighbor prize may not exceed 10% of the announced prize for a given game.'
          }}
        />
      </FormSubsection>
      <FormSubsection type="secondary">
        <PrizeDistributionDynamicSection
          subtotalHeader={'Total Other Game Prizes'}
          resetButtonText={'Reset Other Games'}
          addButtonText={'Add Another Game'}
          fieldName={WeeklyBeanoStepThreeField.otherGames}
        />
      </FormSubsection>
      <FormSubsection type="secondary">
        <PrizeDistributionDynamicSection
          subtotalHeader={'Total 50/50 Prizes'}
          resetButtonText={'Reset 50/50'}
          addButtonText={'Add Another 50/50'}
          fieldName={WeeklyBeanoStepThreeField.fiftyFifty}
        />
      </FormSubsection>
      <FormSubsection type="secondary">
        <PrizeDistributionBreakage />
      </FormSubsection>
    </div>
  )
}
