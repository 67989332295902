import * as React from 'react'
import { useFormErrorModal } from '@Hooks/use-form-error-modal'
import { FormProps } from '@Components/form-general/types'
import {
  WeeklyBeanoFormBodySubmission,
  WeeklyBeanoFormResponse
} from './api/types'
import {
  WeeklyBeanoFormAction,
  WeeklyBeanoFormPayload,
  WeeklyBeanoTotal
} from './form-state-reducer/actions'
import { WeeklyBeanoFormReducer } from './form-state-reducer/reducer'
import {
  isCompleteWeaklyBeanoForm,
  WeeklyBeanoFormStep
} from './form-state-reducer/shape'
import { WeeklyBeanoStepOne } from './step-1/weekly-beano-one-ui'
import { WeeklyBeanoStepThree } from './step-3/weekly-beano-three-ui'
import { WeeklyBeanoStepTwo } from './step-2/weekly-beano-two-ui'
import { getNumWinnerTakeAllSections } from './step-2/helpers'
import { WeeklyBeanoStepFour } from './step-4/weekly-beano-four-ui'
import { WeeklyBeanoSubmit } from './sign-and-submit/weekly-beano-submit-ui'
import { getEventDate } from './sign-and-submit/constants'
import {
  transformWeeklyBeanoResponseToFormState,
  transformWeeklyBeanoStateToApiRequest
} from './api/transformers'
import './styles.scss'

export type WeeklyBeanoFormProps = Omit<
  FormProps<WeeklyBeanoFormBodySubmission>,
  'initialValues'
> & {
  dateString: string
  initialValues?: WeeklyBeanoFormResponse
  occasionTime: string // 'AFTERNOON' | 'EVENING'
}

const ROOT_CLASS = 'weekly-beano-form'
const LAST_STEP = 5

export const WeeklyBeanoForm = (props: WeeklyBeanoFormProps) => {
  const handleSubmit = async () => {
    if (!isCompleteWeaklyBeanoForm(state)) {
      return
    }
    const transformedPayload = transformWeeklyBeanoStateToApiRequest(
      props.dateString,
      props.organizationDetails,
      props.occasionTime,
      state
    )
    await props.onSubmit(transformedPayload)
  }
  const { SubmissionErrorModal: ErrorModal } = useFormErrorModal({
    error: props.error,
    component: true,
    handleSubmit: handleSubmit
  })
  const [state, dispatch] = React.useReducer(
    WeeklyBeanoFormReducer,
    transformWeeklyBeanoResponseToFormState(props.initialValues)
  )

  const decrementStep = () =>
    dispatch(WeeklyBeanoFormAction.changeFormStep(state.currentStep - 1))

  React.useEffect(() => {
    if (state.currentStep > LAST_STEP) {
      handleSubmit()
      decrementStep()
    }
  }, [state.currentStep, decrementStep])

  const updateFormData = <Step extends WeeklyBeanoFormStep>(
    step: Step,
    payload: WeeklyBeanoFormPayload[Step],
    totals?: WeeklyBeanoTotal<Step>
  ) => dispatch(WeeklyBeanoFormAction.updateFormStep(step, payload, totals))

  const eventDate = React.useMemo(
    () => getEventDate(props.dateString),
    [props.dateString]
  )

  const numWinnerTakeAllSections = getNumWinnerTakeAllSections(
    state[WeeklyBeanoFormStep.StepTwo]
  )

  const getFormBody = () => {
    switch (state.currentStep) {
      case 1:
        return (
          <WeeklyBeanoStepOne
            {...props.organizationDetails}
            occasionTime={props.occasionTime}
            dateString={props.dateString}
            initialValues={state[WeeklyBeanoFormStep.StepOne]}
            backOffice={props.backOffice}
            onSubmit={(formData) =>
              updateFormData(WeeklyBeanoFormStep.StepOne, formData)
            }
          />
        )
      case 2:
        return (
          <WeeklyBeanoStepTwo
            onBackClick={decrementStep}
            backOffice={props.backOffice}
            initialValues={state[WeeklyBeanoFormStep.StepTwo]}
            onSubmit={(formData, totals) =>
              updateFormData(WeeklyBeanoFormStep.StepTwo, formData, totals)
            }
          />
        )
      case 3:
        return (
          <WeeklyBeanoStepThree
            numWinnerTakeAllSections={numWinnerTakeAllSections}
            mslcTaxPaidInDollars={state.totals.mslcTaxPaidInDollars}
            date={props.dateString}
            initialValues={state[WeeklyBeanoFormStep.StepThree]}
            grossBeanoReceiptsInDollars={
              state.totals.grossBeanoReceiptsInDollars
            }
            progressiveOneProceedsInDollars={
              state.totals.progressiveOneProceedsInDollars
            }
            progressiveTwoProceedsInDollars={
              state.totals.progressiveTwoProceedsInDollars
            }
            onBackClick={decrementStep}
            backOffice={props.backOffice}
            onSubmit={(formData, totals) => {
              updateFormData(WeeklyBeanoFormStep.StepThree, formData, totals)
            }}
          />
        )
      case 4:
        return (
          <WeeklyBeanoStepFour
            onSubmit={(formData, totals) =>
              updateFormData(WeeklyBeanoFormStep.StepFour, formData, totals)
            }
            initialValues={state[WeeklyBeanoFormStep.StepFour]}
            netProfitOrLossInDollars={state.totals.netProfitInDollars}
            backOffice={props.backOffice}
            defaultGrandTotalCashPrizeInDollars={
              state.totals.beanoGrandTotalCashPrizesInDollars
            }
            grossBeanoReceiptsInDollars={
              state.totals.grossBeanoReceiptsInDollars
            }
            onBackClick={decrementStep}
          />
        )
      case LAST_STEP:
      default:
        return (
          <WeeklyBeanoSubmit
            eventDate={eventDate}
            submitDisabled={props.submitDisabled}
            onBackClick={decrementStep}
            initialValues={state[WeeklyBeanoFormStep.Submit]}
            backOffice={props.backOffice}
            onSubmit={async (formData) => {
              if (props.error) {
                await handleSubmit()
              } else {
                updateFormData(WeeklyBeanoFormStep.Submit, formData)
              }
            }}
          />
        )
    }
  }
  return (
    <section className={ROOT_CLASS}>
      {ErrorModal}
      {getFormBody()}
    </section>
  )
}
