import {
  RaffleFormActivityType,
  RaffleFormActivityTypeField,
  RaffleFormActivityTypeFormData
} from '@Components/raffle-form/activity-type/constants'
import { RaffleFormStep } from '@Components/raffle-form/form-state-reducer/shape'
import {
  RaffleStepOneField,
  RaffleStepOneFormData
} from '@Components/raffle-form/step-1/constants'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { RaffleFormResponse } from '../../types'

type RaffleStateStepOneAndType = {
  [RaffleFormStep.ActivityType]: RaffleFormActivityTypeFormData
  [RaffleFormStep.StepOne]: RaffleStepOneFormData
}

const getActivityType = (
  activityTypeResponse: string
): RaffleFormActivityTypeFormData => {
  if (
    activityTypeResponse === RaffleFormActivityType.raffle ||
    activityTypeResponse === RaffleFormActivityType.bazaar
  ) {
    return {
      [RaffleFormActivityTypeField.activityType]: activityTypeResponse
    }
  }
  return {
    [RaffleFormActivityTypeField.activityType]: RaffleFormActivityType.other,
    [RaffleFormActivityTypeField.otherExplained]: activityTypeResponse
  }
}

export const transformRaffleResponseToStepOneAndType = (
  initialValues: RaffleFormResponse
): RaffleStateStepOneAndType => {
  return {
    [RaffleFormStep.ActivityType]: getActivityType(initialValues.activityType),
    [RaffleFormStep.StepOne]: {
      [RaffleStepOneField.occasionDate]: dateUtil(
        initialValues.occasionDate
      ).format(DayFormat.monthDayYearDoubleDigit),
      [RaffleStepOneField.orgFid]: initialValues.orgFid
    }
  }
}
