import * as React from 'react'
import { getObjectKeys } from '@Helpers/util'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

type SelectElementProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

export type DropdownOptions = Record<string, string>
export type Props = {
  placeholder?: string
  options: DropdownOptions
  className?: string
  error?: string
  isPlaceholderValue?: boolean
} & Pick<SelectElementProps, 'name' | 'disabled' | 'onBlur' | 'onChange'>

const ROOT_CLASS = 'dropdown-selector'

export const DropdownSelector = React.forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    // Not a controlled component; this is used for placeholder styling when props.isPlaceholderValue is undefined
    const [valueCopy, setValueCopy] = React.useState<string>()
    const [isFocused, setFocused] = React.useState(false)
    const isPlaceholderValue = !!props.placeholder && !isFocused && !valueCopy

    const placeholderClass = getModifierClass(
      ROOT_CLASS,
      'is-placeholder',
      props.isPlaceholderValue !== undefined
        ? props.isPlaceholderValue
        : isPlaceholderValue
    )
    const errorClass = getModifierClass(
      ROOT_CLASS,
      'error',
      Boolean(props.error)
    )
    return (
      <div
        className={joinClasses([
          ROOT_CLASS,
          errorClass,
          placeholderClass,
          props.className
        ])}
      >
        <select
          ref={ref}
          id={props.name}
          name={props.name}
          disabled={props.disabled}
          aria-labelledby={`${props.name}-label`}
          onChange={(e) => {
            setValueCopy(e.target.value)
            props.onChange?.(e)
          }}
          onBlur={(e) => {
            setFocused(false)
            props.onBlur?.(e)
          }}
          onFocus={(e) => {
            setFocused(true)
          }}
        >
          {props.placeholder && (
            <option value={''} disabled={isFocused}>
              {props.placeholder}
            </option>
          )}
          {getObjectKeys(props.options).map((display: string) => (
            <option value={props.options[display]} key={display}>
              {display}
            </option>
          ))}
        </select>
      </div>
    )
  }
)
