import { DollarizedRaffleStepTwoTotals } from '../step-2/constants'
import { DollarizedRaffleStepThreeTotals } from '../step-3/constants'
import { CompleteRaffleFormState, RaffleFormStep } from './shape'

export enum RaffleFormActions {
  UPDATE_FORM_STATE = 'UPDATE_FORM_STATE',
  CHANGE_FORM_STEP = 'CHANGE_FORM_STEP'
}

export type RaffleFormPayload = Omit<
  CompleteRaffleFormState,
  'totals' | 'currentStep'
>

export type RaffleFormTotals = {
  [RaffleFormStep.StepTwo]: DollarizedRaffleStepTwoTotals
  [RaffleFormStep.StepThree]: DollarizedRaffleStepThreeTotals
}

type StepWithTotals = keyof RaffleFormTotals

export type RaffleTotal<Step extends RaffleFormStep> =
  Step extends StepWithTotals ? RaffleFormTotals[Step] : undefined

export type ChangeRaffleFormStep = {
  type: RaffleFormActions.CHANGE_FORM_STEP
  step: number
}

export type UpdateRaffleFormAction<Step extends RaffleFormStep> = {
  type: RaffleFormActions.UPDATE_FORM_STATE
  step: Step
  payload: RaffleFormPayload[Step]
  totals?: RaffleTotal<Step>
}

export type RaffleFormAction<Step extends RaffleFormStep> =
  | ChangeRaffleFormStep
  | UpdateRaffleFormAction<Step>

const changeFormStep = (step: number): ChangeRaffleFormStep => ({
  type: RaffleFormActions.CHANGE_FORM_STEP,
  step
})

const updateFormStep = <Step extends RaffleFormStep>(
  step: Step,
  payload: RaffleFormPayload[Step],
  totals?: RaffleTotal<Step>
): UpdateRaffleFormAction<Step> => ({
  type: RaffleFormActions.UPDATE_FORM_STATE,
  step,
  payload,
  totals
})

export const RaffleFormAction = {
  changeFormStep,
  updateFormStep
}
