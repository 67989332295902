import { joinClasses } from '@Helpers/components'
import * as React from 'react'
import './styles.scss'

export type Props = {
  onClick: () => void
  className?: string
}

const ROOT_CLASS = 'button-delete'

const ButtonIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Delete</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-4" fill="#0F1821" transform="translate(4.000000, 0.000000)">
        <path
          d="M22.9597523,8.00830054 L22.9597523,29.9999995 C22.9597523,31.104569 22.0643218,31.9999995 20.9597523,31.9999995 L4.45201238,31.9999995 C3.34744288,31.9999995 2.45201238,31.104569 2.45201238,29.9999995 L2.45201238,8.00830054 L22.9597523,8.00830054 Z M8.71826625,13.3015769 C7.93857014,13.3015769 7.30650155,13.9336455 7.30650155,14.7133416 L7.30650155,14.7133416 L7.30650155,25.2614576 L7.31296421,25.39742 C7.38137338,26.1133277 7.98443461,26.6732223 8.71826625,26.6732223 C9.49796237,26.6732223 10.130031,26.0411537 10.130031,25.2614576 L10.130031,25.2614576 L10.130031,14.7133416 L10.1235683,14.5773793 C10.0551591,13.8614716 9.45209789,13.3015769 8.71826625,13.3015769 Z M16.6934985,13.3015769 C15.9138023,13.3015769 15.2817337,13.9336455 15.2817337,14.7133416 L15.2817337,14.7133416 L15.2817337,25.2614576 L15.2881964,25.39742 C15.3566056,26.1133277 15.9596668,26.6732223 16.6934985,26.6732223 C17.4731946,26.6732223 18.1052632,26.0411537 18.1052632,25.2614576 L18.1052632,25.2614576 L18.1052632,14.7133416 L18.0988005,14.5773793 C18.0303913,13.8614716 17.4273301,13.3015769 16.6934985,13.3015769 Z"
          id="Combined-Shape"
        ></path>
        <path
          d="M14.3281734,0.470588235 C15.6276669,0.470588235 16.6811146,1.52403588 16.6811146,2.82352941 L16.6811146,2.82352941 L16.6811146,3.39155551 C16.6811146,3.43901644 16.6797094,3.48614916 16.6769383,3.53291435 L23.5294118,3.53356203 C24.3091079,3.53356203 24.9411765,4.16563062 24.9411765,4.94532674 C24.9411765,5.67915838 24.3812818,6.28221961 23.6653741,6.35062878 L23.5294118,6.35709145 L1.88235294,6.35709145 C1.10265682,6.35709145 0.470588235,5.72502286 0.470588235,4.94532674 C0.470588235,4.2114951 1.0304829,3.60843387 1.74639058,3.5400247 L1.88235294,3.53356203 L8.73458824,3.53258824 L8.73065015,3.39155551 L8.73065015,2.82352941 C8.73065015,1.52403588 9.7840978,0.470588235 11.0835913,0.470588235 L11.0835913,0.470588235 Z"
          id="Combined-Shape"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export const ButtonDelete = (props: Props) => (
  <button
    className={joinClasses([ROOT_CLASS, props.className])}
    onClick={props.onClick}
    type="button"
    aria-label="delete"
  >
    <ButtonIcon />
  </button>
)
