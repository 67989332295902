import { WeeklyBeanoTotals } from '@Components/weekly-beano-form/form-state-reducer/shape'
import { getDollarsFromCents } from '@Helpers/currency'
import {
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormResponse
} from '../../types'
import { transformCentsToDollars } from '@Components/form-general/transformers'
import { sumList } from '@Helpers/numbers'
import {
  getExpectedUnexpendedNetProfitInDollars,
  getNetProceedsFromCharityGamesDollars
} from '@Components/weekly-beano-form/step-4/helpers'
import { defaultDispositionOfAndAccountingForNetProfitsDetails } from '../../default-responses'

export const transformWeeklyBeanoResponseToTotals = (
  response:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoTotals => {
  const fundsAvailableForDisbursementInDollars = getDollarsFromCents(
    response.fundsAvailableForDisbursementInCents
  )
  const totalDisbursementsInDollars = getDollarsFromCents(
    response.totalDisbursementsInCents
  )
  const expectedUnexpendedNetProfitInDollars =
    getExpectedUnexpendedNetProfitInDollars(
      fundsAvailableForDisbursementInDollars,
      totalDisbursementsInDollars
    )
  const disbursementsDetails =
    response.dispositionOfAndAccountingForNetProfitsDetails ??
    defaultDispositionOfAndAccountingForNetProfitsDetails
  const disbursementsDetailsInDollars =
    transformCentsToDollars(disbursementsDetails)

  const cgTicketNetProceedsInDollars = getNetProceedsFromCharityGamesDollars(
    disbursementsDetailsInDollars.nonBeanoCgtProceedsInDollars,
    disbursementsDetailsInDollars.cgtProceedsInDollars
  )

  const otherIncomeInDollars = sumList([
    disbursementsDetailsInDollars.interestInDollars,
    disbursementsDetailsInDollars.gameBankInDollars,
    disbursementsDetailsInDollars.othersInDollars
  ])
  return {
    specialGamesReceiptsTotalInDollars: getDollarsFromCents(
      response.beanoReceiptsDetails?.specialGamesReceiptsTotalInCents ?? 0
    ),
    grossBeanoReceiptsInDollars: getDollarsFromCents(
      response.grossBeanoReceiptsInCents
    ),
    netProfitInDollars: getDollarsFromCents(response.netProfitInCents),
    progressiveOneProceedsInDollars: getDollarsFromCents(
      response.beanoExpendituresDetails?.beanoPrizeSummary
        ?.progressiveGame1Details?.grossBeanoReceiptsInCents ?? 0
    ),
    progressiveTwoProceedsInDollars: getDollarsFromCents(
      response.beanoExpendituresDetails?.beanoPrizeSummary
        ?.progressiveGame2Details?.grossBeanoReceiptsInCents ?? 0
    ),
    beanoGrandTotalCashPrizesInDollars: getDollarsFromCents(
      response.beanoExpendituresDetails?.beanoGrandTotalCashPrizesInCents ?? 0
    ),
    mslcTaxPaidInDollars: getDollarsFromCents(
      response.beanoExpendituresDetails?.mslcTaxPaidInCents ?? 0
    ),
    totalBeanoExpendituresInDollars: getDollarsFromCents(
      response.totalBeanoExpendituresInCents ?? 0
    ),
    totalDepositForOccasionInDollars: getDollarsFromCents(
      response.totalDepositForOccasionInCents
    ),
    fundsAvailableForDisbursementInDollars,
    totalDisbursementsInDollars,
    expectedUnexpendedNetProfitInDollars,
    cgTicketNetProceedsInDollars,
    otherIncomeInDollars
  }
}
