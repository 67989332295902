import * as React from 'react'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

export type Props = {
  label?: string
  value: string
  className?: string
  alignRight?: boolean
  noMargin?: boolean
}

const ROOT_CLASS = 'read-only-field'

export const ReadOnlyField = (props: Props) => (
  <div
    className={joinClasses([
      ROOT_CLASS,
      props.className,
      getModifierClass(ROOT_CLASS, 'right-aligned', props.alignRight === true),
      getModifierClass(ROOT_CLASS, 'no-label', !props.label),
      getModifierClass(ROOT_CLASS, 'no-margin', props.noMargin === true)
    ])}
  >
    {props.label && <label>{props.label}</label>}
    <p>{props.value}</p>
  </div>
)
