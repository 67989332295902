import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'

export enum SubmitFieldLabel {
  memberRole = 'Member Role',
  phoneNumber = 'Phone No.',
  signature = 'Signature',
  date = 'Date',
  email = 'Email'
}

export enum MemberRole {
  memberInCharge = 'Member in Charge',
  bookkeeper = 'Bookkeeper',
  headOfOrganization = 'Head of Organization'
}

export const memberRoleOptions: DropdownOptions = {
  [MemberRole.memberInCharge]: 'Member in Charge',
  [MemberRole.bookkeeper]: 'Bookkeeper',
  [MemberRole.headOfOrganization]: 'Head of Organization'
}
