import {
  FieldValues,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormReturn
} from 'react-hook-form'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'

export type RegisterParams<TFieldValues extends FieldValues> = Parameters<
  UseFormRegister<TFieldValues>
>

export type UseFormPlusRegisterReturn = UseFormRegisterReturn & {
  labelText: string
  type?: React.HTMLInputTypeAttribute
  error?: string
  placeholder?: string
}

const EMAIL_REGEX = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) // https://ui.dev/validate-email-address-javascript/

const registerRequired = <TFieldValues extends FieldValues, TContext = any>(
  methods: UseFormReturn<TFieldValues, TContext>,
  fieldLabel: string,
  ...params: RegisterParams<TFieldValues>
): UseFormPlusRegisterReturn => {
  const fieldName = params[0]
  const options = params[1]
  return {
    labelText: fieldLabel,
    error: methods.formState.errors[fieldName]?.message,
    required: true,
    ...methods.register(fieldName, {
      ...options,
      required: {
        value: true,
        message: `${fieldLabel} is required.`
      }
    })
  }
}

const registerNumber = <TFieldValues extends FieldValues, TContext = any>(
  methods: UseFormReturn<TFieldValues, TContext>,
  fieldLabel: string,
  ...params: RegisterParams<TFieldValues>
): UseFormPlusRegisterReturn => {
  const fieldName = params[0]
  const options = params[1]
  return {
    type: 'number',
    labelText: fieldLabel,
    error: methods.formState.errors[fieldName]?.message,
    ...methods.register(fieldName, {
      ...options,
      valueAsNumber: true
    })
  }
}

const registerDate = <TFieldValues extends FieldValues, TContext = any>(
  methods: UseFormReturn<TFieldValues, TContext>,
  fieldLabel: string,
  ...params: RegisterParams<TFieldValues>
): UseFormPlusRegisterReturn => {
  const fieldName = params[0]
  const options = params[1]
  return {
    labelText: fieldLabel,
    error: methods.formState.errors[fieldName]?.message,
    placeholder: 'mm/dd/yyyy',
    ...methods.register(fieldName, {
      ...options,
      validate: {
        isValidDate: (value: string) => {
          if (!value) {
            return true
          }
          const isValid =
            dateUtil(
              value,
              DayFormat.monthDayYearDoubleDigit,
              true
            ).isValid() ||
            dateUtil(value, DayFormat.monthDayYear, true).isValid()
          return isValid
            ? true
            : `${fieldLabel} is not a valid mm/dd/yyyy date.`
        }
      }
    })
  }
}

const registerRequiredNumber = <
  TFieldValues extends FieldValues,
  TContext = any
>(
  methods: UseFormReturn<TFieldValues, TContext>,
  fieldLabel: string,
  ...params: RegisterParams<TFieldValues>
): UseFormPlusRegisterReturn => {
  const fieldName = params[0]
  const options = params[1]
  return {
    ...registerNumber(methods, fieldLabel, fieldName),
    ...registerRequired(methods, fieldLabel, fieldName, options)
  }
}

const registerEmail = <TFieldValues extends FieldValues, TContext = any>(
  methods: UseFormReturn<TFieldValues, TContext>,
  fieldLabel: string,
  ...params: RegisterParams<TFieldValues>
): UseFormPlusRegisterReturn => {
  const fieldName = params[0]
  const options = params[1]
  return {
    labelText: fieldLabel,
    error: methods.formState.errors[fieldName]?.message,
    ...methods.register(fieldName, {
      ...options,
      validate: {
        ...options?.validate,
        isValidEmail: (value: string) => {
          if (!value) {
            return true
          }
          const isValid = EMAIL_REGEX.test(value)
          return isValid ? true : `${value} is not a valid email.`
        }
      }
    })
  }
}

export const UseFormPlusRegisters = {
  registerDate,
  registerEmail,
  registerNumber,
  registerRequired,
  registerRequiredNumber
}
