import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { transformDollarsToCents } from '@Components/form-general/transformers'
import {
  CompleteWeeklyBeanoFormState,
  WeeklyBeanoFormStep
} from '@Components/weekly-beano-form/form-state-reducer/shape'
import {
  defaultProgressiveGameSummary,
  ExpenditureDetail,
  FormPrizeSummaryProgressiveGameDetails,
  PaidViaEftSelection,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData,
  WTAFormDetails
} from '@Components/weekly-beano-form/step-3/constants'
import { getWinnerTakeAllTotalDollars } from '@Components/weekly-beano-form/step-3/summary-of-beano-prizes/constants'
import { PrizeDistributionGame } from '@Components/weekly-beano-form/step-3/summary-of-beano-prizes/prize-distribution-details/prize-distribution-game-names'
import { getLicenseeProfitInDollars } from '@Components/weekly-beano-form/step-3/summary-of-beano-prizes/progressive-games/constants'
import { getCentsFromDollars } from '@Helpers/currency'
import {
  BeanoExpendituresDetails,
  BeanoPrizeSummary,
  OtherExpense,
  PrizeDistributionDetails,
  PrizeSummaryProgressiveGameDetails,
  WeeklyBeanoFormBodySubmission,
  WTAGameDetails
} from '../../types'

export type StepThreeBodyData = Pick<
  WeeklyBeanoFormBodySubmission,
  | 'beanoExpendituresDetails'
  | 'totalBeanoExpendituresInCents'
  | 'netProfitInCents'
>

const mapWtaAmountsToCents = (wtaInDollars: WTAFormDetails): number[] =>
  wtaInDollars.amountsInDollars.map(({ amountInDollars }) =>
    getCentsFromDollars(amountInDollars)
  )

const mapExpenditureToOtherExpense = (
  expenditure: ExpenditureDetail
): OtherExpense => ({
  checkNumber: expenditure.checkNumber,
  payee: expenditure.payee,
  description: expenditure.description,
  amountInCents: getCentsFromDollars(expenditure.amountInDollars),
  expenseType: expenditure.type
})

const getOtherExpenseDetails = (
  stepThreeData: WeeklyBeanoStepThreeFormData
): OtherExpense[] =>
  stepThreeData[WeeklyBeanoStepThreeField.expenditures].map(
    mapExpenditureToOtherExpense
  )

const getPrizeDistributionsFromDynamicField = (
  stepThreeState: WeeklyBeanoStepThreeFormData,
  fieldName:
    | WeeklyBeanoStepThreeField.otherGames
    | WeeklyBeanoStepThreeField.fiftyFifty
): PrizeDistributionDetails[] => {
  const fieldData = stepThreeState[fieldName]
  const gameName =
    fieldName === WeeklyBeanoStepThreeField.fiftyFifty
      ? PrizeDistributionGame.fiftyFifty
      : undefined
  return fieldData.map((existingDetails) => ({
    ...transformDollarsToCents(existingDetails),
    gameName: gameName ?? existingDetails.gameName
  }))
}

const getPrizeDistributionDetails = (
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): PrizeDistributionDetails[] => {
  const stepThreeData = weeklyBeanoState[WeeklyBeanoFormStep.StepThree]
  const prizeDistributionDetails: PrizeDistributionDetails[] = [
    {
      gameName: PrizeDistributionGame.spGame,
      prizeInCents: getCentsFromDollars(
        stepThreeData[WeeklyBeanoStepThreeField.spGamePrize]
      ),
      numGames: stepThreeData[WeeklyBeanoStepThreeField.spGameNumGames]
    },
    ...getPrizeDistributionsFromDynamicField(
      stepThreeData,
      WeeklyBeanoStepThreeField.otherGames
    ),
    ...getPrizeDistributionsFromDynamicField(
      stepThreeData,
      WeeklyBeanoStepThreeField.fiftyFifty
    ),
    {
      gameName: PrizeDistributionGame.goodNeighbor,
      prizeInCents: getCentsFromDollars(
        stepThreeData[WeeklyBeanoStepThreeField.goodNeighborPrize]
      ),
      numGames: stepThreeData[WeeklyBeanoStepThreeField.goodNeighborNumGames]
    }
  ]
  return prizeDistributionDetails
}

const getProgressiveDetailsFromSummary = (
  proceedsInDollars: number,
  progressiveGameSummary?: FormPrizeSummaryProgressiveGameDetails
): PrizeSummaryProgressiveGameDetails => {
  const activeSummary = progressiveGameSummary ?? defaultProgressiveGameSummary
  const activeSummaryInCents = transformDollarsToCents(activeSummary)
  const taxPaidInDollars = calculateTaxPaidDollars(proceedsInDollars)
  return {
    numCalls: activeSummary.numCalls,
    numGames: activeSummary.numGames,
    beginningCarryoverInCents: activeSummaryInCents.beginningCarryoverInCents,
    endingCarryoverInCents: activeSummaryInCents.endingCarryoverInCents,
    grossBeanoReceiptsInCents: getCentsFromDollars(proceedsInDollars),
    taxDeductionInCents: getCentsFromDollars(taxPaidInDollars),
    prizesPaidInCents: getCentsFromDollars(activeSummary.prizesPaidInDollars),
    licenseeProfitInCents: getCentsFromDollars(
      getLicenseeProfitInDollars(
        proceedsInDollars,
        taxPaidInDollars,
        activeSummary.prizesPaidInDollars
      )
    )
  }
}

const getPrizeSummary = (
  dateString: string,
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): BeanoPrizeSummary => {
  const stepThreeData = weeklyBeanoState[WeeklyBeanoFormStep.StepThree]
  const progressiveGamesInCents = transformDollarsToCents(
    stepThreeData[WeeklyBeanoStepThreeField.progressiveGames]
  )
  const doorPrizeCashInCents = getCentsFromDollars(
    stepThreeData[WeeklyBeanoStepThreeField.doorCashPrize]
  )

  const merchandiseCashCents = getCentsFromDollars(
    stepThreeData[WeeklyBeanoStepThreeField.merchandiseAmount]
  )
  const wtasInDollars = stepThreeData[WeeklyBeanoStepThreeField.winnerTakeAll]
  const wtaGameDetails: WTAGameDetails[] = wtasInDollars.map(
    (wtaInDollars: WTAFormDetails): WTAGameDetails => ({
      amountsInCents: mapWtaAmountsToCents(wtaInDollars),
      totalInCents: getCentsFromDollars(
        getWinnerTakeAllTotalDollars(wtaInDollars)
      )
    })
  )

  const prizeSummary: BeanoPrizeSummary = {
    progressiveGame1Details: getProgressiveDetailsFromSummary(
      weeklyBeanoState.totals.progressiveOneProceedsInDollars,
      stepThreeData[WeeklyBeanoStepThreeField.progressiveGames][0]
    ),
    progressiveGame1TotalInCents:
      progressiveGamesInCents[0]?.prizesPaidInCents ?? 0,
    progressiveGame2Details: getProgressiveDetailsFromSummary(
      weeklyBeanoState.totals.progressiveTwoProceedsInDollars,
      stepThreeData[WeeklyBeanoStepThreeField.progressiveGames][1]
    ),
    progressiveGame2TotalInCents:
      progressiveGamesInCents[1]?.prizesPaidInCents ?? 0,
    prizeDistributionDetails: getPrizeDistributionDetails(weeklyBeanoState),
    breakageAmountInCents: getCentsFromDollars(
      stepThreeData[WeeklyBeanoStepThreeField.breakageAmountInDollars]
    ),
    doorPrizeDetails: {
      cashPrizeNumGames:
        stepThreeData[WeeklyBeanoStepThreeField.doorCashNumGames],
      cashPrizeAwardedInCents: doorPrizeCashInCents,
      merchandiseDetails: {
        clerkReferenceNumber:
          stepThreeData[WeeklyBeanoStepThreeField.doorPrizeClerkNumber],
        date: dateString,
        description:
          stepThreeData[WeeklyBeanoStepThreeField.doorPrizeDescription],
        valueInCents: merchandiseCashCents
      }
    },
    doorPrizeTotalInCents: doorPrizeCashInCents + merchandiseCashCents,
    wtaGameDetails
  }
  return prizeSummary
}

const getExpenditureDetails = (
  dateString: string,
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): BeanoExpendituresDetails => {
  const stepThreeData = weeklyBeanoState[WeeklyBeanoFormStep.StepThree]

  let paidViaEft = undefined
  switch (stepThreeData[WeeklyBeanoStepThreeField.paidViaEftSelection]) {
    case PaidViaEftSelection.yes:
      paidViaEft = true
      break
    case PaidViaEftSelection.no:
      paidViaEft = false
      break
    default:
      break
  }

  const expenditureDetails: BeanoExpendituresDetails = {
    mslcTaxEftPayment: paidViaEft,
    mslcTaxCheckNumber: paidViaEft
      ? ''
      : stepThreeData[WeeklyBeanoStepThreeField.checkNumber],
    mslcTaxPaidInCents: getCentsFromDollars(
      weeklyBeanoState.totals.mslcTaxPaidInDollars
    ),
    beanoPrizeSummary: getPrizeSummary(dateString, weeklyBeanoState),
    beanoGrandTotalCashPrizesInCents: getCentsFromDollars(
      weeklyBeanoState.totals.beanoGrandTotalCashPrizesInDollars
    ),
    otherExpenses: getOtherExpenseDetails(stepThreeData)
  }
  return expenditureDetails
}

export const transformStepThreeData = (
  dateString: string,
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): StepThreeBodyData => ({
  totalBeanoExpendituresInCents: getCentsFromDollars(
    weeklyBeanoState.totals.totalBeanoExpendituresInDollars
  ),
  netProfitInCents: getCentsFromDollars(
    weeklyBeanoState.totals.netProfitInDollars
  ),
  beanoExpendituresDetails: getExpenditureDetails(dateString, weeklyBeanoState)
})
