import * as React from 'react'
import { SubmissionFailureModal } from '@Components/submission-failure-modal/submission-failure-modal-ui'

type UseFormErrorModalComponentProps = {
  component: true
  handleSubmit: (...params: any[]) => any
}

type UseFormErrorModalNoComponentProps = {
  component?: false
}

export type UseFormErrorModalProps = {
  error?: boolean
} & (UseFormErrorModalComponentProps | UseFormErrorModalNoComponentProps)

export const useFormErrorModal = (props: UseFormErrorModalProps) => {
  const [errorModalOpen, setErrorModalOpen] = React.useState(false)
  React.useEffect(() => {
    props.error ? setErrorModalOpen(true) : setErrorModalOpen(false)
    return () => {}
  }, [props.error])

  return {
    errorModalOpen,
    setErrorModalOpen,
    SubmissionErrorModal: props.component ? (
      <SubmissionFailureModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        onSubmit={props.handleSubmit}
      />
    ) : undefined
  }
}
