import * as React from 'react'
import { TotalFundsForDisbursement } from './total-funds-available-for-disbursement'
import { UnexpectedNetProfit } from './unexpected-net-profit-ui'
import { NetBeanoProfitOrLoss } from './net-beano-profit-or-loss-ui'
import { SaleOfCharityGameTix } from './sale-of-charity-game-tickets'
import { BASE_ROOT_CLASS } from './constants'
import { OtherIncome } from './other-income-ui'
import './styles.scss'
import { WeeklyBeanoStepFourTotals } from '../constants'

type FundsForDisbursementProps = Pick<
  WeeklyBeanoStepFourTotals,
  | 'netProfitInDollars'
  | 'cgTicketNetProceedsInDollars'
  | 'fundsAvailableForDisbursementInDollars'
  | 'otherIncomeInDollars'
>

export const FundsForDisbursement = (props: FundsForDisbursementProps) => (
  <>
    <section className={BASE_ROOT_CLASS}>
      <UnexpectedNetProfit />
      <NetBeanoProfitOrLoss
        netBeanoProfitOrLossDollars={props.netProfitInDollars}
      />
      <SaleOfCharityGameTix
        cgTicketNetProceedsInDollars={props.cgTicketNetProceedsInDollars}
      />
      <OtherIncome otherIncomeInDollars={props.otherIncomeInDollars} />
    </section>
    <TotalFundsForDisbursement
      fundsAvailableForDisbursementInDollars={
        props.fundsAvailableForDisbursementInDollars
      }
    />
  </>
)
