import * as React from 'react'
import { FieldPath, FieldValues } from '@Hooks/use-form-plus'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { InputDropdown } from '@Components/input-dropdown/input-dropdown-ui'
import { joinClasses } from '@Helpers/components'
import { memberRoleOptions, SubmitFieldLabel } from './constants'
import './styles.scss'
import { getRequiredLabel } from '@Helpers/test-util'

export type SignAndSubmitTopFieldsProps<
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
> = {
  className?: string
  fields: {
    role: TFieldNames
    email: TFieldNames
    phone: TFieldNames
  }
}

const ROOT_CLASS = 'sign-and-submit-top-fields'

export const SignAndSubmitTopFields = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
>(
  props: SignAndSubmitTopFieldsProps<TFieldValues, TFieldNames>
) => {
  const {
    registerRequired,
    registerEmail,
    formState: { errors },
    watch
  } = useFormContextPlus<TFieldValues>()
  const roleValue = watch(props.fields.role)

  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      <InputDropdown
        {...registerRequired(SubmitFieldLabel.memberRole, props.fields.role)}
        error={errors[props.fields.role]?.message}
        options={memberRoleOptions}
        placeholder={'Select...'}
        isPlaceholderValue={!roleValue}
      />
      <Input
        {...registerEmail(
          getRequiredLabel(SubmitFieldLabel.email),
          props.fields.email,
          {
            required: `${SubmitFieldLabel.email} is required.`
          }
        )}
        error={errors[props.fields.email]?.message}
      />
      <Input
        {...registerRequired(SubmitFieldLabel.phoneNumber, props.fields.phone)}
        error={errors[props.fields.phone]?.message}
      />
    </div>
  )
}
