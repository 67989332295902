import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel,
  AnnualBeanoStepThreeFormData
} from '../constants'
import './styles.scss'

export type DisbursementFieldProps = {
  fieldLabel: AnnualBeanoStepThreeFieldLabel
  fieldName: AnnualBeanoStepThreeField
  header: string
}

export type DisbursementRowProps = {
  fields: DisbursementFieldProps[]
}

const ROOT_CLASS = 'disbursement-row'

const DisbursementField = (fieldProps: DisbursementFieldProps) => {
  const { registerNumber } = useFormContextPlus<AnnualBeanoStepThreeFormData>()
  return (
    <div className={getBlockClass(ROOT_CLASS, 'field')}>
      <p className={getBlockClass(ROOT_CLASS, 'field-header')}>
        {fieldProps.header}
      </p>
      <Input
        {...registerNumber(fieldProps.fieldLabel, fieldProps.fieldName)}
        moneyField
      />
    </div>
  )
}

export const DisbursementRow = (props: DisbursementRowProps) => {
  return (
    <article className={ROOT_CLASS}>
      {props.fields.map((field) => (
        <DisbursementField {...field} key={field.fieldName} />
      ))}
    </article>
  )
}
