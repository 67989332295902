import * as React from 'react'
import { FormStepOneProps } from '@Components/form-general/types'
import { CasinoStepOneFormData } from './constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormCard } from '@Components/form-card/form-card-ui'
import { CasinoDateAndTime } from './date-and-times/casino-date-and-times-ui'
import {
  CASINO_FORM_NUM_DISPLAY_STEPS,
  CASINO_FORM_NUM_STEPS
} from '../constants'
import {
  OrganizationDetails,
  OrganizationDetailsProps
} from '@Components/organization-details/organization-details-ui'
import './styles.scss'

export type CasinoFormStepOneProps = FormStepOneProps<CasinoStepOneFormData> & {
  organizationDetails: OrganizationDetailsProps
}

const FORM_ID = 'casino-step-one'
const FORM_STEP = 1
const ROOT_CLASS = FORM_ID

export const CasinoFormStepOne = (props: CasinoFormStepOneProps) => {
  const methods = useForm<CasinoStepOneFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  return (
    <>
      <FormCard
        currentStep={FORM_STEP}
        totalSteps={CASINO_FORM_NUM_DISPLAY_STEPS}
      >
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(props.onSubmit)} id={FORM_ID}>
              <OrganizationDetails {...props.organizationDetails} />
              <CasinoDateAndTime />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={CASINO_FORM_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
        />
      )}
    </>
  )
}
