import * as React from 'react'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { GeneralFormSummary } from '@Components/form-general/general-form-summary/general-form-summary-ui'
import { useInfoModal } from '@Hooks/use-info-modal'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import {
  PenaltyAndInterestInfoModal,
  PENALTY_MODAL_HEADER
} from '@Components/form-general/penalty-and-interest-modal/penalty-and-interest-info-modal'
import {
  RaffleStepTwoField,
  RaffleStepTwoFieldLabel,
  RaffleStepTwoFormData
} from '../constants'
import './styles.scss'

export type RaffleTaxesProps = {
  baseTaxInDollars: number
  totalTaxDueInDollars: number
}

export const ROOT_CLASS = 'raffle-taxes'

export const RaffleTaxes = (props: RaffleTaxesProps) => {
  const { registerNumber } = useFormContextPlus<RaffleStepTwoFormData>()
  const { InfoModal, openModal } = useInfoModal({
    header: PENALTY_MODAL_HEADER,
    ariaLabel: PENALTY_MODAL_HEADER,
    body: <PenaltyAndInterestInfoModal />
  })
  return (
    <>
      {InfoModal}
      <article className={ROOT_CLASS}>
        <FormSubsection type="primary">
          <h4 className={getBlockClass(ROOT_CLASS, 'header')}>
            3. Tax paid to MSLC
          </h4>
          <div className={getBlockClass(ROOT_CLASS, 'content')}>
            <div className={getBlockClass(ROOT_CLASS, 'fields')}>
              <ReadOnlyField
                value={getDollarString(props.baseTaxInDollars)}
                label={'Amount (5% of Receipts)'}
              />
              <Input
                {...registerNumber(
                  RaffleStepTwoFieldLabel.penaltyAndInterest,
                  RaffleStepTwoField.penaltyAndInterestInDollars
                )}
                labelSupplement={<ButtonQuestionIcon onClick={openModal} />}
                moneyField
              />
            </div>
            <ReadOnlyField
              value={getDollarString(props.totalTaxDueInDollars)}
              noMargin
            />
          </div>
        </FormSubsection>
      </article>
      <GeneralFormSummary
        title="Total Tax Due"
        totalDollars={props.totalTaxDueInDollars}
      />
    </>
  )
}
