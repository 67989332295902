import * as React from 'react'
import { Modal, ModalProps as ModalProps } from '@Components/modal/modal-ui'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { Button, ButtonFill } from '@Components/button/button-ui'
import './styles.scss'

type CTAProps = {
  onClick: () => void
  text: string
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export type Props = {
  className?: string
  headerText: string
  bodyText: string
  primaryButton: CTAProps
  secondaryButton: CTAProps
} & ModalProps

const ROOT_CLASS = 'modal-cta'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const BODY_CLASS = getBlockClass(ROOT_CLASS, 'body')
const BUTTON_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'button-container')
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')

export const ModalCta = (props: Props) => (
  <Modal
    ariaLabel={props.ariaLabel}
    isOpen={props.isOpen}
    handleClose={props.handleClose}
  >
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      <h2 className={joinClasses([HEADER_CLASS, props.className])}>
        {props.headerText}
      </h2>
      <p className={joinClasses([BODY_CLASS, props.className])}>
        {props.bodyText}
      </p>
      <div className={BUTTON_CONTAINER_CLASS}>
        <Button
          htmlProps={{
            onClick: props.primaryButton.onClick
          }}
          className={joinClasses([BUTTON_CLASS, props.className])}
        >
          {props.primaryButton.text}
        </Button>
        <Button
          htmlProps={{
            onClick: props.secondaryButton.onClick
          }}
          style={ButtonFill.outlined}
          className={joinClasses([BUTTON_CLASS, props.className])}
        >
          {props.secondaryButton.text}
        </Button>
      </div>
    </div>
  </Modal>
)
