import { AnnualBeanoFormAction, AnnualBeanoFormActions } from './actions'
import {
  AnnualBeanoFormState,
  AnnualBeanoFormStep,
  AnnualBeanoTotals
} from './shape'

export const AnnualBeanoFormReducer = <Step extends AnnualBeanoFormStep>(
  state: AnnualBeanoFormState,
  action: AnnualBeanoFormAction<Step>
): AnnualBeanoFormState => {
  switch (action.type) {
    case AnnualBeanoFormActions.UPDATE_FORM_STATE:
      return {
        ...state,
        totals: getAnnualBeanoTotals(state, action),
        [action.step]: action.payload,
        currentStep: state.currentStep + 1
      }
    case AnnualBeanoFormActions.CHANGE_FORM_STEP:
      return {
        ...state,
        currentStep: action.step
      }
    default:
      return state
  }
}

const getAnnualBeanoTotals = <Step extends AnnualBeanoFormStep>(
  state: AnnualBeanoFormState,
  action: AnnualBeanoFormAction<Step>
): AnnualBeanoTotals =>
  'totals' in action
    ? {
        ...state.totals,
        ...action.totals
      }
    : state.totals
