import { DollarizedData } from '@Components/form-general/types'
import {
  RaffleFormSubmissionBody,
  RaffleReceiptsDetails,
  RaffleTaxDetails
} from '../api/types'

export enum RaffleStepTwoField {
  numTicketsSold = 'numTicketsSold',
  costPerTicketInDollars = 'costPerTicketInDollars',
  otherReceiptsInDollars = 'otherReceiptsInDollars',
  otherReceiptsDescription = 'otherReceiptsDescription',
  penaltyAndInterestInDollars = 'penaltyAndInterestInDollars'
}

export enum RaffleStepTwoFieldLabel {
  numTicketsSold = 'No. of Tickets/Chances Sold',
  costPerTicketInDollars = 'Price per Ticket/Chances Sold',
  description = 'Description',
  total = 'Total',
  amount = 'Amount',
  baseTax = 'Amount (5% of Receipts)',
  penaltyAndInterest = 'Penalty & Interest'
}

type DollarizedReceipts = DollarizedData<
  Omit<RaffleReceiptsDetails, 'totalTicketSalesInCents'>
>
type DollarizedTaxDetails = DollarizedData<
  Omit<RaffleTaxDetails, 'baseTaxInCents'>
>

export type DollarizedRaffleStepTwoTotals = DollarizedData<
  Pick<
    RaffleFormSubmissionBody,
    'grossReceiptsInCents' | 'totalTaxDueInCents'
  > &
    Pick<RaffleReceiptsDetails, 'totalTicketSalesInCents'> &
    Pick<RaffleTaxDetails, 'baseTaxInCents'>
>

export type RaffleStepTwoFormData = DollarizedReceipts & DollarizedTaxDetails

export const defaultRaffleStepTwoFormData: RaffleStepTwoFormData = {
  [RaffleStepTwoField.numTicketsSold]: 0,
  [RaffleStepTwoField.costPerTicketInDollars]: 0,
  [RaffleStepTwoField.otherReceiptsInDollars]: 0,
  [RaffleStepTwoField.otherReceiptsDescription]: '',
  [RaffleStepTwoField.penaltyAndInterestInDollars]: 0
}
