import * as React from 'react'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

export enum ButtonFill {
  filled = 'filled',
  outlined = 'outlined'
}

export enum ButtonColor {
  blue = 'blue',
  green = 'green',
  white = 'white',
  teal = 'teal'
}

export enum ButtonHeight {
  skinny = 'skinny',
  normal = 'normal'
}

export type ButtonProps = {
  htmlProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
  style?: ButtonFill
  color?: ButtonColor
  height?: ButtonHeight
  noStyle?: true
  className?: string
}

const ROOT_CLASS = 'button'

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      className={joinClasses([
        ROOT_CLASS,
        props.className,
        getModifierClass(ROOT_CLASS, props.style),
        getModifierClass(ROOT_CLASS, props.color ?? ButtonColor.blue),
        getModifierClass(ROOT_CLASS, props.height),
        getModifierClass(ROOT_CLASS, 'no-style', Boolean(props.noStyle))
      ])}
      type="button"
      {...props.htmlProps}
    >
      {props.children}
    </button>
  )
}
