// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-card{-webkit-box-shadow:0px 1px 3px 0px rgba(15,24,33,.3);box-shadow:0px 1px 3px 0px rgba(15,24,33,.3);border-radius:6px;margin-bottom:16px}", "",{"version":3,"sources":["webpack://./src/components/form-card/styles.scss","webpack://./src/styles/_constants.scss"],"names":[],"mappings":"AAEA,WACE,oDCMmB,CDNnB,4CCMmB,CDLnB,iBCIsB,CDHtB,kBAAA","sourcesContent":["@use 'src/styles/constants' as *;\n\n.form-card {\n  box-shadow: $default-box-shadow;\n  border-radius: $default-border-radius;\n  margin-bottom: 16px;\n}\n","@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');\n\n// Typefaces\n$roboto-slab: 'Roboto Slab', serif;\n$roboto: 'Roboto', sans-serif;\n\n// Common Styles\n$default-border-radius: 6px;\n$default-box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.3);\n$default-container-padding: 8px 12px;\n$input-flex-gap: 4px;\n$default-form-section-max-width: 584px;\n\n// Colors\n$primary-teal: #58c2b7;\n$primary-navy: #173e67;\n$light-teal: #d8eae8;\n$secondary-teal: #a7ddd7;\n$separator-green: #1e7e74;\n$button-green: #7fcbc4;\n$input-border-color: #aab3be;\n$almost-black: #121921;\n$error-red: #ff0000;\n$back-office-blue: #1e4b75;\n$back-office-primary-border: #69dbdb;\n$back-office-primary-fill: #059f9f;\n\n// Breakpoints\n$breakpoint-mobile: 640px;\n$breakpoint-tablet: 720px;\n$breakpoint-desktop: 960px;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
