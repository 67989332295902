export enum TabIndex {
  sequential = 0,
  notSequential = -1
}

export enum AccessibleKeys {
  enter = 'Enter',
  space = ' ',
  upArrow = 'ArrowUp',
  leftArrow = 'ArrowLeft',
  downArrow = 'ArrowDown',
  rightArrow = 'ArrowRight',
  escape = 'Escape',
  tab = 'Tab'
}
