import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import {
  DynamicCardInputs,
  DynamicCardInputsProps
} from '../card-inputs/card-inputs-dynamic-ui'
import { SpecialGameField } from '../constants'
import { BeanoSpecialGameName } from './constants'
import './styles.scss'

export type SpecialGameGroupProps<T extends SpecialGameField> = {
  type: BeanoSpecialGameName
  cardInputsPropsOne: DynamicCardInputsProps<T>
  cardInputsPropsTwo: DynamicCardInputsProps<T>
}

const ROOT_CLASS = 'special-game-group'

const getHeaderNumberText = <T extends SpecialGameField>(
  cardInputsProps: DynamicCardInputsProps<T>,
  second = false
): string => {
  const row = cardInputsProps.index * 2 + 1
  const number = second ? row + 1 : row
  return `#${number}`
}

export const SpecialGameGroup = <T extends SpecialGameField>(
  props: SpecialGameGroupProps<T>
) => {
  return (
    <div
      className={ROOT_CLASS}
      key={`${props.type}-${props.cardInputsPropsOne.index}`}
    >
      <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
        <div>
          <h4>{`${props.type} ${getHeaderNumberText(
            props.cardInputsPropsOne
          )}`}</h4>
          <DynamicCardInputs {...props.cardInputsPropsOne} />
        </div>
        <div>
          <h4>{`${props.type} ${getHeaderNumberText(
            props.cardInputsPropsTwo,
            true
          )}`}</h4>
          <DynamicCardInputs {...props.cardInputsPropsTwo} />
        </div>
      </div>
    </div>
  )
}
