import {
  casinoStepThreeDefaultFormData,
  CasinoStepThreeFormData
} from '@Components/casino-form/step-3/constants'
import { DollarizedCasinoFormSubmissionBody } from '../../types'

export const transformCasinoStepThreeInitialValues = (
  submissionBodyInDollars: DollarizedCasinoFormSubmissionBody
): CasinoStepThreeFormData => {
  const {
    dispositionOfAndAccountingForNetProfitsDetails,
    totalDisbursementDetails
  } = submissionBodyInDollars
  return {
    ...casinoStepThreeDefaultFormData,
    ...dispositionOfAndAccountingForNetProfitsDetails,
    ...totalDisbursementDetails
  }
}
