import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { CasinoStepTwoTotals } from '../constants'
import { CasinoBasicExpenses } from './casino-basic-expenditures-ui'
import { CasinoOtherExpenses } from './other-expenditures/casino-other-expenses-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

export type CasinoExpendituresProps = Pick<
  CasinoStepTwoTotals,
  | 'taxDueInDollars'
  | 'grandTotalCashPrizesInDollars'
  | 'totalTaxDueInDollars'
  | 'totalExpendituresInDollars'
>

const ROOT_CLASS = 'casino-expenditures'

export const CasinoExpenditures = (props: CasinoExpendituresProps) => {
  return (
    <div className={ROOT_CLASS}>
      <FormSubsection type="primary">
        <CasinoBasicExpenses
          taxDueInDollars={props.taxDueInDollars}
          totalTaxDueInDollars={props.totalTaxDueInDollars}
        />
        <CasinoOtherExpenses
          totalExpendituresInDollars={props.totalExpendituresInDollars}
        />
        <div className={getBlockClass(ROOT_CLASS, 'total-expenditures')}>
          <h2>Total Expenditures</h2>
          <ReadOnlyField
            noMargin
            value={getDollarString(props.totalExpendituresInDollars)}
          />
        </div>
      </FormSubsection>
    </div>
  )
}
