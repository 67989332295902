import { InputNumberAndTextProps } from '@Components/form-general/input-number-and-text/input-number-and-text-ui'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel,
  AnnualBeanoStepThreeFormData
} from '../constants'

const firstDayLastYear = dateUtil().month(0).date(1).subtract(1, 'year')
const firstDayLastYearFormatted = firstDayLastYear.format(
  DayFormat.fullMonthDateAndYear
)

export const fundsForDisbursementFields: InputNumberAndTextProps<
  AnnualBeanoStepThreeFormData,
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel
>[] = [
  {
    header: '1. Special Beano Checking Account',
    headerSupplement: `(as of ${firstDayLastYearFormatted})`,
    subtotalValue: 'watch',
    subtotalHeader: 'Total',
    numberField: {
      name: AnnualBeanoStepThreeField.beanoCheckingBalanceInDollars,
      label: AnnualBeanoStepThreeFieldLabel.balance
    }
  },
  {
    header: '2. Special Beano Savings Account',
    headerSupplement: `(as of ${firstDayLastYearFormatted})`,
    subtotalValue: 'watch',
    numberField: {
      name: AnnualBeanoStepThreeField.beanoSavingsBalanceInDollars,
      label: AnnualBeanoStepThreeFieldLabel.balance
    }
  },
  {
    header: '3. Other Income',
    subtotalValue: 'watch',
    numberField: {
      name: AnnualBeanoStepThreeField.otherIncomeInDollars,
      label: AnnualBeanoStepThreeFieldLabel.amount
    },
    textField: {
      name: AnnualBeanoStepThreeField.otherIncomeDescription,
      label: AnnualBeanoStepThreeFieldLabel.description
    }
  }
]
