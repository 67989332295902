import { FormSummary } from '@Components/form-summary/form-summary-ui'
import * as React from 'react'
import './styles.scss'

export type TotalReceiptsFromSpecialGamesProps = {
  specialGamesReceiptsTotalInDollars: number
}

export const TotalReceiptsFromSpecialGames = (
  props: TotalReceiptsFromSpecialGamesProps
) => (
  <FormSummary.Complete
    text="Total Receipts from Special Games"
    totalDollars={props.specialGamesReceiptsTotalInDollars}
  />
)
