export const getStartTime = (occasionTime: string) => {
  switch (occasionTime) {
    case 'AFTERNOON':
      return '1:00 PM'
    case 'EVENING':
      return '6:00 PM'
    default:
      return '-'
  }
}

export const getEndTime = (occasionTime: string) => {
  switch (occasionTime) {
    case 'AFTERNOON':
      return '6:00 PM'
    case 'EVENING':
      return '12:00 AM'
    default:
      return '-'
  }
}
