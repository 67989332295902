import * as React from 'react'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { getDollarString } from '@Helpers/currency'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import { ModalInfo } from '@Components/modal-info/modal-info-ui'
import { calculateNetProfitOrLossDollars } from './helpers'
import './styles.scss'

export type Props = {
  grossReceiptsDollars: number
  totalExpendituresDollars: number
  beano?: true
}

const ROOT_CLASS = 'net-profit-or-loss'

export const NetProfitOrLoss = React.memo((props: Props) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const closeModal = () => setModalOpen(false)
  const netProfitOrLossDollars = calculateNetProfitOrLossDollars(
    props.totalExpendituresDollars,
    props.grossReceiptsDollars
  )
  const bodyText = props.beano
    ? 'This is calculated by deducting the Total Beano Expenditures from the Gross Beano Receipts.'
    : 'This is calculated by deducting the Total Expenses from the Gross Receipts.'
  return (
    <>
      <ModalInfo
        isOpen={modalOpen}
        ariaLabel="Net Profit or Loss Information"
        handleClose={closeModal}
        header="Net Profit or Loss"
        body={bodyText}
        buttonText="Okay"
        onButtonClick={closeModal}
      />
      <FormSummary>
        <div className={ROOT_CLASS}>
          <FormSummary.Title>Net Profit Or Loss</FormSummary.Title>
          <ButtonQuestionIcon onClick={() => setModalOpen(true)} />
        </div>
        <FormSummary.Total>
          {getDollarString(netProfitOrLossDollars)}
        </FormSummary.Total>
      </FormSummary>
    </>
  )
})
