import {
  transformCleanAllNumbers,
  transformDollarsToCents
} from '@Components/form-general/transformers'
import {
  CompleteWeeklyBeanoFormState,
  WeeklyBeanoFormStep
} from '@Components/weekly-beano-form/form-state-reducer/shape'
import { getAdmissionsTotal } from '@Components/weekly-beano-form/step-2/admission-cards/constants'
import {
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData,
  SpecialGameInputRow
} from '@Components/weekly-beano-form/step-2/constants'
import { getCentsFromDollars } from '@Helpers/currency'
import {
  BeanoReceiptsDetails,
  SpecialGameReceipt,
  WeeklyBeanoFormBodySubmission
} from '../../types'

type StepTwoBodyData = Pick<
  WeeklyBeanoFormBodySubmission,
  'beanoReceiptsDetails' | 'grossBeanoReceiptsInCents'
>

type ProgressiveReceiptDetails = Pick<
  BeanoReceiptsDetails,
  'progressiveGame1ReceiptDetails' | 'progressiveGame2ReceiptDetails'
>

const specialGameIsValid = (specialGame: SpecialGameReceipt) =>
  specialGame.numCards * specialGame.priceInCents > 0

const filterValidGames = (specialGames: SpecialGameReceipt[]) =>
  specialGames.filter(specialGameIsValid)

const getSpecialGameReceiptsFromWinnerTakeAllDetails = (
  wta: SpecialGameInputRow
): SpecialGameReceipt[] => {
  const wtaInCents = transformDollarsToCents(wta)
  const wtaOdd: SpecialGameReceipt = {
    numCards: wtaInCents.oddNumCards,
    priceInCents: wtaInCents.oddPriceInCents
  }
  const wtaEven: SpecialGameReceipt = {
    numCards: wtaInCents.evenNumCards,
    priceInCents: wtaInCents.evenPriceInCents
  }
  return filterValidGames([wtaOdd, wtaEven])
}

const getSpecialGameReceiptsFromGameRow = (
  gameRow: SpecialGameInputRow
): SpecialGameReceipt[] => {
  const gameRowInCents = transformDollarsToCents(gameRow)
  const gameOdd: SpecialGameReceipt = {
    numCards: gameRowInCents.oddNumCards,
    priceInCents: gameRowInCents.oddPriceInCents
  }

  const gameEven: SpecialGameReceipt = {
    numCards: gameRowInCents.evenNumCards,
    priceInCents: gameRowInCents.evenPriceInCents
  }
  return filterValidGames([gameOdd, gameEven])
}

export const getProgressiveReceiptDetails = (
  stepTwoFormData: WeeklyBeanoStepTwoFormData
): ProgressiveReceiptDetails => {
  const progressiveDetailsInCents = transformDollarsToCents(
    stepTwoFormData.allProgressive
  )
  const progressiveGameOneReceiptDetails: SpecialGameReceipt = {
    numCards: progressiveDetailsInCents[0]?.numCards ?? 0,
    priceInCents: progressiveDetailsInCents[0]?.pricePerCardInCents ?? 0
  }
  const progressiveGameTwoReceiptDetails: SpecialGameReceipt = {
    numCards: progressiveDetailsInCents[1]?.numCards ?? 0,
    priceInCents: progressiveDetailsInCents[1]?.pricePerCardInCents ?? 0
  }

  const receiptDetails: ProgressiveReceiptDetails = {
    progressiveGame1ReceiptDetails: specialGameIsValid(
      progressiveGameOneReceiptDetails
    )
      ? progressiveGameOneReceiptDetails
      : undefined,
    progressiveGame2ReceiptDetails: specialGameIsValid(
      progressiveGameTwoReceiptDetails
    )
      ? progressiveGameTwoReceiptDetails
      : undefined
  }
  return receiptDetails
}

export const transformStepTwoData = (
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): StepTwoBodyData => {
  const stepTwoState = transformCleanAllNumbers(
    weeklyBeanoState[WeeklyBeanoFormStep.StepTwo]
  )
  const {
    admissionsNumCards,
    admissionsCardPrice,
    admissionsNumCardsXRegular,
    admissionsCardPriceXRegular,
    bonusAdmissionsNumCards,
    bonusAdmissionsCardPrice,
    bonusAdmissionsNumCardsXRegular,
    bonusAdmissionsCardPriceXRegular
  } = stepTwoState

  const otherSpecialReceiptsDetails: SpecialGameReceipt[] =
    stepTwoState.otherSpecialReceiptsDetails
      .map(getSpecialGameReceiptsFromGameRow)
      .flat()

  const wtaGameReceiptDetails: SpecialGameReceipt[] =
    stepTwoState.wtaGameReceiptDetails
      .map(getSpecialGameReceiptsFromWinnerTakeAllDetails)
      .flat()

  const progressiveReceiptDetails = getProgressiveReceiptDetails(stepTwoState)

  const stepTwoBodyData: StepTwoBodyData = {
    beanoReceiptsDetails: {
      admissionCardsDetails: {
        quantity: admissionsNumCards,
        priceInCents: getCentsFromDollars(admissionsCardPrice),
        xRegQuantity: admissionsNumCardsXRegular,
        xRegPriceInCents: getCentsFromDollars(admissionsCardPriceXRegular)
      },
      admissionCardsTotalInCents: getCentsFromDollars(
        getAdmissionsTotal(
          admissionsNumCards,
          admissionsCardPrice,
          admissionsNumCardsXRegular,
          admissionsCardPriceXRegular
        )
      ),
      bonusCardsAdmissionsDetails: {
        quantity: stepTwoState[WeeklyBeanoStepTwoField.bonusAdmissionsNumCards],
        priceInCents: getCentsFromDollars(bonusAdmissionsCardPrice),
        xRegQuantity: bonusAdmissionsNumCardsXRegular,
        xRegPriceInCents: getCentsFromDollars(bonusAdmissionsCardPriceXRegular)
      },
      bonusCardsAdmissionsTotalInCents: getCentsFromDollars(
        getAdmissionsTotal(
          bonusAdmissionsNumCards,
          bonusAdmissionsCardPrice,
          bonusAdmissionsNumCardsXRegular,
          bonusAdmissionsCardPriceXRegular
        )
      ),
      ...progressiveReceiptDetails,
      wtaGameReceiptDetails,
      otherSpecialReceiptsDetails,
      specialGamesReceiptsTotalInCents: getCentsFromDollars(
        weeklyBeanoState.totals.specialGamesReceiptsTotalInDollars
      ),
      beanoSuppliesSalesInCents: getCentsFromDollars(
        stepTwoState[WeeklyBeanoStepTwoField.saleOfBeanoSupplies]
      ),
      otherReceiptsDescription:
        stepTwoState[WeeklyBeanoStepTwoField.otherReceiptsDescription],
      otherReceiptsInCents: getCentsFromDollars(
        stepTwoState[WeeklyBeanoStepTwoField.otherReceiptsAmount]
      )
    },
    grossBeanoReceiptsInCents: getCentsFromDollars(
      weeklyBeanoState.totals.grossBeanoReceiptsInDollars
    )
  }
  return stepTwoBodyData
}
