import dateUtil from '@Services/dayjs'
import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'
import { DayFormat } from '@Services/dayjs/constants'
import { getIncrementedTimeOptions } from '@Helpers/dropdown-options'

export const getStartEndTimeOptions = (): DropdownOptions => {
  const noon = dateUtil().hour(12).minute(0).second(0)
  const midnight = noon.add(12, 'hours')
  return getIncrementedTimeOptions(
    noon,
    midnight,
    30,
    'minute',
    DayFormat.hourMinuteMeridiem
  )
}

export const validStartAndEndTimes = (
  startTimeValue?: string,
  endTimeValue?: string
): boolean => {
  if (!startTimeValue || !endTimeValue) {
    return true
  }
  const startTime = dateUtil(startTimeValue, DayFormat.hourMinuteMeridiem)
  let endTime = dateUtil(endTimeValue, DayFormat.hourMinuteMeridiem)
  if (endTimeValue.endsWith('AM')) {
    endTime = endTime.add(1, 'day')
  }
  return startTime.isBefore(endTime)
}
