import * as React from 'react'
import { joinClasses } from '@Helpers/components'
import { IconExit } from '../icon-exit/icon-exit-ui'
import './styles.scss'

export type Props = {
  sizePx?: number
  onClick: () => void
  className?: string
}

const ROOT_CLASS = 'button-exit'
const DEFAULT_SIZE_PX = 18

export const ButtonExit = (props: Props) => (
  <button
    className={joinClasses([ROOT_CLASS, props.className])}
    onClick={props.onClick}
  >
    <IconExit sizePx={props.sizePx ?? DEFAULT_SIZE_PX} />
  </button>
)
