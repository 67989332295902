import * as React from 'react'
import {
  InputBaseLabel,
  InputLabelProps
} from '@Components/input-base/input-label-ui'
import {
  InputBaseError,
  Props as InputErrorProps
} from '@Components/input-base/input-base-error-ui'
import {
  DropdownSelector,
  Props as DropdownProps
} from '@Components/dropdown-selector/dropdown-selector-ui'
import './styles.scss'

export type InputDropdownProps = InputErrorProps &
  DropdownProps &
  Omit<InputLabelProps, 'htmlFor'>

const ROOT_CLASS = 'input-dropdown'

export const InputDropdown = React.forwardRef<
  HTMLSelectElement,
  InputDropdownProps
>((props, ref) => {
  return (
    <div className={ROOT_CLASS}>
      <InputBaseLabel
        disabled={props.disabled}
        error={props.error}
        htmlFor={props.name}
        required={props.required}
        labelText={props.labelText}
        className={props.className}
      />
      <DropdownSelector
        ref={ref}
        name={props.name}
        disabled={props.disabled}
        className={props.className}
        options={props.options}
        error={props.error}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onChange={props.onChange}
        isPlaceholderValue={props.isPlaceholderValue}
      />
      {props.error && (
        <InputBaseError error={props.error} className={props.className} />
      )}
    </div>
  )
})
