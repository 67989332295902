import * as React from 'react'
import { FormStepProps } from '@Components/form-general/types'
import { FormCard } from '@Components/form-card/form-card-ui'
import { RAFFLE_DISPLAY_NUM_STEPS } from '@Components/raffle-form/constants'
import { FormProvider, useFormPlus } from '@Hooks/use-form-plus'
import { getCasinoStepThreeTotals } from './helpers'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { CASINO_FORM_NUM_STEPS } from '../constants'
import { CasinoFundsAvailableForDisbursement } from './funds-available-for-disbursement/casino-funds-available-for-disbursement-ui'
import { CasinoDisbursements } from './disbursements/casino-disbursements'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { SpecialCasinoCheckingBalance } from './special-casino-checking-balance/special-casino-checking-balance-ui'
import {
  casinoStepThreeDefaultFormData,
  CasinoStepThreeFormData,
  CasinoStepThreeTotals
} from './constants'

export type CasinoStepThreeProps = FormStepProps<
  CasinoStepThreeFormData,
  CasinoStepThreeTotals
> &
  Pick<CasinoStepThreeTotals, 'netProfitInDollars'>

const FORM_ID = 'casino-step-three'
const FORM_STEP = 3
const ROOT_CLASS = FORM_ID

export const CasinoFormStepThree = (props: CasinoStepThreeProps) => {
  const methods = useFormPlus<CasinoStepThreeFormData>({
    defaultValues: {
      ...casinoStepThreeDefaultFormData,
      ...props.initialValues
    }
  })

  const totals = getCasinoStepThreeTotals(
    methods.watch(),
    props.netProfitInDollars
  )
  const handleSubmit = async (
    data: CasinoStepThreeFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit(data, totals)
  }

  return (
    <>
      <FormCard
        currentStep={FORM_STEP}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
        title="Schedule 2: Disposition of, and Accounting for Net Profits"
      >
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <CasinoFundsAvailableForDisbursement
                fundsAvailableForDisbursementInDollars={
                  totals.fundsAvailableForDisbursementInDollars
                }
                netProfitInDollars={totals.netProfitInDollars}
              />
              <CasinoDisbursements />
              <FormSummary.Complete
                text="Total Disbursements"
                totalDollars={totals.totalDisbursementsInDollars}
              />
              <SpecialCasinoCheckingBalance
                specialCasinoCheckingBalanceInDollars={
                  totals.specialCasinoCheckingBalanceInDollars
                }
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={CASINO_FORM_NUM_STEPS}
          formId={FORM_ID}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
