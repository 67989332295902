import * as React from 'react'
import { ModalProps } from '@Components/modal/modal-ui'
import { ModalCta } from '@Components/modal-cta/modal-cta-ui'
import './styles.scss'

const BODY_TEXT = `The balances underneath Line Item 6 do not add up to the calculated Unexpended Net Profit. Please double-check the numbers you've entered before proceeding.`

type Props = {
  onGoBackClick: () => void
  onContinueClick: () => void
} & Omit<ModalProps, 'ariaLabel'>

export const UnexpendedNetProfitWarningModal = (props: Props) => (
  <ModalCta
    ariaLabel="Unexpended Net Profit Warning"
    isOpen={props.isOpen}
    handleClose={props.handleClose}
    headerText="Warning"
    bodyText={BODY_TEXT}
    primaryButton={{
      onClick: props.onGoBackClick,
      text: 'Go Back'
    }}
    secondaryButton={{
      onClick: props.onContinueClick,
      text: 'Continue'
    }}
  />
)
