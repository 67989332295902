import * as React from 'react'
import { AccessibleKeys, TabIndex } from '../../helpers/dom'
import {
  getModifierClass,
  getBlockClass,
  joinClasses
} from '../../helpers/components'
import './styles.scss'

type InputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export type InputRadioProps = {
  value: string
  name: string
  required?: boolean
  className?: string
} & Pick<
  InputElementProps,
  | 'disabled'
  | 'max'
  | 'min'
  | 'required'
  | 'type'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'id'
  | 'checked'
>

const ROOT_CLASS = 'input-radio'

export const InputRadio = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<InputRadioProps>
>((props, ref) => {
  return (
    <label
      className={joinClasses([
        ROOT_CLASS,
        props.className,
        getModifierClass(ROOT_CLASS, 'checked', props.checked)
      ])}
      tabIndex={TabIndex.sequential}
      htmlFor={props.id}
      onKeyDown={(e) => {
        // hack to trigger onChange for keyboard users; not required for controlled component
        const inputNode = props.id ? document.getElementById(props.id) : null
        if (e.key === AccessibleKeys.enter || e.key === AccessibleKeys.space) {
          const clickEvent = new MouseEvent('click', { bubbles: true })
          inputNode?.dispatchEvent(clickEvent)
        }
      }}
    >
      <input
        tabIndex={TabIndex.notSequential}
        type="radio"
        ref={ref}
        id={props.id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        required={props.required}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
      <span className={getBlockClass(ROOT_CLASS, 'checkbox')}></span>
      <div className={getBlockClass(ROOT_CLASS, 'contents')}>
        {props.children}
      </div>
    </label>
  )
})
