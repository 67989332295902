import * as React from 'react'
import { useDynamicFields } from '@Hooks/use-form-plus'
import {
  CasinoStepTwoField,
  CasinoStepTwoFormData,
  CasinoStepTwoTotals,
  defaultOtherExpenditure
} from '../../constants'
import { getBlockClass } from '@Helpers/components'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { CasinoOtherExpense } from './casino-other-expense'
import './styles.scss'

export type CasinoOtherExpensesProps = Pick<
  CasinoStepTwoTotals,
  'totalExpendituresInDollars'
>

const ROOT_CLASS = 'casino-other-expenses'

export const CasinoOtherExpenses = (props: CasinoOtherExpensesProps) => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<CasinoStepTwoFormData>({
    name: CasinoStepTwoField.otherExpenditures,
    defaultData: defaultOtherExpenditure,
    getSubtotal: ['amountInDollars']
  })
  return (
    <section className={ROOT_CLASS}>
      <h4>6. Other Expenses</h4>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <DynamicFieldManager
          elements={fields.map((data, index) => (
            <CasinoOtherExpense key={data.id} id={data.id} index={index} />
          ))}
          removeElement={removeElement}
          addElement={addElement}
          onResetClick={reset}
          getSubtotalDisplay={getSubtotalDisplay}
          isResetDisabled={isResetDisabled()}
          addButtonText={'Add Another Expense'}
          resetButtonText={'Reset Other Expenses'}
        />
      </div>
    </section>
  )
}
