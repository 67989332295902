import * as React from 'react'
import { FormProps } from '@Components/form-general/types'
import { useFormErrorModal } from '@Hooks/use-form-error-modal'
import { AnnualBeanoFormReducer } from './form-state-reducer/reducer'
import {
  AnnualBeanoFormStep,
  getInitialState,
  isCompleteAnnualBeanoFormState
} from './form-state-reducer/shape'
import { AnnualBeanoSubmissionBody } from './api/types'
import { AnnualBeanoStepOne } from './step-1/annual-beano-one-ui'
import {
  AnnualBeanoFormAction,
  AnnualBeanoFormPayload,
  AnnualBeanoTotal
} from './form-state-reducer/actions'
import { AnnualBeanoStepTwo } from './step-2/annual-beano-two-ui'
import { AnnualBeanoStepThree } from './step-3/annual-beano-three-ui'
import { AnnualBeanoSubmit } from './submit/annual-beano-submit-ui'
import { AnnualBeanoTransformers } from './api/transformers'

export type AnnualBeanoFormProps = FormProps<AnnualBeanoSubmissionBody> & {
  year: string
}

const ROOT_CLASS = 'annual-beano-form'
const LAST_STEP = 4

export const AnnualBeanoForm = (props: AnnualBeanoFormProps) => {
  const [state, dispatch] = React.useReducer(
    AnnualBeanoFormReducer,
    getInitialState(props.initialValues)
  )
  const handleSubmit = async () => {
    if (!isCompleteAnnualBeanoFormState(state)) {
      return
    }
    const submissionBody = AnnualBeanoTransformers.getSubmissionBodyFromState(
      props.year,
      props.organizationDetails.idNumber,
      state
    )
    await props.onSubmit(submissionBody)
  }
  const { SubmissionErrorModal } = useFormErrorModal({
    error: props.error,
    component: true,
    handleSubmit: handleSubmit
  })

  const onBackClick = () =>
    dispatch(AnnualBeanoFormAction.changeFormStep(state.currentStep - 1))

  React.useEffect(() => {
    if (state.currentStep > LAST_STEP) {
      handleSubmit()
      onBackClick()
    }
  }, [state.currentStep, onBackClick])

  const updateFormData = <Step extends AnnualBeanoFormStep>(
    step: Step,
    payload: AnnualBeanoFormPayload[Step],
    totals?: AnnualBeanoTotal<Step>
  ) => dispatch(AnnualBeanoFormAction.updateFormStep(step, payload, totals))

  const getFormBody = () => {
    switch (state.currentStep) {
      case 1:
        return (
          <AnnualBeanoStepOne
            initialValues={state[AnnualBeanoFormStep.StepOne]}
            organizationDetails={props.organizationDetails}
            backOffice={props.backOffice}
            onSubmit={(formData) =>
              updateFormData(AnnualBeanoFormStep.StepOne, formData)
            }
          />
        )
      case 2:
        return (
          <AnnualBeanoStepTwo
            initialValues={state[AnnualBeanoFormStep.StepTwo]}
            onBackClick={onBackClick}
            backOffice={props.backOffice}
            onSubmit={(formData, totals) =>
              updateFormData(AnnualBeanoFormStep.StepTwo, formData, totals)
            }
          />
        )
      case 3:
        return (
          <AnnualBeanoStepThree
            initialValues={state[AnnualBeanoFormStep.StepThree]}
            onBackClick={onBackClick}
            backOffice={props.backOffice}
            totalNetProfitOrLossDollars={
              state.totals.totalNetProfitOrLossDollars
            }
            onSubmit={(formData, totals) =>
              updateFormData(AnnualBeanoFormStep.StepThree, formData, totals)
            }
          />
        )
      case LAST_STEP:
      default:
        return (
          <AnnualBeanoSubmit
            initialValues={state[AnnualBeanoFormStep.Submit]}
            onBackClick={onBackClick}
            submitDisabled={props.submitDisabled}
            backOffice={props.backOffice}
            onSubmit={async (formData) => {
              if (props.error) {
                await handleSubmit()
              } else {
                updateFormData(AnnualBeanoFormStep.Submit, formData)
              }
            }}
          />
        )
    }
  }

  return (
    <section className={ROOT_CLASS}>
      {SubmissionErrorModal}
      {getFormBody()}
    </section>
  )
}
