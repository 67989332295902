import { AnnualBeanoTotals } from '@Components/annual-beano-form/form-state-reducer/shape'
import { AnnualBeanoStepTwoFormData } from '@Components/annual-beano-form/step-2/constants'
import { calculateNetProfitOrLossDollars } from '@Components/form-general/net-profit-or-loss/helpers'
import { transformDollarsToCents } from '@Components/form-general/transformers'
import { getCentsFromDollars } from '@Helpers/currency'
import { AnnualBeanoSubmissionBody } from '../../types'

export type AnnualBeanoStepTwoSubmissionData = Pick<
  AnnualBeanoSubmissionBody,
  | 'beanoGameReceiptsDetails'
  | 'grossBeanoReceiptsInCents'
  | 'beanoGameExpenditureDetails'
  | 'totalBeanoExpendituresInCents'
  | 'netBeanoProfitInCents'
  | 'charityGameTicketDetails'
  | 'netCgTicketProfitInCents'
  | 'netBeanoAndCgTicketProfitInCents'
>

export const transformStepTwoFormToSubmissionData = (
  formData: AnnualBeanoStepTwoFormData,
  totals: AnnualBeanoTotals
): AnnualBeanoStepTwoSubmissionData => {
  const formDataInCents = transformDollarsToCents(formData)
  const netProfitOrLossDollars = calculateNetProfitOrLossDollars(
    totals.totalExpendituresDollars,
    totals.grossBeanoReceiptsDollars
  )
  return {
    beanoGameReceiptsDetails: {
      regularBeanoGameReceiptsInCents:
        formDataInCents.regularBeanoGameReceiptsInCents,
      otherReceiptsInCents: formDataInCents.otherReceiptsInCents,
      otherReceiptsDescription: formDataInCents.otherReceiptsDescription
    },
    grossBeanoReceiptsInCents: getCentsFromDollars(
      totals.grossBeanoReceiptsDollars
    ),
    beanoGameExpenditureDetails: {
      prizesInCents: formDataInCents.prizesInCents,
      taxPaidInCents: formDataInCents.taxPaidInCents,
      rentInCents: formDataInCents.rentInCents,
      equipmentInCents: formDataInCents.equipmentInCents,
      suppliesInCents: formDataInCents.suppliesInCents,
      servicesInCents: formDataInCents.servicesInCents,
      otherExpenses: formDataInCents.otherExpenses
    },
    totalBeanoExpendituresInCents: getCentsFromDollars(
      totals.totalExpendituresDollars
    ),
    netBeanoProfitInCents: getCentsFromDollars(netProfitOrLossDollars),
    charityGameTicketDetails: {
      ticketProceedsInCents: formDataInCents.ticketProceedsInCents,
      ticketCostsInCents: formDataInCents.ticketCostsInCents
    },
    netCgTicketProfitInCents: getCentsFromDollars(
      totals.charityNetProfitOrLoss
    ),
    netBeanoAndCgTicketProfitInCents: getCentsFromDollars(
      totals.totalNetProfitOrLossDollars
    )
  }
}
