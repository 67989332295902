import * as React from 'react'
import { ButtonExit } from '@Components/button-exit/button-exit-ui'
import { Button } from '@Components/button/button-ui'
import { Modal } from '@Components/modal/modal-ui'
import { getBlockClass } from '@Helpers/components'
import './styles.scss'

export type SubmissionFailureModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

const ROOT_CLASS = 'submission-failure-modal'

export const SubmissionFailureModal = (props: SubmissionFailureModalProps) => (
  <Modal
    ariaLabel="Submission Failed"
    isOpen={props.isOpen}
    handleClose={props.onClose}
  >
    <div className={ROOT_CLASS}>
      <ButtonExit
        onClick={props.onClose}
        className={getBlockClass(ROOT_CLASS, 'exit')}
      />
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <h2>Whoops a Daisy!</h2>
        <p>Something went wrong on our end, please try again.</p>
        <Button
          htmlProps={{ onClick: props.onSubmit, type: 'submit' }}
          className={getBlockClass(ROOT_CLASS, 'resubmit')}
        >
          Submit
        </Button>
      </div>
    </div>
  </Modal>
)
