import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

const ROOT_CLASS = 'prize-distribution-breakage'

export const PrizeDistributionBreakage = () => {
  const { registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const value = watch(WeeklyBeanoStepThreeField.breakageAmountInDollars)
  return (
    <div className={ROOT_CLASS}>
      <h4>Breakage</h4>
      <div className={getBlockClass(ROOT_CLASS, 'field')}>
        <Input
          {...registerNumber(
            '',
            WeeklyBeanoStepThreeField.breakageAmountInDollars
          )}
          moneyField
        />
        <ReadOnlyField value={getDollarString(value)} />
      </div>
    </div>
  )
}
