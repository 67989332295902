import {
  AnnualBeanoFormStep,
  CompleteAnnualBeanoFormState
} from '@Components/annual-beano-form/form-state-reducer/shape'
import { AnnualBeanoStepTwoField } from '@Components/annual-beano-form/step-2/constants'
import { transformCentsToDollars } from '@Components/form-general/transformers'
import { defaultFormData as defaultStepTwoFormData } from '@Components/weekly-beano-form/step-2/constants'
import {
  AnnualBeanoStepThreeField,
  defaultFormData as defaultStepThreeFormData
} from '@Components/annual-beano-form/step-3/constants'
import { AnnualBeanoSubmissionBody } from '../types'
import { transformStepTwoFormToSubmissionData } from './submission/annual-beano-step-2-submission-transformer'
import { transformStepThreeFormToSubmissionData } from './submission/annual-beano-step-3-submission-transformer'
import { getAnnualBeanoIncomeTotals } from '@Components/annual-beano-form/step-2/helpers'
import { getAnnualBeanoDisbursementTotals } from '@Components/annual-beano-form/step-3/helpers'
import { getMemberRole } from '@Components/form-general/helpers'

const getSubmissionBodyFromState = (
  year: string,
  licenseNumber: string,
  state: CompleteAnnualBeanoFormState
): AnnualBeanoSubmissionBody => {
  const formSubmission: AnnualBeanoSubmissionBody = {
    year: year,
    licenseNumber: licenseNumber,
    numOccasions: state[AnnualBeanoFormStep.StepOne].numOccasions,
    averageNumPeoplePerOccasion:
      state[AnnualBeanoFormStep.StepOne].averageNumPeoplePerOccasion,
    ...transformStepTwoFormToSubmissionData(
      state[AnnualBeanoFormStep.StepTwo],
      state.totals
    ),
    ...transformStepThreeFormToSubmissionData(
      state[AnnualBeanoFormStep.StepThree],
      state.totals
    ),
    declarationDetails: {
      memberRole: state[AnnualBeanoFormStep.Submit].memberRole,
      phoneNumber: state[AnnualBeanoFormStep.Submit].phoneNumber,
      signature: state[AnnualBeanoFormStep.Submit].signature,
      date: state[AnnualBeanoFormStep.Submit].date,
      email: state[AnnualBeanoFormStep.Submit].email
    }
  }
  return formSubmission
}

const getFormStateFromInitialValues = (
  initialValues: AnnualBeanoSubmissionBody
): CompleteAnnualBeanoFormState => {
  const initialValuesInDollars = transformCentsToDollars(initialValues)
  const formDataNoTotals: Omit<CompleteAnnualBeanoFormState, 'totals'> = {
    [AnnualBeanoFormStep.StepOne]: {
      numOccasions: initialValuesInDollars.numOccasions,
      averageNumPeoplePerOccasion:
        initialValuesInDollars.averageNumPeoplePerOccasion
    },
    [AnnualBeanoFormStep.StepTwo]: {
      ...defaultStepTwoFormData,
      [AnnualBeanoStepTwoField.regularBeanoGameReceiptsInDollars]:
        initialValuesInDollars.beanoGameReceiptsDetails
          .regularBeanoGameReceiptsInDollars,
      [AnnualBeanoStepTwoField.otherReceiptsInDollars]:
        initialValuesInDollars.beanoGameReceiptsDetails.otherReceiptsInDollars,
      [AnnualBeanoStepTwoField.otherReceiptsDescription]:
        initialValuesInDollars.beanoGameReceiptsDetails
          .otherReceiptsDescription,
      [AnnualBeanoStepTwoField.prizesInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.prizesInDollars,
      [AnnualBeanoStepTwoField.taxPaidInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.taxPaidInDollars,
      [AnnualBeanoStepTwoField.rentInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.rentInDollars,
      [AnnualBeanoStepTwoField.equipmentInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.equipmentInDollars,
      [AnnualBeanoStepTwoField.suppliesInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.suppliesInDollars,
      [AnnualBeanoStepTwoField.servicesInDollars]:
        initialValuesInDollars.beanoGameExpenditureDetails.servicesInDollars,
      [AnnualBeanoStepTwoField.otherExpenses]:
        initialValuesInDollars.beanoGameExpenditureDetails.otherExpenses,
      [AnnualBeanoStepTwoField.ticketProceedsInDollars]:
        initialValuesInDollars.charityGameTicketDetails.ticketProceedsInDollars,
      [AnnualBeanoStepTwoField.ticketCostsInDollars]:
        initialValuesInDollars.charityGameTicketDetails.ticketCostsInDollars
    },
    [AnnualBeanoFormStep.StepThree]: {
      ...defaultStepThreeFormData,
      [AnnualBeanoStepThreeField.charitableInDollars]:
        initialValuesInDollars.totalDisbursementDetails.charitableInDollars,
      [AnnualBeanoStepThreeField.patrioticInDollars]:
        initialValuesInDollars.totalDisbursementDetails.patrioticInDollars,
      [AnnualBeanoStepThreeField.youthActivitiesInDollars]:
        initialValuesInDollars.totalDisbursementDetails
          .youthActivitiesInDollars,
      [AnnualBeanoStepThreeField.educationalInDollars]:
        initialValuesInDollars.totalDisbursementDetails.educationalInDollars,
      [AnnualBeanoStepThreeField.religiousInDollars]:
        initialValuesInDollars.totalDisbursementDetails.religiousInDollars,
      [AnnualBeanoStepThreeField.firemenActivitiesInDollars]:
        initialValuesInDollars.totalDisbursementDetails
          .firemenActivitiesInDollars,
      [AnnualBeanoStepThreeField.publicAmbulanceRescueServicesInDollars]:
        initialValuesInDollars.totalDisbursementDetails
          .publicAmbulanceRescueServicesInDollars,
      [AnnualBeanoStepThreeField.otherDisbursements]:
        initialValuesInDollars.totalDisbursementDetails.otherDisbursements,
      [AnnualBeanoStepThreeField.beanoCheckingBalanceInDollars]:
        initialValuesInDollars.fundsAvailableForDisbursementDetails
          .beanoCheckingBalanceInDollars,
      [AnnualBeanoStepThreeField.beanoSavingsBalanceInDollars]:
        initialValuesInDollars.fundsAvailableForDisbursementDetails
          .beanoSavingsBalanceInDollars,
      [AnnualBeanoStepThreeField.otherIncomeInDollars]:
        initialValuesInDollars.fundsAvailableForDisbursementDetails
          .otherIncomeInDollars,
      [AnnualBeanoStepThreeField.otherIncomeDescription]:
        initialValuesInDollars.fundsAvailableForDisbursementDetails
          .otherIncomeDescription,
      [AnnualBeanoStepThreeField.specialBeanoCheckbookBalanceInDollars]:
        initialValuesInDollars.totalSpecialBeanoAccountDetails
          .specialBeanoCheckbookBalanceInDollars,
      [AnnualBeanoStepThreeField.specialBeanoSavingsBalanceInDollars]:
        initialValuesInDollars.totalSpecialBeanoAccountDetails
          .specialBeanoSavingsBalanceInDollars
    },
    [AnnualBeanoFormStep.Submit]: {
      memberRole:
        getMemberRole(initialValuesInDollars.declarationDetails?.memberRole) ??
        '',
      email: initialValuesInDollars.declarationDetails?.email ?? '',
      phoneNumber: initialValuesInDollars.declarationDetails?.phoneNumber ?? '',
      signature: initialValuesInDollars.declarationDetails?.signature ?? '',
      date: initialValuesInDollars.declarationDetails?.date ?? ''
    },
    currentStep: 1
  }
  const incomeTotals = getAnnualBeanoIncomeTotals(
    formDataNoTotals[AnnualBeanoFormStep.StepTwo]
  )
  const disbursementTotals = getAnnualBeanoDisbursementTotals(
    formDataNoTotals[AnnualBeanoFormStep.StepThree],
    incomeTotals.totalNetProfitOrLossDollars
  )
  return {
    ...formDataNoTotals,
    totals: {
      ...incomeTotals,
      ...disbursementTotals
    }
  }
}

export const AnnualBeanoTransformers = {
  getFormStateFromInitialValues,
  getSubmissionBodyFromState
}
