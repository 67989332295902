import { CasinoStepTwoTotals } from '../step-2/constants'
import { CasinoStepThreeTotals } from '../step-3/constants'
import { CompleteCasinoFormState, CasinoFormStep } from './shape'

export enum CasinoFormActions {
  UPDATE_FORM_STATE = 'UPDATE_FORM_STATE',
  CHANGE_FORM_STEP = 'CHANGE_FORM_STEP'
}

export type CasinoFormPayload = Omit<
  CompleteCasinoFormState,
  'totals' | 'currentStep'
>

export type CasinoFormTotalsByStep = {
  [CasinoFormStep.StepTwo]: CasinoStepTwoTotals
  [CasinoFormStep.StepThree]: CasinoStepThreeTotals
}

type StepWithTotals = keyof CasinoFormTotalsByStep

export type CasinoTotal<Step extends CasinoFormStep> =
  Step extends StepWithTotals ? CasinoFormTotalsByStep[Step] : never

export type ChangeCasinoFormStep = {
  type: CasinoFormActions.CHANGE_FORM_STEP
  step: number
}

export type UpdateCasinoFormAction<Step extends CasinoFormStep> = {
  type: CasinoFormActions.UPDATE_FORM_STATE
  step: Step
  payload: CasinoFormPayload[Step]
  totals?: CasinoTotal<Step>
}

export type CasinoFormAction<Step extends CasinoFormStep> =
  | ChangeCasinoFormStep
  | UpdateCasinoFormAction<Step>

const changeFormStep = (step: number): ChangeCasinoFormStep => ({
  type: CasinoFormActions.CHANGE_FORM_STEP,
  step
})

const updateFormStep = <Step extends CasinoFormStep>(
  step: Step,
  payload: CasinoFormPayload[Step],
  totals?: CasinoTotal<Step>
): UpdateCasinoFormAction<Step> => ({
  type: CasinoFormActions.UPDATE_FORM_STATE,
  step,
  payload,
  totals
})

export const casinoFormAction = {
  changeFormStep,
  updateFormStep
}
