import * as React from 'react'
import { InputNumberAndText } from '@Components/form-general/input-number-and-text/input-number-and-text-ui'
import { getBlockClass } from '@Helpers/components'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { fundsForDisbursementFields } from './constants'
import './styles.scss'

export type AnnualBeanoFundsForDisbursementProps = {
  totalNetProfitOrLossDollars: number
  fundsAvailableForDisbursementDollars: number
}

const ROOT_CLASS = 'annual-beano-funds-for-disbursement'

export const AnnualBeanoFundsForDisbursement = (
  props: AnnualBeanoFundsForDisbursementProps
) => {
  return (
    <section className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'fields')}>
        {fundsForDisbursementFields.map((field) => (
          <FormSubsection type="primary" key={field.header}>
            <InputNumberAndText {...field} />
          </FormSubsection>
        ))}
        <FormSubsection type="primary">
          <div className={getBlockClass(ROOT_CLASS, 'net-profit-loss')}>
            <h4>4. Net profit or loss from Beano and Charity Game Tickets</h4>
            <ReadOnlyField
              value={getDollarString(props.totalNetProfitOrLossDollars)}
              noMargin
            />
          </div>
        </FormSubsection>
      </div>
      <FormSummary>
        <div className={getBlockClass(ROOT_CLASS, 'total')}>
          <FormSummary.Title>
            Total Funds Available for Disbursement
          </FormSummary.Title>
        </div>
        <FormSummary.Total>
          {getDollarString(props.fundsAvailableForDisbursementDollars)}
        </FormSummary.Total>
      </FormSummary>
    </section>
  )
}
