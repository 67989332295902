import * as React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { getBlockClass } from '@Helpers/components'
import '@reach/dialog/styles.css'
import './styles.scss'

export type ModalProps = {
  ariaLabel: string
  isOpen: boolean
  handleClose: () => void
}

const ROOT_CLASS = 'modal'

export const Modal: React.FC<ModalProps> = (props) => {
  return (
    <DialogOverlay
      className={getBlockClass(ROOT_CLASS, 'overlay')}
      isOpen={props.isOpen}
      onDismiss={props.handleClose}
    >
      <DialogContent
        className={getBlockClass(ROOT_CLASS, 'content')}
        aria-label={props.ariaLabel}
      >
        {props.children}
      </DialogContent>
    </DialogOverlay>
  )
}
