import dateUtil from '.'
import { Dayjs } from 'dayjs'
import { DayFormat } from './constants'

export const getDayOfWeek = (date: string) => {
  const dayObj = dateUtil(date, DayFormat.monthDayYear)
  return dayObj.isValid() ? dayObj.format(DayFormat.dayOfWeek) : ''
}

export const clearTimes = (dayjs: Dayjs) =>
  dayjs.hour(0).minute(0).second(0).millisecond(0)
