import * as React from 'react'
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonHeight
} from '@Components/button/button-ui'
import './styles.scss'

export type Props = {
  onClick: () => void
}

const ROOT_CLASS = 'button-add'

const IconAdd = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Add</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon/add/dark" fill="#0F1821" fillRule="nonzero">
        <path
          d="M16,0 C17.6633517,0 19.0302905,1.26909458 19.1853513,2.89181864 L19.2,3.2 L19.2,12.8 L28.8,12.8 C30.5673112,12.8 32,14.2326888 32,16 C32,17.6633517 30.7309054,19.0302905 29.1081814,19.1853513 L28.8,19.2 L19.2,19.2 L19.2,28.8 C19.2,30.5673112 17.7673112,32 16,32 C14.3366483,32 12.9697095,30.7309054 12.8146487,29.1081814 L12.8,28.8 L12.8,19.2 L3.2,19.2 C1.4326888,19.2 4.53827331e-13,17.7673112 4.57074923e-13,16 C4.6013148e-13,14.3366483 1.26909458,12.9697095 2.89181864,12.8146487 L3.2,12.8 L12.8,12.8 L12.8,3.2 C12.8,1.4326888 14.2326888,0 16,0 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
)

export const ButtonAdd: React.FC<Props> = (props) => (
  <Button
    style={ButtonFill.filled}
    color={ButtonColor.green}
    height={ButtonHeight.skinny}
    htmlProps={{
      onClick: props.onClick,
      'aria-label': 'add field'
    }}
  >
    <span className={ROOT_CLASS}>
      {props.children} <IconAdd />
    </span>
  </Button>
)
