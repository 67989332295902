import { getObjectKeys } from '@Helpers/util'
import { RaffleFormActivityTypeFormData } from '../activity-type/constants'
import { getRaffleStateFromInitialValues } from '../api/transformers'
import { RaffleStepOneFormData } from '../step-1/constants'
import {
  DollarizedRaffleStepTwoTotals,
  RaffleStepTwoFormData
} from '../step-2/constants'
import {
  DollarizedRaffleStepThreeTotals,
  RaffleStepThreeFormData
} from '../step-3/constants'
import { RaffleSubmitFormData } from '../submit/constants'

export enum RaffleFormStep {
  ActivityType = 'activityType',
  StepOne = 'stepOne',
  StepTwo = 'stepTwo',
  StepThree = 'stepThree',
  Submit = 'submit'
}

export type RaffleTotals = DollarizedRaffleStepTwoTotals &
  DollarizedRaffleStepThreeTotals

export type RaffleFormState = {
  [RaffleFormStep.ActivityType]?: RaffleFormActivityTypeFormData
  [RaffleFormStep.StepOne]?: RaffleStepOneFormData
  [RaffleFormStep.StepTwo]?: RaffleStepTwoFormData
  [RaffleFormStep.StepThree]?: RaffleStepThreeFormData
  [RaffleFormStep.Submit]?: RaffleSubmitFormData
  currentStep: number
  totals: RaffleTotals
}

export type CompleteRaffleFormState = Required<RaffleFormState>

export const isCompleteRaffleForm = (
  formState: RaffleFormState | CompleteRaffleFormState
): formState is CompleteRaffleFormState =>
  getObjectKeys(formState)
    .map((key) => Boolean(formState[key]))
    .reduce((acc, curr) => acc && curr, true)

export const getInitialState = (initialValues?: any): RaffleFormState =>
  initialValues
    ? getRaffleStateFromInitialValues(initialValues)
    : {
        currentStep: 1,
        totals: {
          totalTaxDueInDollars: 0,
          grossReceiptsInDollars: 0,
          netProfitInDollars: 0,
          totalExpensesInDollars: 0,
          baseTaxInDollars: 0,
          totalTicketSalesInDollars: 0
        }
      }
