import * as React from 'react'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { ButtonDelete } from '@Components/button-delete/button-delete-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonHeight
} from '@Components/button/button-ui'
import { ButtonAdd } from '@Components/button-add/button-add-ui'
import './styles.scss'

export type Props = {
  elements: React.ReactNode[]
  getSubtotalDisplay?: (index: number) => string
  removeElement: (index: number) => void
  addElement: () => void
  onResetClick: () => void
  isResetDisabled: boolean
  addButtonText: string
  resetButtonText: string
  className?: string
}

const ROOT_CLASS = 'dynamic-field-manager'
const ELEMENT_BLOCK = 'element'

export const DynamicFieldManager = (props: Props) => (
  <section className={joinClasses([ROOT_CLASS, props.className])}>
    {props.elements.map((element, index) => (
      <div
        className={joinClasses([
          getBlockClass(ROOT_CLASS, ELEMENT_BLOCK),
          props.className
            ? getBlockClass(props.className, ELEMENT_BLOCK)
            : undefined
        ])}
        key={`dynamic-field-${index}`}
      >
        {element}
        {index > 0 && (
          <ButtonDelete
            onClick={() => props.removeElement(index)}
            className={getBlockClass(ROOT_CLASS, 'delete-button')}
          />
        )}
        {props.getSubtotalDisplay && (
          <ReadOnlyField
            value={props.getSubtotalDisplay(index)}
            className={getBlockClass(ROOT_CLASS, 'subtotal')}
            noMargin
          />
        )}
      </div>
    ))}
    <div className={getBlockClass(ROOT_CLASS, 'button-container')}>
      <ButtonAdd onClick={props.addElement}>{props.addButtonText}</ButtonAdd>
      <Button
        style={ButtonFill.outlined}
        color={ButtonColor.green}
        height={ButtonHeight.skinny}
        htmlProps={{
          disabled: props.isResetDisabled,
          onClick: props.onResetClick,
          'aria-label': 'reset fields'
        }}
      >
        {props.resetButtonText}
      </Button>
    </div>
  </section>
)
