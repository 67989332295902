import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { InputRadio } from '@Components/input-radio/input-radio-ui'
import { InputBaseLabel } from '@Components/input-base/input-label-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  PaidViaEftSelection,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../constants'
import './styles.scss'

export type Props = {
  mslcTaxPaidInDollars: number
}

const ROOT_CLASS = 'weekly-beano-step-three-tax-paid'
const PAID_VIA_EFT_OPTION_CLASS = getBlockClass(ROOT_CLASS, 'eft-option')
const PAID_VIA_EFT_OPTION_TEXT_CLASS = getBlockClass(
  ROOT_CLASS,
  'eft-option-text'
)

const PaidViaEFT = () => {
  const { register, watch } = useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const checkedValue = watch(WeeklyBeanoStepThreeField.paidViaEftSelection)

  return (
    <div className={getBlockClass(ROOT_CLASS, 'paid-via-eft')}>
      <InputBaseLabel labelText={WeeklyBeanoStepThreeFieldLabel.paidViaEFT} />
      <div className={getBlockClass(ROOT_CLASS, 'paid-via-eft-options')}>
        <InputRadio
          {...register(WeeklyBeanoStepThreeField.paidViaEftSelection)}
          value={PaidViaEftSelection.yes}
          id={`PaidViaEFTSelection-${PaidViaEftSelection.yes}`}
          checked={checkedValue === PaidViaEftSelection.yes}
          className={PAID_VIA_EFT_OPTION_CLASS}
        >
          <p className={PAID_VIA_EFT_OPTION_TEXT_CLASS}>
            {PaidViaEftSelection.yes}
          </p>
        </InputRadio>
        <InputRadio
          {...register(WeeklyBeanoStepThreeField.paidViaEftSelection)}
          value={PaidViaEftSelection.no}
          id={`PaidViaEFTSelection-${PaidViaEftSelection.no}`}
          checked={checkedValue === PaidViaEftSelection.no}
          className={PAID_VIA_EFT_OPTION_CLASS}
        >
          <p className={PAID_VIA_EFT_OPTION_TEXT_CLASS}>
            {PaidViaEftSelection.no}
          </p>
        </InputRadio>
      </div>
    </div>
  )
}

export const TaxPaid = (props: Props) => {
  const { register, watch, setValue } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const amountDisplay = getDollarString(props.mslcTaxPaidInDollars)
  const paidViaEFTSelection = watch(
    WeeklyBeanoStepThreeField.paidViaEftSelection
  )

  React.useEffect(() => {
    if (paidViaEFTSelection === PaidViaEftSelection.yes) {
      setValue(WeeklyBeanoStepThreeField.checkNumber, '')
    }
  }, [setValue, paidViaEFTSelection])

  return (
    <FormSubsection type="primary">
      <h3 className={getBlockClass(ROOT_CLASS, 'header')}>
        1. Tax Paid to MSLC
      </h3>
      <div className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
          <PaidViaEFT />
          <Input
            {...register(WeeklyBeanoStepThreeField.checkNumber)}
            labelText={WeeklyBeanoStepThreeFieldLabel.checkNumberIfApplicable}
            disabled={paidViaEFTSelection === PaidViaEftSelection.yes}
          />
          <ReadOnlyField
            label="Amount (5% of Gross Beano Receipts)"
            value={amountDisplay}
          />
        </div>
        <ReadOnlyField label="Total" value={amountDisplay} alignRight />
      </div>
    </FormSubsection>
  )
}
