import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'
import { AnnualBeanoDeclarationDetails } from '../api/types'

export const MemberRoleOptions: DropdownOptions = {
  ['Member in Charge']: 'Member in Charge',
  Bookkeeper: 'Bookkeeper',
  ['Head of Organization']: 'Head of Organization'
}

export enum AnnualBeanoSubmitFieldLabel {
  memberRole = 'Member Role',
  phoneNumber = 'Phone No.',
  signature = 'Signature'
}

export enum AnnualBeanoSubmitField {
  memberRole = 'memberRole',
  phoneNumber = 'phoneNumber',
  signature = 'signature',
  email = 'email'
}

export type AnnualBeanoSubmitFormData = AnnualBeanoDeclarationDetails
