import { transformDollarsToCents } from '@Components/form-general/transformers'
import { CentsifiedData } from '@Components/form-general/types'
import { RaffleTotals } from '@Components/raffle-form/form-state-reducer/shape'
import { RaffleStepThreeFormData } from '@Components/raffle-form/step-3/constants'
import { RaffleFormSubmissionBody } from '../../types'

type RaffleStepThreeSubmissionData = Pick<
  RaffleFormSubmissionBody,
  'expensesDetails' | 'totalExpensesInCents' | 'netProfitInCents'
>

export const transformRaffleStepThreeToSubmission = (
  formData: RaffleStepThreeFormData,
  totalsInCents: CentsifiedData<RaffleTotals>
): RaffleStepThreeSubmissionData => {
  const formDataInCents = transformDollarsToCents(formData)
  return {
    expensesDetails: {
      totalPrizesAwardedInCents: formDataInCents.totalPrizesAwardedInCents,
      totalPrizesAwardedDescription:
        formDataInCents.totalPrizesAwardedDescription,
      taxPaid: {
        amountInCents: totalsInCents.totalTaxDueInCents,
        checkNumber: formDataInCents.taxPaidCheckNumber
      },
      otherExpenses: formDataInCents.otherExpenses
    },
    totalExpensesInCents: totalsInCents.totalExpensesInCents,
    netProfitInCents: totalsInCents.netProfitInCents
  }
}
