import * as React from 'react'
import { joinClasses } from '@Helpers/components'
import './styles.scss'

type TextAreaElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export type Props = {
  className?: string
  placeholder?: string
} & TextAreaElementProps

const ROOT_CLASS = 'input-textarea'

export const InputTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => (
    <textarea
      {...props}
      className={joinClasses([ROOT_CLASS, props.className])}
      ref={ref}
      placeholder={props.placeholder ?? 'Begin typing…'}
    />
  )
)
