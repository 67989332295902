import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import { CasinoFormBodyResponse, CasinoFormSubmissionBody } from '../api/types'
import { CasinoFormReducer } from '../form-state-reducer/reducer'
import { CasinoFormStep, getInitialState } from '../form-state-reducer/shape'
import { CasinoFormStepOne } from '../step-1/casino-form-step-one-ui'
import { CasinoFormStepTwo } from '../step-2/casino-step-two-ui'
import { CasinoFormStepThree } from '../step-3/casino-step-three-ui'
import { CasinoSignAndSubmit } from '../sign-and-submit/casino-sign-and-submit'
import { CasinoSubmitFields } from '../sign-and-submit/constants'
import { CasinoStepOneField } from '../step-1/constants'
import './styles.scss'

export type CasinoFormPrintProps = {
  formData: CasinoFormSubmissionBody | CasinoFormBodyResponse
  organizationDetails: OrganizationDetailsProps
}

const ROOT_CLASS = 'casino-form-print'
const noBackClick = () => {}
const noSubmit = (formData: any) => {}
const today = dateUtil().format(DayFormat.monthDayYearDoubleDigit)

export const CasinoFormPrint = React.forwardRef<
  HTMLElement,
  CasinoFormPrintProps
>((props, ref) => {
  const [state] = React.useReducer(
    CasinoFormReducer,
    getInitialState(props.formData)
  )
  return (
    <section ref={ref} className={ROOT_CLASS}>
      <CasinoFormStepOne
        organizationDetails={props.organizationDetails}
        initialValues={state[CasinoFormStep.StepOne]}
        onSubmit={noSubmit}
        printMode
      />
      <CasinoFormStepTwo
        initialValues={state[CasinoFormStep.StepTwo]}
        onBackClick={noBackClick}
        onSubmit={noSubmit}
        printMode
      />
      <CasinoFormStepThree
        initialValues={state[CasinoFormStep.StepThree]}
        onBackClick={noBackClick}
        onSubmit={noSubmit}
        netProfitInDollars={state.totals.netProfitInDollars}
        printMode
      />
      <CasinoSignAndSubmit
        initialValues={state[CasinoFormStep.Submit]}
        onBackClick={noBackClick}
        onSubmit={noSubmit}
        date={state[CasinoFormStep.Submit]?.[CasinoSubmitFields.date] ?? today}
        dateOfOccasion={dateUtil(
          state[CasinoFormStep.StepOne]?.[CasinoStepOneField.occasionDate]
        )}
        printMode
      />
    </section>
  )
})
