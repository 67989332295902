import { getBlockClass } from '@Helpers/components'
import { subtractList } from '@Helpers/numbers'

export const PROGRESSIVE_GAME_SUBSECTION_CLASS = 'progressive-game-subsection'
export const ROW_CLASS = getBlockClass(PROGRESSIVE_GAME_SUBSECTION_CLASS, 'row')
export const ROW_LABEL_CLASS = getBlockClass(
  PROGRESSIVE_GAME_SUBSECTION_CLASS,
  'row-label'
)
export const ROW_VALUE_CLASS = getBlockClass(
  PROGRESSIVE_GAME_SUBSECTION_CLASS,
  'row-value'
)

export const getLicenseeProfitInDollars = (
  progressiveProceedsInDollars: number,
  progressiveTaxPaidInDollars: number,
  progressivePrizesPaidInDollars: number
) =>
  subtractList([
    progressiveProceedsInDollars,
    progressiveTaxPaidInDollars,
    progressivePrizesPaidInDollars
  ])
