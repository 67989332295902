import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Helpers/components'
import { INPUT_ROOT_CLASS } from './constants'
import './styles.scss'

type InputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const ROOT_CLASS = getBlockClass(INPUT_ROOT_CLASS, 'base')

export type InputBaseProps = {
  className?: string
  error?: string | boolean
  moneyField?: boolean
} & Pick<
  InputElementProps,
  | 'disabled'
  | 'max'
  | 'min'
  | 'name'
  | 'required'
  | 'type'
  | 'onChange'
  | 'onBlur'
  | 'placeholder'
  | 'defaultValue'
>

const getStep = (
  type: InputElementProps['type'],
  moneyField?: boolean
): InputElementProps['step'] => {
  if (type !== 'number') {
    return undefined
  }
  return moneyField ? '0.01' : '1'
}

const getInputMode = (
  type: InputElementProps['type'],
  moneyField?: boolean
): InputElementProps['inputMode'] => {
  if (type !== 'number') {
    return undefined
  }
  return moneyField ? 'decimal' : 'numeric'
}

export const InputBase = React.forwardRef(
  (props: InputBaseProps, ref: React.Ref<HTMLInputElement>) => {
    const errorClass = getModifierClass(
      ROOT_CLASS,
      'error',
      Boolean(props.error)
    )
    return (
      <input
        className={joinClasses([ROOT_CLASS, errorClass, props.className])}
        aria-labelledby={`${props.name}-label`}
        ref={ref}
        id={props.name}
        type={props.type}
        step={getStep(props.type, props.moneyField)}
        disabled={props.disabled}
        inputMode={getInputMode(props.type, props.moneyField)}
        max={props.max}
        min={props.min}
        name={props.name}
        required={props.required}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={(e) => e.target.select()}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
    )
  }
)
