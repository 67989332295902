import { DollarizedData } from '@Components/form-general/types'
import { OtherExpendituresDetails } from '@Components/raffle-form/api/types'
import {
  CasinoExpendituresDetail,
  CasinoReceiptsDetails,
  CasinoSummaryOfPrizes,
  DollarizedCasinoFormSubmissionBody,
  CasinoPaymentDetails
} from '../api/types'

export enum CasinoStepTwoField {
  // receipts
  admissionTicketsInDollars = 'admissionTicketsInDollars',
  raffleTicketsInDollars = 'raffleTicketsInDollars',
  gamingTablesInDollars = 'gamingTablesInDollars',
  overShortInDollars = 'overShortInDollars',
  overShortSelection = 'overShortSelection',
  otherReceiptsInDollars = 'otherReceiptsInDollars',
  otherReceiptsDescription = 'otherReceiptsDescription',

  // prizes
  cashDoorPrize1InDollars = 'cashDoorPrize1.amountInDollars',
  cashDoorPrize1NumPrizes = 'cashDoorPrize1.numPrizes',
  cashDoorPrize2InDollars = 'cashDoorPrize2.amountInDollars',
  cashDoorPrize2NumPrizes = 'cashDoorPrize2.numPrizes',
  cashRafflePrize1InDollars = 'cashRafflePrize1.amountInDollars',
  cashRafflePrize1NumPrizes = 'cashRafflePrize1.numPrizes',
  cashRafflePrize2InDollars = 'cashRafflePrize2.amountInDollars',
  cashRafflePrize2NumPrizes = 'cashRafflePrize2.numPrizes',
  merchandiseDoorPrizeInDollars = 'merchandiseDoorPrize.valueInDollars',
  merchandiseDoorPrizeDescription = 'merchandiseDoorPrize.description',
  merchandiseRafflePrizeInDollars = 'merchandiseRafflePrize.valueInDollars',
  merchandiseRafflePrizeDescription = 'merchandiseRafflePrize.description',

  // expenditures
  chipRedemptionsInDollars = 'chipRedemptionsInDollars',
  penaltyAndInterestInDollars = 'penaltyAndInterestInDollars',
  totalTaxDueInDollars = 'totalTaxDueInDollars',
  taxCheckNumber = 'totalTaxDue.checkNumber',
  equipmentCheckNumber = 'equipmentSuppliesFee.checkNumber',
  equipmentAmountInDollars = 'equipmentSuppliesFee.amountInDollars',
  equipmentFirm = 'equipmentSuppliesFee.nameOfFirm',
  hallCheckNumber = 'hallRentalFee.checkNumber',
  hallAmountInDollars = 'hallRentalFee.amountInDollars',
  hallFirm = 'hallRentalFee.nameOfHall',
  hallAddress = 'hallRentalFee.hallAddress',
  otherExpenditures = 'otherExpenditures'
}

export enum CasinoStepTwoFieldLabel {
  // receipts
  amount = 'Amount',
  description = 'Description',
  selectOverUnder = 'Select (for a $0.00 balance, select either option)',

  // prizes
  numPrizes = 'No. of Prizes',
  prizeAmount = 'Prize Amount',

  // expenditures
  checkNumber = 'Check No.',
  taxDue = 'Tax Due (5% of Gross Receipts)',
  penaltyAndInterest = 'Penalty & Interest',
  nameOfFirm = 'Name of Firm',
  nameOfHall = 'Name of Hall',
  address = 'Address',
  payee = 'Payee'
}

type DollarizedCasinoExpenditures = DollarizedData<CasinoExpendituresDetail>
type DollarizedPaymentDetails = DollarizedData<CasinoPaymentDetails>

type ExpenditureTotals = Pick<
  DollarizedCasinoExpenditures,
  'taxDueInDollars' // 5% auto-calculated
> & {
  totalTaxDueInDollars: number // taxDueInDollars + penalty and interest
}

type ExpenditureFormData = Omit<
  DollarizedCasinoExpenditures,
  'taxDueInDollars'
> & {
  totalTaxDue: Omit<DollarizedPaymentDetails, 'amountInDollars'>
}

export const defaultOtherExpenditure: DollarizedData<OtherExpendituresDetails> =
  {
    payee: '',
    amountInDollars: 0,
    description: '',
    checkNumber: ''
  }

export type CasinoStepTwoTotals = Pick<
  DollarizedCasinoFormSubmissionBody,
  | 'grossReceiptsInDollars'
  | 'totalExpendituresInDollars'
  | 'grandTotalCashPrizesInDollars'
  | 'netProfitInDollars'
> &
  ExpenditureTotals

export type CasinoStepTwoFormData = DollarizedData<CasinoReceiptsDetails> &
  ExpenditureFormData &
  DollarizedData<CasinoSummaryOfPrizes>

export const casinoStepTwoDefaultFormData: Partial<CasinoStepTwoFormData> = {
  admissionTicketsInDollars: 0,
  raffleTicketsInDollars: 0,
  gamingTablesInDollars: 0,
  overShortInDollars: 0,
  otherReceiptsInDollars: 0,
  otherReceiptsDescription: '',
  chipRedemptionsInDollars: 0,
  penaltyAndInterestInDollars: 0,
  equipmentSuppliesFee: {
    amountInDollars: 0,
    checkNumber: '',
    nameOfFirm: ''
  },
  hallRentalFee: {
    amountInDollars: 0,
    checkNumber: '',
    nameOfHall: '',
    hallAddress: ''
  },
  otherExpenditures: [defaultOtherExpenditure]
}
