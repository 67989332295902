import * as React from 'react'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { SpecialGameGroup } from './special-game-group-ui'
import { getSpecialGameRowTotal } from '../helpers'
import { BeanoSpecialGameName } from './constants'
import {
  defaultSpecialGameInputRow,
  WeeklyBeanoReceiptField,
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import './styles.scss'

const ROOT_CLASS = 'winner-take-all'

export const WinnerTakeAllSection = () => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<
    WeeklyBeanoStepTwoFormData,
    WeeklyBeanoStepTwoField.wtaGameReceiptDetails
  >({
    name: WeeklyBeanoStepTwoField.wtaGameReceiptDetails,
    defaultData: defaultSpecialGameInputRow,
    getSubtotal: getSpecialGameRowTotal
  })
  return (
    <div className={ROOT_CLASS}>
      <DynamicFieldManager
        elements={fields.map((data, index) => (
          <SpecialGameGroup
            key={data.id}
            type={BeanoSpecialGameName.winnerTakeAll}
            cardInputsPropsOne={{
              type: WeeklyBeanoStepTwoField.wtaGameReceiptDetails,
              numCardsfieldName: WeeklyBeanoReceiptField.oddNumCards,
              pricePerCardFieldName: WeeklyBeanoReceiptField.oddPriceInDollars,
              id: data.id,
              index: index
            }}
            cardInputsPropsTwo={{
              type: WeeklyBeanoStepTwoField.wtaGameReceiptDetails,
              numCardsfieldName: WeeklyBeanoReceiptField.evenNumCards,
              pricePerCardFieldName: WeeklyBeanoReceiptField.evenPriceInDollars,
              id: data.id,
              index: index
            }}
          />
        ))}
        removeElement={removeElement}
        addElement={addElement}
        onResetClick={reset}
        getSubtotalDisplay={getSubtotalDisplay}
        isResetDisabled={isResetDisabled()}
        addButtonText={'Add Another WTA'}
        resetButtonText={'Reset WTA'}
      />
    </div>
  )
}
