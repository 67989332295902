import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  WeeklyBeanoStepTwoFieldLabels,
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { getCleanNumber } from '@Helpers/numbers'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import './styles.scss'

const ROOT_CLASS = 'other-receipts'

export const OtherReceipts = React.memo(() => {
  const { register, registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  const dollarTotal = getDollarString(
    getCleanNumber(watch(WeeklyBeanoStepTwoField.otherReceiptsAmount))
  )
  return (
    <FormSubsection type="primary">
      <h3>5. Other Receipts</h3>
      <div className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
          <Input
            {...register(WeeklyBeanoStepTwoField.otherReceiptsDescription)}
            labelText={WeeklyBeanoStepTwoFieldLabels.description}
          />
          <Input
            {...registerNumber(
              WeeklyBeanoStepTwoFieldLabels.amount,
              WeeklyBeanoStepTwoField.otherReceiptsAmount
            )}
            moneyField
          />
        </div>
        <ReadOnlyField value={dollarTotal} alignRight />
      </div>
    </FormSubsection>
  )
})
