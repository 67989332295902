import * as React from 'react'
import {
  useFormContextPlus,
  FieldPath,
  FieldValues
} from '@Hooks/use-form-plus'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { InputDropdown } from '@Components/input-dropdown/input-dropdown-ui'
import { Input } from '@Components/input-base/input-ui'
import { getStartEndTimeOptions } from './helpers'
import { StartEndTimeValidation } from './validators'
import { StartEndTimeFieldLabel } from './constants'
import './styles.scss'

export type StartAndEndTimeProps<
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
> = {
  startTime: TFieldNames
  endTime: TFieldNames
  numPlayers: TFieldNames
  className?: string
}

const ROOT_CLASS = 'start-and-end-times'
const SECTION_CLASS_NAME = getBlockClass(ROOT_CLASS, 'section')
const timeOptions = getStartEndTimeOptions()

export const StartAndEndTimes = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
>(
  props: StartAndEndTimeProps<TFieldValues, TFieldNames>
) => {
  const {
    registerRequired,
    registerRequiredNumber,
    getValues,
    trigger,
    watch
  } = useFormContextPlus<TFieldValues>()
  const startTimeWatchValue = watch(props.startTime)
  const endTimeWatchValue = watch(props.endTime)

  React.useEffect(() => {
    if (startTimeWatchValue && endTimeWatchValue) {
      trigger([props.startTime, props.endTime])
    }
  }, [props.startTime, props.endTime, startTimeWatchValue, endTimeWatchValue])

  return (
    <div className={joinClasses([SECTION_CLASS_NAME, props.className])}>
      <div className={SECTION_CLASS_NAME}>
        <InputDropdown
          isPlaceholderValue={!startTimeWatchValue}
          options={timeOptions}
          placeholder={'6:30 PM'}
          {...registerRequired(
            StartEndTimeFieldLabel.startTime,
            props.startTime,
            {
              validate: StartEndTimeValidation.startTime(
                getValues,
                props.endTime
              )
            }
          )}
        />
        <InputDropdown
          isPlaceholderValue={!endTimeWatchValue}
          options={timeOptions}
          placeholder={'10:30 PM'}
          {...registerRequired(StartEndTimeFieldLabel.endTime, props.endTime, {
            validate: StartEndTimeValidation.endTime(getValues, props.startTime)
          })}
        />
      </div>
      <Input
        {...registerRequiredNumber(
          StartEndTimeFieldLabel.numPlayers,
          props.numPlayers,
          {
            min: {
              value: 1,
              message: `${StartEndTimeFieldLabel.numPlayers} must be at least 1`
            }
          }
        )}
      />
    </div>
  )
}
