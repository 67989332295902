import { DollarizedData } from '@Components/form-general/types'
import {
  OtherExpendituresDetails,
  RaffleExpensesDetails,
  RaffleFormSubmissionBody
} from '../api/types'

export enum RaffleStepThreeField {
  totalPrizesAwardedInDollars = 'totalPrizesAwardedInDollars',
  totalPrizesAwardedDescription = 'totalPrizesAwardedDescription',
  taxPaidInDollars = 'taxPaidInDollars',
  taxPaidCheckNumber = 'taxPaidCheckNumber',
  otherExpenses = 'otherExpenses'
}

export enum RaffleStepThreeFieldLabel {
  amountValue = 'Amount/Value',
  amount = 'Amount',
  description = 'Description',
  checkNumberIfApplicable = 'Check No. (if applicable)',
  checkNumber = 'Check No',
  taxAmount = 'Amount (5% of Gross Receipts)',
  payee = 'Payee'
}

type DollarizedPaymentDetails = {
  taxPaidCheckNumber: string
}
type DollarizedExpenseDetails = DollarizedData<
  Omit<RaffleExpensesDetails, 'taxPaid'>
>
type DollarizedOtherExpendituresDetails =
  DollarizedData<OtherExpendituresDetails>

export const defaultRaffleOtherExpenditure: DollarizedOtherExpendituresDetails =
  {
    checkNumber: '',
    payee: '',
    description: '',
    amountInDollars: 0
  }

export type DollarizedRaffleStepThreeTotals = DollarizedData<
  Pick<RaffleFormSubmissionBody, 'totalExpensesInCents' | 'netProfitInCents'>
>

export type RaffleStepThreeFormData = DollarizedExpenseDetails &
  DollarizedPaymentDetails

export const defaultRaffleStepThreeFormData: RaffleStepThreeFormData = {
  [RaffleStepThreeField.totalPrizesAwardedInDollars]: 0,
  [RaffleStepThreeField.totalPrizesAwardedDescription]: '',
  [RaffleStepThreeField.taxPaidCheckNumber]: '',
  [RaffleStepThreeField.otherExpenses]: [defaultRaffleOtherExpenditure]
}
