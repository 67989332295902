import * as React from 'react'
import { useDynamicFields, useFormContext } from '@Hooks/use-form-plus'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { PrizeDistributionSection } from './prize-distribution-section'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDynamicSummarySubtotal } from '../constants'
import { getDollarString } from '@Helpers/currency'
import { PrizeDistributionGame } from './prize-distribution-game-names'
import {
  defaultPrizeDetail,
  PrizeSummaryDynamicField,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import './styles.scss'

export type PrizeDistributionDynamicSectionProps = {
  subtotalHeader: string
  resetButtonText: string
  addButtonText: string
  fieldName: PrizeSummaryDynamicField
}

const ROOT_CLASS = 'prize-distribution-dynamic'

const getHeaderName =
  (fieldName: PrizeDistributionDynamicSectionProps['fieldName']) =>
  (index: number) => {
    const rowNumber = index + 1
    switch (fieldName) {
      case WeeklyBeanoStepThreeField.fiftyFifty:
        return `${PrizeDistributionGame.fiftyFifty} #${rowNumber}`
      case WeeklyBeanoStepThreeField.otherGames:
        return `${PrizeDistributionGame.otherGame} #${rowNumber}`
      default:
        return undefined
    }
  }

export const PrizeDistributionDynamicSection = (
  props: PrizeDistributionDynamicSectionProps
) => {
  const { watch } = useFormContext<WeeklyBeanoStepThreeFormData>()
  const { fields, removeElement, addElement, isResetDisabled, reset } =
    useDynamicFields<WeeklyBeanoStepThreeFormData, typeof props.fieldName>({
      name: props.fieldName,
      defaultData: defaultPrizeDetail,
      getSubtotal: ['prizeInDollars']
    })

  const getHeaderNameCurried = (index: number) =>
    getHeaderName(props.fieldName)(index)

  return (
    <div className={ROOT_CLASS}>
      <DynamicFieldManager
        elements={fields.map((data, index) => (
          <PrizeDistributionSection
            key={data.id}
            field={{
              type: 'dynamic',
              name: props.fieldName,
              index: index,
              id: data.id,
              header: getHeaderNameCurried(index)
            }}
          />
        ))}
        removeElement={removeElement}
        addElement={addElement}
        onResetClick={reset}
        isResetDisabled={isResetDisabled()}
        addButtonText={props.addButtonText}
        resetButtonText={props.resetButtonText}
        className={ROOT_CLASS}
      />
      <div className={getBlockClass(ROOT_CLASS, 'subtotal')}>
        <h2>{props.subtotalHeader}</h2>
        <ReadOnlyField
          alignRight
          value={getDollarString(
            getDynamicSummarySubtotal(watch(props.fieldName))
          )}
        />
      </div>
    </div>
  )
}
