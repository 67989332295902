import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import {
  CompleteWeeklyBeanoFormState,
  WeeklyBeanoFormStep
} from '@Components/weekly-beano-form/form-state-reducer/shape'
import { WeeklyBeanoFormBodySubmission } from '../../types'
import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import {
  getEndTime,
  getStartTime
} from '@Components/weekly-beano-form/step-1/date-and-times/helpers'

type StepOneBodyData = Pick<
  WeeklyBeanoFormBodySubmission,
  | 'licenseNumber'
  | 'numPlayers'
  | 'occasionDate'
  | 'occasionDay'
  | 'occasionStartTime'
  | 'occasionEndTime'
>

export const transformStepOneData = (
  dateString: string,
  organizationDetails: OrganizationDetailsProps,
  occasionTime: string,
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): StepOneBodyData => {
  const { numPlayers } = weeklyBeanoState[WeeklyBeanoFormStep.StepOne]
  if (!numPlayers) {
    console.error('ERROR: numPlayers is not present', numPlayers)
  }
  return {
    occasionDate: dateString,
    occasionDay: dateUtil(dateString, DayFormat.monthDayYear).format(
      DayFormat.dayOfWeek
    ),
    licenseNumber: organizationDetails.idNumber,
    occasionStartTime: getStartTime(occasionTime),
    occasionEndTime: getEndTime(occasionTime),
    numPlayers: numPlayers ?? 0
  }
}
