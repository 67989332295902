import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { getCleanNumber, sumList } from '@Helpers/numbers'
import {
  DollarizedRaffleStepTwoTotals,
  RaffleStepTwoField,
  RaffleStepTwoFormData
} from './constants'

export const getTotalTicketSalesInDollars = (
  costPerTicketInDollars?: number,
  numTicketsSold?: number
) => getCleanNumber(costPerTicketInDollars) * getCleanNumber(numTicketsSold)

export const getRaffleStepTwoTotals = (
  formData: RaffleStepTwoFormData
): DollarizedRaffleStepTwoTotals => {
  const totalTicketSalesInDollars = getTotalTicketSalesInDollars(
    formData[RaffleStepTwoField.costPerTicketInDollars],
    formData[RaffleStepTwoField.numTicketsSold]
  )
  const grossReceiptsInDollars = sumList([
    formData[RaffleStepTwoField.otherReceiptsInDollars],
    totalTicketSalesInDollars
  ])
  const baseTaxInDollars = calculateTaxPaidDollars(grossReceiptsInDollars)
  const totalTaxDueInDollars = sumList([
    baseTaxInDollars,
    formData[RaffleStepTwoField.penaltyAndInterestInDollars]
  ])
  return {
    grossReceiptsInDollars,
    totalTaxDueInDollars,
    totalTicketSalesInDollars,
    baseTaxInDollars
  }
}
