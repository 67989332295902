// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".weekly-beano-step-one{width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;padding:16px;padding-top:0}", "",{"version":3,"sources":["webpack://./src/components/weekly-beano-form/step-1/styles.scss"],"names":[],"mappings":"AAEA,uBACE,UAAA,CACA,6BAAA,CAAA,qBAAA,CACA,YAAA,CACA,aAAA","sourcesContent":["@use 'src/styles/constants' as *;\n\n.weekly-beano-step-one {\n  width: 100%;\n  box-sizing: border-box;\n  padding: 16px;\n  padding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
