import {
  DispositionOfAndAccountingForNetProfitsDetails,
  WeeklyBeanoTotalDisbursementDetails,
  UnexpendedNetProfitDetails
} from './types'

export const defaultDispositionOfAndAccountingForNetProfitsDetails: DispositionOfAndAccountingForNetProfitsDetails =
  {
    unexpendedNetProfitInCents: 0,
    netBeanoProfitInCents: 0,
    endingCarryOverInCents: 0,
    prizesPaidInCents: 0,
    nonBeanoCgtProceedsInCents: 0,
    cgtProceedsInCents: 0,
    interestInCents: 0,
    gameBankInCents: 0,
    othersInCents: 0,
    otherIncomeInCents: 0
  }

export const defaultUnexpendedNetProfitDetails: UnexpendedNetProfitDetails = {
  balancePerSpecialBeanoCheckbookInCents: 0,
  balancePerSpecialBeanoSavingsAcctInCents: 0,
  balanceOfCertificateOfDepositInCents: 0
}

export const defaultTotalDisbursementDetails: WeeklyBeanoTotalDisbursementDetails =
  {
    disbursements: []
  }
