import * as React from 'react'
import { GeneralFormSummary } from '../general-form-summary/general-form-summary-ui'

export type GrossBeanoReceiptsProps = {
  grossBeanoReceiptsDollars: number
}

export const GrossBeanoReceipts = (props: GrossBeanoReceiptsProps) => (
  <GeneralFormSummary
    info="Tax based on 5% of this amount"
    title="Gross Beano Receipts"
    totalDollars={props.grossBeanoReceiptsDollars}
  />
)
