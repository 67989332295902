import * as React from 'react'
import { IconQuestionCircleOutline } from '@Assets/icons/icon-question-circle-outline'
import { Button } from '@Components/button/button-ui'
import { joinClasses } from '@Helpers/components'
import './styles.scss'

export type Props = {
  onClick: () => any
  className?: string
  sizePx?: number
}

const ROOT_CLASS = 'button-question-icon'
const DEFAULT_QUESTION_ICON_SIZE_PX = 15

export const ButtonQuestionIcon = (props: Props) => {
  return (
    <Button
      className={joinClasses([ROOT_CLASS, props.className])}
      htmlProps={{ onClick: props.onClick, type: 'button' }}
      noStyle
    >
      <IconQuestionCircleOutline
        height={props.sizePx ?? DEFAULT_QUESTION_ICON_SIZE_PX}
        width={props.sizePx ?? DEFAULT_QUESTION_ICON_SIZE_PX}
      />
    </Button>
  )
}
