import { DollarizedData } from '@Components/form-general/types'
import {
  FundsAvailableForDisbursementDetails,
  OtherExpenseDetail,
  TotalDisbursementDetails,
  TotalSpecialBeanoAccountDetails
} from '../api/types'

export const BASE_ROOT_CLASS = 'annual-beano-step-three'

export type AnnualBeanoDisbursementTotals = {
  totalFundsAvailableForDisbursementDollars: number
  totalDisbursementsDollars: number
  baseDisbursementsTotalDollars: number
  expectedTotalBalanceDollars: number
}

export enum AnnualBeanoStepThreeField {
  charitableInDollars = 'charitableInDollars',
  patrioticInDollars = 'patrioticInDollars',
  youthActivitiesInDollars = 'youthActivitiesInDollars',
  educationalInDollars = 'educationalInDollars',
  religiousInDollars = 'religiousInDollars',
  firemenActivitiesInDollars = 'firemenActivitiesInDollars',
  publicAmbulanceRescueServicesInDollars = 'publicAmbulanceRescueServicesInDollars',
  otherDisbursements = 'otherDisbursements',

  beanoCheckingBalanceInDollars = 'beanoCheckingBalanceInDollars',
  beanoSavingsBalanceInDollars = 'beanoSavingsBalanceInDollars',
  otherIncomeInDollars = 'otherIncomeInDollars',
  otherIncomeDescription = 'otherIncomeDescription',

  specialBeanoCheckbookBalanceInDollars = 'specialBeanoCheckbookBalanceInDollars',
  specialBeanoSavingsBalanceInDollars = 'specialBeanoSavingsBalanceInDollars'
}

export enum AnnualBeanoStepThreeFieldLabel {
  amount = 'Amount',
  balance = 'Balance',
  balancePerSpecialBeanoCheckbook = 'Balance per Special Beano Checkbook',
  balancePerSpecialBeanoSavings = 'Balance per Special Beano Savings Account',
  description = 'Description'
}

export type AnnualBeanoStepThreeFormData =
  DollarizedData<TotalDisbursementDetails> &
    DollarizedData<FundsAvailableForDisbursementDetails> &
    DollarizedData<TotalSpecialBeanoAccountDetails>

export const defaultDisbursement: DollarizedData<OtherExpenseDetail> = {
  amountInDollars: 0,
  description: ''
}

export const defaultFormData: AnnualBeanoStepThreeFormData = {
  [AnnualBeanoStepThreeField.charitableInDollars]: 0,
  [AnnualBeanoStepThreeField.patrioticInDollars]: 0,
  [AnnualBeanoStepThreeField.youthActivitiesInDollars]: 0,
  [AnnualBeanoStepThreeField.educationalInDollars]: 0,
  [AnnualBeanoStepThreeField.religiousInDollars]: 0,
  [AnnualBeanoStepThreeField.firemenActivitiesInDollars]: 0,
  [AnnualBeanoStepThreeField.publicAmbulanceRescueServicesInDollars]: 0,
  [AnnualBeanoStepThreeField.otherDisbursements]: [defaultDisbursement],
  [AnnualBeanoStepThreeField.beanoCheckingBalanceInDollars]: 0,
  [AnnualBeanoStepThreeField.beanoSavingsBalanceInDollars]: 0,
  [AnnualBeanoStepThreeField.otherIncomeInDollars]: 0,
  [AnnualBeanoStepThreeField.otherIncomeDescription]: '',
  [AnnualBeanoStepThreeField.specialBeanoCheckbookBalanceInDollars]: 0,
  [AnnualBeanoStepThreeField.specialBeanoSavingsBalanceInDollars]: 0
}
