import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { SignAndSubmitTopFields } from '@Components/form-general/sign-and-submit/sign-and-submit-top-fields'
import { AnnualBeanoSubmitField } from './constants'
import { SignAndSubmitBottomFields } from '@Components/form-general/sign-and-submit/sign-and-submit-bottom-fields'
import './styles.scss'

export type AnnualBeanoSubmitProps = {
  date?: string
}

const ROOT_CLASS = 'annual-beano-submit-fields'
const today = dateUtil().format(DayFormat.monthDayYearDoubleDigit)

export const AnnualBeanoSubmitFields = (props: AnnualBeanoSubmitProps) => (
  <div className={ROOT_CLASS}>
    <SignAndSubmitTopFields
      fields={{
        role: AnnualBeanoSubmitField.memberRole,
        email: AnnualBeanoSubmitField.email,
        phone: AnnualBeanoSubmitField.phoneNumber
      }}
    />
    <SignAndSubmitBottomFields
      date={props.date ?? today}
      fields={{
        signature: AnnualBeanoSubmitField.signature
      }}
    />
  </div>
)
