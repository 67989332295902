import * as React from 'react'
import dayjs from 'dayjs'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { getBlockClass } from '@Helpers/components'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormStepProps } from '@Components/form-general/types'
import { SignAndSubmitFields } from './sign-and-submit-fields'
import { WEEKLY_BEANO_NUM_STEPS } from '../constants'
import { BASE_ROOT_CLASS, WeeklyBeanoSubmitFormData } from './constants'
import './styles.scss'

export type WeeklyBeanoSubmitProps =
  FormStepProps<WeeklyBeanoSubmitFormData> & {
    submitDisabled?: boolean
    eventDate: dayjs.Dayjs
  }

const FORM_STEP = 4
const FORM_ID = BASE_ROOT_CLASS
const today = dateUtil()

export const WeeklyBeanoSubmit = (props: WeeklyBeanoSubmitProps) => {
  const methods = useForm<WeeklyBeanoSubmitFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  const signedDate = props.initialValues?.date
    ? dateUtil(props.initialValues.date).format(
        DayFormat.monthDayYearDoubleDigit
      )
    : today.format(DayFormat.monthDayYearDoubleDigit)

  const handleSubmit = async (
    data: WeeklyBeanoSubmitFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit({ ...data, date: signedDate })
  }

  return (
    <>
      <FormCard
        title={'Sign & Submit'}
        currentStep={FORM_STEP}
        totalSteps={WEEKLY_BEANO_NUM_STEPS}
      >
        <div className={BASE_ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <h3 className={getBlockClass(BASE_ROOT_CLASS, 'legal-header')}>
                THIS DECLARATION MUST BE COMPLETED:
              </h3>
              <p className={getBlockClass(BASE_ROOT_CLASS, 'legal-body')}>
                I declare, subject to the penalties of perjury, that I was the
                member in charge of the Beano event conducted by the licensee
                herein on the{' '}
                <b>{props.eventDate.format(DayFormat.dayWithOrdinal)}</b> day of{' '}
                <b>{props.eventDate.format(DayFormat.fullMonthAndYear)}</b>.
                That I personally supervised said event. That I have read the
                foregoing statement and that this statement is true to the best
                of my knowledge and belief.
              </p>
              <SignAndSubmitFields date={signedDate} />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={WEEKLY_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid || props.submitDisabled}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
