import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { getCleanNumber, sumList } from '@Helpers/numbers'
import {
  ProgressiveReceiptDetails,
  WeeklyBeanoReceiptField,
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData,
  WeeklyBeanoStepTwoTotals,
  SpecialGameInputRow
} from './constants'

const getAdmissionsCardsTotal = (stepTwoFormData: WeeklyBeanoStepTwoFormData) =>
  getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.admissionsNumCards] *
      stepTwoFormData[WeeklyBeanoStepTwoField.admissionsCardPrice]
  ) +
  getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.admissionsNumCardsXRegular] *
      stepTwoFormData[WeeklyBeanoStepTwoField.admissionsCardPriceXRegular]
  )

const getBonusAdmissionsTotal = (stepTwoFormData: WeeklyBeanoStepTwoFormData) =>
  getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.bonusAdmissionsNumCards] *
      stepTwoFormData[WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice]
  ) +
  getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular] *
      stepTwoFormData[WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular]
  )

const getSpecialGameOddTotal = (value: SpecialGameInputRow) =>
  getCleanNumber(
    Number(value[WeeklyBeanoReceiptField.oddNumCards]) *
      Number(value[WeeklyBeanoReceiptField.oddPriceInDollars])
  )

const getSpecialGameEvenTotal = (value: SpecialGameInputRow) =>
  getCleanNumber(
    Number(value[WeeklyBeanoReceiptField.evenNumCards]) *
      Number(value[WeeklyBeanoReceiptField.evenPriceInDollars])
  )

export const getSpecialGameRowTotal = (value: SpecialGameInputRow) => {
  const oddValue = getSpecialGameOddTotal(value)
  const evenValue = getSpecialGameEvenTotal(value)
  return sumList([oddValue, evenValue])
}

export const getProgressiveGameTotal = (value: ProgressiveReceiptDetails) =>
  getCleanNumber(value[WeeklyBeanoReceiptField.numCards]) *
  getCleanNumber(value[WeeklyBeanoReceiptField.pricePerCardInDollars])

export const calculateWeeklyBeanoGrossReceiptsDollars = (
  stepTwoFormData: WeeklyBeanoStepTwoFormData,
  specialGamesReceiptsTotalInDollars: number
): number => {
  const admissionsTotal = getAdmissionsCardsTotal(stepTwoFormData)
  const bonusAdmissionsTotal = getBonusAdmissionsTotal(stepTwoFormData)
  const saleOfBeanoSupplies = getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.saleOfBeanoSupplies]
  )
  const otherReceiptsAmount = getCleanNumber(
    stepTwoFormData[WeeklyBeanoStepTwoField.otherReceiptsAmount]
  )
  const totals = [
    admissionsTotal,
    bonusAdmissionsTotal,
    specialGamesReceiptsTotalInDollars,
    saleOfBeanoSupplies,
    otherReceiptsAmount
  ]
  return sumList(totals)
}

export const getNumWinnerTakeAllSections = (
  formData?: WeeklyBeanoStepTwoFormData
) => {
  if (!formData) {
    return 0
  }
  const allWinnerTakeAllReceipts =
    formData[WeeklyBeanoStepTwoField.wtaGameReceiptDetails]
  const numGroupsWithData = sumList(
    allWinnerTakeAllReceipts.map((wtaGroup) => {
      const numOdd = getSpecialGameOddTotal(wtaGroup) ? 1 : 0
      const numEven = getSpecialGameEvenTotal(wtaGroup) ? 1 : 0
      return numOdd + numEven
    })
  )
  return numGroupsWithData
}

export const getWeeklyBeanoStepTwoTotals = (
  formData: WeeklyBeanoStepTwoFormData
): WeeklyBeanoStepTwoTotals => {
  const wtaTotals = formData[WeeklyBeanoStepTwoField.wtaGameReceiptDetails].map(
    getSpecialGameRowTotal
  )
  const progressiveTotals = formData[
    WeeklyBeanoStepTwoField.allProgressive
  ].map(getProgressiveGameTotal)
  const otherGameTotals = formData[
    WeeklyBeanoStepTwoField.otherSpecialReceiptsDetails
  ].map(getSpecialGameRowTotal)
  const specialGamesReceiptsTotalInDollars = sumList([
    ...wtaTotals,
    ...progressiveTotals,
    ...otherGameTotals
  ])
  const grossBeanoReceiptsInDollars = calculateWeeklyBeanoGrossReceiptsDollars(
    formData,
    specialGamesReceiptsTotalInDollars
  )
  return {
    grossBeanoReceiptsInDollars,
    specialGamesReceiptsTotalInDollars,
    mslcTaxPaidInDollars: calculateTaxPaidDollars(grossBeanoReceiptsInDollars),
    progressiveOneProceedsInDollars: progressiveTotals[0] ?? 0,
    progressiveTwoProceedsInDollars: progressiveTotals[1] ?? 0
  }
}
