import * as React from 'react'
import { useDynamicFields, useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { Input } from '@Components/input-base/input-ui'
import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel,
  AnnualBeanoStepThreeFormData,
  defaultDisbursement
} from '../constants'
import './styles.scss'

export type AnnualBeanoOtherDisbursementProps = {
  index: number
  id: string
}

const ROOT_CLASS = 'annual-beano-other-disbursements'

export const AnnualBeanoOtherDisbursement = (
  props: AnnualBeanoOtherDisbursementProps
) => {
  const { registerNumber, register } =
    useFormContextPlus<AnnualBeanoStepThreeFormData>()
  const baseFieldName =
    `${AnnualBeanoStepThreeField.otherDisbursements}.${props.index}` as const
  return (
    <div className={getBlockClass(ROOT_CLASS, 'row')}>
      <Input
        {...registerNumber(
          AnnualBeanoStepThreeFieldLabel.amount,
          `${baseFieldName}.amountInDollars`
        )}
        className={getBlockClass(ROOT_CLASS, 'expense-input')}
        moneyField
      />
      <Input
        {...register(`${baseFieldName}.description`)}
        labelText={AnnualBeanoStepThreeFieldLabel.description}
      />
    </div>
  )
}

export const AnnualBeanoOtherDisbursements = () => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<AnnualBeanoStepThreeFormData>({
    name: AnnualBeanoStepThreeField.otherDisbursements,
    defaultData: defaultDisbursement,
    getSubtotal: ['amountInDollars']
  })
  return (
    <article className={ROOT_CLASS}>
      <p className={getBlockClass(ROOT_CLASS, 'header')}>Other Expenses</p>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <DynamicFieldManager
          elements={fields.map((data, index) => (
            <AnnualBeanoOtherDisbursement
              index={index}
              key={data.id}
              id={data.id}
            />
          ))}
          removeElement={removeElement}
          addElement={addElement}
          onResetClick={reset}
          getSubtotalDisplay={getSubtotalDisplay}
          isResetDisabled={isResetDisabled()}
          addButtonText={'Add Another Disbursement'}
          resetButtonText={'Reset Other Disbursements'}
          className={getBlockClass(ROOT_CLASS, 'rows')}
        />
      </div>
    </article>
  )
}
