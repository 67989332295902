import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { BASE_ROOT_CLASS } from './constants'
import './styles.scss'

type Props = {
  header: string
  totalDollars: number
  includeTotalTitle?: true
}

export const FundsForDisbursementSection: React.FC<Props> = (props) => {
  return (
    <FormSubsection type="primary">
      <div className={getBlockClass(BASE_ROOT_CLASS, 'section')}>
        <h3 className={getBlockClass(BASE_ROOT_CLASS, 'header')}>
          {props.header}
        </h3>
        <div className={getBlockClass(BASE_ROOT_CLASS, 'content')}>
          {props.children}
          <ReadOnlyField
            value={getDollarString(props.totalDollars)}
            label={props.includeTotalTitle ? 'Total' : undefined}
            noMargin={!props.includeTotalTitle}
            alignRight
          />
        </div>
      </div>
    </FormSubsection>
  )
}
