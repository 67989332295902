import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'
import { getCleanNumber, sum } from '@Helpers/numbers'
import { ExpenditureDetail } from '../constants'

export const ExpenseTypeOptions: DropdownOptions = {
  Rent: 'Rent',
  Bookkeeper: 'Bookkeeper',
  Supplies: 'Supplies',
  Other: 'Other'
}

export const getOtherExpensesTotalDollars = (
  expenditures?: ExpenditureDetail[]
): number =>
  expenditures
    ?.map(({ amountInDollars }) => getCleanNumber(amountInDollars))
    .reduce(sum, 0) ?? 0
