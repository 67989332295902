export enum RaffleFormActivityType {
  raffle = 'raffle',
  bazaar = 'bazaar',
  other = 'other'
}

export enum RaffleFormActivityTypeField {
  activityType = 'activityType',
  otherExplained = 'otherExplained'
}

export type RaffleFormActivityTypeFormData = {
  activityType: string
  otherExplained?: string
}
