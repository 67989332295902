import * as React from 'react'
import { joinClasses } from '@Helpers/components'
import {
  ExternalLinkIcon,
  Props as ExternalLinkIconProps
} from './external-link-icon'
import './styles.scss'

export type ExternalLinkProps = ExternalLinkIconProps & {
  ariaLabel?: string
  className?: string
  href?: string
  onClick?: () => void
  hideExternalLinkIcon?: true
}

const ROOT_CLASS = 'external-link'

export const ExternalLink: React.FunctionComponent<ExternalLinkProps> = (
  props
) => {
  const {
    ariaLabel,
    className,
    href,
    onClick,
    hideExternalLinkIcon,
    iconColor,
    sizePx,
    ...rest
  } = props
  return (
    <a
      {...rest}
      aria-label={props.ariaLabel}
      className={joinClasses([ROOT_CLASS, props.className])}
      href={props.href}
      onClick={props.onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      {props.children}
      {!props.hideExternalLinkIcon && (
        <span className={`${ROOT_CLASS}__icon`}>
          <ExternalLinkIcon iconColor={props.iconColor} sizePx={props.sizePx} />
        </span>
      )}
    </a>
  )
}
