import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { ReactHookFormHelpers } from '@Services/react-hook-form/helpers'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import { getBlockClass } from '@Helpers/components'
import { FormStepProps } from '@Components/form-general/types'
import { FORM_SCHEDULE_ONE, WEEKLY_BEANO_NUM_STEPS } from '../constants'
import {
  BASE_ROOT_CLASS,
  defaultFormData,
  WeeklyBeanoStepTwoFormData,
  WeeklyBeanoStepTwoTotals
} from './constants'
import { AdmissionsCards } from './admission-cards/admission-cards-ui'
import { BonusAdmissionsCards } from './admission-cards/bonus-admission-cards-ui'
import { SpecialGamesReceipts } from './special-games/special-games-ui'
import { SaleOfBeanoSupplies } from './sale-of-beano-supplies/sale-of-beano-supplies-ui'
import { OtherReceipts } from './other-receipts/other-receipts-ui'
import { GrossBeanoReceipts } from '@Components/form-general/gross-beano-receipts/gross-beano-receipts-ui'
import { getWeeklyBeanoStepTwoTotals } from './helpers'
import { TotalReceiptsFromSpecialGames } from './special-games/total-receipts-from-special-games-ui'
import './styles.scss'

export type Props = FormStepProps<
  WeeklyBeanoStepTwoFormData,
  WeeklyBeanoStepTwoTotals
>

const FORM_ID = BASE_ROOT_CLASS
const SECTION_CONTAINER_CLASS = getBlockClass(
  BASE_ROOT_CLASS,
  'section-container'
)
const FORM_STEP = 2

export const WeeklyBeanoStepTwo = (props: Props) => {
  const methods = useForm<WeeklyBeanoStepTwoFormData>(
    ReactHookFormHelpers.getDefaultUseFormOptions<WeeklyBeanoStepTwoFormData>({
      defaultValues: {
        ...defaultFormData,
        ...props.initialValues
      }
    })
  )

  const totals = getWeeklyBeanoStepTwoTotals(methods.watch())

  const handleSubmit = async (
    data: WeeklyBeanoStepTwoFormData,
    event?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit(data, totals)
  }

  return (
    <>
      <FormCard
        title={FORM_SCHEDULE_ONE}
        currentStep={FORM_STEP}
        totalSteps={WEEKLY_BEANO_NUM_STEPS}
      >
        <div className={BASE_ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <h2>A. Receipts</h2>
              <div className={SECTION_CONTAINER_CLASS}>
                <AdmissionsCards />
                <BonusAdmissionsCards />
                <SpecialGamesReceipts />
              </div>
              <TotalReceiptsFromSpecialGames
                specialGamesReceiptsTotalInDollars={
                  totals.specialGamesReceiptsTotalInDollars
                }
              />
              <div className={SECTION_CONTAINER_CLASS}>
                <SaleOfBeanoSupplies />
                <OtherReceipts />
              </div>
            </form>
            <GrossBeanoReceipts
              grossBeanoReceiptsDollars={totals.grossBeanoReceiptsInDollars}
            />
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={WEEKLY_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
