import * as React from 'react'
import { ExternalLink } from '@Components/external-link/external-link-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getBlockClass } from '@Helpers/components'
import './styles.scss'

export type OrganizationDetailsProps = {
  organizationName: string
  idNumber: string
  address: string
  city: string
  state: string
  zipCode: string
}

const ROOT_CLASS = 'organization-details'
const SECTION_CLASS_NAME = getBlockClass(ROOT_CLASS, 'section')
const READ_ONLY_FIELD_CLASS_NAME = getBlockClass(ROOT_CLASS, 'read-only-field')

enum OrganizationDetailFields {
  name = 'Name of Organization',
  idNumber = 'Organization ID No.',
  address = 'Street Address',
  city = 'City',
  state = 'State',
  zipCode = 'Zip Code'
}

export const OrganizationDetails = React.memo(
  (props: OrganizationDetailsProps) => (
    <article className={ROOT_CLASS}>
      <h2>Organization Details</h2>
      <p className={getBlockClass(ROOT_CLASS, 'contact')}>
        Please contact the Charitable Gaming division at{' '}
        <ExternalLink iconColor="blue" sizePx={12} href="tel:781-849-5527">
          (781) 849-5527
        </ExternalLink>{' '}
        if you'd like to update your address.
      </p>
      <div className={SECTION_CLASS_NAME}>
        <ReadOnlyField
          label={OrganizationDetailFields.name}
          value={props.organizationName}
          className={READ_ONLY_FIELD_CLASS_NAME}
        />
        <ReadOnlyField
          label={OrganizationDetailFields.idNumber}
          value={String(props.idNumber)}
          className={READ_ONLY_FIELD_CLASS_NAME}
        />
      </div>
      <ReadOnlyField
        label={OrganizationDetailFields.address}
        value={props.address}
        className={READ_ONLY_FIELD_CLASS_NAME}
      />
      <div className={SECTION_CLASS_NAME}>
        <ReadOnlyField
          label={OrganizationDetailFields.city}
          value={props.city}
          className={READ_ONLY_FIELD_CLASS_NAME}
        />
        <div className={SECTION_CLASS_NAME}>
          <ReadOnlyField
            label={OrganizationDetailFields.state}
            value={props.state}
            className={READ_ONLY_FIELD_CLASS_NAME}
          />
          <ReadOnlyField
            label={OrganizationDetailFields.zipCode}
            value={props.zipCode}
            className={READ_ONLY_FIELD_CLASS_NAME}
          />
        </div>
      </div>
    </article>
  )
)
