// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__overlay{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:5vh 0;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}.modal__content{background:none;padding:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./src/components/modal/styles.scss"],"names":[],"mappings":"AAGE,gBACE,mBAAA,CAAA,oBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,aAAA,CACA,wBAAA,CAAA,0BAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,8BAAA,CAAA,oBAAA,CAAA,sBAAA,CAEF,gBACE,eAAA,CACA,SAAA,CACA,mBAAA,CAAA,oBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,6BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,0BAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA","sourcesContent":["@use 'src/styles/constants' as *;\n\n.modal {\n  &__overlay {\n    display: flex;\n    padding: 5vh 0;\n    align-items: center;\n    justify-content: center;\n  }\n  &__content {\n    background: none;\n    padding: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
