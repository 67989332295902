import { getCentsFromDollars, getDollarsFromCents } from '@Helpers/currency'
import { getCleanNumber } from '@Helpers/numbers'
import { transformObject } from '@Helpers/util'
import { CentsifiedData, DollarizedData } from '../types'

const dollarsSuffix = 'InDollars'
const centsSuffix = 'InCents'

const isNumber = (key: string, obj: any) => typeof obj[key] === 'number'
const isDollarsKey = (key: string) => key.endsWith(dollarsSuffix)
const isCentsKey = (key: string) => key.endsWith(centsSuffix)
const maintainKey = (key: string) => key
const toCentsKeyTransformer = (key: string) =>
  key.replace(dollarsSuffix, centsSuffix)
const toDollarsKeyTransformer = (key: string) =>
  key.replace(centsSuffix, dollarsSuffix)

export const transformCentsToDollars = <T extends Record<string, any>>(
  obj: T
) =>
  transformObject<T>(
    obj,
    isCentsKey,
    toDollarsKeyTransformer,
    getDollarsFromCents
  ) as DollarizedData<T>

export const transformDollarsToCents = <T extends Record<string, any>>(
  obj: T
) =>
  transformObject<T>(
    obj,
    isDollarsKey,
    toCentsKeyTransformer,
    getCentsFromDollars
  ) as CentsifiedData<T>

export const transformCleanAllNumbers = <T extends Record<string, any>>(
  obj: T
) => transformObject<T>(obj, isNumber, maintainKey, getCleanNumber) as T
