import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { AnnualBeanoStepTwoField, ROOT_HEADER_CLASS } from '../constants'
import { AnnualBeanoIncomeField } from '../income-field/annual-beano-income-field'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import './styles.scss'

export type AnnualBeanoCharityGameProps = {
  charityNetProfitOrLossDollars: number
}

const ROOT_CLASS = 'annual-beano-charity-game'

export const AnnualBeanoCharityGame = (props: AnnualBeanoCharityGameProps) => (
  <section className={ROOT_CLASS}>
    <h2 className={ROOT_HEADER_CLASS}>
      C. Charity Game Ticket Receipts and Costs
    </h2>
    <FormSubsection type="primary">
      <div className={getBlockClass(ROOT_CLASS, 'container')}>
        <AnnualBeanoIncomeField
          header="1. Charity Game Ticket Proceeds"
          headerSupplement="Total the Weekly Beano Reports (Schedule 2 Line 3) and then subtract CGT paid by check"
          fieldName={AnnualBeanoStepTwoField.ticketProceedsInDollars}
        />
        <AnnualBeanoIncomeField
          header="2. Cost of Charity Game Tickets"
          fieldName={AnnualBeanoStepTwoField.ticketCostsInDollars}
          negativeDisplay
        />
        <div className={getBlockClass(ROOT_CLASS, 'total')}>
          <h4>3. Net profit or Loss from Charity Game Ticket Sales</h4>
          <ReadOnlyField
            noMargin
            value={getDollarString(props.charityNetProfitOrLossDollars)}
          />
        </div>
      </div>
    </FormSubsection>
  </section>
)
