import { AnnualBeanoIncomeTotals } from '../step-2/constants'
import { AnnualBeanoDisbursementTotals } from '../step-3/constants'
import { AnnualBeanoFormStep, CompleteAnnualBeanoFormState } from './shape'

export enum AnnualBeanoFormActions {
  UPDATE_FORM_STATE = 'UPDATE_FORM_STATE',
  CHANGE_FORM_STEP = 'CHANGE_FORM_STEP'
}

export type AnnualBeanoFormPayload = Omit<
  CompleteAnnualBeanoFormState,
  'totals' | 'currentStep'
>

export type AnnualBeanoFormTotals = {
  [AnnualBeanoFormStep.StepTwo]: AnnualBeanoIncomeTotals
  [AnnualBeanoFormStep.StepThree]: AnnualBeanoDisbursementTotals
}

type StepWithTotals = keyof AnnualBeanoFormTotals

export type AnnualBeanoTotal<Step extends AnnualBeanoFormStep> =
  Step extends StepWithTotals ? AnnualBeanoFormTotals[Step] : undefined

export type ChangeAnnualBeanoFormStep = {
  type: AnnualBeanoFormActions.CHANGE_FORM_STEP
  step: number
}

export type UpdateAnnualBeanoFormAction<Step extends AnnualBeanoFormStep> = {
  type: AnnualBeanoFormActions.UPDATE_FORM_STATE
  step: Step
  payload: AnnualBeanoFormPayload[Step]
  totals?: AnnualBeanoTotal<Step>
}

export type AnnualBeanoFormAction<Step extends AnnualBeanoFormStep> =
  | ChangeAnnualBeanoFormStep
  | UpdateAnnualBeanoFormAction<Step>

const changeFormStep = (step: number): ChangeAnnualBeanoFormStep => ({
  type: AnnualBeanoFormActions.CHANGE_FORM_STEP,
  step
})

const updateFormStep = <Step extends AnnualBeanoFormStep>(
  step: Step,
  payload: AnnualBeanoFormPayload[Step],
  totals?: AnnualBeanoTotal<Step>
): UpdateAnnualBeanoFormAction<Step> => ({
  type: AnnualBeanoFormActions.UPDATE_FORM_STATE,
  step,
  payload,
  totals
})

export const AnnualBeanoFormAction = {
  changeFormStep,
  updateFormStep
}
