import * as React from 'react'
import {
  AnnualBeanoStepTwoField,
  AnnualBeanoStepTwoFieldLabel,
  ROOT_HEADER_CLASS
} from '../constants'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { calculateTaxPaidDollars } from '@Components/form-general/helpers'
import { getDollarString } from '@Helpers/currency'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { AnnualBeanoOtherExpenditures } from './annual-beano-other-expenditures-ui'
import {
  AnnualBeanoIncomeField,
  AnnualBeanoIncomeFieldProps
} from '../income-field/annual-beano-income-field'
import './styles.scss'

export type AnnualBeanoExpendituresProps = {
  grossBeanoReceiptsDollars: number
  totalExpendituresDollars: number
}

const ROOT_CLASS = 'annual-beano-expenditures'

const getBasicExpenditureProps = (
  grossBeanoReceiptsDollars: number
): AnnualBeanoIncomeFieldProps[] => [
  {
    header: '1. Prizes',
    fieldName: AnnualBeanoStepTwoField.prizesInDollars,
    headerSupplement: '(including all Progressive Beano prizes paid by check)'
  },
  {
    header: '2. Tax Paid to MSLC',
    fieldLabel: AnnualBeanoStepTwoFieldLabel.amountTax,
    fieldName: AnnualBeanoStepTwoField.taxPaidInDollars,
    defaultValue: calculateTaxPaidDollars(grossBeanoReceiptsDollars)
  },
  {
    header: '3. Rent',
    fieldName: AnnualBeanoStepTwoField.rentInDollars
  },
  {
    header: '4. Equipment',
    fieldName: AnnualBeanoStepTwoField.equipmentInDollars
  },
  {
    header: '5. Supplies',
    fieldName: AnnualBeanoStepTwoField.suppliesInDollars
  },
  {
    header: '6. Services',
    fieldName: AnnualBeanoStepTwoField.servicesInDollars
  }
]

export const AnnualBeanoExpenditures = (
  props: AnnualBeanoExpendituresProps
) => {
  const basicExpenditureProps = React.useMemo(
    () => getBasicExpenditureProps(props.grossBeanoReceiptsDollars),
    [props.grossBeanoReceiptsDollars]
  )

  return (
    <section className={ROOT_CLASS}>
      <h2 className={ROOT_HEADER_CLASS}>B. Expenditures</h2>
      <FormSubsection type="primary">
        <div className={getBlockClass(ROOT_CLASS, 'basic-expenditures')}>
          {basicExpenditureProps.map((expenditureProps) => (
            <AnnualBeanoIncomeField
              {...expenditureProps}
              key={expenditureProps.header}
            />
          ))}
          <AnnualBeanoOtherExpenditures />
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'total-expenditures')}>
          <h4>8. Total Beano Expenditures</h4>
          <ReadOnlyField
            noMargin
            value={getDollarString(props.totalExpendituresDollars)}
          />
        </div>
      </FormSubsection>
    </section>
  )
}
