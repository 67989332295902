import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'
import { getAscendingArray, getCleanNumber, sumList } from '@Helpers/numbers'
import {
  PrizeDetail,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData,
  WTAFormDetails
} from '../constants'

const MAX_NUM_GAMES = 15
export const BASE_ROOT_CLASS = 'summary-of-beano-prizes'
export const winnerTakeAllNumGamesOptions: DropdownOptions = getAscendingArray(
  MAX_NUM_GAMES,
  false
)
  .map((numGames) => ({
    [String(numGames)]: String(numGames)
  }))
  .reduce((acc, curr) => ({ ...acc, ...curr }), {})

export const getWinnerTakeAllTotalDollars = (wta: WTAFormDetails): number =>
  sumList(wta.amountsInDollars?.map((amount) => amount.amountInDollars) ?? [])

export const getDoorPrizeTotal = (
  formData: Pick<
    WeeklyBeanoStepThreeFormData,
    | WeeklyBeanoStepThreeField.doorCashPrize
    | WeeklyBeanoStepThreeField.merchandiseAmount
  >
): number => {
  const { doorCashPrize, merchandiseAmount } = formData
  const doorPrizeTotal =
    getCleanNumber(doorCashPrize) + getCleanNumber(merchandiseAmount)
  return doorPrizeTotal
}

export const getDynamicSummarySubtotal = (prizeDetail: PrizeDetail[]) =>
  sumList(prizeDetail.map((prizeSummary) => prizeSummary.prizeInDollars))

const getGamesSummarySubtotal = (formData: WeeklyBeanoStepThreeFormData) =>
  sumList([
    formData[WeeklyBeanoStepThreeField.spGamePrize],
    formData[WeeklyBeanoStepThreeField.goodNeighborPrize],
    formData[WeeklyBeanoStepThreeField.breakageAmountInDollars],
    getDynamicSummarySubtotal(formData[WeeklyBeanoStepThreeField.otherGames]),
    getDynamicSummarySubtotal(formData[WeeklyBeanoStepThreeField.fiftyFifty])
  ])

const getWtaTotal = (formData: WeeklyBeanoStepThreeFormData) => {
  const allWtaAmounts = formData[WeeklyBeanoStepThreeField.winnerTakeAll].map(
    getWinnerTakeAllTotalDollars
  )
  return sumList(allWtaAmounts)
}

export const getGrandTotalCashPrizes = (
  formData: WeeklyBeanoStepThreeFormData
): number => {
  const wtaTotal = getWtaTotal(formData)
  const progressiveGamesSubtotals = sumList(
    formData[WeeklyBeanoStepThreeField.progressiveGames]?.map((summary) =>
      getCleanNumber(summary.prizesPaidInDollars)
    )
  )
  const gamesSummaryTotal = getGamesSummarySubtotal(formData)
  const doorSubtotal = getDoorPrizeTotal(formData)
  return sumList([
    wtaTotal,
    progressiveGamesSubtotals,
    gamesSummaryTotal,
    doorSubtotal
  ])
}
