import {
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFieldLabel
} from '../constants'
import { DisbursementRowProps } from './disbursement-row-ui'

export const disbursementRows: DisbursementRowProps[] = [
  {
    fields: [
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.charitableInDollars,
        header: 'Charitable'
      },
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.patrioticInDollars,
        header: 'Patriotic'
      }
    ]
  },
  {
    fields: [
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.youthActivitiesInDollars,
        header: 'Youth Activites'
      },
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.educationalInDollars,
        header: 'Education'
      }
    ]
  },
  {
    fields: [
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.religiousInDollars,
        header: 'Religious'
      },
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName: AnnualBeanoStepThreeField.firemenActivitiesInDollars,
        header: `Firemen's Activities`
      }
    ]
  },
  {
    fields: [
      {
        fieldLabel: AnnualBeanoStepThreeFieldLabel.amount,
        fieldName:
          AnnualBeanoStepThreeField.publicAmbulanceRescueServicesInDollars,
        header: 'Public Ambulance or Rescue Services'
      }
    ]
  }
]
