import { getValuesFromFieldNames } from '@Components/form-general/helpers'
import { sumList } from '@Helpers/numbers'
import {
  AnnualBeanoDisbursementTotals,
  AnnualBeanoStepThreeField,
  AnnualBeanoStepThreeFormData
} from './constants'

const disbursementFields = [
  AnnualBeanoStepThreeField.charitableInDollars,
  AnnualBeanoStepThreeField.patrioticInDollars,
  AnnualBeanoStepThreeField.youthActivitiesInDollars,
  AnnualBeanoStepThreeField.educationalInDollars,
  AnnualBeanoStepThreeField.religiousInDollars,
  AnnualBeanoStepThreeField.firemenActivitiesInDollars,
  AnnualBeanoStepThreeField.publicAmbulanceRescueServicesInDollars
]

const fundsAvailableForDisbursementFields = [
  AnnualBeanoStepThreeField.beanoCheckingBalanceInDollars,
  AnnualBeanoStepThreeField.beanoSavingsBalanceInDollars,
  AnnualBeanoStepThreeField.otherIncomeInDollars
]

const getOtherDisbursementsTotal = (
  otherDisbursements: AnnualBeanoStepThreeFormData[AnnualBeanoStepThreeField.otherDisbursements]
) =>
  sumList(
    otherDisbursements.map((disbursement) => disbursement.amountInDollars)
  )

const getTotalDisbursementDollars = (
  formData: AnnualBeanoStepThreeFormData
) => {
  const baseDisbursementValues = getValuesFromFieldNames<
    AnnualBeanoStepThreeField,
    AnnualBeanoStepThreeFormData
  >(disbursementFields, formData)

  const otherDisbursementsTotal = getOtherDisbursementsTotal(
    formData[AnnualBeanoStepThreeField.otherDisbursements]
  )
  const baseDisbursementsTotalDollars = sumList(baseDisbursementValues)
  return {
    baseDisbursementsTotalDollars,
    totalDisbursementsDollars:
      otherDisbursementsTotal + baseDisbursementsTotalDollars
  }
}

const getFundsAvailableForDisbursementTotal = (
  formData: AnnualBeanoStepThreeFormData,
  totalNetProfitOrLossDollars: number
) =>
  sumList(
    getValuesFromFieldNames<
      AnnualBeanoStepThreeField,
      AnnualBeanoStepThreeFormData
    >(fundsAvailableForDisbursementFields, formData)
  ) + totalNetProfitOrLossDollars

export const getAnnualBeanoDisbursementTotals = (
  formData: AnnualBeanoStepThreeFormData,
  totalNetProfitOrLossDollars: number
): AnnualBeanoDisbursementTotals => {
  const totalFundsAvailableForDisbursementDollars =
    getFundsAvailableForDisbursementTotal(formData, totalNetProfitOrLossDollars)
  const { baseDisbursementsTotalDollars, totalDisbursementsDollars } =
    getTotalDisbursementDollars(formData)
  const expectedTotalBalanceDollars =
    totalFundsAvailableForDisbursementDollars - totalDisbursementsDollars

  return {
    totalFundsAvailableForDisbursementDollars,
    baseDisbursementsTotalDollars,
    totalDisbursementsDollars,
    expectedTotalBalanceDollars
  }
}
