import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { getRequiredLabel } from '@Helpers/test-util'
import {
  RaffleStepOneField,
  RaffleStepOneFieldLabel,
  RaffleStepOneFormData
} from './constants'
import './styles.scss'

const ROOT_CLASS = 'raffle-event-details'

export const RaffleEventDetails = () => {
  const {
    registerDate,
    registerRequired,
    formState: { errors }
  } = useFormContextPlus<RaffleStepOneFormData>()
  return (
    <article className={ROOT_CLASS}>
      <h2>Event Details</h2>
      <div className={getBlockClass(ROOT_CLASS, 'fields')}>
        <Input
          {...registerRequired(
            RaffleStepOneFieldLabel.orgFid,
            RaffleStepOneField.orgFid
          )}
          error={errors[RaffleStepOneField.orgFid]?.message}
          placeholder="00-1234567"
        />
        <Input
          {...registerDate(
            RaffleStepOneFieldLabel.occasionDate,
            RaffleStepOneField.occasionDate,
            {
              required: {
                value: true,
                message: `${RaffleStepOneFieldLabel.occasionDate} is required.`
              }
            }
          )}
          labelText={getRequiredLabel(RaffleStepOneFieldLabel.occasionDate)}
          error={errors[RaffleStepOneField.occasionDate]?.message}
        />
      </div>
    </article>
  )
}
