import { GetValueFunction } from '@Services/react-hook-form/constants'
import { getDollarString } from '@Helpers/currency'
import { sumList } from '@Helpers/numbers'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFormData
} from '../constants'

const isValidUnexpendedNetProfit = (
  expectedUnexpendedNetProfitDollars: number,
  balancePerSpecialBeanoCheckbook?: number,
  balancePerSpecialBeanoSavings?: number,
  balanceOfCertificateDeposit?: number
) => {
  const currentBalance = sumList([
    balancePerSpecialBeanoCheckbook ?? 0,
    balancePerSpecialBeanoSavings ?? 0,
    balanceOfCertificateDeposit ?? 0
  ])
  return currentBalance === expectedUnexpendedNetProfitDollars
}

const matchesExpectedUnexpendedNetProfit = (
  expectedUnexpendedNetProfitDollars: number,
  getValues: GetValueFunction<WeeklyBeanoStepFourFormData>
) => {
  const [
    specialBeanoCheckbookValue,
    specialBeanoSavingsValue,
    certificateDepositValue
  ] = getValues([
    WeeklyBeanoStepFourField.balancePerSpecialBeanoCheckbookInDollars,
    WeeklyBeanoStepFourField.balancePerSpecialBeanoSavingsAcctInDollars,
    WeeklyBeanoStepFourField.balanceOfCertificateOfDepositInDollars
  ])
  const isValid = isValidUnexpendedNetProfit(
    expectedUnexpendedNetProfitDollars,
    specialBeanoCheckbookValue,
    specialBeanoSavingsValue,
    certificateDepositValue
  )
  return {
    isValid,
    errorMessage: isValid
      ? undefined
      : `Unexpended net profit should equal ${getDollarString(
          expectedUnexpendedNetProfitDollars
        )}.`
  }
}

export const WeeklyBeanoUnexpendedNetProfitValidation = {
  isValidUnexpendedNetProfit,
  matchesExpectedUnexpendedNetProfit
}
