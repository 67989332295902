import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import { RaffleFormResponse, RaffleFormSubmissionBody } from '../api/types'
import { RaffleFormReducer } from '../form-state-reducer/reducer'
import { getInitialState, RaffleFormStep } from '../form-state-reducer/shape'
import { ActivityType } from '../activity-type/raffle-activity-type-ui'
import { RaffleStepOne } from '../step-1/raffle-step-one-ui'
import { RaffleStepTwo } from '../step-2/raffle-step-two-ui'
import { RaffleStepThree } from '../step-3/raffle-step-three-ui'
import { RaffleSubmit } from '../submit/raffle-submit-ui'
import './styles.scss'

export type RaffleFormPrintProps = {
  formData: RaffleFormSubmissionBody | RaffleFormResponse
  organizationDetails: OrganizationDetailsProps
}

const ROOT_CLASS = 'raffle-form-print'

const noBackClick = () => {}
const noSubmit = (formData: any) => {}
const today = dateUtil().format(DayFormat.monthDayYearDoubleDigit)

export const RaffleFormPrint = React.forwardRef<
  HTMLElement,
  RaffleFormPrintProps
>((props, ref) => {
  const [state] = React.useReducer(
    RaffleFormReducer,
    getInitialState(props.formData)
  )
  return (
    <section ref={ref} className={ROOT_CLASS}>
      <ActivityType
        initialValues={state[RaffleFormStep.ActivityType]}
        onSubmit={noSubmit}
        printMode
      />
      <RaffleStepOne
        organizationDetails={props.organizationDetails}
        onSubmit={noSubmit}
        initialValues={state[RaffleFormStep.StepOne]}
        printMode
      />
      <RaffleStepTwo
        onBackClick={noBackClick}
        initialValues={state[RaffleFormStep.StepTwo]}
        onSubmit={noSubmit}
        printMode
      />
      <RaffleStepThree
        onBackClick={noBackClick}
        grossReceiptsInDollars={state.totals.grossReceiptsInDollars}
        totalTaxDueInDollars={state.totals.totalTaxDueInDollars}
        initialValues={state[RaffleFormStep.StepThree]}
        onSubmit={noSubmit}
        printMode
      />
      <RaffleSubmit
        onBackClick={noBackClick}
        initialValues={state[RaffleFormStep.Submit]}
        onSubmit={noSubmit}
        date={props.formData?.formPreparationDetails.date ?? today}
        printMode
      />
    </section>
  )
})
