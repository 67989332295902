import * as React from 'react'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { InputDropdown } from '@Components/input-dropdown/input-dropdown-ui'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getNestedArraysWithLength } from '@Helpers/util'
import { DynamicElementProps } from '@Components/form-general/types'
import {
  defaultWTAAmount,
  defaultWTAFormDetails,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import {
  BASE_ROOT_CLASS,
  getWinnerTakeAllTotalDollars,
  winnerTakeAllNumGamesOptions
} from '../constants'
import './styles.scss'

type WinnerTakeAllSectionProps = DynamicElementProps & {
  subtotalDisplay: string
}

const ROOT_CLASS = `${BASE_ROOT_CLASS}-winner-take-all`
const AMOUNTS_PER_ROW = 5

const WinnerTakeAllSection = React.memo((props: WinnerTakeAllSectionProps) => {
  const baseFieldName =
    `${WeeklyBeanoStepThreeField.winnerTakeAll}.${props.index}` as const
  const numGamesFieldName = `${baseFieldName}.numGames` as const
  const amountsFieldName = `${baseFieldName}.amountsInDollars` as const
  const { registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const numAmounts = watch(numGamesFieldName)

  const {
    fields: amountFields,
    addElement,
    removeElement
  } = useDynamicFields<WeeklyBeanoStepThreeFormData>({
    name: amountsFieldName,
    defaultData: defaultWTAAmount,
    getSubtotal: []
  })

  React.useEffect(() => {
    const numFields = amountFields.length
    const difference = numFields - numAmounts
    if (difference > 0) {
      removeElement(numAmounts - 1)
    } else if (difference < 0) {
      addElement()
    }
  }, [numAmounts, amountFields])

  const groupedAmounts = getNestedArraysWithLength(
    amountFields,
    AMOUNTS_PER_ROW
  )
  let inputIndex = 0

  return (
    <div className={getBlockClass(ROOT_CLASS, 'section')}>
      <p
        className={getBlockClass(ROOT_CLASS, 'section-header')}
      >{`Winner-Take-All #${props.index + 1}`}</p>
      <div className={getBlockClass(ROOT_CLASS, 'fields-container')}>
        <InputDropdown
          options={winnerTakeAllNumGamesOptions}
          {...registerNumber(
            WeeklyBeanoStepThreeFieldLabel.numGames,
            numGamesFieldName
          )}
        />
        <div className={getBlockClass(ROOT_CLASS, 'amounts')}>
          <div className={getBlockClass(ROOT_CLASS, 'amounts-fields')}>
            {groupedAmounts.map((rowFields, index) => {
              return (
                <div
                  className={getBlockClass(ROOT_CLASS, 'amounts-row')}
                  key={`row-${index}`}
                >
                  <label htmlFor={amountsFieldName}>Amount</label>
                  <div className={getBlockClass(ROOT_CLASS, 'row-contents')}>
                    {rowFields.map((field) => {
                      const input = (
                        <Input
                          key={field.id}
                          {...registerNumber(
                            '',
                            `${amountsFieldName}.${inputIndex}.amountInDollars`
                          )}
                          moneyField
                          className={getBlockClass(ROOT_CLASS, 'money-field')}
                        />
                      )
                      ++inputIndex
                      return input
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <ReadOnlyField value={props.subtotalDisplay} alignRight />
        </div>
      </div>
    </div>
  )
})

export const WinnerTakeAll = () => {
  const { fields, getSubtotalDisplay } = useDynamicFields<
    WeeklyBeanoStepThreeFormData,
    WeeklyBeanoStepThreeField.winnerTakeAll
  >({
    name: WeeklyBeanoStepThreeField.winnerTakeAll,
    defaultData: defaultWTAFormDetails,
    getSubtotal: getWinnerTakeAllTotalDollars
  })
  return (
    <FormSubsection type="secondary">
      <div className={ROOT_CLASS}>
        <h4>Winner-Take-All *</h4>
        {fields.map((data, index) => (
          <WinnerTakeAllSection
            index={index}
            key={data.id}
            id={data.id}
            subtotalDisplay={getSubtotalDisplay(index)}
          />
        ))}
        <b>
          * Winner Takes All (WTA) amount awarded after 5% Tax and up to 5% cost
          of supplies have been deducted.
        </b>
      </div>
    </FormSubsection>
  )
}
