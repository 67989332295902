import { DollarizedData } from '@Components/form-general/types'
import {
  CasinoDispositionOfAndAccountForNetProfitDetails,
  CasinoTotalDisbursementDetails,
  DollarizedCasinoFormSubmissionBody
} from '../api/types'

export enum CasinoStepThreeField {
  disbursements = 'disbursements',
  prevSpecialCasinoCheckingBalanceInDollars = 'prevSpecialCasinoCheckingBalanceInDollars',
  netProfitInDollars = 'netProfitInDollars',
  otherIncomeInDollars = 'otherIncomeInDollars',
  otherIncomeDescription = 'otherIncomeDescription'
}

export enum CasinoStepThreeFieldLabel {
  scheduleTwoLastReport = 'Schedule 2, Item 5 of Last CFT Report',
  scheduleOneThisReport = 'Schedule 1 from This Report',
  amount = 'Amount',
  description = 'Description',

  date = 'Date',
  checkNumber = 'Check No.',
  payee = 'Payee'
}

type DollarizedDispositionOfNetProfitFormData =
  DollarizedData<CasinoDispositionOfAndAccountForNetProfitDetails>

export type DispositionOfNetProfitFormData = Omit<
  DollarizedDispositionOfNetProfitFormData,
  'netProfitInDollars'
>

export type DollarizedDisbursements =
  DollarizedData<CasinoTotalDisbursementDetails>

export const defaultCasinoDisbursement: DollarizedDisbursements['disbursements'][0] =
  {
    date: '',
    payee: '',
    amountInDollars: 0,
    checkNumber: '',
    description: ''
  }

export type CasinoStepThreeFormData = DispositionOfNetProfitFormData &
  DollarizedDisbursements

export type CasinoStepThreeTotals = Pick<
  DollarizedCasinoFormSubmissionBody,
  | 'fundsAvailableForDisbursementInDollars'
  | 'totalDisbursementsInDollars'
  | 'specialCasinoCheckingBalanceInDollars'
> &
  Pick<DollarizedDispositionOfNetProfitFormData, 'netProfitInDollars'>

export const casinoStepThreeDefaultFormData: Partial<CasinoStepThreeFormData> =
  {
    [CasinoStepThreeField.disbursements]: [defaultCasinoDisbursement]
  }
