import { getMemberRole } from '@Components/form-general/helpers'
import { MemberRole } from '@Components/form-general/sign-and-submit/constants'
import {
  WeeklyBeanoSubmitField,
  WeeklyBeanoSubmitFormData
} from '@Components/weekly-beano-form/sign-and-submit/constants'
import {
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormResponse
} from '../../types'

export const transformWeeklyBeanoResponseToSubmitData = (
  response:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoSubmitFormData => ({
  [WeeklyBeanoSubmitField.memberRole]:
    getMemberRole(response.declarationDetails?.memberRole) ??
    MemberRole.headOfOrganization, // TODO re-evaluate error state
  [WeeklyBeanoSubmitField.phoneNumber]:
    response.declarationDetails?.phoneNumber ?? '',
  [WeeklyBeanoSubmitField.signature]:
    response.declarationDetails?.signature ?? '',
  [WeeklyBeanoSubmitField.email]: response.declarationDetails?.email ?? '',
  date: response.declarationDetails?.date ?? ''
})
