import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import { ModalInfo } from '@Components/modal-info/modal-info-ui'
import { InputBaseError } from '@Components/input-base/input-base-error-ui'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFieldLabel,
  WeeklyBeanoStepFourFormData
} from '../constants'
import './styles.scss'

type Props = {
  expectedUnexpendedNetProfitDollars: number
  error?: string
}

const ROOT_CLASS = 'unexpended-net-profit'
const INFO_BODY_TEXT =
  'This is calculated by deducting the total disbursements from the total funds available, and should equal out to the sum of balances in Item 6.'

export const UnexpendedNetProfit = React.memo((props: Props) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepFourFormData>()
  const [modalOpen, setModalOpen] = React.useState(false)
  const closeModal = () => setModalOpen(false)
  const hasError = Boolean(props.error)

  return (
    <>
      <ModalInfo
        isOpen={modalOpen}
        ariaLabel="Unexpended Net Profit Information"
        handleClose={closeModal}
        header="Unexpended Net Profit"
        body={INFO_BODY_TEXT}
        buttonText="Okay"
        onButtonClick={closeModal}
      />
      <div className={ROOT_CLASS}>
        <FormSubsection type="primary">
          <div className={getBlockClass(ROOT_CLASS, 'top-level-info')}>
            <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
              <h3 className={getBlockClass(ROOT_CLASS, 'header')}>
                6. Unexpended Net Profit
              </h3>
              <ButtonQuestionIcon onClick={() => setModalOpen(true)} />
            </div>
            <ReadOnlyField
              value={getDollarString(props.expectedUnexpendedNetProfitDollars)}
              noMargin
              alignRight
            />
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'content')}>
            <Input
              {...registerNumber(
                WeeklyBeanoStepFourFieldLabel.balancePerSpecialBeanoCheckbook,
                WeeklyBeanoStepFourField.balancePerSpecialBeanoCheckbookInDollars
              )}
              error={hasError}
              moneyField
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepFourFieldLabel.balancePerSpecialBeanoSavings,
                WeeklyBeanoStepFourField.balancePerSpecialBeanoSavingsAcctInDollars
              )}
              error={hasError}
              moneyField
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepFourFieldLabel.balanceOfCertificateDeposit,
                WeeklyBeanoStepFourField.balanceOfCertificateOfDepositInDollars
              )}
              error={hasError}
              moneyField
            />
          </div>
          <InputBaseError
            error={props.error}
            className={getBlockClass(ROOT_CLASS, 'error')}
          />
        </FormSubsection>
      </div>
    </>
  )
})
