import { transformDollarsToCents } from '@Components/form-general/transformers'
import { CentsifiedData } from '@Components/form-general/types'
import { RaffleTotals } from '@Components/raffle-form/form-state-reducer/shape'
import { RaffleStepTwoFormData } from '@Components/raffle-form/step-2/constants'
import { RaffleFormSubmissionBody } from '../../types'

type RaffleStepTwoSubmissionData = Pick<
  RaffleFormSubmissionBody,
  | 'receiptsDetails'
  | 'grossReceiptsInCents'
  | 'taxDetails'
  | 'totalTaxDueInCents'
>

export const transformRaffleStepTwoToSubmission = (
  formData: RaffleStepTwoFormData,
  totalsInCents: CentsifiedData<RaffleTotals>
): RaffleStepTwoSubmissionData => {
  const formDataInCents = transformDollarsToCents(formData)
  return {
    receiptsDetails: {
      numTicketsSold: formDataInCents.numTicketsSold,
      costPerTicketInCents: formDataInCents.costPerTicketInCents,
      totalTicketSalesInCents: totalsInCents.totalTicketSalesInCents,
      otherReceiptsInCents: formDataInCents.otherReceiptsInCents,
      otherReceiptsDescription: formDataInCents.otherReceiptsDescription
    },
    grossReceiptsInCents: totalsInCents.grossReceiptsInCents,
    taxDetails: {
      baseTaxInCents: totalsInCents.baseTaxInCents,
      penaltyAndInterestInCents: formDataInCents.penaltyAndInterestInCents
    },
    totalTaxDueInCents: totalsInCents.totalTaxDueInCents
  }
}
