import * as React from 'react'
import { getDollarString } from '@Helpers/currency'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { getBlockClass } from '@Helpers/components'
import './styles.scss'

export type GrossReceiptsProps = {
  grossReceiptsDollars: number
}

const ROOT_CLASS = 'gross-beano-receipts'

export const GrossReceipts = (props: GrossReceiptsProps) => {
  return (
    <FormSummary>
      <div className={ROOT_CLASS}>
        <FormSummary.Title className={getBlockClass(ROOT_CLASS, 'header')}>
          Gross Receipts
        </FormSummary.Title>
      </div>
      <FormSummary.Total>
        {getDollarString(props.grossReceiptsDollars)}
      </FormSummary.Total>
    </FormSummary>
  )
}
