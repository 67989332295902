import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import {
  CompleteWeeklyBeanoFormState,
  initialState,
  WeeklyBeanoFormState,
  WeeklyBeanoFormStep
} from '../form-state-reducer/shape'
import { WeeklyBeanoStepOneFields } from '../step-1/constants'
import { transformWeeklyBeanoResponseToStepTwoData } from './transformers/from-initial-values/weekly-beano-step-2-initial-transformer'
import { transformWeeklyBeanoResponseToStepThreeData } from './transformers/from-initial-values/weekly-beano-step-3-initial-transformer'
import { transformWeeklyBeanoResponseToStepFourData } from './transformers/from-initial-values/weekly-beano-step-4-initial-transformer'
import { transformWeeklyBeanoResponseToSubmitData } from './transformers/from-initial-values/weekly-beano-submit-initial-transformer'
import { transformWeeklyBeanoResponseToTotals } from './transformers/from-initial-values/weekly-beano-totals-initial-transformer'
import { transformStepOneData } from './transformers/to-submit/weekly-beano-step-1-transformer'
import { transformStepTwoData } from './transformers/to-submit/weekly-beano-step-2-transformer'
import { transformStepThreeData } from './transformers/to-submit/weekly-beano-step-3-transformer'
import { transformStepFourData } from './transformers/to-submit/weekly-beano-step-4-transformer'
import {
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormBodySubmission,
  WeeklyBeanoFormResponse
} from './types'

export const transformWeeklyBeanoStateToApiRequest = (
  dateString: string,
  organizationDetails: OrganizationDetailsProps,
  occasionTime: string,
  weeklyBeanoState: CompleteWeeklyBeanoFormState
): WeeklyBeanoFormBodySubmission => {
  const dataToSubmit: WeeklyBeanoFormBodySubmission = {
    ...transformStepOneData(
      dateString,
      organizationDetails,
      occasionTime,
      weeklyBeanoState
    ),
    ...transformStepTwoData(weeklyBeanoState),
    ...transformStepThreeData(dateString, weeklyBeanoState),
    ...transformStepFourData(weeklyBeanoState)
  }
  return dataToSubmit
}

export const transformWeeklyBeanoResponseToFormState = (
  response?:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoFormState => {
  if (!response) {
    return initialState
  }
  return {
    [WeeklyBeanoFormStep.StepOne]: {
      [WeeklyBeanoStepOneFields.numPlayers]: response.numPlayers ?? 0
    },
    [WeeklyBeanoFormStep.StepTwo]:
      transformWeeklyBeanoResponseToStepTwoData(response),
    [WeeklyBeanoFormStep.StepThree]:
      transformWeeklyBeanoResponseToStepThreeData(response),
    [WeeklyBeanoFormStep.StepFour]:
      transformWeeklyBeanoResponseToStepFourData(response),
    [WeeklyBeanoFormStep.Submit]:
      transformWeeklyBeanoResponseToSubmitData(response),
    currentStep: 1,
    totals: transformWeeklyBeanoResponseToTotals(response)
  }
}
