import dayjs from 'dayjs'
import { DropdownOptions } from '@Components/dropdown-selector/dropdown-selector-ui'
import { DayFormat } from '../services/dayjs/constants'

export const getIncrementedTimeOptions = (
  startDay: dayjs.Dayjs,
  endDay: dayjs.Dayjs,
  incrementNumber: number,
  incrementUnit: dayjs.ManipulateType,
  format: DayFormat
): DropdownOptions => {
  const timeOptions: DropdownOptions = {}
  let timeToAdd = startDay
  const pastEndDay = endDay.add(1, 'second')
  while (timeToAdd.isBefore(pastEndDay)) {
    const displayFormat = timeToAdd.format(format)
    timeOptions[displayFormat] = displayFormat
    timeToAdd = timeToAdd.add(incrementNumber, incrementUnit)
  }
  return timeOptions
}
