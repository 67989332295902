import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { SignAndSubmitTopFields } from '@Components/form-general/sign-and-submit/sign-and-submit-top-fields'
import { SignAndSubmitBottomFields } from '@Components/form-general/sign-and-submit/sign-and-submit-bottom-fields'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import {
  RaffleSubmitField,
  RaffleSubmitFieldLabel,
  RaffleSubmitFormData
} from './constants'
import './styles.scss'

export type RaffleSubmitFieldsProps = {
  date: string
}

const ROOT_CLASS = 'raffle-submit-fields'

export const RaffleSubmitFields = (props: RaffleSubmitFieldsProps) => {
  const { registerRequired } = useFormContextPlus<RaffleSubmitFormData>()
  return (
    <article className={ROOT_CLASS}>
      <SignAndSubmitTopFields
        fields={{
          role: RaffleSubmitField.memberRole,
          email: RaffleSubmitField.email,
          phone: RaffleSubmitField.phoneNumber
        }}
      />
      <div className={getBlockClass(ROOT_CLASS, 'address-fields')}>
        <Input
          {...registerRequired(
            RaffleSubmitFieldLabel.residenceAddress,
            RaffleSubmitField.residenceAddress
          )}
        />
        <Input
          {...registerRequired(
            RaffleSubmitFieldLabel.city,
            RaffleSubmitField.city
          )}
        />
      </div>
      <SignAndSubmitBottomFields
        date={props.date}
        fields={{
          signature: RaffleSubmitField.signature
        }}
      />
    </article>
  )
}
