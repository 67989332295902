import * as React from 'react'
import { FormStepProps } from '@Components/form-general/types'
import {
  AnnualBeanoStepThreeFormData,
  AnnualBeanoDisbursementTotals,
  BASE_ROOT_CLASS,
  defaultFormData
} from './constants'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { ANNUAL_BEANO_NUM_STEPS } from '../constants'
import { getAnnualBeanoDisbursementTotals } from './helpers'
import { FormCard } from '@Components/form-card/form-card-ui'
import { getBlockClass } from '@Helpers/components'
import { AnnualBeanoDisbursements } from './disbursements/annual-beano-disbursements-ui'
import { AnnualBeanoFundsForDisbursement } from './funds-for-disbursement/funds-for-disbursement-ui'
import { AnnualBeanoBalanceInAccounts } from './total-balance-in-accounts/annual-beano-balance-in-accounts-ui'
import { AnnualBeanoTotalBalanceValidation } from './total-balance-in-accounts/validators'
import { TotalBalanceWarningModal } from './total-balance-in-accounts/total-balance-warning-modal'

export type AnnualBeanoStepThreeProps = FormStepProps<
  AnnualBeanoStepThreeFormData,
  AnnualBeanoDisbursementTotals
> & {
  totalNetProfitOrLossDollars: number
}

const FORM_ID = BASE_ROOT_CLASS
const FORM_STEP = 3

export const AnnualBeanoStepThree = (props: AnnualBeanoStepThreeProps) => {
  const [warningModalOpen, setWarningModalOpen] = React.useState(false)
  const closeWarningModal = () => setWarningModalOpen(false)
  const methods = useForm<AnnualBeanoStepThreeFormData>({
    defaultValues: {
      ...defaultFormData,
      ...props.initialValues
    }
  })

  const totals = getAnnualBeanoDisbursementTotals(
    methods.watch(),
    props.totalNetProfitOrLossDollars
  )

  const totalBalanceValidation =
    AnnualBeanoTotalBalanceValidation.matchesExpectedTotalBalance(
      totals.expectedTotalBalanceDollars,
      methods.getValues
    )

  const onSubmit = (
    data: AnnualBeanoStepThreeFormData,
    e?: React.BaseSyntheticEvent,
    forceSubmit = false
  ) => {
    const shouldOpenWarningModal =
      !totalBalanceValidation.isValid && !forceSubmit
    shouldOpenWarningModal
      ? setWarningModalOpen(true)
      : props.onSubmit(data, totals)
  }

  return (
    <>
      <TotalBalanceWarningModal
        isOpen={warningModalOpen}
        handleClose={closeWarningModal}
        onContinueClick={() => onSubmit(methods.watch(), undefined, true)}
        onGoBackClick={closeWarningModal}
      />
      <FormCard
        title={'Schedule 2: Disposition of, & Accounting for, Net Profit'}
        currentStep={FORM_STEP}
        totalSteps={ANNUAL_BEANO_NUM_STEPS}
      >
        <div className={FORM_ID}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} id={FORM_ID}>
              <div className={getBlockClass(FORM_ID, 'container')}>
                <AnnualBeanoFundsForDisbursement
                  fundsAvailableForDisbursementDollars={
                    totals.totalFundsAvailableForDisbursementDollars
                  }
                  totalNetProfitOrLossDollars={
                    props.totalNetProfitOrLossDollars
                  }
                />
                <AnnualBeanoDisbursements
                  totalDisbursementsDollars={totals.totalDisbursementsDollars}
                  baseDisbursementsTotalDollars={
                    totals.baseDisbursementsTotalDollars
                  }
                  totalNetProfitOrLossDollars={
                    props.totalNetProfitOrLossDollars
                  }
                />
                <AnnualBeanoBalanceInAccounts
                  expectedTotalBalanceDollars={
                    totals.expectedTotalBalanceDollars
                  }
                  error={
                    methods.formState.isSubmitted
                      ? totalBalanceValidation.errorMessage
                      : undefined
                  }
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={ANNUAL_BEANO_NUM_STEPS + 1}
          formId={FORM_ID}
          onBackClick={() => {
            props.onSubmit(methods.getValues(), totals)
            props.onBackClick()
          }}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
