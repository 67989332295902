import * as React from 'react'
import { Modal, ModalProps } from '@Components/modal/modal-ui'
import { getBlockClass } from '@Helpers/components'
import { Button } from '@Components/button/button-ui'
import './styles.scss'

export type ModalInfoProps = {
  header: string
  body: string | JSX.Element
  buttonText: string
  onButtonClick: () => void
} & ModalProps

const ROOT_CLASS = 'info-modal'

export const ModalInfo: React.FC<ModalInfoProps> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      ariaLabel={props.ariaLabel}
    >
      <div className={ROOT_CLASS}>
        <h2 className={getBlockClass(ROOT_CLASS, 'header')}>{props.header}</h2>
        {typeof props.body === 'string' ? (
          <p className={getBlockClass(ROOT_CLASS, 'text')}>{props.body}</p>
        ) : (
          props.body
        )}
        <Button
          className={getBlockClass(ROOT_CLASS, 'button')}
          htmlProps={{ onClick: props.onButtonClick }}
        >
          {props.buttonText}
        </Button>
      </div>
    </Modal>
  )
}
