import * as React from 'react'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { INPUT_ROOT_CLASS } from './constants'
import './styles.scss'

export type Props = {
  error?: string
  className?: string
}

const ROOT_CLASS = getBlockClass(INPUT_ROOT_CLASS, 'error')

export const InputBaseError = (props: Props) =>
  props.error ? (
    <p
      className={joinClasses([ROOT_CLASS, props.className])}
      aria-label="alert"
      role="alert"
    >
      {props.error}
    </p>
  ) : null
