import * as React from 'react'

export type Props = {
  iconColor?: 'white' | 'black' | 'teal' | 'blue'
  sizePx?: number
}

const title = 'External Link Icon'
const blackFill = '#0F1821'
const whiteFill = '#FFFFFF'
const tealFill = '#1E7E74'
const blueFill = '#000EEE'

export const ExternalLinkIcon = ({ iconColor, sizePx }: Props) => {
  let fillColor = blackFill
  switch (iconColor) {
    case 'white':
      fillColor = whiteFill
      break
    case 'teal':
      fillColor = tealFill
      break
    case 'blue':
      fillColor = blueFill
      break
    case 'black':
    default:
      break
  }
  const size = sizePx ?? 10
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/external-link/hyperlink" fill={fillColor} fillRule="nonzero">
          <g id="Group">
            <path
              d="M22.1523958,2.22331114e-14 C23.0075464,2.22331114e-14 23.7007829,0.693236516 23.7007829,1.5483871 C23.7007829,2.3532347 23.0867049,3.0146567 22.3015158,3.08968611 L22.1523958,3.09677419 L14.0239322,3.097 L31.4827103,20.5563164 C32.0873931,21.1609992 32.0873931,22.1413837 31.4827103,22.7460664 C30.9158202,23.3129565 30.0187236,23.3483872 29.4105576,22.8523583 L29.2929603,22.7460664 L11.8739322,5.327 L11.8747063,13.3744637 C11.8747063,14.1793113 11.2606283,14.8407333 10.4754393,14.9157627 L10.3263192,14.9228507 C9.52147164,14.9228507 8.86004965,14.3087727 8.78502023,13.5235837 L8.77793215,13.3744637 L8.77793215,1.5483871 C8.77793215,0.743539492 9.39201017,0.0821174935 10.1771992,0.00708808058 L10.3263192,2.22331114e-14 L22.1523958,2.22331114e-14 Z"
              id="Combined-Shape"
              transform="translate(20.357077, 11.599789) rotate(-270.000000) translate(-20.357077, -11.599789) "
            ></path>
            <path
              d="M22.1636865,8.22414885 C22.9685341,8.22414885 23.6299561,8.83822687 23.7049855,9.62341594 L23.7120736,9.77253595 L23.7120736,30.3878353 C23.7120736,31.1926829 23.0979956,31.8541049 22.3128065,31.9291343 L22.1636865,31.9362224 L12.1324014,31.9362224 C11.2772508,31.9362224 10.5840143,31.2429859 10.5840143,30.3878353 C10.5840143,29.5829877 11.1980923,28.9215657 11.9832814,28.8465363 L12.1324014,28.8394482 L20.6148877,28.8388933 L20.6148877,11.3204416 L3.0964361,11.3204416 L3.09677419,20.4229332 C3.09677419,21.2277808 2.48269617,21.8892028 1.69750711,21.9642322 L1.5483871,21.9713203 C0.743539492,21.9713203 0.0821174935,21.3572423 0.00708808058,20.5720532 L-3.85068321e-14,20.4229332 L-3.85068321e-14,9.77253595 C-3.85068321e-14,8.96768834 0.614078021,8.30626635 1.39926709,8.23123693 L1.5483871,8.22414885 L22.1636865,8.22414885 Z"
              id="Rectangle"
              transform="translate(11.856037, 20.080186) rotate(-180.000000) translate(-11.856037, -20.080186) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
