import * as React from 'react'
import dayjs from 'dayjs'
import { FormStepProps } from '@Components/form-general/types'
import { RAFFLE_DISPLAY_NUM_STEPS } from '@Components/raffle-form/constants'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { DeclarationDetails } from '@Components/weekly-beano-form/api/types'
import { CasinoSubmitFields, CasinoSubmitFormData } from './constants'
import { CasinoSignAndSubmitLegal } from './casino-sign-and-submit-legal'
import { SignAndSubmitTopFields } from '@Components/form-general/sign-and-submit/sign-and-submit-top-fields'
import { SignAndSubmitBottomFields } from '@Components/form-general/sign-and-submit/sign-and-submit-bottom-fields'
import { getBlockClass } from '@Helpers/components'
import { CASINO_FORM_NUM_STEPS } from '../constants'

export type CasinoSignAndSubmitProps = FormStepProps<CasinoSubmitFormData> & {
  submitDisabled?: boolean
  date: string
  dateOfOccasion: dayjs.Dayjs
}

const ROOT_CLASS = 'casino-sign-and-submit'
const FORM_ID = ROOT_CLASS
const FORM_STEP = 4

export const CasinoSignAndSubmit = (props: CasinoSignAndSubmitProps) => {
  const methods = useForm<DeclarationDetails>({
    mode: 'all',
    defaultValues: props.initialValues
  })

  const handleSubmit = async (
    data: DeclarationDetails,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit({ ...data, date: props.date })
  }

  return (
    <>
      <FormCard
        title={'Sign & Submit'}
        currentStep={FORM_STEP - 1}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
      >
        <div className={FORM_ID}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              id={FORM_ID}
              className={FORM_ID}
            >
              <CasinoSignAndSubmitLegal
                dateOfOccassion={props.dateOfOccasion}
              />
              <div className={getBlockClass(ROOT_CLASS, 'fields')}>
                <SignAndSubmitTopFields
                  fields={{
                    role: CasinoSubmitFields.memberRole,
                    email: CasinoSubmitFields.email,
                    phone: CasinoSubmitFields.phoneNumber
                  }}
                />
                <SignAndSubmitBottomFields
                  date={props.date}
                  fields={{
                    signature: CasinoSubmitFields.signature
                  }}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={CASINO_FORM_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid || props.submitDisabled}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
