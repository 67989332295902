import { WeeklyBeanoStepOneFormData } from '../step-1/constants'
import {
  WeeklyBeanoStepTwoFormData,
  WeeklyBeanoStepTwoTotals
} from '../step-2/constants'
import {
  WeeklyBeanoStepThreeFormData,
  WeeklyBeanoStepThreeTotals
} from '../step-3/constants'
import {
  WeeklyBeanoStepFourFormData,
  WeeklyBeanoStepFourTotals
} from '../step-4/constants'
import { WeeklyBeanoSubmitFormData } from '../sign-and-submit/constants'
import { getObjectKeys } from '@Helpers/util'

export enum WeeklyBeanoFormStep {
  StepOne = 'stepOne',
  StepTwo = 'stepTwo',
  StepThree = 'stepThree',
  StepFour = 'stepFour',
  Submit = 'submit'
}

export type WeeklyBeanoTotals = WeeklyBeanoStepTwoTotals &
  WeeklyBeanoStepThreeTotals &
  WeeklyBeanoStepFourTotals

export type WeeklyBeanoFormState = {
  [WeeklyBeanoFormStep.StepOne]?: WeeklyBeanoStepOneFormData
  [WeeklyBeanoFormStep.StepTwo]?: WeeklyBeanoStepTwoFormData
  [WeeklyBeanoFormStep.StepThree]?: WeeklyBeanoStepThreeFormData
  [WeeklyBeanoFormStep.StepFour]?: WeeklyBeanoStepFourFormData
  [WeeklyBeanoFormStep.Submit]?: WeeklyBeanoSubmitFormData
  currentStep: number
  totals: WeeklyBeanoTotals
}

export type CompleteWeeklyBeanoFormState = Required<WeeklyBeanoFormState>

export const isCompleteWeaklyBeanoForm = (
  formState: any
): formState is CompleteWeeklyBeanoFormState =>
  getObjectKeys(formState)
    .map((key) => formState[key])
    .reduce((acc, curr) => acc && curr, true)

export const initialState: WeeklyBeanoFormState = {
  currentStep: 1,
  totals: {
    specialGamesReceiptsTotalInDollars: 0,
    grossBeanoReceiptsInDollars: 0,
    netProfitInDollars: 0,
    progressiveOneProceedsInDollars: 0,
    progressiveTwoProceedsInDollars: 0,
    beanoGrandTotalCashPrizesInDollars: 0,
    mslcTaxPaidInDollars: 0,
    totalBeanoExpendituresInDollars: 0,
    cgTicketNetProceedsInDollars: 0,
    fundsAvailableForDisbursementInDollars: 0,
    totalDisbursementsInDollars: 0,
    totalDepositForOccasionInDollars: 0,
    expectedUnexpendedNetProfitInDollars: 0,
    otherIncomeInDollars: 0
  }
}
