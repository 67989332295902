import * as React from 'react'
import { FieldPath, FieldValues } from '@Hooks/use-form-plus'
import { SubmitFieldLabel } from './constants'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { joinClasses } from '@Helpers/components'

export type SignAndSubmitBottomFieldsProps<
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
> = {
  date: string
  className?: string
  fields: {
    signature: TFieldNames
  }
}

const ROOT_CLASS = 'sign-and-submit-bottom-fields'

export const SignAndSubmitBottomFields = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
>(
  props: SignAndSubmitBottomFieldsProps<TFieldValues, TFieldNames>
) => {
  const {
    registerRequired,
    formState: { errors }
  } = useFormContextPlus<TFieldValues>()

  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      <Input
        {...registerRequired(
          SubmitFieldLabel.signature,
          props.fields.signature
        )}
        error={errors[props.fields.signature]?.message}
      />
      <ReadOnlyField label="Date" value={props.date} />
    </div>
  )
}
