import * as React from 'react'
import { getDollarString } from '@Helpers/currency'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Helpers/components'
import './styles.scss'

export type GrossBeanoReceiptsProps = {
  totalDollars: number
  title: string
  info?: string
}

const ROOT_CLASS = 'general-form-summary'

export const GeneralFormSummary = (props: GrossBeanoReceiptsProps) => (
  <FormSummary>
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'with-info', props.info !== undefined)
      ])}
    >
      <FormSummary.Title className={getBlockClass(ROOT_CLASS, 'header')}>
        {props.title}
      </FormSummary.Title>
      {props.info && <FormSummary.Info>{props.info}</FormSummary.Info>}
    </div>
    <FormSummary.Total>{getDollarString(props.totalDollars)}</FormSummary.Total>
  </FormSummary>
)
