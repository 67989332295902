import {
  CasinoFormStep,
  CompleteCasinoFormState
} from '@Components/casino-form/form-state-reducer/shape'
import { CasinoStepOneField } from '@Components/casino-form/step-1/constants'
import {
  transformCleanAllNumbers,
  transformDollarsToCents
} from '@Components/form-general/transformers'
import dateUtil from '@Services/dayjs'
import { getDayOfWeek } from '@Services/dayjs/helpers'
import { CasinoFormSubmissionBody } from '../types'

export const transformCasinoFormToSubmissionBody = (
  raffleId: string,
  formState: CompleteCasinoFormState
): CasinoFormSubmissionBody => {
  const cleanFormState = transformCleanAllNumbers(formState)
  const stepTwoInCents = transformDollarsToCents(
    cleanFormState[CasinoFormStep.StepTwo]
  )
  const stepThreeInCents = transformDollarsToCents(
    cleanFormState[CasinoFormStep.StepThree]
  )
  const totalsInCents = transformDollarsToCents(cleanFormState.totals)
  const { taxDueInCents, totalTaxDueInCents, ...taxlessTotalsInCents } =
    totalsInCents
  const occasionDateValue =
    cleanFormState[CasinoFormStep.StepOne][CasinoStepOneField.occasionDate]

  return {
    raffleId,
    ...cleanFormState[CasinoFormStep.StepOne],
    occasionDate: dateUtil(occasionDateValue).toISOString(),
    occasionDay: getDayOfWeek(occasionDateValue),
    receiptsDetails: {
      admissionTicketsInCents: stepTwoInCents.admissionTicketsInCents,
      raffleTicketsInCents: stepTwoInCents.raffleTicketsInCents,
      gamingTablesInCents: stepTwoInCents.gamingTablesInCents,
      overShortInCents: stepTwoInCents.overShortInCents,
      overShortSelection: stepTwoInCents.overShortSelection,
      otherReceiptsInCents: stepTwoInCents.otherReceiptsInCents,
      otherReceiptsDescription: stepTwoInCents.otherReceiptsDescription
    },
    expendituresDetails: {
      chipRedemptionsInCents: stepTwoInCents.chipRedemptionsInCents,
      taxDueInCents: totalsInCents.taxDueInCents,
      penaltyAndInterestInCents: stepTwoInCents.penaltyAndInterestInCents,
      totalTaxDue: {
        ...stepTwoInCents.totalTaxDue,
        amountInCents: totalsInCents.totalTaxDueInCents
      },
      equipmentSuppliesFee: stepTwoInCents.equipmentSuppliesFee,
      hallRentalFee: stepTwoInCents.hallRentalFee,
      otherExpenditures: stepTwoInCents.otherExpenditures
    },
    summaryOfPrizes: {
      cashDoorPrize1: stepTwoInCents.cashDoorPrize1,
      cashDoorPrize2: stepTwoInCents.cashDoorPrize2,
      cashRafflePrize1: stepTwoInCents.cashRafflePrize1,
      cashRafflePrize2: stepTwoInCents.cashRafflePrize2,
      merchandiseDoorPrize: stepTwoInCents.merchandiseDoorPrize,
      merchandiseRafflePrize: stepTwoInCents.merchandiseRafflePrize
    },
    dispositionOfAndAccountingForNetProfitsDetails: {
      prevSpecialCasinoCheckingBalanceInCents:
        stepThreeInCents.prevSpecialCasinoCheckingBalanceInCents,
      netProfitInCents: totalsInCents.netProfitInCents,
      otherIncomeInCents: stepThreeInCents.otherIncomeInCents,
      otherIncomeDescription: stepThreeInCents.otherIncomeDescription
    },
    totalDisbursementDetails: {
      disbursements: stepThreeInCents.disbursements
    },
    declarationDetails: {
      ...formState[CasinoFormStep.Submit],
      date: dateUtil(formState[CasinoFormStep.Submit].date).toISOString()
    },
    ...taxlessTotalsInCents
  }
}
