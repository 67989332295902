import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  CasinoStepTwoField,
  CasinoStepTwoFieldLabel,
  CasinoStepTwoFormData,
  CasinoStepTwoTotals
} from '../constants'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import {
  PenaltyAndInterestInfoModal,
  PENALTY_MODAL_HEADER
} from '@Components/form-general/penalty-and-interest-modal/penalty-and-interest-info-modal'
import { useInfoModal } from '@Hooks/use-info-modal'
import './styles.scss'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'

export type CasinoBasicExpensesProps = Pick<
  CasinoStepTwoTotals,
  'taxDueInDollars' | 'totalTaxDueInDollars'
>

const ROOT_CLASS = 'casino-basic-expenses'
const EXPENSE_CLASS = getBlockClass(ROOT_CLASS, 'expense')
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const HEADERS_CLASS = getBlockClass(ROOT_CLASS, 'headers')
const HEADER_SUPPLEMENT_CLASS = getBlockClass(ROOT_CLASS, 'header-supplement')
const FIELD_CLASS = getBlockClass(ROOT_CLASS, 'field')
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')
const FEE_INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'fee-inputs')

export const CasinoBasicExpenses = (props: CasinoBasicExpensesProps) => {
  const { InfoModal, openModal } = useInfoModal({
    header: PENALTY_MODAL_HEADER,
    ariaLabel: PENALTY_MODAL_HEADER,
    body: <PenaltyAndInterestInfoModal />
  })
  const { registerNumber, register, watch } =
    useFormContextPlus<CasinoStepTwoFormData>()
  const chipRedemptionsAmount = watch(
    CasinoStepTwoField.chipRedemptionsInDollars
  )
  const equipmentAmount = watch(CasinoStepTwoField.equipmentAmountInDollars)
  const hallAmount = watch(CasinoStepTwoField.hallAmountInDollars)

  return (
    <>
      {InfoModal}
      <article className={ROOT_CLASS}>
        <div className={EXPENSE_CLASS}>
          <div className={HEADERS_CLASS}>
            <h4 className={HEADER_CLASS}>2. Chip Redemptions</h4>
            <i className={HEADER_SUPPLEMENT_CLASS}>
              (from Control Sheet (H) Line 17)
            </i>
          </div>
          <div className={FIELD_CLASS}>
            <div className={INPUTS_CLASS}>
              <Input
                moneyField
                {...registerNumber(
                  CasinoStepTwoFieldLabel.amount,
                  CasinoStepTwoField.chipRedemptionsInDollars
                )}
              />
            </div>
            <ReadOnlyField
              value={getDollarString(chipRedemptionsAmount)}
              alignRight
            />
          </div>
        </div>
        <div className={EXPENSE_CLASS}>
          <div className={HEADERS_CLASS}>
            <h4 className={HEADER_CLASS}>3. Total Tax Due</h4>
            <i className={HEADER_SUPPLEMENT_CLASS}>
              (Make checks payable to Massachusetts State Lottery Commission)
            </i>
          </div>
          <div className={FIELD_CLASS}>
            <div className={INPUTS_CLASS}>
              <Input
                labelText={CasinoStepTwoFieldLabel.checkNumber}
                {...register(CasinoStepTwoField.taxCheckNumber)}
              />
              <ReadOnlyField
                label={CasinoStepTwoFieldLabel.taxDue}
                value={getDollarString(props.taxDueInDollars)}
              />
              <Input
                moneyField
                labelSupplement={<ButtonQuestionIcon onClick={openModal} />}
                {...registerNumber(
                  CasinoStepTwoFieldLabel.penaltyAndInterest,
                  CasinoStepTwoField.penaltyAndInterestInDollars
                )}
              />
            </div>
            <ReadOnlyField
              value={getDollarString(props.totalTaxDueInDollars)}
              alignRight
            />
          </div>
        </div>
        <div className={EXPENSE_CLASS}>
          <h4 className={HEADER_CLASS}>4. Equipment Supplies Fee</h4>
          <div className={FIELD_CLASS}>
            <div className={FEE_INPUTS_CLASS}>
              <Input
                labelText={CasinoStepTwoFieldLabel.checkNumber}
                {...register(CasinoStepTwoField.equipmentCheckNumber)}
              />
              <Input
                moneyField
                {...registerNumber(
                  CasinoStepTwoFieldLabel.amount,
                  CasinoStepTwoField.equipmentAmountInDollars
                )}
              />
              <Input
                labelText={CasinoStepTwoFieldLabel.nameOfFirm}
                {...register(CasinoStepTwoField.equipmentFirm)}
              />
            </div>
            <ReadOnlyField
              value={getDollarString(equipmentAmount)}
              alignRight
            />
          </div>
        </div>
        <div className={EXPENSE_CLASS}>
          <h4 className={HEADER_CLASS}>5. Hall Rental Fee</h4>
          <div className={FIELD_CLASS}>
            <div className={FEE_INPUTS_CLASS}>
              <Input
                labelText={CasinoStepTwoFieldLabel.checkNumber}
                {...register(CasinoStepTwoField.hallCheckNumber)}
              />
              <Input
                moneyField
                {...registerNumber(
                  CasinoStepTwoFieldLabel.amount,
                  CasinoStepTwoField.hallAmountInDollars
                )}
              />
              <Input
                labelText={CasinoStepTwoFieldLabel.nameOfHall}
                {...register(CasinoStepTwoField.hallFirm)}
              />
            </div>
            <ReadOnlyField value={getDollarString(hallAmount)} alignRight />
          </div>
        </div>
        <Input
          labelText={CasinoStepTwoFieldLabel.address}
          {...register(CasinoStepTwoField.hallAddress)}
          className={getBlockClass(ROOT_CLASS, 'hall-address')}
        />
      </article>
    </>
  )
}
