import * as React from 'react'
import {
  FormHeader,
  Props as FormHeaderProps
} from '@Components/form-header/form-header-ui'
import { FormBody } from '@Components/form-body/form-body-ui'
import './styles.scss'

const ROOT_CLASS = 'form-card'

export type Props = FormHeaderProps

export const FormCard: React.FC<Props> = (props) => (
  <div className={ROOT_CLASS}>
    <FormHeader
      title={props.title}
      totalSteps={props.totalSteps}
      currentStep={props.currentStep}
    />
    <FormBody>{props.children}</FormBody>
  </div>
)
