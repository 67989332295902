import { getBlockClass, joinClasses } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'form-summary'

type FormSummaryProps = {
  className?: string
}

type FormSummaryCompleteProps = {
  text: string
  totalDollars: number
}

const FormSummary = (props: React.PropsWithChildren<FormSummaryProps>) => (
  <>
    <hr />
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      {props.children}
    </div>
  </>
)

const Title: React.FC<FormSummaryProps> = (props) => (
  <h2
    className={joinClasses([
      getBlockClass(ROOT_CLASS, 'title'),
      props.className
    ])}
  >
    {props.children}
  </h2>
)

const Info: React.FC<FormSummaryProps> = (props) => (
  <p
    className={joinClasses([
      getBlockClass(ROOT_CLASS, 'info'),
      props.className
    ])}
  >
    {props.children}
  </p>
)

const Total: React.FC<FormSummaryProps> = (props) => (
  <p
    className={joinClasses([
      getBlockClass(ROOT_CLASS, 'total'),
      props.className
    ])}
  >
    {props.children}
  </p>
)

const Complete = (props: FormSummaryCompleteProps) => (
  <FormSummary>
    <div className={getBlockClass(ROOT_CLASS, 'complete-title')}>
      <FormSummary.Title>{props.text}</FormSummary.Title>
    </div>
    <FormSummary.Total>{getDollarString(props.totalDollars)}</FormSummary.Total>
  </FormSummary>
)

FormSummary.Title = Title
FormSummary.Info = Info
FormSummary.Total = Total
FormSummary.Complete = Complete

export { FormSummary }
