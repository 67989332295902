import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { WinnerTakeAllSection } from './winner-take-all-ui'
import { WeeklyBeanoProgressiveGames } from './progressive-games-ui'
import { OtherGamesSection } from './other-games-ui'
import '../styles.scss'
import './styles.scss'

const ROOT_CLASS = 'special-games-receipts'

export const SpecialGamesReceipts = React.memo(() => (
  <FormSubsection type="primary">
    <h3>3. Receipts from Special Games</h3>
    <div className={ROOT_CLASS}>
      <WinnerTakeAllSection />
      <WeeklyBeanoProgressiveGames />
      <OtherGamesSection />
    </div>
  </FormSubsection>
))
