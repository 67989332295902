export const getCleanNumber = (number?: number): number =>
  number === undefined || isNaN(number) ? 0 : number

export const sum = (a: number, b: number): number => a + b
export const diff = (a: number, b: number): number => a - b

export const sumList = (list: number[]): number =>
  list.reduce((a, b) => sum(getCleanNumber(a), getCleanNumber(b)), 0)
export const subtractList = (list: number[]): number => {
  const [first, ...rest] = list
  return rest.reduce(
    (a, b) => diff(getCleanNumber(a), getCleanNumber(b)),
    first
  )
}

export const getAscendingArray = (maxNumber: number, includeZero = true) =>
  Array(includeZero ? maxNumber + 1 : maxNumber)
    .fill(0)
    .map((_, i) => (includeZero ? i : i + 1))

export const roundToDecimals = (number: number) =>
  Math.round(number * 100) / 100
