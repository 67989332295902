import * as React from 'react'
import { FormProvider } from '@Hooks/use-form-plus'
import { useFormPlus } from '@Hooks/use-form-plus'
import { FormStepOneProps } from '@Components/form-general/types'
import { RAFFLE_DISPLAY_NUM_STEPS, RAFFLE_NUM_STEPS } from '../constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import {
  OrganizationDetails,
  OrganizationDetailsProps
} from '@Components/organization-details/organization-details-ui'
import { RaffleEventDetails } from './raffle-event-details-ui'
import { RaffleStepOneFormData } from './constants'
import './styles.scss'

export type RaffleStepOneProps = FormStepOneProps<RaffleStepOneFormData> & {
  organizationDetails: OrganizationDetailsProps
}

export const ROOT_CLASS = 'raffle-step-one'
const FORM_ID = ROOT_CLASS
const FORM_STEP = 1

export const RaffleStepOne = (props: RaffleStepOneProps) => {
  const methods = useFormPlus<RaffleStepOneFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  return (
    <>
      <FormCard currentStep={FORM_STEP} totalSteps={RAFFLE_DISPLAY_NUM_STEPS}>
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(props.onSubmit)} id={FORM_ID}>
              <OrganizationDetails {...props.organizationDetails} />
              <RaffleEventDetails />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={RAFFLE_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
        />
      )}
    </>
  )
}
