import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'raffle-taxes-info-modal'
export const PENALTY_MODAL_HEADER = 'Penalty & Interest Calculations'

export const PenaltyAndInterestInfoModal = () => (
  <ul className={ROOT_CLASS}>
    <li>
      <p>
        <b>1. Interest</b> - Charged at 12% from date due to the date paid.
      </p>
    </li>
    <li>
      <p>
        <b>2. Penalty for Late Filing of Return</b> - One return of the tax due
        for each month or part therof from date return due to the date filed up
        to a maximum of 25% of the tax due.
      </p>
    </li>
    <li>
      <p>
        <b>3. Penalty for Late Payment of Tax</b> - 0.5% of the tax due for each
        month or part thereof from the date tax due to the date paid up to a
        maximum of 25% of the tax due.
      </p>
    </li>
  </ul>
)
