import * as React from 'react'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  CasinoStepOneField,
  CasinoStepOneFieldLabel,
  CasinoStepOneFormData
} from '../constants'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getRequiredLabel } from '@Helpers/test-util'
import { getDayOfWeek } from '@Services/dayjs/helpers'
import { StartAndEndTimes } from '@Components/form-general/start-and-end-times/start-and-end-times-ui'
import './styles.scss'

const ROOT_CLASS = 'casino-date-and-time'
const SECTION_CLASS = getBlockClass(ROOT_CLASS, 'section')

export const CasinoDateAndTime = () => {
  const { registerDate, watch } = useFormContextPlus<CasinoStepOneFormData>()

  return (
    <article className={ROOT_CLASS}>
      <h2>Date & Times</h2>
      <div className={SECTION_CLASS}>
        <Input
          {...registerDate(
            getRequiredLabel(CasinoStepOneFieldLabel.occasionDate),
            CasinoStepOneField.occasionDate,
            {
              required: {
                value: true,
                message: `${CasinoStepOneFieldLabel.occasionDate} is required.`
              }
            }
          )}
        />
        <ReadOnlyField
          label={CasinoStepOneFieldLabel.occasionDay}
          value={getDayOfWeek(watch(CasinoStepOneField.occasionDate))}
        />
      </div>
      <StartAndEndTimes
        startTime={CasinoStepOneField.occasionStartTime}
        endTime={CasinoStepOneField.occasionEndTime}
        numPlayers={CasinoStepOneField.numPlayers}
        className={getBlockClass(ROOT_CLASS, 'start-and-end-times')}
      />
    </article>
  )
}
