import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { FormStepProps } from '@Components/form-general/types'
import { AnnualBeanoSubmitFormData } from './constants'
import { FormCard } from '@Components/form-card/form-card-ui'
import { ANNUAL_BEANO_NUM_STEPS } from '../constants'
import { getBlockClass } from '@Helpers/components'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { AnnualBeanoSubmitFields } from './annual-beano-submit-fields-ui'
import './styles.scss'

export type AnnualBeanoSubmitProps =
  FormStepProps<AnnualBeanoSubmitFormData> & {
    submitDisabled?: boolean
  }

const FORM_STEP = 3
const FORM_ID = 'annual-beano-submit'
const BASE_ROOT_CLASS = FORM_ID
const LEGAL_BODY_CLASS = getBlockClass(BASE_ROOT_CLASS, 'legal-body')
const today = dateUtil().format(DayFormat.monthDayYearDoubleDigit)

export const AnnualBeanoSubmit = (props: AnnualBeanoSubmitProps) => {
  const methods = useForm<AnnualBeanoSubmitFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  const date = props.initialValues?.date ?? today

  const handleSubmit = async (
    data: AnnualBeanoSubmitFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit({ ...data, date })
  }

  return (
    <>
      <FormCard
        title={'Sign & Submit'}
        currentStep={FORM_STEP}
        totalSteps={ANNUAL_BEANO_NUM_STEPS}
      >
        <div className={FORM_ID}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              id={FORM_ID}
              className={FORM_ID}
            >
              <h3 className={getBlockClass(BASE_ROOT_CLASS, 'legal-header')}>
                SIGNED UNDER THE PAINS AND PENALTIES OF PERJURY:
              </h3>
              <p className={LEGAL_BODY_CLASS}>
                A copy of this report must be filed with the mayor and council
                in a city or selectmen in a town.
              </p>
              <p className={LEGAL_BODY_CLASS}>
                <b>City of Boston Licensees Only:</b> A copy of this report must
                be filed with the Boston Licensing Board.
              </p>
              <AnnualBeanoSubmitFields date={date} />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={ANNUAL_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid || props.submitDisabled}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
