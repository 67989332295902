import { getBlockClass } from '@Helpers/components'
import { getCleanNumber } from '@Helpers/numbers'

export const ROOT_CLASS = 'admissions-cards'
export const X_REGULAR_CLASS = getBlockClass(ROOT_CLASS, 'x-regular')
export const ADMISSIONS_INPUT_CLASS = getBlockClass(ROOT_CLASS, 'inputs')

export const getAdmissionsTotal = (
  numCards: number,
  pricePerCardDollars: number,
  numXCards: number,
  pricePerXCardDollars: number
) => {
  const normalTotal = getCleanNumber(numCards * pricePerCardDollars)
  const xTotal = getCleanNumber(numXCards * pricePerXCardDollars)
  return normalTotal + xTotal
}
