import { getObjectKeys } from '@Helpers/util'
import { transformCasinoSubmissionBodyToFormData } from '../api/transformers/casino-initial-values-transformer'
import { CasinoFormSubmissionBody } from '../api/types'
import { CasinoSubmitFormData } from '../sign-and-submit/constants'
import { CasinoStepOneFormData } from '../step-1/constants'
import { CasinoStepTwoFormData, CasinoStepTwoTotals } from '../step-2/constants'
import {
  CasinoStepThreeFormData,
  CasinoStepThreeTotals
} from '../step-3/constants'

export enum CasinoFormStep {
  StepOne = 'stepOne',
  StepTwo = 'stepTwo',
  StepThree = 'stepThree',
  Submit = 'submit'
}

export type CasinoTotals = CasinoStepTwoTotals & CasinoStepThreeTotals

export type CasinoFormState = {
  [CasinoFormStep.StepOne]?: CasinoStepOneFormData
  [CasinoFormStep.StepTwo]?: CasinoStepTwoFormData
  [CasinoFormStep.StepThree]?: CasinoStepThreeFormData
  [CasinoFormStep.Submit]?: CasinoSubmitFormData
  currentStep: number
  totals: CasinoTotals
}

export type CompleteCasinoFormState = Required<CasinoFormState>

export const isCompleteCasinoForm = (
  formState: CasinoFormState | CompleteCasinoFormState
): formState is CompleteCasinoFormState =>
  getObjectKeys(formState)
    .map((key) => Boolean(formState[key]))
    .reduce((acc, curr) => acc && curr, true)

const defaultInitialState: CasinoFormState = {
  currentStep: 1,
  totals: {
    grossReceiptsInDollars: 0,
    totalExpendituresInDollars: 0,
    grandTotalCashPrizesInDollars: 0,
    netProfitInDollars: 0,
    taxDueInDollars: 0, // 5% auto-calculated
    totalTaxDueInDollars: 0, // taxDueInDollars + penalty and interest

    fundsAvailableForDisbursementInDollars: 0,
    totalDisbursementsInDollars: 0,
    specialCasinoCheckingBalanceInDollars: 0
  }
}

export const getInitialState = (
  initialValues?: CasinoFormSubmissionBody
): CasinoFormState =>
  initialValues
    ? transformCasinoSubmissionBodyToFormData(initialValues)
    : defaultInitialState
