import {
  UpdateWeeklyBeanoFormAction,
  WeeklyBeanoFormAction,
  WeeklyBeanoFormActions
} from './actions'
import {
  WeeklyBeanoFormState,
  WeeklyBeanoFormStep,
  WeeklyBeanoTotals
} from './shape'

export const WeeklyBeanoFormReducer = <Step extends WeeklyBeanoFormStep>(
  state: WeeklyBeanoFormState,
  action: WeeklyBeanoFormAction<Step>
): WeeklyBeanoFormState => {
  switch (action.type) {
    case WeeklyBeanoFormActions.UPDATE_FORM_STATE:
      return {
        ...state,
        totals: getWeeklyBeanoTotals(state, action),
        [action.step]: action.payload,
        currentStep: state.currentStep + 1
      }
    case WeeklyBeanoFormActions.CHANGE_FORM_STEP:
      return {
        ...state,
        currentStep: action.step
      }
    default:
      return state
  }
}

const getWeeklyBeanoTotals = <Step extends WeeklyBeanoFormStep>(
  state: WeeklyBeanoFormState,
  action: UpdateWeeklyBeanoFormAction<Step>
): WeeklyBeanoTotals =>
  'totals' in action
    ? {
        ...state.totals,
        ...action.totals
      }
    : state.totals
