import * as React from 'react'
import { InputRadio } from '@Components/input-radio/input-radio-ui'
import { getBlockClass } from '@Helpers/components'
import { useFormPlus } from '@Hooks/use-form-plus'
import { InputTextarea } from '@Components/input-textarea/input-textarea-ui'
import { FormStepOneProps } from '@Components/form-general/types'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { RAFFLE_NUM_STEPS } from '../constants'
import { InputBaseError } from '@Components/input-base/input-base-error-ui'
import {
  RaffleFormActivityType,
  RaffleFormActivityTypeField,
  RaffleFormActivityTypeFormData
} from './constants'
import './styles.scss'

export type ActivityTypeProps = FormStepOneProps<RaffleFormActivityTypeFormData>

const ROOT_CLASS = 'raffle-activity-type'
const OPTION_CLASS = getBlockClass(ROOT_CLASS, 'option')
const RADIO_CLASS = getBlockClass(ROOT_CLASS, 'radio')
const LABEL_TEXT = 'Select activity type'

const FORM_ID = ROOT_CLASS
const FORM_STEP = 1

export const ActivityType = (props: ActivityTypeProps) => {
  const {
    registerRequired,
    register,
    watch,
    getValues,
    formState,
    handleSubmit
  } = useFormPlus<RaffleFormActivityTypeFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })
  const checkedValue = watch(RaffleFormActivityTypeField.activityType)
  const otherTypeError =
    formState.errors[RaffleFormActivityTypeField.otherExplained]
  return (
    <>
      <form
        className={ROOT_CLASS}
        id={FORM_ID}
        onSubmit={handleSubmit(props.onSubmit)}
      >
        <h3 className={getBlockClass(ROOT_CLASS, 'label')}>{LABEL_TEXT}</h3>
        <div className={getBlockClass(ROOT_CLASS, 'options')}>
          <InputRadio
            {...registerRequired(
              LABEL_TEXT,
              RaffleFormActivityTypeField.activityType
            )}
            value={RaffleFormActivityType.raffle}
            id={`${RaffleFormActivityType}-${RaffleFormActivityType.raffle}`}
            checked={checkedValue === RaffleFormActivityType.raffle}
            className={RADIO_CLASS}
          >
            <p className={OPTION_CLASS}>Raffle</p>
          </InputRadio>
          <InputRadio
            {...registerRequired(
              LABEL_TEXT,
              RaffleFormActivityTypeField.activityType
            )}
            value={RaffleFormActivityType.bazaar}
            id={`${RaffleFormActivityType}-${RaffleFormActivityType.bazaar}`}
            checked={checkedValue === RaffleFormActivityType.bazaar}
            className={RADIO_CLASS}
          >
            <p className={OPTION_CLASS}>Bazaar</p>
          </InputRadio>
          <InputRadio
            {...registerRequired(
              LABEL_TEXT,
              RaffleFormActivityTypeField.activityType
            )}
            value={RaffleFormActivityType.other}
            id={`${RaffleFormActivityType}-${RaffleFormActivityType.other}`}
            checked={checkedValue === RaffleFormActivityType.other}
            className={RADIO_CLASS}
          >
            <p className={OPTION_CLASS}>Other (please specify)</p>
          </InputRadio>
        </div>
        <InputTextarea
          {...register(RaffleFormActivityTypeField.otherExplained, {
            validate: {
              requiredIfOther: (value?: string) => {
                const checkedValue = getValues(
                  RaffleFormActivityTypeField.activityType
                )
                if (checkedValue === RaffleFormActivityType.other) {
                  return value
                    ? true
                    : 'Specification for other activity type is required'
                }
                return true
              }
            }
          })}
          className={getBlockClass(ROOT_CLASS, 'no-game-reason')}
        />
        {otherTypeError && <InputBaseError error={otherTypeError.message} />}
      </form>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={RAFFLE_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!formState.isValid}
        />
      )}
    </>
  )
}
