import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { useDynamicFields, useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import {
  AnnualBeanoStepTwoField,
  AnnualBeanoStepTwoFieldLabel,
  AnnualBeanoStepTwoFormData,
  defaultOtherExpense
} from '../constants'
import './styles.scss'

export type AnnualBeanoOtherExpenditureProps = {
  index: number
  id: string
}

const ROOT_CLASS = 'annnual-beano-other-expenditures'

const AnnualBeanoOtherExpenditure = (
  props: AnnualBeanoOtherExpenditureProps
) => {
  const { register, registerNumber } =
    useFormContextPlus<AnnualBeanoStepTwoFormData>()
  const baseFieldName =
    `${AnnualBeanoStepTwoField.otherExpenses}.${props.index}` as const
  return (
    <div className={getBlockClass(ROOT_CLASS, 'expense')}>
      <Input
        {...registerNumber(
          AnnualBeanoStepTwoFieldLabel.amount,
          `${baseFieldName}.amountInDollars`
        )}
        className={getBlockClass(ROOT_CLASS, 'expense-input')}
        moneyField
      />
      <Input
        {...register(`${baseFieldName}.description`)}
        labelText={AnnualBeanoStepTwoFieldLabel.description}
      />
    </div>
  )
}

export const AnnualBeanoOtherExpenditures = () => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<AnnualBeanoStepTwoFormData>({
    name: AnnualBeanoStepTwoField.otherExpenses,
    defaultData: defaultOtherExpense,
    getSubtotal: ['amountInDollars']
  })
  return (
    <article className={ROOT_CLASS}>
      <h4>7. Other Expense</h4>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <DynamicFieldManager
          elements={fields.map((data, index) => (
            <AnnualBeanoOtherExpenditure
              index={index}
              key={data.id}
              id={data.id}
            />
          ))}
          removeElement={removeElement}
          addElement={addElement}
          onResetClick={reset}
          getSubtotalDisplay={getSubtotalDisplay}
          isResetDisabled={isResetDisabled()}
          addButtonText={'Add Another Expense'}
          resetButtonText={'Reset Other Expenses'}
        />
      </div>
    </article>
  )
}
