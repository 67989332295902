import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { CasinoDisbursement } from './casino-disbursement-ui'
import { useDynamicFields } from '@Hooks/use-form-plus'
import {
  CasinoStepThreeField,
  CasinoStepThreeFormData,
  defaultCasinoDisbursement
} from '../constants'
import './styles.scss'

const ROOT_CLASS = 'casino-disbursements'

export const CasinoDisbursements = () => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<CasinoStepThreeFormData>({
    name: CasinoStepThreeField.disbursements,
    defaultData: defaultCasinoDisbursement,
    getSubtotal: ['amountInDollars']
  })
  return (
    <FormSubsection type="primary">
      <h4 className={getBlockClass(ROOT_CLASS, 'header')}>
        4. Total Disbursements Since Last Report
      </h4>
      <p className={getBlockClass(ROOT_CLASS, 'copy')}>
        Include purchase of charity game tickets and religious, chartiable,
        education disbursements
      </p>
      <DynamicFieldManager
        elements={fields.map((data, index) => (
          <CasinoDisbursement index={index} key={data.id} id={data.id} />
        ))}
        removeElement={removeElement}
        addElement={addElement}
        onResetClick={reset}
        getSubtotalDisplay={getSubtotalDisplay}
        isResetDisabled={isResetDisabled()}
        addButtonText={'Add Another Disbursement'}
        resetButtonText={'Reset Other Disbursements'}
      />
    </FormSubsection>
  )
}
