import * as React from 'react'
import { CardHeader } from '@Components/card-header/card-header-ui'
import {
  FormStepIndicator,
  Props as FormStepProps
} from '@Components/form-step-indicator/form-step-indicator-ui'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

export type Props = {
  title?: string
} & FormStepProps

const ROOT_CLASS = 'form-header'

export const FormHeader = (props: Props) => (
  <CardHeader>
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'with-title', props.title !== undefined)
      ])}
    >
      {props.title && <h2>{props.title}</h2>}
      <FormStepIndicator
        currentStep={props.currentStep}
        totalSteps={props.totalSteps}
      />
    </div>
  </CardHeader>
)
