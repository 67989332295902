import * as React from 'react'
import { FormProvider } from '@Hooks/use-form-plus'
import { useFormPlus } from '@Hooks/use-form-plus'
import { FormStepProps } from '@Components/form-general/types'
import { RAFFLE_DISPLAY_NUM_STEPS, RAFFLE_NUM_STEPS } from '../constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import { RaffleTaxes } from './taxes/raffle-tax-ui'
import { RaffleReceipts } from './raffle-receipts'
import { getRaffleStepTwoTotals } from './helpers'
import {
  defaultRaffleStepTwoFormData,
  DollarizedRaffleStepTwoTotals,
  RaffleStepTwoFormData
} from './constants'
import './styles.scss'

export type RaffleStepTwoProps = FormStepProps<
  RaffleStepTwoFormData,
  DollarizedRaffleStepTwoTotals
>

export const ROOT_CLASS = 'raffle-step-two'
const FORM_ID = ROOT_CLASS
const FORM_STEP = 2

export const RaffleStepTwo = (props: RaffleStepTwoProps) => {
  const methods = useFormPlus<RaffleStepTwoFormData>({
    defaultValues: {
      ...defaultRaffleStepTwoFormData,
      ...props.initialValues
    }
  })
  const totals = getRaffleStepTwoTotals(methods.watch())

  const handleSubmit = async (
    data: RaffleStepTwoFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit(data, totals)
  }

  return (
    <>
      <FormCard
        currentStep={FORM_STEP}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
        title="Schedule 1: Computation of Tax"
      >
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <RaffleReceipts
                grossReceiptsInDollars={totals.grossReceiptsInDollars}
                totalTicketSalesInDollars={totals.totalTicketSalesInDollars}
              />
              <RaffleTaxes
                totalTaxDueInDollars={totals.totalTaxDueInDollars}
                baseTaxInDollars={totals.baseTaxInDollars}
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={RAFFLE_NUM_STEPS}
          formId={FORM_ID}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
