import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { TimesAndNumPlayers } from './times-and-num-players-ui'
import './styles.scss'

export type Props = {
  dateString: string
  occasionTime: string // 'AFTERNOON' | 'EVENING'
}

const ROOT_CLASS = 'date-and-times'
const SECTION_CLASS_NAME = getBlockClass(ROOT_CLASS, 'section')
const READ_ONLY_FIELD_CLASS_NAME = getBlockClass(ROOT_CLASS, 'read-only-field')

export const DateAndTimes = (props: Props) => {
  const dayOfOccasion = React.useMemo(
    () => dateUtil(props.dateString).format(DayFormat.dayOfWeek),
    [props.dateString]
  )

  return (
    <article className={ROOT_CLASS}>
      <h2>Date & Times</h2>
      <div className={SECTION_CLASS_NAME}>
        <ReadOnlyField
          label="Date of Occasion *"
          value={props.dateString}
          className={READ_ONLY_FIELD_CLASS_NAME}
        />
        <ReadOnlyField
          label="Day of Occasion"
          value={dayOfOccasion}
          className={READ_ONLY_FIELD_CLASS_NAME}
        />
      </div>
      <TimesAndNumPlayers occasionTime={props.occasionTime} />
    </article>
  )
}
