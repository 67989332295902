import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { SignAndSubmitTopFields } from '@Components/form-general/sign-and-submit/sign-and-submit-top-fields'
import { SignAndSubmitBottomFields } from '@Components/form-general/sign-and-submit/sign-and-submit-bottom-fields'
import { BASE_ROOT_CLASS, WeeklyBeanoSubmitField } from './constants'
import './styles.scss'

type Props = {
  date: string
}

const ROOT_CLASS = getBlockClass(BASE_ROOT_CLASS, 'fields')

export const SignAndSubmitFields = (props: Props) => (
  <div className={ROOT_CLASS}>
    <SignAndSubmitTopFields
      fields={{
        role: WeeklyBeanoSubmitField.memberRole,
        email: WeeklyBeanoSubmitField.email,
        phone: WeeklyBeanoSubmitField.phoneNumber
      }}
    />
    <SignAndSubmitBottomFields
      date={props.date}
      fields={{
        signature: WeeklyBeanoSubmitField.signature
      }}
    />
  </div>
)
