import * as React from 'react'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import { getDollarString } from '@Helpers/currency'
import { ModalInfo } from '@Components/modal-info/modal-info-ui'
import { ButtonQuestionIcon } from '@Components/button-question-icon/button-question-icon-ui'
import './styles.scss'

export type TotalNetProfitOrLossProps = {
  totalNetProfitOrLossDollars: number
}

const ROOT_CLASS = 'annual-beano-two-total-net-profit'

export const TotalNetProfitOrLoss = (props: TotalNetProfitOrLossProps) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const closeModal = () => setModalOpen(false)
  return (
    <>
      <ModalInfo
        isOpen={modalOpen}
        ariaLabel="Total Net Profit or Loss Information"
        handleClose={closeModal}
        header="Net Profit or Loss from Beano and Charity Game Tickets"
        body="This is calculated by adding the Net Profit or Loss to the Net Profit or Loss from Beano and Charity Game Tickets Sales."
        buttonText="Okay"
        onButtonClick={closeModal}
      />
      <FormSummary>
        <div className={ROOT_CLASS}>
          <FormSummary.Title>
            Net Profit or Loss from Beano and Charity Game Tickets
          </FormSummary.Title>
          <ButtonQuestionIcon onClick={() => setModalOpen(true)} />
        </div>
        <FormSummary.Total>
          {getDollarString(props.totalNetProfitOrLossDollars)}
        </FormSummary.Total>
      </FormSummary>
    </>
  )
}
