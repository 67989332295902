import {
  DollarizedBeanoExpendituresDetails,
  DollarizedPrizeSummaryProgressiveGameDetails,
  DollarizedWeeklyBeanoFormBody
} from '../api/types-dollarized'

export const BASE_ROOT_CLASS = 'weekly-beano-step-three'

export enum WeeklyBeanoStepThreeField {
  paidViaEft = 'paidViaEft',
  paidViaEftSelection = 'paidViaEftSelection',
  checkNumber = 'checkNumber',
  winnerTakeAll = 'winnerTakeAll',
  progressiveGames = 'progressiveGames',
  expenditures = 'expenditures',

  // Prize Details
  spGameNumGames = 'spGameNumGames',
  spGamePrize = 'spGamePrize',
  goodNeighborNumGames = 'goodNeighborNumGames',
  goodNeighborPrize = 'goodNeighborPrize',
  breakageAmountInDollars = 'breakageAmountInDollars',
  otherGames = 'otherGames',
  fiftyFifty = 'fiftyFifty',

  // Door Prizes
  doorCashNumGames = 'doorCashNumGames',
  doorCashPrize = 'doorCashPrize',
  doorPrizeClerkNumber = 'doorPrizeClerkNumber',
  doorPrizeDescription = 'doorPrizeDescription',
  merchandiseAmount = 'merchandiseAmount'
}

export enum WeeklyBeanoStepThreeFieldLabel {
  amount = 'Amount',
  beginningCarryover = 'Beginning Carryover',
  checkNumberIfApplicable = 'Check No. (if applicable) ',
  checkNumber = 'Check No.',
  clerkNumber = 'Reference to Clerk No.',
  description = 'Description',
  endingCarryover = 'Ending Carryover',
  expenseType = 'Expense Type',
  numGames = 'No. of Games',
  numCalls = 'No. of Calls',
  paidViaEFT = 'Paid via EFT',
  payee = 'Payee',
  prizeAwarded = 'Prize Awarded',
  prizesPaid = 'Prize(s) Paid'
}

export enum PaidViaEftSelection {
  yes = 'Yes',
  no = 'No'
}

export type WTAAmount = {
  amountInDollars: number
}

export type WTAFormDetails = {
  amountsInDollars: WTAAmount[]
  numGames: number
}

export type ExpenditureDetail = {
  checkNumber: string
  payee: string
  description: string
  amountInDollars: number
  type: string
}

export type PrizeDetail = {
  gameName: string
  prizeInDollars: number
  numGames: number
}

export type FormPrizeSummaryProgressiveGameDetails = Omit<
  DollarizedPrizeSummaryProgressiveGameDetails,
  | 'taxDeductionInDollars'
  | 'licenseeProfitInDollars'
  | 'grossBeanoReceiptsInDollars'
>

export type PrizeSummaryDynamicField =
  | WeeklyBeanoStepThreeField.otherGames
  | WeeklyBeanoStepThreeField.fiftyFifty

export type WeeklyBeanoStepThreeFormData = {
  [WeeklyBeanoStepThreeField.paidViaEftSelection]?: string
  [WeeklyBeanoStepThreeField.checkNumber]: string
  [WeeklyBeanoStepThreeField.winnerTakeAll]: WTAFormDetails[]
  [WeeklyBeanoStepThreeField.progressiveGames]: FormPrizeSummaryProgressiveGameDetails[]
  [WeeklyBeanoStepThreeField.expenditures]: ExpenditureDetail[]
  [WeeklyBeanoStepThreeField.spGameNumGames]: number
  [WeeklyBeanoStepThreeField.spGamePrize]: number
  [WeeklyBeanoStepThreeField.goodNeighborNumGames]: number
  [WeeklyBeanoStepThreeField.goodNeighborPrize]: number
  [WeeklyBeanoStepThreeField.otherGames]: PrizeDetail[]
  [WeeklyBeanoStepThreeField.fiftyFifty]: PrizeDetail[]
  [WeeklyBeanoStepThreeField.breakageAmountInDollars]: number
  [WeeklyBeanoStepThreeField.doorCashNumGames]: number
  [WeeklyBeanoStepThreeField.doorCashPrize]: number
  [WeeklyBeanoStepThreeField.doorPrizeClerkNumber]: string
  [WeeklyBeanoStepThreeField.doorPrizeDescription]: string
  [WeeklyBeanoStepThreeField.merchandiseAmount]: number
}

export const defaultExpenditureDetail: ExpenditureDetail = {
  checkNumber: '',
  payee: '',
  description: '',
  amountInDollars: 0,
  type: ''
}

export const defaultWTAAmount: WTAAmount = {
  amountInDollars: 0
}

export const defaultWTAFormDetails: WTAFormDetails = {
  amountsInDollars: [defaultWTAAmount],
  numGames: 1
}

export const defaultProgressiveGameSummary: FormPrizeSummaryProgressiveGameDetails =
  {
    numCalls: 0,
    numGames: 0,
    beginningCarryoverInDollars: 0,
    endingCarryoverInDollars: 0,
    prizesPaidInDollars: 0
  }

export const defaultPrizeDetail: PrizeDetail = {
  numGames: 0,
  prizeInDollars: 0,
  gameName: ''
}

export type WeeklyBeanoStepThreeTotals = Pick<
  DollarizedWeeklyBeanoFormBody,
  'totalBeanoExpendituresInDollars' | 'netProfitInDollars'
> &
  Pick<DollarizedBeanoExpendituresDetails, 'beanoGrandTotalCashPrizesInDollars'>

export const defaultFormData: WeeklyBeanoStepThreeFormData = {
  [WeeklyBeanoStepThreeField.winnerTakeAll]: [],
  [WeeklyBeanoStepThreeField.progressiveGames]: [
    defaultProgressiveGameSummary,
    defaultProgressiveGameSummary
  ],
  [WeeklyBeanoStepThreeField.expenditures]: [defaultExpenditureDetail],
  [WeeklyBeanoStepThreeField.checkNumber]: '',
  [WeeklyBeanoStepThreeField.spGameNumGames]: 0,
  [WeeklyBeanoStepThreeField.spGamePrize]: 0,
  [WeeklyBeanoStepThreeField.goodNeighborNumGames]: 0,
  [WeeklyBeanoStepThreeField.goodNeighborPrize]: 0,
  [WeeklyBeanoStepThreeField.otherGames]: [defaultPrizeDetail],
  [WeeklyBeanoStepThreeField.fiftyFifty]: [defaultPrizeDetail],
  [WeeklyBeanoStepThreeField.breakageAmountInDollars]: 0,
  [WeeklyBeanoStepThreeField.doorCashNumGames]: 0,
  [WeeklyBeanoStepThreeField.doorCashPrize]: 0,
  [WeeklyBeanoStepThreeField.doorPrizeClerkNumber]: '',
  [WeeklyBeanoStepThreeField.doorPrizeDescription]: '',
  [WeeklyBeanoStepThreeField.merchandiseAmount]: 0
}

export const getWeeklyBeanoThreeDefaultFormData = (
  numWinnerTakeAllSections: number,
  initialValues?: WeeklyBeanoStepThreeFormData
): WeeklyBeanoStepThreeFormData => {
  const existingWtaExpenditures =
    initialValues?.[WeeklyBeanoStepThreeField.winnerTakeAll] ?? []
  const numExistingWtaExpenditures = existingWtaExpenditures.length
  const difference = numWinnerTakeAllSections - numExistingWtaExpenditures
  let wtaExpenditures = existingWtaExpenditures
  if (difference < 0) {
    wtaExpenditures = wtaExpenditures.slice(0, numWinnerTakeAllSections)
  } else if (difference > 0) {
    const newWtaGroups = Array(difference).fill(defaultWTAFormDetails)
    wtaExpenditures.push(...newWtaGroups)
  }
  return {
    ...defaultFormData,
    ...initialValues,
    [WeeklyBeanoStepThreeField.winnerTakeAll]: wtaExpenditures
  }
}
