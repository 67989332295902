import * as React from 'react'
import { getModifierClass, joinClasses } from '@Helpers/components'
import './styles.scss'

export type Props = {
  type: 'primary' | 'secondary'
}

const ROOT_CLASS = 'form-subsection'

export const FormSubsection: React.FC<Props> = (props) => (
  <article
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(ROOT_CLASS, props.type)
    ])}
  >
    {props.children}
  </article>
)
