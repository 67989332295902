import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { CasinoReceipt } from './casino-receipt-ui'
import { casinoReceptFields } from './constants'
import { getBlockClass } from '@Helpers/components'
import './styles.scss'

const ROOT_CLASS = 'casino-receipts'

export const CasinoReceipts = () => (
  <div className={ROOT_CLASS}>
    <h3>A. Receipts</h3>
    <FormSubsection type="primary">
      <div className={getBlockClass(ROOT_CLASS, 'fields')}>
        {casinoReceptFields.map((receiptField) => (
          <CasinoReceipt
            {...receiptField}
            key={`${receiptField.amountFieldName}-receipt`}
          />
        ))}
      </div>
    </FormSubsection>
  </div>
)
