import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { InputDropdown } from '@Components/input-dropdown/input-dropdown-ui'
import { getBlockClass } from '@Helpers/components'
import {
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../constants'
import { ExpenseTypeOptions } from './constants'
import './styles.scss'

type Props = {
  index: number
  id: string
}

const ROOT_CLASS = 'weekly-beano-step-three-expense'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')

export const Expense = (props: Props) => {
  const { register, registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const baseFieldName =
    `${WeeklyBeanoStepThreeField.expenditures}.${props.index}` as const
  const typeValue = watch(`${baseFieldName}.type`)

  return (
    <div className={ROOT_CLASS}>
      <div className={ROW_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'row-group')}>
          <InputDropdown
            options={ExpenseTypeOptions}
            labelText={WeeklyBeanoStepThreeFieldLabel.expenseType}
            {...register(`${baseFieldName}.type`)}
            placeholder="Select..."
            isPlaceholderValue={!typeValue}
          />
          <Input
            {...register(`${baseFieldName}.checkNumber`)}
            labelText={WeeklyBeanoStepThreeFieldLabel.checkNumber}
          />
        </div>
        <Input
          {...register(`${baseFieldName}.payee`)}
          labelText={WeeklyBeanoStepThreeFieldLabel.payee}
        />
      </div>
      <div className={ROW_CLASS}>
        <Input
          {...register(`${baseFieldName}.description`)}
          labelText={WeeklyBeanoStepThreeFieldLabel.description}
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepThreeFieldLabel.amount,
            `${baseFieldName}.amountInDollars`
          )}
          moneyField
        />
      </div>
    </div>
  )
}
