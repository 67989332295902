import * as React from 'react'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { useFormErrorModal } from '@Hooks/use-form-error-modal'
import { prettyPrint } from '@Helpers/misc'
import { RaffleFormReducer } from './form-state-reducer/reducer'
import {
  getInitialState,
  isCompleteRaffleForm,
  RaffleFormStep
} from './form-state-reducer/shape'
import { FormProps } from '@Components/form-general/types'
import { ActivityType } from './activity-type/raffle-activity-type-ui'
import { RAFFLE_NUM_STEPS } from './constants'
import { RaffleStepOne } from './step-1/raffle-step-one-ui'
import { RaffleStepTwo } from './step-2/raffle-step-two-ui'
import { RaffleStepThree } from './step-3/raffle-step-three-ui'
import { RaffleSubmit } from './submit/raffle-submit-ui'
import { RaffleFormSubmissionBody } from './api/types'
import { getRaffleSubmissionBodyFromState } from './api/transformers'
import {
  RaffleFormAction,
  RaffleFormPayload,
  RaffleTotal
} from './form-state-reducer/actions'

export type RaffleFormProps = FormProps<RaffleFormSubmissionBody>

const ROOT_CLASS = 'raffle-form'
const today = dateUtil().format(DayFormat.monthDayYearDoubleDigit)

export const RaffleForm = (props: RaffleFormProps) => {
  const [state, dispatch] = React.useReducer(
    RaffleFormReducer,
    getInitialState(props.initialValues)
  )
  const handleSubmit = async () => {
    if (!isCompleteRaffleForm(state)) {
      console.error(
        'Raffle Form state is not complete for submission',
        prettyPrint(state)
      )
      return
    }
    const submissionBody = getRaffleSubmissionBodyFromState(
      state,
      props.organizationDetails.idNumber
    )
    await props.onSubmit(submissionBody)
  }

  const { SubmissionErrorModal } = useFormErrorModal({
    error: props.error,
    component: true,
    handleSubmit: handleSubmit
  })

  const updateFormData = <Step extends RaffleFormStep>(
    step: Step,
    payload: RaffleFormPayload[Step],
    totals?: RaffleTotal<Step>
  ) => dispatch(RaffleFormAction.updateFormStep(step, payload, totals))

  const onBackClick = () =>
    dispatch(RaffleFormAction.changeFormStep(state.currentStep - 1))

  React.useEffect(() => {
    if (state.currentStep > RAFFLE_NUM_STEPS) {
      handleSubmit()
      onBackClick()
    }
  }, [state.currentStep, onBackClick])

  const getFormBody = () => {
    switch (state.currentStep) {
      case 1:
        return (
          <ActivityType
            backOffice={props.backOffice}
            initialValues={state[RaffleFormStep.ActivityType]}
            onSubmit={(data) =>
              updateFormData(RaffleFormStep.ActivityType, data)
            }
          />
        )
      case 2:
        return (
          <RaffleStepOne
            backOffice={props.backOffice}
            organizationDetails={props.organizationDetails}
            onSubmit={(data) => updateFormData(RaffleFormStep.StepOne, data)}
            initialValues={state[RaffleFormStep.StepOne]}
          />
        )
      case 3:
        return (
          <RaffleStepTwo
            backOffice={props.backOffice}
            onBackClick={onBackClick}
            initialValues={state[RaffleFormStep.StepTwo]}
            onSubmit={(data, totals) =>
              updateFormData(RaffleFormStep.StepTwo, data, totals)
            }
          />
        )
      case 4:
        return (
          <RaffleStepThree
            backOffice={props.backOffice}
            onBackClick={onBackClick}
            grossReceiptsInDollars={state.totals.grossReceiptsInDollars}
            totalTaxDueInDollars={state.totals.totalTaxDueInDollars}
            initialValues={state[RaffleFormStep.StepThree]}
            onSubmit={(data, totals) =>
              updateFormData(RaffleFormStep.StepThree, data, totals)
            }
          />
        )
      case 5:
      default:
        return (
          <RaffleSubmit
            backOffice={props.backOffice}
            onBackClick={onBackClick}
            initialValues={state[RaffleFormStep.Submit]}
            date={props.initialValues?.formPreparationDetails.date ?? today}
            submitDisabled={props.submitDisabled}
            onSubmit={async (formData) => {
              if (props.error) {
                await handleSubmit()
              } else {
                updateFormData(RaffleFormStep.Submit, formData)
              }
            }}
          />
        )
    }
  }
  return (
    <section className={ROOT_CLASS}>
      {SubmissionErrorModal}
      {getFormBody()}
    </section>
  )
}
