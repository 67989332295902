import * as React from 'react'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { Expense } from './expense-ui'
import {
  defaultExpenditureDetail,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData
} from '../constants'
import './styles.scss'

export type Props = {
  totalExpenditures: number
}

const ROOT_CLASS = 'weekly-beano-step-three-other-expenses'

export const OtherExpenses = (props: Props) => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<
    WeeklyBeanoStepThreeFormData,
    WeeklyBeanoStepThreeField.expenditures
  >({
    name: WeeklyBeanoStepThreeField.expenditures,
    defaultData: defaultExpenditureDetail,
    getSubtotal: ['amountInDollars']
  })

  return (
    <FormSubsection type="primary">
      <h3 className={getBlockClass(ROOT_CLASS, 'header')}>3. Other Expenses</h3>
      <DynamicFieldManager
        elements={fields.map((data, index) => (
          <Expense index={index} key={data.id} id={data.id} />
        ))}
        isResetDisabled={isResetDisabled()}
        removeElement={removeElement}
        addElement={addElement}
        addButtonText={'Add Another Expense'}
        resetButtonText={'Reset Other Expenses'}
        onResetClick={reset}
        getSubtotalDisplay={getSubtotalDisplay}
      />
      <hr />
      <div className={getBlockClass(ROOT_CLASS, 'total-expenditures')}>
        <h2>Total Beano Expenditures</h2>
        <p>{getDollarString(props.totalExpenditures)}</p>
      </div>
    </FormSubsection>
  )
}
