import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { FormSummary } from '@Components/form-summary/form-summary-ui'
import {
  CasinoStepThreeField,
  CasinoStepThreeFieldLabel,
  CasinoStepThreeFormData,
  CasinoStepThreeTotals
} from '../constants'
import './styles.scss'

export type CasinoFundsAvailableForDisbursementProps = Pick<
  CasinoStepThreeTotals,
  'netProfitInDollars' | 'fundsAvailableForDisbursementInDollars'
>

const ROOT_CLASS = 'casino-funds-for-disbursement'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const FIELDS_CLASS = getBlockClass(ROOT_CLASS, 'fields')
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')

const CasinoTotalFundsAvailableForDisbursement = (
  props: Pick<
    CasinoFundsAvailableForDisbursementProps,
    'fundsAvailableForDisbursementInDollars'
  >
) => (
  <FormSummary>
    <div className={getBlockClass(ROOT_CLASS, 'total')}>
      <FormSummary.Title>
        Total Funds Available for Disbursement
      </FormSummary.Title>
    </div>
    <FormSummary.Total>
      {getDollarString(props.fundsAvailableForDisbursementInDollars)}
    </FormSummary.Total>
  </FormSummary>
)

export const CasinoFundsAvailableForDisbursement = (
  props: CasinoFundsAvailableForDisbursementProps
) => {
  const { registerNumber, register, watch } =
    useFormContextPlus<CasinoStepThreeFormData>()
  const prevSpecialCasinoCheckingBalanceValue = watch(
    CasinoStepThreeField.prevSpecialCasinoCheckingBalanceInDollars
  )
  const otherIncomeValue = watch(CasinoStepThreeField.otherIncomeInDollars)
  const netProfitDisplay = getDollarString(props.netProfitInDollars)
  return (
    <>
      <section className={ROOT_CLASS}>
        <FormSubsection type="primary">
          <h4 className={HEADER_CLASS}>
            1. Previous Balance in Special Casino Checking Account
          </h4>
          <div className={FIELDS_CLASS}>
            <div className={INPUTS_CLASS}>
              <Input
                moneyField
                {...registerNumber(
                  CasinoStepThreeFieldLabel.scheduleTwoLastReport,
                  CasinoStepThreeField.prevSpecialCasinoCheckingBalanceInDollars
                )}
              />
            </div>
            <ReadOnlyField
              label="Total"
              alignRight
              value={getDollarString(prevSpecialCasinoCheckingBalanceValue)}
            />
          </div>
        </FormSubsection>
        <FormSubsection type="primary">
          <h4 className={HEADER_CLASS}>2. Net Profit or Loss</h4>
          <div className={FIELDS_CLASS}>
            <ReadOnlyField
              label={CasinoStepThreeFieldLabel.scheduleOneThisReport}
              value={netProfitDisplay}
              noMargin
            />
            <ReadOnlyField alignRight value={netProfitDisplay} />
          </div>
        </FormSubsection>
        <FormSubsection type="primary">
          <h4 className={HEADER_CLASS}>3. Other Income (interest, etc.)</h4>
          <div className={FIELDS_CLASS}>
            <div className={INPUTS_CLASS}>
              <Input
                moneyField
                {...registerNumber(
                  CasinoStepThreeFieldLabel.amount,
                  CasinoStepThreeField.otherIncomeInDollars
                )}
              />
              <Input
                labelText={CasinoStepThreeFieldLabel.description}
                {...register(CasinoStepThreeField.otherIncomeDescription)}
              />
            </div>
            <ReadOnlyField
              alignRight
              value={getDollarString(otherIncomeValue)}
              noMargin
            />
          </div>
        </FormSubsection>
      </section>
      <CasinoTotalFundsAvailableForDisbursement
        fundsAvailableForDisbursementInDollars={
          props.fundsAvailableForDisbursementInDollars
        }
      />
    </>
  )
}
