import * as React from 'react'

export type Props = {
  sizePx?: number
}
const DEFAULT_SIZE_PX = 32

export const IconExit = (props: Props) => (
  <svg
    width={`${props.sizePx ?? DEFAULT_SIZE_PX}`}
    height={`${props.sizePx ?? DEFAULT_SIZE_PX}`}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>129278F6-CF70-4658-8B3D-4EBE05FCD733@2x</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon/exit/dark" fill="#0F1821" fillRule="nonzero">
        <g id="Group-8" transform="translate(1.500000, 2.000000)">
          <path
            d="M1.61586613,1.68256245 C2.53194548,0.731249284 4.01977745,0.662567042 5.01781103,1.49654499 L5.15077088,1.61586613 L27.6507709,23.2825328 C28.6453256,24.2402521 28.6751865,25.8228829 27.7174672,26.8174376 C26.8013879,27.7687507 25.3135559,27.837433 24.3155223,27.003455 L24.1825624,26.8841339 L1.68256245,5.2174672 C0.688007777,4.25974789 0.658146819,2.67711712 1.61586613,1.68256245 Z"
            id="Line-6-Copy-3"
          ></path>
          <path
            d="M1.61586613,1.68256245 C2.53194548,0.731249284 4.01977745,0.662567042 5.01781103,1.49654499 L5.15077088,1.61586613 L27.6507709,23.2825328 C28.6453256,24.2402521 28.6751865,25.8228829 27.7174672,26.8174376 C26.8013879,27.7687507 25.3135559,27.837433 24.3155223,27.003455 L24.1825624,26.8841339 L1.68256245,5.2174672 C0.688007777,4.25974789 0.658146819,2.67711712 1.61586613,1.68256245 Z"
            id="Line-6-Copy-5"
            transform="translate(14.666667, 14.250000) scale(-1, 1) translate(-14.666667, -14.250000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
