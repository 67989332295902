import * as React from 'react'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import {
  CasinoStepTwoField,
  CasinoStepTwoFieldLabel,
  CasinoStepTwoFormData
} from '../../constants'
import './styles.scss'

export type CasinoOtherExpenditureProps = {
  index: number
  id: string
}

const ROOT_CLASS = 'casino-other-expense'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')

export const CasinoOtherExpense = (props: CasinoOtherExpenditureProps) => {
  const { register, registerNumber } =
    useFormContextPlus<CasinoStepTwoFormData>()
  const baseFieldName =
    `${CasinoStepTwoField.otherExpenditures}.${props.index}` as const
  return (
    <div className={ROOT_CLASS}>
      <div className={ROW_CLASS}>
        <Input
          {...register(`${baseFieldName}.checkNumber`)}
          labelText={CasinoStepTwoFieldLabel.checkNumber}
        />
        <Input
          {...register(`${baseFieldName}.payee`)}
          labelText={CasinoStepTwoFieldLabel.payee}
        />
      </div>
      <div className={ROW_CLASS}>
        <Input
          {...registerNumber(
            CasinoStepTwoFieldLabel.amount,
            `${baseFieldName}.amountInDollars`
          )}
          moneyField
        />
        <Input
          {...register(`${baseFieldName}.description`)}
          labelText={CasinoStepTwoFieldLabel.description}
        />
      </div>
    </div>
  )
}
