import * as React from 'react'
import { FundsForDisbursementSection } from './funds-for-dispersment-section'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import './styles.scss'
import { getDollarString } from '@Helpers/currency'

type Props = {
  netBeanoProfitOrLossDollars: number
}

export const NetBeanoProfitOrLoss = React.memo((props: Props) => {
  return (
    <FundsForDisbursementSection
      totalDollars={props.netBeanoProfitOrLossDollars}
      header="2. Net Beano Profit or Loss"
    >
      <ReadOnlyField
        value={getDollarString(props.netBeanoProfitOrLossDollars)}
        label="Schedule 1 from This Report"
      />
    </FundsForDisbursementSection>
  )
})
