import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { GrossReceipts } from '@Components/form-general/gross-receipts/gross-receipts-ui'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import {
  RaffleStepTwoField,
  RaffleStepTwoFieldLabel,
  RaffleStepTwoFormData
} from './constants'
import './styles.scss'

export type RaffleReceiptsProps = {
  grossReceiptsInDollars: number
  totalTicketSalesInDollars: number
}

const ROOT_CLASS = 'raffle-receipts'
const INPUT_SECTION_CLASS = getBlockClass(ROOT_CLASS, 'input-section')
const INPUT_SECTION_HEADER_CLASS = getBlockClass(
  ROOT_CLASS,
  'input-section-header'
)
const FIELDS_CLASS = getBlockClass(ROOT_CLASS, 'fields')

export const RaffleReceipts = (props: RaffleReceiptsProps) => {
  const { registerNumber, watch, register } =
    useFormContextPlus<RaffleStepTwoFormData>()
  const otherReceiptsInDollars = watch(
    RaffleStepTwoField.otherReceiptsInDollars
  )
  return (
    <>
      <article className={ROOT_CLASS}>
        <h3>A. Receipts</h3>
        <FormSubsection type="primary">
          <div className={getBlockClass(ROOT_CLASS, 'all-fields')}>
            <div className={INPUT_SECTION_CLASS}>
              <h4 className={INPUT_SECTION_HEADER_CLASS}>
                1. Receipts from Sales
              </h4>
              <div className={FIELDS_CLASS}>
                <div
                  className={getBlockClass(ROOT_CLASS, 'receipts-from-sales')}
                >
                  <Input
                    {...registerNumber(
                      RaffleStepTwoFieldLabel.numTicketsSold,
                      RaffleStepTwoField.numTicketsSold
                    )}
                  />
                  <Input
                    {...registerNumber(
                      RaffleStepTwoFieldLabel.costPerTicketInDollars,
                      RaffleStepTwoField.costPerTicketInDollars
                    )}
                    moneyField
                  />
                </div>
                <ReadOnlyField
                  value={getDollarString(props.totalTicketSalesInDollars)}
                  label="Total"
                />
              </div>
            </div>
            <div className={INPUT_SECTION_CLASS}>
              <h4 className={INPUT_SECTION_HEADER_CLASS}>2. Other Receipts</h4>
              <div className={FIELDS_CLASS}>
                <div className={getBlockClass(ROOT_CLASS, 'other-receipts')}>
                  <Input
                    {...registerNumber(
                      RaffleStepTwoFieldLabel.amount,
                      RaffleStepTwoField.otherReceiptsInDollars
                    )}
                    moneyField
                  />
                  <Input
                    {...register(RaffleStepTwoField.otherReceiptsDescription)}
                    labelText={RaffleStepTwoFieldLabel.description}
                  />
                </div>
                <ReadOnlyField
                  value={getDollarString(otherReceiptsInDollars)}
                />
              </div>
            </div>
          </div>
        </FormSubsection>
      </article>
      <GrossReceipts grossReceiptsDollars={props.grossReceiptsInDollars} />
    </>
  )
}
