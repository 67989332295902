import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import {
  RaffleStepThreeField,
  RaffleStepThreeFieldLabel,
  RaffleStepThreeFormData
} from './constants'

export type RaffleBasicExpendituresProps = {
  totalTaxDueInDollars: number
}

const ROOT_CLASS = 'raffle-basic-expenditures'
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')
const FIELDS_CLASS = getBlockClass(ROOT_CLASS, 'fields')
const FIELDS_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'fields-container')

export const RaffleBasicExpenditures = (
  props: RaffleBasicExpendituresProps
) => {
  const { registerNumber, register, watch } =
    useFormContextPlus<RaffleStepThreeFormData>()
  const prizesAwardedValue = watch(
    RaffleStepThreeField.totalPrizesAwardedInDollars
  )
  const totalTaxDueDisplay = getDollarString(props.totalTaxDueInDollars)
  return (
    <article className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'prizes-awarded')}>
        <h4 className={HEADER_CLASS}>1. Total Prizes Awarded</h4>
        <div className={FIELDS_CONTAINER_CLASS}>
          <div className={FIELDS_CLASS}>
            <Input
              {...registerNumber(
                RaffleStepThreeFieldLabel.amountValue,
                RaffleStepThreeField.totalPrizesAwardedInDollars
              )}
              moneyField
            />
            <Input
              {...register(RaffleStepThreeField.totalPrizesAwardedDescription)}
              labelText={RaffleStepThreeFieldLabel.description}
            />
          </div>
          <ReadOnlyField
            value={getDollarString(prizesAwardedValue)}
            label="Total"
            alignRight
          />
        </div>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'tax-paid')}>
        <div className={getBlockClass(ROOT_CLASS, 'tax-paid-headers')}>
          <h4 className={HEADER_CLASS}>2. Tax Paid to MSLC</h4>
          <i>(Make checks payable to Massachusetts State Lottery Commission)</i>
        </div>
        <div className={FIELDS_CONTAINER_CLASS}>
          <div className={FIELDS_CLASS}>
            <Input
              {...register(RaffleStepThreeField.taxPaidCheckNumber)}
              labelText={RaffleStepThreeFieldLabel.checkNumberIfApplicable}
            />
            <ReadOnlyField
              value={totalTaxDueDisplay}
              label={RaffleStepThreeFieldLabel.taxAmount}
            />
          </div>
          <ReadOnlyField value={totalTaxDueDisplay} noMargin />
        </div>
      </div>
    </article>
  )
}
