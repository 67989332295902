import { WeeklyBeanoStepTwoTotals } from '../step-2/constants'
import { WeeklyBeanoStepThreeTotals } from '../step-3/constants'
import { WeeklyBeanoStepFourTotals } from '../step-4/constants'
import { CompleteWeeklyBeanoFormState, WeeklyBeanoFormStep } from './shape'

export enum WeeklyBeanoFormActions {
  UPDATE_FORM_STATE = 'UPDATE_FORM_STATE',
  CHANGE_FORM_STEP = 'CHANGE_FORM_STEP'
}

export type WeeklyBeanoFormPayload = Omit<
  CompleteWeeklyBeanoFormState,
  'totals' | 'currentStep'
>

export type WeeklyBeanoFormTotals = {
  [WeeklyBeanoFormStep.StepTwo]: WeeklyBeanoStepTwoTotals
  [WeeklyBeanoFormStep.StepThree]: WeeklyBeanoStepThreeTotals
  [WeeklyBeanoFormStep.StepFour]: WeeklyBeanoStepFourTotals
}

type StepWithTotals = keyof WeeklyBeanoFormTotals

export type WeeklyBeanoTotal<Step extends WeeklyBeanoFormStep> =
  Step extends StepWithTotals ? WeeklyBeanoFormTotals[Step] : undefined

export type ChangeWeeklyBeanoFormStep = {
  type: WeeklyBeanoFormActions.CHANGE_FORM_STEP
  step: number
}

export type UpdateWeeklyBeanoFormAction<Step extends WeeklyBeanoFormStep> = {
  type: WeeklyBeanoFormActions.UPDATE_FORM_STATE
  step: Step
  payload: WeeklyBeanoFormPayload[Step]
  totals?: WeeklyBeanoTotal<Step>
}

export type WeeklyBeanoFormAction<Step extends WeeklyBeanoFormStep> =
  | ChangeWeeklyBeanoFormStep
  | UpdateWeeklyBeanoFormAction<Step>

const changeFormStep = (step: number): ChangeWeeklyBeanoFormStep => ({
  type: WeeklyBeanoFormActions.CHANGE_FORM_STEP,
  step
})

const updateFormStep = <Step extends WeeklyBeanoFormStep>(
  step: Step,
  payload: WeeklyBeanoFormPayload[Step],
  totals?: WeeklyBeanoTotal<Step>
): UpdateWeeklyBeanoFormAction<Step> => ({
  type: WeeklyBeanoFormActions.UPDATE_FORM_STATE,
  step,
  payload,
  totals
})

export const WeeklyBeanoFormAction = {
  changeFormStep,
  updateFormStep
}
