import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import './styles.scss'
import { getCleanNumber, sum } from '@Helpers/numbers'

export type Props = {
  date: string
}

const ROOT_CLASS = 'door-prizes'
const LABEL_CLASS = getBlockClass(ROOT_CLASS, 'label')
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')

export const DoorPrizes = (props: Props) => {
  const { registerNumber, register, watch } =
    useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const subtotals = watch([
    WeeklyBeanoStepThreeField.merchandiseAmount,
    WeeklyBeanoStepThreeField.doorCashPrize
  ])
  const total = subtotals
    .map((subtotal) => getCleanNumber(subtotal))
    .reduce(sum, 0)
  return (
    <FormSubsection type="secondary">
      <div className={ROOT_CLASS}>
        <h5>Door Prizes</h5>
        <p className={LABEL_CLASS}>Cash Door Prizes Awarded this Occasion</p>
        <div className={getBlockClass(ROOT_CLASS, 'input-row')}>
          <div className={INPUTS_CLASS}>
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.numGames,
                WeeklyBeanoStepThreeField.doorCashNumGames
              )}
              type="number"
            />
            <Input
              {...registerNumber(
                WeeklyBeanoStepThreeFieldLabel.prizeAwarded,
                WeeklyBeanoStepThreeField.doorCashPrize
              )}
              moneyField
              type="number"
            />
          </div>
          <ReadOnlyField value={getDollarString(total)} noMargin />
        </div>
        <p className={LABEL_CLASS}>Cash Door Prizes Awarded this Occasion</p>
        <div className={INPUTS_CLASS}>
          <Input
            {...register(WeeklyBeanoStepThreeField.doorPrizeClerkNumber)}
            labelText={WeeklyBeanoStepThreeFieldLabel.clerkNumber}
          />
          <ReadOnlyField label="Date" value={props.date} />
        </div>
        <div className={`${INPUTS_CLASS}-merchandise`}>
          <Input
            {...register(WeeklyBeanoStepThreeField.doorPrizeDescription)}
            labelText={WeeklyBeanoStepThreeFieldLabel.description}
          />
          <Input
            {...registerNumber(
              WeeklyBeanoStepThreeFieldLabel.amount,
              WeeklyBeanoStepThreeField.merchandiseAmount
            )}
            moneyField
            type="number"
          />
        </div>
      </div>
    </FormSubsection>
  )
}
