import { roundToDecimals } from '@Helpers/numbers'
import { Optional } from '@Helpers/util'
import { MemberRole } from './sign-and-submit/constants'

export const calculateTaxPaidDollars = (grossReceiptsDollars: number): number =>
  roundToDecimals(grossReceiptsDollars * 0.05)

export const getValuesFromFieldNames = <
  FieldNames extends string,
  FormData extends Partial<Record<FieldNames, any>>
>(
  fieldNames: FieldNames[],
  formData: FormData
): number[] =>
  fieldNames
    .map((fieldName) => formData[fieldName])
    .filter((val) => typeof val === 'number')

export const getMemberRole = (memberRole?: string): Optional<MemberRole> =>
  Object.values(MemberRole).find(
    (role) => role.toLowerCase() === memberRole?.toLowerCase()
  )
