import { sumList } from '@Helpers/numbers'
import {
  CasinoStepThreeField,
  CasinoStepThreeFormData,
  CasinoStepThreeTotals
} from './constants'

export const getCasinoStepThreeTotals = (
  formData: CasinoStepThreeFormData,
  netProfitInDollars: number
): CasinoStepThreeTotals => {
  const fundsAvailableForDisbursementInDollars = sumList([
    netProfitInDollars,
    formData[CasinoStepThreeField.prevSpecialCasinoCheckingBalanceInDollars],
    formData[CasinoStepThreeField.otherIncomeInDollars]
  ])
  const disbursementAmounts = formData[CasinoStepThreeField.disbursements].map(
    (disbursement) => disbursement.amountInDollars
  )
  const totalDisbursementsInDollars = sumList(disbursementAmounts)
  const specialCasinoCheckingBalanceInDollars =
    fundsAvailableForDisbursementInDollars - totalDisbursementsInDollars

  return {
    fundsAvailableForDisbursementInDollars,
    totalDisbursementsInDollars,
    specialCasinoCheckingBalanceInDollars,
    netProfitInDollars
  }
}
