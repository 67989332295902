import { CasinoStepTwoField } from '../constants'

export enum CasinoReceiptFieldSupplement {
  overShort = 'overShort',
  description = 'description'
}

export type CasinoReceiptField =
  | CasinoStepTwoField.admissionTicketsInDollars
  | CasinoStepTwoField.raffleTicketsInDollars
  | CasinoStepTwoField.gamingTablesInDollars
  | CasinoStepTwoField.overShortInDollars
  | CasinoStepTwoField.otherReceiptsInDollars

export type CasinoReceiptProps = {
  fieldSupplement?: CasinoReceiptFieldSupplement
  amountFieldName: CasinoReceiptField
  headerBoldText: string
  headerSupplement?: string
  readOnlyLabel?: true
}

export const casinoReceptFields: CasinoReceiptProps[] = [
  {
    amountFieldName: CasinoStepTwoField.admissionTicketsInDollars,
    headerBoldText: '1. Admission Tickets',
    headerSupplement: '(from Control Sheet (E) Line 6: Gross Proceeds)',
    readOnlyLabel: true
  },
  {
    amountFieldName: CasinoStepTwoField.raffleTicketsInDollars,
    headerBoldText: '2. Raffle Tickets',
    headerSupplement: '(from Control Sheet (E) Total Col 8)'
  },
  {
    amountFieldName: CasinoStepTwoField.gamingTablesInDollars,
    headerBoldText: '3. Gaming Tables ',
    headerSupplement: '(from Control Sheet (F) Total Col 1)'
  },
  {
    amountFieldName: CasinoStepTwoField.overShortInDollars,
    headerBoldText: '4. Over/Short',
    fieldSupplement: CasinoReceiptFieldSupplement.overShort,
    headerSupplement: '(from Control Sheet (H) Line 13)'
  },
  {
    amountFieldName: CasinoStepTwoField.otherReceiptsInDollars,
    fieldSupplement: CasinoReceiptFieldSupplement.description,
    headerBoldText: '5. Other Receipt'
  }
]
