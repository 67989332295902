import { transformCentsToDollars } from '@Components/form-general/transformers'
import {
  defaultExpenditureDetail,
  defaultFormData,
  defaultProgressiveGameSummary,
  ExpenditureDetail,
  FormPrizeSummaryProgressiveGameDetails,
  PaidViaEftSelection,
  PrizeDetail,
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFormData,
  WTAAmount,
  WTAFormDetails
} from '@Components/weekly-beano-form/step-3/constants'
import { PrizeDistributionGame } from '@Components/weekly-beano-form/step-3/summary-of-beano-prizes/prize-distribution-details/prize-distribution-game-names'
import { getDollarsFromCents } from '@Helpers/currency'
import { Optional } from '@Helpers/util'
import {
  OtherExpense,
  PrizeDistributionDetails,
  PrizeSummaryProgressiveGameDetails,
  WeeklyBeanoFormBody,
  WeeklyBeanoFormBodyResponse,
  WeeklyBeanoFormResponse,
  WTAGameDetails
} from '../../types'

type PrizeDistributionData = {
  spGame: PrizeDetail
  otherGames: PrizeDetail[]
  goodNeighbor: PrizeDetail
  fiftyFifty: PrizeDetail[]
  breakage: PrizeDetail
}

type DynamicPrizeDestributions = Pick<
  PrizeDistributionData,
  'fiftyFifty' | 'otherGames'
>

const getExpenditureFromResponse = (
  expense: OtherExpense
): Required<ExpenditureDetail> => ({
  checkNumber: expense.checkNumber ?? defaultExpenditureDetail.checkNumber,
  payee: expense.payee ?? defaultExpenditureDetail.payee,
  description: expense.description ?? defaultExpenditureDetail.description,
  amountInDollars: getDollarsFromCents(expense.amountInCents ?? 0),
  type: expense.expenseType ?? defaultExpenditureDetail.type
})

const getFullProgressiveGameData = (
  progressiveDetails?: PrizeSummaryProgressiveGameDetails
): FormPrizeSummaryProgressiveGameDetails => {
  if (!progressiveDetails) {
    return defaultProgressiveGameSummary
  }
  return transformCentsToDollars(progressiveDetails)
}

const getPrizeDistributionData = (
  prizeDistributionDetails?: PrizeDistributionDetails[]
): PrizeDistributionData => {
  const defaultGamePrizeDistributionData: PrizeDetail = {
    numGames: 0,
    prizeInDollars: 0,
    gameName: ''
  }
  const prizeDistributionData: PrizeDistributionData = {
    spGame: defaultGamePrizeDistributionData,
    otherGames: [],
    goodNeighbor: defaultGamePrizeDistributionData,
    fiftyFifty: [],
    breakage: defaultGamePrizeDistributionData
  }
  prizeDistributionDetails?.forEach((prizeDistributionDetail) => {
    const distributionData: PrizeDetail = transformCentsToDollars(
      prizeDistributionDetail
    )
    const { gameName } = prizeDistributionDetail
    if (gameName.includes(PrizeDistributionGame.spGame)) {
      prizeDistributionData.spGame = distributionData
    } else if (gameName.includes(PrizeDistributionGame.goodNeighbor)) {
      prizeDistributionData.goodNeighbor = distributionData
    } else if (gameName.includes(PrizeDistributionGame.fiftyFifty)) {
      prizeDistributionData.fiftyFifty.push(distributionData)
    } else {
      prizeDistributionData.otherGames.push(distributionData)
    }
  })
  const dynamicKeys: (keyof DynamicPrizeDestributions)[] = [
    'fiftyFifty',
    'otherGames'
  ]
  for (const key of dynamicKeys) {
    if (!prizeDistributionData[key].length) {
      prizeDistributionData[key].push(defaultGamePrizeDistributionData)
    }
  }
  return prizeDistributionData
}

const getWTAFormDetailsFromInitialResponse = (
  wtaResponse?: WTAGameDetails[]
): WTAFormDetails[] =>
  wtaResponse?.map((response: WTAGameDetails): WTAFormDetails => {
    const amountsInDollars = response.amountsInCents.map(
      (amountInCents: number): WTAAmount => ({
        amountInDollars: getDollarsFromCents(amountInCents)
      })
    )
    return {
      amountsInDollars,
      numGames: amountsInDollars.length
    }
  }) ?? []

const getPaidViaEftInitialValue = (
  initialValue?: boolean
): Optional<PaidViaEftSelection> => {
  switch (initialValue) {
    case true:
      return PaidViaEftSelection.yes
    case false:
      return PaidViaEftSelection.no
    default:
      return undefined
  }
}

export const transformWeeklyBeanoResponseToStepThreeData = (
  response:
    | WeeklyBeanoFormResponse
    | WeeklyBeanoFormBody
    | WeeklyBeanoFormBodyResponse
): WeeklyBeanoStepThreeFormData => {
  const { beanoExpendituresDetails } = response

  const prizeDistributionData = getPrizeDistributionData(
    beanoExpendituresDetails?.beanoPrizeSummary?.prizeDistributionDetails ?? []
  )

  const doorPrizeDetails =
    beanoExpendituresDetails?.beanoPrizeSummary?.doorPrizeDetails

  return {
    ...defaultFormData,
    [WeeklyBeanoStepThreeField.paidViaEftSelection]: getPaidViaEftInitialValue(
      beanoExpendituresDetails?.mslcTaxEftPayment
    ),
    [WeeklyBeanoStepThreeField.checkNumber]:
      beanoExpendituresDetails?.mslcTaxCheckNumber ?? '',
    [WeeklyBeanoStepThreeField.winnerTakeAll]:
      getWTAFormDetailsFromInitialResponse(
        beanoExpendituresDetails?.beanoPrizeSummary?.wtaGameDetails
      ),
    [WeeklyBeanoStepThreeField.progressiveGames]: [
      getFullProgressiveGameData(
        beanoExpendituresDetails?.beanoPrizeSummary?.progressiveGame1Details
      ),
      getFullProgressiveGameData(
        beanoExpendituresDetails?.beanoPrizeSummary?.progressiveGame2Details
      )
    ],
    [WeeklyBeanoStepThreeField.expenditures]: beanoExpendituresDetails
      ?.otherExpenses.length
      ? beanoExpendituresDetails.otherExpenses.map(getExpenditureFromResponse)
      : defaultFormData[WeeklyBeanoStepThreeField.expenditures],
    [WeeklyBeanoStepThreeField.spGameNumGames]:
      prizeDistributionData.spGame.numGames,
    [WeeklyBeanoStepThreeField.spGamePrize]:
      prizeDistributionData.spGame.prizeInDollars,
    [WeeklyBeanoStepThreeField.goodNeighborNumGames]:
      prizeDistributionData.goodNeighbor.numGames,
    [WeeklyBeanoStepThreeField.goodNeighborPrize]:
      prizeDistributionData.goodNeighbor.prizeInDollars,
    [WeeklyBeanoStepThreeField.breakageAmountInDollars]: getDollarsFromCents(
      beanoExpendituresDetails?.beanoPrizeSummary?.breakageAmountInCents ?? 0
    ),
    [WeeklyBeanoStepThreeField.otherGames]: prizeDistributionData.otherGames,
    [WeeklyBeanoStepThreeField.fiftyFifty]: prizeDistributionData.fiftyFifty,
    [WeeklyBeanoStepThreeField.doorCashNumGames]:
      doorPrizeDetails?.cashPrizeNumGames ?? 0,
    [WeeklyBeanoStepThreeField.doorCashPrize]: getDollarsFromCents(
      doorPrizeDetails?.cashPrizeAwardedInCents ?? 0
    ),
    [WeeklyBeanoStepThreeField.doorPrizeClerkNumber]:
      doorPrizeDetails?.merchandiseDetails?.clerkReferenceNumber ?? '',
    [WeeklyBeanoStepThreeField.doorPrizeDescription]:
      doorPrizeDetails?.merchandiseDetails?.description ?? '',
    [WeeklyBeanoStepThreeField.merchandiseAmount]: getDollarsFromCents(
      doorPrizeDetails?.merchandiseDetails?.valueInCents ?? 0
    )
  }
}
