import * as React from 'react'
import { getBlockClass } from '@Helpers/components'
import { getDollarString } from '@Helpers/currency'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import {
  PROGRESSIVE_GAME_SUBSECTION_CLASS,
  ROW_CLASS,
  ROW_LABEL_CLASS,
  ROW_VALUE_CLASS
} from './constants'

type ProgressiveProfitToLicenseeProps = {
  proceedsFromReceiptsInDollars: number
  taxPaidInDollars: number
  totalPrizePaidInDollars: number
  profitToLicenseeInDollars: number
  fieldIndex: number
}

const ROOT_CLASS = PROGRESSIVE_GAME_SUBSECTION_CLASS

export const ProgressiveProfitToLicensee = (
  props: ProgressiveProfitToLicenseeProps
) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'ledger')}>
        <div className={ROW_CLASS}>
          <p className={ROW_LABEL_CLASS}>Proceeds</p>
          <p className={ROW_VALUE_CLASS}>
            {getDollarString(props.proceedsFromReceiptsInDollars)}
          </p>
        </div>
        <div className={ROW_CLASS}>
          <p className={ROW_LABEL_CLASS}>State Tax (5%)</p>
          <p className={ROW_VALUE_CLASS}>
            {`-${getDollarString(props.taxPaidInDollars)}`}
          </p>
        </div>
        <div className={ROW_CLASS}>
          <p className={ROW_LABEL_CLASS}>Total Prize Paid</p>
          <p className={ROW_VALUE_CLASS}>
            {`-${getDollarString(props.totalPrizePaidInDollars)}`}
          </p>
        </div>
      </div>
      <hr />
      <div className={getBlockClass(ROOT_CLASS, 'summary')}>
        <b>Profit to Licensee</b>
        <ReadOnlyField
          value={getDollarString(props.profitToLicenseeInDollars)}
          className={getBlockClass(ROOT_CLASS, 'summary-value')}
        />
      </div>
    </div>
  )
}
