import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { DynamicElementProps } from '@Components/form-general/types'
import {
  WeeklyBeanoStepTwoFieldLabels,
  SpecialGameField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import { CARD_INPUTS_ROOT_CLASS } from './constants'
import './styles.scss'

export type DynamicCardInputsProps<T extends SpecialGameField> = {
  numCardsfieldName: keyof WeeklyBeanoStepTwoFormData[T][0]
  pricePerCardFieldName: keyof WeeklyBeanoStepTwoFormData[T][0]
  type: T
} & DynamicElementProps

export const DynamicCardInputs = <T extends SpecialGameField>(
  props: DynamicCardInputsProps<T>
) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  const baseFieldName = `${props.type}.${props.index}` as const
  return (
    <div className={CARD_INPUTS_ROOT_CLASS}>
      <Input
        {...registerNumber(
          WeeklyBeanoStepTwoFieldLabels.numCards,
          `${baseFieldName}.${String(props.numCardsfieldName)}` as any
        )}
      />
      <Input
        {...registerNumber(
          WeeklyBeanoStepTwoFieldLabels.pricePerCard,
          `${baseFieldName}.${String(props.pricePerCardFieldName)}` as any
        )}
        moneyField
      />
    </div>
  )
}
