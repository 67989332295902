import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { StaticCardInputs } from '@Components/weekly-beano-form/step-2/card-inputs/card-inputs-ui'
import { getDollarString } from '@Helpers/currency'
import {
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import {
  ADMISSIONS_INPUT_CLASS,
  getAdmissionsTotal,
  ROOT_CLASS,
  X_REGULAR_CLASS
} from './constants'
import '../styles.scss'
import './styles.scss'

export const BonusAdmissionsCards = React.memo(() => {
  const { watch } = useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  const numCards = watch(WeeklyBeanoStepTwoField.bonusAdmissionsNumCards)
  const pricePerCard = watch(WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice)
  const numXCards = watch(
    WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular
  )
  const pricePerXCard = watch(
    WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular
  )
  const total = getDollarString(
    getAdmissionsTotal(numCards, pricePerCard, numXCards, pricePerXCard)
  )
  return (
    <FormSubsection type="primary">
      <h3>2. Bonus Cards Admissions</h3>
      <div className={ROOT_CLASS}>
        <div className={ADMISSIONS_INPUT_CLASS}>
          <StaticCardInputs
            numCardsfieldName={WeeklyBeanoStepTwoField.bonusAdmissionsNumCards}
            pricePerCardFieldName={
              WeeklyBeanoStepTwoField.bonusAdmissionsCardPrice
            }
          />
          <p className={X_REGULAR_CLASS}>+ X-Regular</p>
          <StaticCardInputs
            numCardsfieldName={
              WeeklyBeanoStepTwoField.bonusAdmissionsNumCardsXRegular
            }
            pricePerCardFieldName={
              WeeklyBeanoStepTwoField.bonusAdmissionsCardPriceXRegular
            }
          />
        </div>
        <ReadOnlyField value={total} alignRight />
      </div>
    </FormSubsection>
  )
})
