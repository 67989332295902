import { sumList } from '@Helpers/numbers'
import {
  DisbursementDetail,
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFormData,
  WeeklyBeanoStepFourTotals
} from './constants'

export const getNetProceedsFromCharityGamesDollars = (
  nonBeanoCGTProceeds: number,
  cgtProceeds: number
): number => sumList([nonBeanoCGTProceeds + cgtProceeds])

const getOtherIncomeInDollars = (formData: WeeklyBeanoStepFourFormData) =>
  sumList([
    formData[WeeklyBeanoStepFourField.interestInDollars],
    formData[WeeklyBeanoStepFourField.gameBankInDollars],
    formData[WeeklyBeanoStepFourField.othersInDollars]
  ])

export const getTotalFundsForDisbursement = (
  formData: WeeklyBeanoStepFourFormData,
  netBeanoProfitOrLoss: number,
  cgTicketNetProceedsInDollars: number,
  otherIncomeInDollars: number
): number =>
  sumList([
    netBeanoProfitOrLoss,
    cgTicketNetProceedsInDollars,
    otherIncomeInDollars,
    formData[WeeklyBeanoStepFourField.unexpendedNetProfitInDollars]
  ])

export const getTotalDepositForOccasion = (
  grossBeanoReceiptsInDollars: number,
  cgTicketNetProceedsInDollars: number,
  gameBankInDollars: number,
  grandTotalCashPrizeInDollars: number
) =>
  sumList([
    grossBeanoReceiptsInDollars,
    cgTicketNetProceedsInDollars,
    gameBankInDollars
  ]) - grandTotalCashPrizeInDollars

export const getDisbursementsTotalDollars = (
  disbursements?: DisbursementDetail[]
): number =>
  sumList(
    disbursements?.map((disbursement) => disbursement.amountInDollars) ?? []
  )

export const getExpectedUnexpendedNetProfitInDollars = (
  fundsAvailableForDisbursementInDollars: number,
  totalDisbursementsInDollars: number
) => fundsAvailableForDisbursementInDollars - totalDisbursementsInDollars

export const getWeeklyBeanoStepFourTotals = (
  formData: WeeklyBeanoStepFourFormData,
  netProfitOrLossInDollars: number,
  grossBeanoReceiptsInDollars: number
): WeeklyBeanoStepFourTotals => {
  const cgTicketNetProceedsInDollars = getNetProceedsFromCharityGamesDollars(
    formData[WeeklyBeanoStepFourField.nonBeanoCgtProceedsInDollars],
    formData[WeeklyBeanoStepFourField.cgtProceedsInDollars]
  )
  const otherIncomeInDollars = getOtherIncomeInDollars(formData)
  const fundsAvailableForDisbursementInDollars = getTotalFundsForDisbursement(
    formData,
    netProfitOrLossInDollars,
    cgTicketNetProceedsInDollars,
    otherIncomeInDollars
  )
  const totalDisbursementsInDollars = getDisbursementsTotalDollars(
    formData[WeeklyBeanoStepFourField.disbursements]
  )
  const totalDepositForOccasionInDollars = getTotalDepositForOccasion(
    grossBeanoReceiptsInDollars,
    cgTicketNetProceedsInDollars,
    formData[WeeklyBeanoStepFourField.gameBankInDollars],
    formData[WeeklyBeanoStepFourField.prizesPaidInDollars]
  )
  const expectedUnexpendedNetProfitInDollars =
    getExpectedUnexpendedNetProfitInDollars(
      fundsAvailableForDisbursementInDollars,
      totalDisbursementsInDollars
    )
  return {
    cgTicketNetProceedsInDollars,
    fundsAvailableForDisbursementInDollars,
    totalDisbursementsInDollars,
    totalDepositForOccasionInDollars,
    otherIncomeInDollars,
    netProfitInDollars: netProfitOrLossInDollars,
    expectedUnexpendedNetProfitInDollars
  }
}
