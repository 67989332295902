import { getValuesFromFieldNames } from '@Components/form-general/helpers'
import { calculateNetProfitOrLossDollars } from '@Components/form-general/net-profit-or-loss/helpers'
import { getCleanNumber, sumList } from '@Helpers/numbers'
import {
  AnnualBeanoIncomeTotals,
  AnnualBeanoStepTwoField,
  AnnualBeanoStepTwoFormData,
  OtherExpenseDetailDollars
} from './constants'

export const getAnnualBeanoOtherExpensesTotal = (
  otherExpenses: OtherExpenseDetailDollars[]
) => sumList(otherExpenses.map((expense) => expense.amountInDollars))

export const getAnnualBeanoExpendituresTotal = (
  expenditureValues: number[],
  taxPaidDollars: number,
  otherExpenses: OtherExpenseDetailDollars[]
) =>
  sumList(expenditureValues) +
  getAnnualBeanoOtherExpensesTotal(otherExpenses) +
  getCleanNumber(taxPaidDollars)

export const getAnnualBeanoGrossReceipts = (
  regularBeanoGameReceiptsInDollars: number,
  otherReceiptsInDollars: number
) => sumList([regularBeanoGameReceiptsInDollars, otherReceiptsInDollars])

export const getNetProfitOrLossFromCharityGames = (
  ticketProceedsInDollars: number,
  ticketCostsInDollars: number
) =>
  getCleanNumber(ticketProceedsInDollars) - getCleanNumber(ticketCostsInDollars)

export const getTotalNetProfitOrLoss = (
  baseNetProfitOrLossDollars: number,
  charityNetProfitOrLossDollars: number
) =>
  getCleanNumber(baseNetProfitOrLossDollars) +
  getCleanNumber(charityNetProfitOrLossDollars)

const expendituresFields = [
  AnnualBeanoStepTwoField.prizesInDollars,
  AnnualBeanoStepTwoField.rentInDollars,
  AnnualBeanoStepTwoField.equipmentInDollars,
  AnnualBeanoStepTwoField.suppliesInDollars,
  AnnualBeanoStepTwoField.servicesInDollars
]

const grossBeanoReceiptsFields = [
  AnnualBeanoStepTwoField.regularBeanoGameReceiptsInDollars,
  AnnualBeanoStepTwoField.otherReceiptsInDollars
]

const charityGameFields = [
  AnnualBeanoStepTwoField.ticketProceedsInDollars,
  AnnualBeanoStepTwoField.ticketCostsInDollars
]

export const getAnnualBeanoIncomeTotals = (
  formData: AnnualBeanoStepTwoFormData
): AnnualBeanoIncomeTotals => {
  const expendituresValues = getValuesFromFieldNames<
    AnnualBeanoStepTwoField,
    AnnualBeanoStepTwoFormData
  >(expendituresFields, formData)
  const [regularReceiptsDollars, otherReceiptsDollars] =
    getValuesFromFieldNames<
      AnnualBeanoStepTwoField,
      AnnualBeanoStepTwoFormData
    >(grossBeanoReceiptsFields, formData)
  const grossBeanoReceiptsDollars = getAnnualBeanoGrossReceipts(
    regularReceiptsDollars,
    otherReceiptsDollars
  )

  const totalExpendituresDollars = getAnnualBeanoExpendituresTotal(
    expendituresValues,
    formData[AnnualBeanoStepTwoField.taxPaidInDollars],
    formData[AnnualBeanoStepTwoField.otherExpenses]
  )

  const [ticketProceedsInDollars, ticketCostsInDollars] =
    getValuesFromFieldNames<
      AnnualBeanoStepTwoField,
      AnnualBeanoStepTwoFormData
    >(charityGameFields, formData)
  const charityNetProfitOrLoss = getNetProfitOrLossFromCharityGames(
    ticketProceedsInDollars,
    ticketCostsInDollars
  )
  const baseNetProfitOrLoss = calculateNetProfitOrLossDollars(
    totalExpendituresDollars,
    grossBeanoReceiptsDollars
  )
  const totalNetProfitOrLossDollars = getTotalNetProfitOrLoss(
    baseNetProfitOrLoss,
    charityNetProfitOrLoss
  )

  return {
    totalExpendituresDollars,
    grossBeanoReceiptsDollars,
    charityNetProfitOrLoss,
    totalNetProfitOrLossDollars
  }
}
