export enum DayFormat {
  dayOfWeek = 'dddd',
  dayWithOrdinal = 'Do',
  fullMonthAndYear = 'MMMM, YYYY',
  fullMonthDateAndYear = 'MMMM D, YYYY',
  hourMinuteMeridiem = 'h:mm A',
  monthDayYear = 'M/D/YYYY',
  monthDayYearDoubleDigit = 'MM/DD/YYYY',
  iso = 'YYYY-MM-DDTHH:mm:ss.SSS'
}
