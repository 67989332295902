import { calculateNetProfitOrLossDollars } from '@Components/form-general/net-profit-or-loss/helpers'
import { sumList } from '@Helpers/numbers'
import {
  WeeklyBeanoStepThreeFormData,
  WeeklyBeanoStepThreeTotals
} from './constants'
import { getOtherExpensesTotalDollars } from './other-expenses/constants'
import { getGrandTotalCashPrizes } from './summary-of-beano-prizes/constants'

const calculateTotalExpenditures = (
  formData: WeeklyBeanoStepThreeFormData,
  mslcTaxPaidInDollars: number,
  beanoGrandTotalCashPrizesInDollars: number
): number => {
  const otherExpenses = getOtherExpensesTotalDollars(formData.expenditures)
  return sumList([
    beanoGrandTotalCashPrizesInDollars,
    otherExpenses,
    mslcTaxPaidInDollars
  ])
}

export const calculateWeeklyBeanoStepThreeTotals = (
  formData: WeeklyBeanoStepThreeFormData,
  mslcTaxPaidInDollars: number,
  grossBeanoReceiptsInDollars: number
): WeeklyBeanoStepThreeTotals => {
  const beanoGrandTotalCashPrizesInDollars = getGrandTotalCashPrizes(formData)
  const totalBeanoExpendituresInDollars = calculateTotalExpenditures(
    formData,
    mslcTaxPaidInDollars,
    beanoGrandTotalCashPrizesInDollars
  )
  const netProfitInDollars = calculateNetProfitOrLossDollars(
    totalBeanoExpendituresInDollars,
    grossBeanoReceiptsInDollars
  )
  return {
    totalBeanoExpendituresInDollars,
    netProfitInDollars,
    beanoGrandTotalCashPrizesInDollars
  }
}
