import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormStepOneProps } from '@Components/form-general/types'
import {
  DateAndTimes,
  Props as DateAndTimeProps
} from './date-and-times/date-and-times-ui'
import {
  OrganizationDetails,
  OrganizationDetailsProps
} from '@Components/organization-details/organization-details-ui'
import { WeeklyBeanoStepOneFormData } from './constants'
import { WEEKLY_BEANO_NUM_STEPS } from '../constants'
import './styles.scss'

export type Props = FormStepOneProps<WeeklyBeanoStepOneFormData> &
  DateAndTimeProps &
  OrganizationDetailsProps & {
    occasionTime: string // 'AFTERNOON' | 'EVENING'
  }

const FORM_ID = 'weekly-beano-step-one'
const FORM_STEP = 1
const ROOT_CLASS = FORM_ID

export const WeeklyBeanoStepOne = (props: Props) => {
  const methods = useForm<WeeklyBeanoStepOneFormData>({
    mode: 'all',
    defaultValues: props.initialValues
  })

  return (
    <>
      <FormCard currentStep={FORM_STEP} totalSteps={WEEKLY_BEANO_NUM_STEPS}>
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(props.onSubmit)} id={FORM_ID}>
              <OrganizationDetails
                organizationName={props.organizationName}
                idNumber={props.idNumber}
                address={props.address}
                city={props.city}
                state={props.state}
                zipCode={props.zipCode}
              />
              <DateAndTimes
                dateString={props.dateString}
                occasionTime={props.occasionTime}
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={WEEKLY_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
