import * as React from 'react'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import {
  WeeklyBeanoStepThreeField,
  WeeklyBeanoStepThreeFieldLabel,
  WeeklyBeanoStepThreeFormData
} from '../../constants'
import { PROGRESSIVE_GAME_SUBSECTION_CLASS, ROW_CLASS } from './constants'

type Props = {
  fieldIndex: number
}

const ROOT_CLASS = PROGRESSIVE_GAME_SUBSECTION_CLASS

export const ProgressiveBank = (props: Props) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepThreeFormData>()
  const baseFieldName =
    `${WeeklyBeanoStepThreeField.progressiveGames}.${props.fieldIndex}` as const

  return (
    <div className={joinClasses([ROOT_CLASS, 'progressive-bank'])}>
      <div className={getBlockClass(ROOT_CLASS, 'ledger')}>
        <b>Progressive Bank</b>
        <div className={ROW_CLASS}>
          <Input
            moneyField
            {...registerNumber(
              WeeklyBeanoStepThreeFieldLabel.beginningCarryover,
              `${baseFieldName}.beginningCarryoverInDollars`
            )}
          />
          <Input
            moneyField
            {...registerNumber(
              WeeklyBeanoStepThreeFieldLabel.endingCarryover,
              `${baseFieldName}.endingCarryoverInDollars`
            )}
          />
        </div>
      </div>
    </div>
  )
}
