import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { Input } from '@Components/input-base/input-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getDollarString } from '@Helpers/currency'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import {
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import './styles.scss'
import { getCleanNumber } from '@Helpers/numbers'
import { getBlockClass } from '@Helpers/components'

const ROOT_CLASS = 'sale-of-beano-supplies'

export const SaleOfBeanoSupplies = React.memo(() => {
  const { registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  const dollarTotal = getDollarString(
    getCleanNumber(watch(WeeklyBeanoStepTwoField.saleOfBeanoSupplies))
  )
  return (
    <FormSubsection type="primary">
      <h3>4. Sale of Beano Supplies</h3>
      <div className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
          <Input
            {...registerNumber('', WeeklyBeanoStepTwoField.saleOfBeanoSupplies)}
            moneyField
          />
        </div>
        <ReadOnlyField value={dollarTotal} alignRight />
      </div>
    </FormSubsection>
  )
})
