import { CasinoDeclarationDetails } from '../api/types'

export enum CasinoSubmitFields {
  memberRole = 'memberRole',
  phoneNumber = 'phoneNumber',
  signature = 'signature',
  date = 'date',
  email = 'email'
}

export type CasinoSubmitFormData = CasinoDeclarationDetails
