import { RaffleFormSubmissionBody } from '../api/types'

export enum RaffleStepOneField {
  orgFid = 'orgFid',
  occasionDate = 'occasionDate'
}

export enum RaffleStepOneFieldLabel {
  orgFid = 'FID Number of Organization',
  occasionDate = 'Date of Occasion'
}

export type RaffleStepOneFormData = Pick<
  RaffleFormSubmissionBody,
  'orgFid' | 'occasionDate'
>
