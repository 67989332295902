import {
  CompleteCasinoFormState,
  CasinoFormStep
} from '@Components/casino-form/form-state-reducer/shape'
import { CasinoStepOneField } from '@Components/casino-form/step-1/constants'
import { transformCentsToDollars } from '@Components/form-general/transformers'
import dateUtil from '@Services/dayjs'
import { DayFormat } from '@Services/dayjs/constants'
import { CasinoFormSubmissionBody } from '../types'
import { transformCasinoStepThreeInitialValues } from './initial-values/casino-initial-values-step-three'
import { transformCasinoStepTwoInitialValues } from './initial-values/casino-initial-values-step-two'

export const transformCasinoSubmissionBodyToFormData = (
  submissionBody: CasinoFormSubmissionBody
): CompleteCasinoFormState => {
  const submissionBodyInDollars = transformCentsToDollars(submissionBody)
  return {
    currentStep: 1,
    [CasinoFormStep.StepOne]: {
      [CasinoStepOneField.occasionDate]: dateUtil(
        submissionBodyInDollars.occasionDate
      ).format(DayFormat.monthDayYearDoubleDigit),
      [CasinoStepOneField.occasionStartTime]:
        submissionBodyInDollars.occasionStartTime,
      [CasinoStepOneField.occasionEndTime]:
        submissionBodyInDollars.occasionEndTime,
      [CasinoStepOneField.numPlayers]: submissionBodyInDollars.numPlayers
    },
    [CasinoFormStep.StepTwo]: transformCasinoStepTwoInitialValues(
      submissionBodyInDollars
    ),
    [CasinoFormStep.StepThree]: transformCasinoStepThreeInitialValues(
      submissionBodyInDollars
    ),
    [CasinoFormStep.Submit]: {
      ...submissionBodyInDollars.declarationDetails,
      date: dateUtil(submissionBodyInDollars.declarationDetails.date).format(
        DayFormat.monthDayYearDoubleDigit
      )
    },
    totals: {
      grossReceiptsInDollars: submissionBodyInDollars.grossReceiptsInDollars,
      totalExpendituresInDollars:
        submissionBodyInDollars.totalExpendituresInDollars,
      grandTotalCashPrizesInDollars:
        submissionBodyInDollars.grandTotalCashPrizesInDollars,
      netProfitInDollars: submissionBodyInDollars.netProfitInDollars,
      taxDueInDollars:
        submissionBodyInDollars.expendituresDetails.taxDueInDollars, // 5% auto-calculated
      totalTaxDueInDollars:
        submissionBodyInDollars.expendituresDetails.totalTaxDue.amountInDollars, // taxDueInDollars + penalty and interest

      fundsAvailableForDisbursementInDollars:
        submissionBodyInDollars.fundsAvailableForDisbursementInDollars,
      totalDisbursementsInDollars:
        submissionBodyInDollars.totalDisbursementsInDollars,
      specialCasinoCheckingBalanceInDollars:
        submissionBodyInDollars.specialCasinoCheckingBalanceInDollars
    }
  }
}
