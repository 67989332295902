import * as React from 'react'
import './styles.scss'

export type Props = {
  currentStep: number
  totalSteps: number
}

const ROOT_CLASS = 'form-step-indicator'

export const FormStepIndicator = (props: Props) => (
  <div
    className={ROOT_CLASS}
  >{`Step ${props.currentStep} of ${props.totalSteps}`}</div>
)
