import * as React from 'react'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { getBlockClass } from '@Helpers/components'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getDollarString } from '@Helpers/currency'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { Disbursement } from './disbursement-ui'
import {
  defaultDisbursement,
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFormData,
  WeeklyBeanoStepFourTotals
} from '../constants'
import './styles.scss'

export type WeeklyBeanoDisbursementsProps = Pick<
  WeeklyBeanoStepFourTotals,
  'totalDisbursementsInDollars'
>

const ROOT_CLASS = 'weekly-beano-disbursements'

export const WeeklyBeanoDisbursements = (
  props: WeeklyBeanoDisbursementsProps
) => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<WeeklyBeanoStepFourFormData>({
    name: WeeklyBeanoStepFourField.disbursements,
    defaultData: defaultDisbursement,
    getSubtotal: ['amountInDollars']
  })

  return (
    <FormSubsection type="primary">
      <h3 className={getBlockClass(ROOT_CLASS, 'header')}>
        5. Total Disbursements Since Last Report
      </h3>
      <DynamicFieldManager
        elements={fields.map((data, index) => (
          <Disbursement index={index} key={data.id} id={data.id} />
        ))}
        isResetDisabled={isResetDisabled()}
        removeElement={removeElement}
        addElement={addElement}
        addButtonText={'Add Another Disbursement'}
        resetButtonText={'Reset New Disbursements'}
        onResetClick={reset}
        getSubtotalDisplay={getSubtotalDisplay}
      />
      <hr />
      <div className={getBlockClass(ROOT_CLASS, 'total')}>
        <h2>Total Disbursements</h2>
        <p>{getDollarString(props.totalDisbursementsInDollars)}</p>
      </div>
    </FormSubsection>
  )
}
