import { getCleanNumber } from './numbers'

const formatCurrency = (value: number, includeDollarSign: boolean = true) => {
  const formattedCurrency = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return includeDollarSign
    ? formattedCurrency
    : formattedCurrency.replace('$', '')
}

export const getCentsFromDollars = (dollars: number) =>
  getCleanNumber(Number((dollars * 100).toFixed(0)))

export const getDollarsFromCents = (cents: number) =>
  getCleanNumber(Number((cents / 100).toFixed(2)))

export const getDollarStringFromCents = (
  cents: number,
  includeDollarSign: boolean = true
) => formatCurrency(getCleanNumber(cents) / 100, includeDollarSign)

export const getDollarString = (
  dollars: number,
  includeDollarSign: boolean = true
) => formatCurrency(getCleanNumber(dollars), includeDollarSign)
