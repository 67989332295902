import { AnnualBeanoTotals } from '@Components/annual-beano-form/form-state-reducer/shape'
import { AnnualBeanoStepThreeFormData } from '@Components/annual-beano-form/step-3/constants'
import { transformDollarsToCents } from '@Components/form-general/transformers'
import { getCentsFromDollars } from '@Helpers/currency'
import { AnnualBeanoSubmissionBody } from '../../types'

export type AnnualBeanoStepThreeSubmissionData = Pick<
  AnnualBeanoSubmissionBody,
  | 'fundsAvailableForDisbursementDetails'
  | 'fundsAvailableForDisbursementInCents'
  | 'totalDisbursementDetails'
  | 'totalDisbursementInCents'
  | 'totalSpecialBeanoBankBalanceInCents'
  | 'totalSpecialBeanoAccountDetails'
>

export const transformStepThreeFormToSubmissionData = (
  formData: AnnualBeanoStepThreeFormData,
  totals: AnnualBeanoTotals
): AnnualBeanoStepThreeSubmissionData => {
  const formDataInCents = transformDollarsToCents(formData)
  return {
    fundsAvailableForDisbursementDetails: {
      beanoCheckingBalanceInCents: formDataInCents.beanoCheckingBalanceInCents,
      beanoSavingsBalanceInCents: formDataInCents.beanoSavingsBalanceInCents,
      otherIncomeInCents: formDataInCents.otherIncomeInCents,
      otherIncomeDescription: formDataInCents.otherIncomeDescription
    },
    fundsAvailableForDisbursementInCents: getCentsFromDollars(
      totals.totalFundsAvailableForDisbursementDollars
    ),
    totalDisbursementDetails: {
      charitableInCents: formDataInCents.charitableInCents,
      patrioticInCents: formDataInCents.patrioticInCents,
      youthActivitiesInCents: formDataInCents.youthActivitiesInCents,
      educationalInCents: formDataInCents.educationalInCents,
      religiousInCents: formDataInCents.religiousInCents,
      firemenActivitiesInCents: formDataInCents.firemenActivitiesInCents,
      publicAmbulanceRescueServicesInCents:
        formDataInCents.publicAmbulanceRescueServicesInCents,
      otherDisbursements: formDataInCents.otherDisbursements
    },
    totalDisbursementInCents: getCentsFromDollars(
      totals.totalDisbursementsDollars
    ),
    totalSpecialBeanoBankBalanceInCents: getCentsFromDollars(
      totals.expectedTotalBalanceDollars
    ),
    totalSpecialBeanoAccountDetails: {
      specialBeanoCheckbookBalanceInCents:
        formDataInCents.specialBeanoCheckbookBalanceInCents,
      specialBeanoSavingsBalanceInCents:
        formDataInCents.specialBeanoSavingsBalanceInCents
    }
  }
}
