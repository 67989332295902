import * as React from 'react'
import { OrganizationDetailsProps } from '@Components/organization-details/organization-details-ui'
import { AnnualBeanoSubmissionBody } from '../api/types'
import { AnnualBeanoFormReducer } from '../form-state-reducer/reducer'
import {
  AnnualBeanoFormStep,
  getInitialState
} from '../form-state-reducer/shape'
import { AnnualBeanoStepOne } from '../step-1/annual-beano-one-ui'
import { AnnualBeanoStepTwo } from '../step-2/annual-beano-two-ui'
import { AnnualBeanoStepThree } from '../step-3/annual-beano-three-ui'
import { AnnualBeanoSubmit } from '../submit/annual-beano-submit-ui'
import './styles.scss'

export type AnnualBeanoFormPrintProps = {
  formData: AnnualBeanoSubmissionBody
  organizationDetails: OrganizationDetailsProps
}

const noBackClick = () => {}
const noSubmit = (formData: any) => {}

const ROOT_CLASS = 'annual-beano-print'

export const AnnualBeanoPrint = React.forwardRef<
  HTMLElement,
  AnnualBeanoFormPrintProps
>((props, ref) => {
  const [state] = React.useReducer(
    AnnualBeanoFormReducer,
    getInitialState(props.formData)
  )
  return (
    <section ref={ref} className={ROOT_CLASS}>
      <AnnualBeanoStepOne
        initialValues={state[AnnualBeanoFormStep.StepOne]}
        organizationDetails={props.organizationDetails}
        onSubmit={noSubmit}
        printMode
      />
      <AnnualBeanoStepTwo
        initialValues={state[AnnualBeanoFormStep.StepTwo]}
        onSubmit={noSubmit}
        onBackClick={noBackClick}
        printMode
      />
      <AnnualBeanoStepThree
        initialValues={state[AnnualBeanoFormStep.StepThree]}
        onSubmit={noSubmit}
        onBackClick={noBackClick}
        totalNetProfitOrLossDollars={state.totals.totalNetProfitOrLossDollars}
        printMode
      />
      <AnnualBeanoSubmit
        initialValues={state[AnnualBeanoFormStep.Submit]}
        onBackClick={noBackClick}
        onSubmit={noSubmit}
        printMode
      />
    </section>
  )
})
