import * as React from 'react'
import { FormProvider, useFormPlus } from '@Hooks/use-form-plus'
import {
  casinoStepTwoDefaultFormData,
  CasinoStepTwoFormData,
  CasinoStepTwoTotals
} from './constants'
import { FormStepProps } from '@Components/form-general/types'
import { FormCard } from '@Components/form-card/form-card-ui'
import { getCasinoStepTwoTotals } from './helpers'
import { RAFFLE_DISPLAY_NUM_STEPS } from '@Components/raffle-form/constants'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { GrossReceipts } from '@Components/form-general/gross-receipts/gross-receipts-ui'
import { NetProfitOrLoss } from '@Components/form-general/net-profit-or-loss/net-profit-or-loss-ui'
import { GrandTotalCashPrizes } from '@Components/form-general/grand-total-cash-prizes/grand-total-cash-prizes-ui'
import { CASINO_FORM_NUM_STEPS } from '../constants'
import { CasinoReceipts } from './receipts/casino-receipts-ui'
import { CasinoCashPrizes } from './expenditures/casino-cash-prizes-ui'
import { CasinoExpenditures } from './misc-expenses/casino-expenses-ui'
import './styles.scss'

export type CasinoFormStepTwoProps = FormStepProps<
  CasinoStepTwoFormData,
  CasinoStepTwoTotals
>

const FORM_ID = 'casino-step-two'
const FORM_STEP = 2
const ROOT_CLASS = FORM_ID

export const CasinoFormStepTwo = (props: CasinoFormStepTwoProps) => {
  const methods = useFormPlus<CasinoStepTwoFormData>({
    defaultValues: {
      ...casinoStepTwoDefaultFormData,
      ...props.initialValues
    }
  })

  const totals = getCasinoStepTwoTotals(methods.watch())
  const handleSubmit = async (
    data: CasinoStepTwoFormData,
    e?: React.BaseSyntheticEvent
  ) => {
    await props.onSubmit(data, totals)
  }

  return (
    <>
      <FormCard
        currentStep={FORM_STEP}
        totalSteps={RAFFLE_DISPLAY_NUM_STEPS}
        title="Schedule 1: Casino Function Income Statement"
      >
        <div className={ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <CasinoReceipts />
              <GrossReceipts
                grossReceiptsDollars={totals.grossReceiptsInDollars}
              />
              <CasinoCashPrizes />
              <GrandTotalCashPrizes
                totalCashPrizeDollars={totals.grandTotalCashPrizesInDollars}
              />
              <CasinoExpenditures
                taxDueInDollars={totals.taxDueInDollars}
                totalTaxDueInDollars={totals.totalTaxDueInDollars}
                totalExpendituresInDollars={totals.totalExpendituresInDollars}
                grandTotalCashPrizesInDollars={
                  totals.grandTotalCashPrizesInDollars
                }
              />
              <NetProfitOrLoss
                grossReceiptsDollars={totals.grossReceiptsInDollars}
                totalExpendituresDollars={totals.totalExpendituresInDollars}
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={CASINO_FORM_NUM_STEPS}
          formId={FORM_ID}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
