import * as React from 'react'
import { DynamicFieldManager } from '@Components/dynamic-field-manager/dynamic-field-manager-ui'
import { getBlockClass } from '@Helpers/components'
import { useDynamicFields } from '@Hooks/use-form-plus'
import { RaffleOtherExpenditure } from './raffle-other-expense-ui'
import {
  defaultRaffleOtherExpenditure,
  RaffleStepThreeField,
  RaffleStepThreeFormData
} from '../constants'
import './styles.scss'

const ROOT_CLASS = 'raffle-other-expenses'

export const RaffleOtherExpenses = () => {
  const {
    fields,
    addElement,
    reset,
    removeElement,
    getSubtotalDisplay,
    isResetDisabled
  } = useDynamicFields<RaffleStepThreeFormData>({
    name: RaffleStepThreeField.otherExpenses,
    defaultData: defaultRaffleOtherExpenditure,
    getSubtotal: ['amountInDollars']
  })
  return (
    <section className={ROOT_CLASS}>
      <h4>3. Other Expenses</h4>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <DynamicFieldManager
          elements={fields.map((data, index) => (
            <RaffleOtherExpenditure index={index} key={data.id} id={data.id} />
          ))}
          removeElement={removeElement}
          addElement={addElement}
          onResetClick={reset}
          getSubtotalDisplay={getSubtotalDisplay}
          isResetDisabled={isResetDisabled()}
          addButtonText={'Add Another Expense'}
          resetButtonText={'Reset Other Expenses'}
        />
      </div>
    </section>
  )
}
