import { CasinoFormAction, CasinoFormActions } from './actions'
import { CasinoFormState, CasinoFormStep, CasinoTotals } from './shape'

export const CasinoFormReducer = <Step extends CasinoFormStep>(
  state: CasinoFormState,
  action: CasinoFormAction<Step>
): CasinoFormState => {
  switch (action.type) {
    case CasinoFormActions.UPDATE_FORM_STATE:
      return {
        ...state,
        totals: getCasinoTotals(state, action),
        [action.step]: action.payload,
        currentStep: state.currentStep + 1
      }
    case CasinoFormActions.CHANGE_FORM_STEP:
      return {
        ...state,
        currentStep: action.step
      }
    default:
      return state
  }
}

const getCasinoTotals = <Step extends CasinoFormStep>(
  state: CasinoFormState,
  action: CasinoFormAction<Step>
): CasinoTotals =>
  'totals' in action
    ? {
        ...state.totals,
        ...action.totals
      }
    : state.totals
