import { DollarizedData } from '@Components/form-general/types'
import { getBlockClass } from '@Helpers/components'
import {
  BeanoGameExpenditureDetails,
  BeanoGameReceiptsDetails,
  CharityGameTicketDetails,
  OtherExpenseDetail
} from '../api/types'

export const BASE_ROOT_CLASS = 'annual-beano-step-two'
export const ROOT_HEADER_CLASS = getBlockClass(BASE_ROOT_CLASS, 'header')

export type AnnualBeanoIncomeTotals = {
  totalExpendituresDollars: number
  grossBeanoReceiptsDollars: number
  charityNetProfitOrLoss: number
  totalNetProfitOrLossDollars: number
}

export type OtherExpenseDetailDollars = DollarizedData<OtherExpenseDetail>

export type GameReceiptsDetailDollars = DollarizedData<BeanoGameReceiptsDetails>

export type BeanoGameExpenditureDetailsDollars =
  DollarizedData<BeanoGameExpenditureDetails>

export type CharityGameDetails = DollarizedData<CharityGameTicketDetails>

export enum AnnualBeanoStepTwoField {
  regularBeanoGameReceiptsInDollars = 'regularBeanoGameReceiptsInDollars',
  otherReceiptsInDollars = 'otherReceiptsInDollars',
  otherReceiptsDescription = 'otherReceiptsDescription',
  prizesInDollars = 'prizesInDollars',
  taxPaidInDollars = 'taxPaidInDollars',
  rentInDollars = 'rentInDollars',
  equipmentInDollars = 'equipmentInDollars',
  suppliesInDollars = 'suppliesInDollars',
  servicesInDollars = 'servicesInDollars',
  otherExpenses = 'otherExpenses',
  ticketProceedsInDollars = 'ticketProceedsInDollars',
  ticketCostsInDollars = 'ticketCostsInDollars'
}

export enum AnnualBeanoStepTwoFieldLabel {
  amount = 'Amount',
  amountTax = 'Amount (5% of Gross Beano Receipts)',
  description = 'Description'
}

export type AnnualBeanoStepTwoFormData = GameReceiptsDetailDollars &
  BeanoGameExpenditureDetailsDollars &
  CharityGameDetails

export const defaultOtherExpense: OtherExpenseDetailDollars = {
  amountInDollars: 0,
  description: ''
}

export const defaultFormData: AnnualBeanoStepTwoFormData = {
  [AnnualBeanoStepTwoField.regularBeanoGameReceiptsInDollars]: 0,
  [AnnualBeanoStepTwoField.otherReceiptsInDollars]: 0,
  [AnnualBeanoStepTwoField.otherReceiptsDescription]: '',
  [AnnualBeanoStepTwoField.prizesInDollars]: 0,
  [AnnualBeanoStepTwoField.taxPaidInDollars]: 0,
  [AnnualBeanoStepTwoField.rentInDollars]: 0,
  [AnnualBeanoStepTwoField.equipmentInDollars]: 0,
  [AnnualBeanoStepTwoField.suppliesInDollars]: 0,
  [AnnualBeanoStepTwoField.servicesInDollars]: 0,
  [AnnualBeanoStepTwoField.otherExpenses]: [defaultOtherExpense],
  [AnnualBeanoStepTwoField.ticketProceedsInDollars]: 0,
  [AnnualBeanoStepTwoField.ticketCostsInDollars]: 0
}
