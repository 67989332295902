import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import {
  AnnualBeanoStepTwoField,
  AnnualBeanoStepTwoFieldLabel,
  AnnualBeanoStepTwoFormData
} from '../constants'
import './styles.scss'

export type AnnualBeanoIncomeFieldProps = {
  header: string
  headerSupplement?: string
  fieldLabel?: AnnualBeanoStepTwoFieldLabel
  fieldName: AnnualBeanoStepTwoField
  negativeDisplay?: true
  defaultValue?: number
}

const ROOT_CLASS = 'annual-beano-income-field'

export const AnnualBeanoIncomeField = (props: AnnualBeanoIncomeFieldProps) => {
  const { registerNumber, watch, setValue } =
    useFormContextPlus<AnnualBeanoStepTwoFormData>()
  const displayValue = `${props.negativeDisplay ? '-' : ''}${getDollarString(
    watch(props.fieldName)
  )}`

  React.useEffect(() => {
    const defaultValueNumber = Number(props.defaultValue)
    if (!Number.isNaN(defaultValueNumber)) {
      setValue(props.fieldName, defaultValueNumber as never) // weird cast necessary as type can't be inferred
    }
  }, [props.defaultValue, setValue])

  return (
    <article className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
        <h4>{props.header}</h4>
        {props.headerSupplement && (
          <p className={getBlockClass(ROOT_CLASS, 'header-supplement')}>
            {props.headerSupplement}
          </p>
        )}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <Input
          {...registerNumber(
            props.fieldLabel ?? AnnualBeanoStepTwoFieldLabel.amount,
            props.fieldName
          )}
          moneyField
          className={getBlockClass(ROOT_CLASS, 'input')}
        />
        <ReadOnlyField value={displayValue} noMargin />
      </div>
    </article>
  )
}
