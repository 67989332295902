import * as React from 'react'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { DynamicElementProps } from '@Components/form-general/types'
import {
  RaffleStepThreeField,
  RaffleStepThreeFieldLabel,
  RaffleStepThreeFormData
} from '../constants'
import './styles.scss'

export type RaffleOtherExpenditureProps = DynamicElementProps

const ROOT_CLASS = 'raffle-other-expenditure'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')

export const RaffleOtherExpenditure = (props: RaffleOtherExpenditureProps) => {
  const { register, registerNumber } =
    useFormContextPlus<RaffleStepThreeFormData>()
  const baseFieldName =
    `${RaffleStepThreeField.otherExpenses}.${props.index}` as const

  return (
    <article className={ROOT_CLASS}>
      <div className={ROW_CLASS}>
        <Input
          {...register(`${baseFieldName}.checkNumber`)}
          labelText={RaffleStepThreeFieldLabel.checkNumber}
        />
        <Input
          {...register(`${baseFieldName}.payee`)}
          labelText={RaffleStepThreeFieldLabel.payee}
        />
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'row-with-total')}>
        <div className={ROW_CLASS}>
          <Input
            {...registerNumber(
              RaffleStepThreeFieldLabel.amount,
              `${baseFieldName}.amountInDollars`
            )}
            moneyField
          />
          <Input
            {...register(`${baseFieldName}.description`)}
            labelText={RaffleStepThreeFieldLabel.description}
          />
        </div>
      </div>
    </article>
  )
}
