import { FieldPath, FieldValues } from '@Hooks/use-form-plus'
import {
  RegisterValidation,
  GetValueFunction
} from '@Services/react-hook-form/constants'
import { StartEndTimeFieldLabel } from './constants'
import { validStartAndEndTimes } from './helpers'

const startTime = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
>(
  getValues: GetValueFunction<TFieldValues>,
  endTimeFieldName: TFieldNames
): RegisterValidation => ({
  beforeEndTime: (startTimeValue?: string) => {
    const endTimeValue = getValues()[endTimeFieldName]
    const isValid = validStartAndEndTimes(startTimeValue, endTimeValue)
    return (
      isValid ||
      `${StartEndTimeFieldLabel.startTime} must be before ${StartEndTimeFieldLabel.endTime}`
    )
  }
})

const endTime = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>
>(
  getValues: GetValueFunction<TFieldValues>,
  startTimeFieldName: TFieldNames
): RegisterValidation => ({
  afterStartTime: (endTimeValue?: string) => {
    const startTimeValue = getValues()[startTimeFieldName]
    const isValid = validStartAndEndTimes(startTimeValue, endTimeValue)
    return (
      isValid ||
      `${StartEndTimeFieldLabel.endTime} must be after ${StartEndTimeFieldLabel.startTime}`
    )
  }
})

export const StartEndTimeValidation = {
  startTime,
  endTime
}
