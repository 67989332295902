import { AnnualBeanoTransformers } from '../api/transformers'
import { AnnualBeanoSubmissionBody } from '../api/types'
import { AnnualBeanoStepOneFormData } from '../step-1/constants'
import {
  AnnualBeanoIncomeTotals,
  AnnualBeanoStepTwoFormData
} from '../step-2/constants'
import {
  AnnualBeanoDisbursementTotals,
  AnnualBeanoStepThreeFormData
} from '../step-3/constants'
import { AnnualBeanoSubmitFormData } from '../submit/constants'

export enum AnnualBeanoFormStep {
  StepOne = 'eventDetails',
  StepTwo = 'income',
  StepThree = 'netProfit',
  Submit = 'submit'
}

export type AnnualBeanoTotals = AnnualBeanoIncomeTotals &
  AnnualBeanoDisbursementTotals

export type AnnualBeanoFormState = {
  [AnnualBeanoFormStep.StepOne]?: AnnualBeanoStepOneFormData
  [AnnualBeanoFormStep.StepTwo]?: AnnualBeanoStepTwoFormData
  [AnnualBeanoFormStep.StepThree]?: AnnualBeanoStepThreeFormData
  [AnnualBeanoFormStep.Submit]?: AnnualBeanoSubmitFormData
  currentStep: number
  totals: AnnualBeanoTotals
}

export type CompleteAnnualBeanoFormState = Required<AnnualBeanoFormState>

export const isCompleteAnnualBeanoFormState = (
  state: AnnualBeanoFormState
): state is CompleteAnnualBeanoFormState =>
  Object.values(AnnualBeanoFormStep).every((step) => state[step])

export const getInitialState = (
  initialValues?: AnnualBeanoSubmissionBody
): AnnualBeanoFormState =>
  initialValues
    ? AnnualBeanoTransformers.getFormStateFromInitialValues(initialValues)
    : {
        currentStep: 1,
        totals: {
          totalExpendituresDollars: 0,
          grossBeanoReceiptsDollars: 0,
          charityNetProfitOrLoss: 0,
          totalNetProfitOrLossDollars: 0,
          totalFundsAvailableForDisbursementDollars: 0,
          totalDisbursementsDollars: 0,
          baseDisbursementsTotalDollars: 0,
          expectedTotalBalanceDollars: 0
        }
      }
