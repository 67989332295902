import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getBlockClass, joinClasses } from '@Helpers/components'
import { Input } from '@Components/input-base/input-ui'
import { CasinoStepTwoFieldLabel, CasinoStepTwoFormData } from '../constants'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { getDollarString } from '@Helpers/currency'
import { getCashPrizeSubtotal } from '../helpers'
import { CasinoPrizeSectionProps } from './constants'
import './styles.scss'

export type CasinoDoorPrizeProps = CasinoPrizeSectionProps & {
  number: '1' | '2'
}

const ROOT_CLASS = 'casino-cash-prize-group'
const FIELDS_CLASS = getBlockClass(ROOT_CLASS, 'fields')
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')

export const CasinoCashPrize = (props: CasinoDoorPrizeProps) => {
  const { registerNumber, watch } = useFormContextPlus<CasinoStepTwoFormData>()

  const cashPrizeOneName = `cash${props.type}Prize${props.number}` as const
  const numPrizesName = `${cashPrizeOneName}.numPrizes` as const
  const amountName = `${cashPrizeOneName}.amountInDollars` as const
  const [numPrizes, amountInDollars] = watch([numPrizesName, amountName])
  const fieldTotal = getCashPrizeSubtotal(numPrizes, amountInDollars)

  return (
    <div className={FIELDS_CLASS}>
      <div className={INPUTS_CLASS}>
        <Input
          {...registerNumber(CasinoStepTwoFieldLabel.numPrizes, numPrizesName)}
        />
        <Input
          moneyField
          {...registerNumber(CasinoStepTwoFieldLabel.prizeAmount, amountName)}
        />
      </div>
      <ReadOnlyField noMargin alignRight value={getDollarString(fieldTotal)} />
    </div>
  )
}

export const CasinoMerchPrize = (props: CasinoPrizeSectionProps) => {
  const { registerNumber, register, watch } =
    useFormContextPlus<CasinoStepTwoFormData>()
  const merchPrizeName = `merchandise${props.type}Prize` as const
  const amountName = `${merchPrizeName}.valueInDollars` as const
  const descriptionName = `${merchPrizeName}.description` as const
  const amountValue = watch(amountName)

  return (
    <div className={FIELDS_CLASS}>
      <div
        className={joinClasses([
          INPUTS_CLASS,
          getBlockClass(ROOT_CLASS, 'merch-fields')
        ])}
      >
        <Input
          moneyField
          {...registerNumber(CasinoStepTwoFieldLabel.amount, amountName)}
        />
        <Input
          labelText={CasinoStepTwoFieldLabel.description}
          {...register(descriptionName)}
        />
      </div>
      <ReadOnlyField noMargin alignRight value={getDollarString(amountValue)} />
    </div>
  )
}
