import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { CasinoPrizeSectionProps } from './constants'
import { CasinoCashPrize, CasinoMerchPrize } from './casino-cash-prize-groups'
import './styles.scss'

const ROOT_CLASS = 'casino-cash-prizes'
const FIELD_HEADER_CLASS = getBlockClass(ROOT_CLASS, 'field-header')

const CasinoPrizeSection = (props: CasinoPrizeSectionProps) => (
  <article className={getBlockClass(ROOT_CLASS, 'section')}>
    <b
      className={getBlockClass(ROOT_CLASS, 'section-header')}
    >{`${props.type} Prizes`}</b>
    <h5 className={FIELD_HEADER_CLASS}>{`Cash ${props.type} Prize #1`}</h5>
    <CasinoCashPrize {...props} number="1" />
    <h5 className={FIELD_HEADER_CLASS}>{`Cash ${props.type} Prize #2`}</h5>
    <CasinoCashPrize {...props} number="2" />
    <h5
      className={FIELD_HEADER_CLASS}
    >{`Merchandise ${props.type} Prize Awarded`}</h5>
    <CasinoMerchPrize {...props} />
  </article>
)

export const CasinoCashPrizes = () => (
  <div className={ROOT_CLASS}>
    <h3>B. Expenditures</h3>
    <FormSubsection type="secondary">
      <h4>1. Summary of Prizes</h4>
      <div className={getBlockClass(ROOT_CLASS, 'contents')}>
        <CasinoPrizeSection type="Door" />
        <CasinoPrizeSection type="Raffle" />
      </div>
    </FormSubsection>
  </div>
)
