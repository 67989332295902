import { screen } from '@testing-library/react'
import { getObjectKeys } from './util'

export const getRequiredLabel = (label: string) => `${label} *`
export const getSectionFromHeader = (header: string) => {
  const element = screen.getByText(header)?.parentElement
  if (!element) {
    throw new Error(`Expected Section with header ${header} to exist`)
  }
  return element
}

export const getParentSiblingContentsByText = (elementText: string) =>
  screen.getByText(elementText).parentElement?.nextSibling?.textContent

export const getSummaryTotal = (summaryText: string) =>
  getParentSiblingContentsByText(summaryText)

export const getReadOnlyValue = (label: string) => {
  const value = screen.getByText(label)?.nextSibling
  if (!value) {
    throw new Error(`Expected read only label ${label} to exist`)
  }
  return value
}

export const existsError = new Error('Expected element to exist')

export const objectHasValue = (obj: any, comparator: (val: any) => boolean) => {
  // console.log('~ objectHasValue')
  let hasValue = false
  getObjectKeys(obj).forEach((key) => {
    const currentVal = obj[key]
    if (Array.isArray(currentVal)) {
      hasValue = currentVal
        .map((val: any) => objectHasValue(val, comparator))
        .reduce((acc: boolean, curr: boolean) => acc && curr, hasValue)
    } else if (typeof currentVal === 'object') {
      hasValue = hasValue || objectHasValue(currentVal, comparator)
    } else {
      hasValue = hasValue || comparator(currentVal)
    }
  })
  return hasValue
}

export const objectHasNulls = (obj: any) =>
  objectHasValue(obj, (val) => val === null)
export const objectHasNaNs = (obj: any) =>
  objectHasValue(obj, (val) => Number.isNaN(val))
