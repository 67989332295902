import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormCard } from '@Components/form-card/form-card-ui'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FORM_SCHEDULE_TWO, WEEKLY_BEANO_NUM_STEPS } from '../constants'
import { FundsForDisbursement } from './funds-available-for-disbursement/funds-for-disbursement-ui'
import { WeeklyBeanoDisbursements } from './disbursements/weekly-beano-disbursements-ui'
import { UnexpendedNetProfit } from './unexpended-net-profit/unexpended-net-profit-ui'
import { TotalDepositForOccasion } from './total-deposit-for-occasion-ui'
import { UnexpendedNetProfitWarningModal } from './unexpended-net-profit/warning-modal-ui'
import { WeeklyBeanoUnexpendedNetProfitValidation } from './unexpended-net-profit/validators'
import { FormStepProps } from '@Components/form-general/types'
import { getWeeklyBeanoStepFourTotals } from './helpers'
import {
  BASE_ROOT_CLASS,
  getWeeklyBeanoFourDefaultFormData,
  WeeklyBeanoStepFourFormData,
  WeeklyBeanoStepFourTotals
} from './constants'
import './styles.scss'

export type Props = {
  netProfitOrLossInDollars: number
  defaultGrandTotalCashPrizeInDollars: number
  grossBeanoReceiptsInDollars: number
} & FormStepProps<WeeklyBeanoStepFourFormData, WeeklyBeanoStepFourTotals>

const FORM_STEP = 4
const FORM_ID = BASE_ROOT_CLASS

export const WeeklyBeanoStepFour = (props: Props) => {
  const [warningModalOpen, setWarningModalOpen] = React.useState(false)
  const closeWarningModal = () => setWarningModalOpen(false)
  const methods = useForm<WeeklyBeanoStepFourFormData>({
    defaultValues: getWeeklyBeanoFourDefaultFormData(
      props.defaultGrandTotalCashPrizeInDollars,
      props.initialValues
    )
  })
  const formData = methods.watch()
  const totals = getWeeklyBeanoStepFourTotals(
    formData,
    props.netProfitOrLossInDollars,
    props.grossBeanoReceiptsInDollars
  )

  const unexpendedNetProfitValidity =
    WeeklyBeanoUnexpendedNetProfitValidation.matchesExpectedUnexpendedNetProfit(
      totals.expectedUnexpendedNetProfitInDollars,
      methods.getValues
    )

  const onSubmit = (
    data: WeeklyBeanoStepFourFormData,
    e?: React.BaseSyntheticEvent,
    forceSubmit = false
  ) => {
    const shouldOpenWarningModal =
      !unexpendedNetProfitValidity.isValid && !forceSubmit
    shouldOpenWarningModal
      ? setWarningModalOpen(true)
      : props.onSubmit(data, totals)
  }

  return (
    <>
      <UnexpendedNetProfitWarningModal
        isOpen={warningModalOpen}
        handleClose={closeWarningModal}
        onContinueClick={() => onSubmit(formData, undefined, true)}
        onGoBackClick={closeWarningModal}
      />
      <FormCard
        title={FORM_SCHEDULE_TWO}
        currentStep={FORM_STEP}
        totalSteps={WEEKLY_BEANO_NUM_STEPS}
      >
        <div className={BASE_ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} id={FORM_ID}>
              <FundsForDisbursement
                otherIncomeInDollars={totals.otherIncomeInDollars}
                netProfitInDollars={props.netProfitOrLossInDollars}
                cgTicketNetProceedsInDollars={
                  totals.cgTicketNetProceedsInDollars
                }
                fundsAvailableForDisbursementInDollars={
                  totals.fundsAvailableForDisbursementInDollars
                }
              />
              <WeeklyBeanoDisbursements
                totalDisbursementsInDollars={totals.totalDisbursementsInDollars}
              />
              <UnexpendedNetProfit
                expectedUnexpendedNetProfitDollars={
                  totals.expectedUnexpendedNetProfitInDollars
                }
                error={
                  methods.formState.isSubmitted
                    ? unexpendedNetProfitValidity.errorMessage
                    : undefined
                }
              />
              <TotalDepositForOccasion
                totalDepositForOccasionInDollars={
                  totals.totalDepositForOccasionInDollars
                }
              />
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={WEEKLY_BEANO_NUM_STEPS + 1}
          formId={FORM_ID}
          onBackClick={() => {
            onSubmit(methods.getValues(), undefined, true)
            props.onBackClick()
          }}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
