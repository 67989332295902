import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { getBlockClass } from '@Helpers/components'
import { FundsForDisbursementSection } from './funds-for-dispersment-section'
import { BASE_ROOT_CLASS } from './constants'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFieldLabel,
  WeeklyBeanoStepFourFormData
} from '../constants'
import './styles.scss'

export type SaleOfCharityGameTixProps = {
  cgTicketNetProceedsInDollars: number
}

const ROOT_CLASS = getBlockClass(BASE_ROOT_CLASS, 'charity-tickets')

export const SaleOfCharityGameTix = (props: SaleOfCharityGameTixProps) => {
  const { registerNumber } = useFormContextPlus<WeeklyBeanoStepFourFormData>()

  return (
    <FundsForDisbursementSection
      totalDollars={props.cgTicketNetProceedsInDollars}
      header="3. Net Proceeds from Sale of Charity Game Tickets"
    >
      <div className={ROOT_CLASS}>
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.endingCarryover,
            WeeklyBeanoStepFourField.endingCarryOverInDollars
          )}
          moneyField
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.grandTotalCashPrizes,
            WeeklyBeanoStepFourField.prizesPaidInDollars
          )}
          moneyField
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.cgtProceeds,
            WeeklyBeanoStepFourField.cgtProceedsInDollars
          )}
          moneyField
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.nonBeanoCGTProceeds,
            WeeklyBeanoStepFourField.nonBeanoCgtProceedsInDollars
          )}
          moneyField
        />
      </div>
    </FundsForDisbursementSection>
  )
}
