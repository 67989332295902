import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { getBlockClass } from '@Helpers/components'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getDollarString } from '@Helpers/currency'
import { Input } from '@Components/input-base/input-ui'
import {
  AnnualBeanoStepTwoField,
  AnnualBeanoStepTwoFieldLabel,
  AnnualBeanoStepTwoFormData,
  ROOT_HEADER_CLASS
} from '../constants'
import './styles.scss'

export type AnnualBeanoGameReceiptsProps = {
  grossBeanoReceiptsDollars: number
}

const ROOT_CLASS = 'annual-beano-game-receipts'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')
const INPUTS_CLASS = getBlockClass(ROOT_CLASS, 'inputs')
const INPUT_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'input-container')

export const AnnualBeanoGameReceipts = (
  props: AnnualBeanoGameReceiptsProps
) => {
  const { watch, registerNumber, register } =
    useFormContextPlus<AnnualBeanoStepTwoFormData>()
  const regularReceiptsValue = watch(
    AnnualBeanoStepTwoField.regularBeanoGameReceiptsInDollars
  )
  const otherReceiptsValue = watch(
    AnnualBeanoStepTwoField.otherReceiptsInDollars
  )
  return (
    <section className={ROOT_CLASS}>
      <h2 className={ROOT_HEADER_CLASS}>A. Beano Game Receipts</h2>
      <FormSubsection type="primary">
        <div className={getBlockClass(ROOT_CLASS, 'container')}>
          <div className={ROW_CLASS}>
            <h4>1. Regular Receipts from Beano Game</h4>
            <div className={INPUT_CONTAINER_CLASS}>
              <div className={INPUTS_CLASS}>
                <Input
                  {...registerNumber(
                    AnnualBeanoStepTwoFieldLabel.amount,
                    AnnualBeanoStepTwoField.regularBeanoGameReceiptsInDollars
                  )}
                  moneyField
                />
              </div>
              <ReadOnlyField
                noMargin
                value={getDollarString(regularReceiptsValue)}
              />
            </div>
          </div>
          <div className={ROW_CLASS}>
            <h4>2. Other Receipts</h4>
            <div className={INPUT_CONTAINER_CLASS}>
              <div className={INPUTS_CLASS}>
                <Input
                  {...registerNumber(
                    AnnualBeanoStepTwoFieldLabel.amount,
                    AnnualBeanoStepTwoField.otherReceiptsInDollars
                  )}
                  moneyField
                />
                <Input
                  {...register(
                    AnnualBeanoStepTwoField.otherReceiptsDescription
                  )}
                  labelText={AnnualBeanoStepTwoFieldLabel.description}
                />
              </div>
              <ReadOnlyField
                noMargin
                value={getDollarString(otherReceiptsValue)}
              />
            </div>
          </div>
        </div>
      </FormSubsection>
    </section>
  )
}
