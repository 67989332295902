import { getDollarString } from '@Helpers/currency'
import { sumList } from '@Helpers/numbers'
import { areEqualObjects } from '@Helpers/util'
import {
  ArrayPath,
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
  useFormContext
} from 'react-hook-form'

export type UseDynamicFieldsProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
  TData = TFieldValues[TFieldArrayName][number]
> = UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> & {
  defaultData: TData
  getSubtotal: (keyof TData)[] | ((data: TData) => number)
}

export const useDynamicFields = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
  TKeyName extends string = 'id'
>(
  props: UseDynamicFieldsProps<TFieldValues, TFieldArrayName, TKeyName>
) => {
  const { watch } = useFormContext()
  const methods = useFieldArray<TFieldValues, TFieldArrayName>({
    name: props.name
  })

  const getValue = (index: number) =>
    watch(props.name)?.[index] ?? props.defaultData

  const getSubtotalDisplay = (index: number) => {
    const value = getValue(index)

    const amount = Array.isArray(props.getSubtotal)
      ? sumList(props.getSubtotal.map((amountKey) => value[amountKey]))
      : props.getSubtotal(value)
    return getDollarString(amount)
  }

  const isResetDisabled = () => {
    const oneField = methods.fields.length === 1
    const firstFieldValue = getValue(0)
    const isDefault = areEqualObjects(firstFieldValue, props.defaultData)
    return isDefault && oneField
  }

  const addElement = () => methods.append(props.defaultData)
  const removeElement = (index: number) => methods.remove(index)
  const reset = () => methods.replace([props.defaultData])
  return {
    ...methods,
    addElement,
    getSubtotalDisplay,
    getValue,
    isResetDisabled,
    removeElement,
    reset
  }
}
