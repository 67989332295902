import { RaffleFormPreparationDetails } from '../api/types'

export enum RaffleSubmitField {
  memberRole = 'memberRole',
  phoneNumber = 'phoneNumber',
  residenceAddress = 'residenceAddress',
  city = 'city',
  signature = 'signature',
  email = 'email',
  date = 'date'
}

export enum RaffleSubmitFieldLabel {
  residenceAddress = 'Residence Address',
  city = 'City or Town'
}

export type RaffleSubmitFormData = RaffleFormPreparationDetails
