import * as React from 'react'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { Input } from '@Components/input-base/input-ui'
import { sumList } from '@Helpers/numbers'
import { FundsForDisbursementSection } from './funds-for-dispersment-section'
import {
  WeeklyBeanoStepFourField,
  WeeklyBeanoStepFourFieldLabel,
  WeeklyBeanoStepFourFormData,
  WeeklyBeanoStepFourTotals
} from '../constants'
import { TRIPLE_FIELD_CLASS } from './constants'
import './styles.scss'

export type OtherIncomeProps = Pick<
  WeeklyBeanoStepFourTotals,
  'otherIncomeInDollars'
>

export const OtherIncome = React.memo((props: OtherIncomeProps) => {
  const { registerNumber, watch } =
    useFormContextPlus<WeeklyBeanoStepFourFormData>()
  const subtotal = sumList(
    watch([
      WeeklyBeanoStepFourField.interestInDollars,
      WeeklyBeanoStepFourField.gameBankInDollars,
      WeeklyBeanoStepFourField.othersInDollars
    ])
  )

  return (
    <FundsForDisbursementSection
      totalDollars={subtotal}
      header="4. Other Income (interest, savings account transfers, etc.)"
    >
      <div className={TRIPLE_FIELD_CLASS}>
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.interest,
            WeeklyBeanoStepFourField.interestInDollars
          )}
          type="number"
          moneyField
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.gameBank,
            WeeklyBeanoStepFourField.gameBankInDollars
          )}
          type="number"
          moneyField
        />
        <Input
          {...registerNumber(
            WeeklyBeanoStepFourFieldLabel.others,
            WeeklyBeanoStepFourField.othersInDollars
          )}
          type="number"
          moneyField
        />
      </div>
    </FundsForDisbursementSection>
  )
})
