import * as React from 'react'
import { FormCard } from '@Components/form-card/form-card-ui'
import { ReactHookFormHelpers } from '@Services/react-hook-form/helpers'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { getBlockClass } from '@Helpers/components'
import { FormStepProps } from '@Components/form-general/types'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { GrossBeanoReceipts } from '@Components/form-general/gross-beano-receipts/gross-beano-receipts-ui'
import { NetProfitOrLoss } from '@Components/form-general/net-profit-or-loss/net-profit-or-loss-ui'
import { ANNUAL_BEANO_NUM_STEPS } from '../constants'
import { AnnualBeanoExpenditures } from './expenditures/annual-beano-expenditures-ui'
import { AnnualBeanoGameReceipts } from './beano-game-receipts/beano-game-receipts-ui'
import { AnnualBeanoCharityGame } from './charity-game/annual-beano-charity-game-ui'
import { TotalNetProfitOrLoss } from './total-net-profit-or-loss/total-net-profit-or-loss-ui'
import { getAnnualBeanoIncomeTotals } from './helpers'
import {
  AnnualBeanoIncomeTotals,
  AnnualBeanoStepTwoFormData,
  BASE_ROOT_CLASS,
  defaultFormData
} from './constants'
import './styles.scss'

export type AnnualBeanoStepTwoProps = FormStepProps<
  AnnualBeanoStepTwoFormData,
  AnnualBeanoIncomeTotals
>

const FORM_ID = BASE_ROOT_CLASS
const FORM_STEP = 2

export const AnnualBeanoStepTwo = (props: AnnualBeanoStepTwoProps) => {
  const methods = useForm<AnnualBeanoStepTwoFormData>(
    ReactHookFormHelpers.getDefaultUseFormOptions({
      defaultValues: {
        ...defaultFormData,
        ...props.initialValues
      }
    })
  )

  const totals = getAnnualBeanoIncomeTotals(methods.watch())

  const handleSubmit = (data: AnnualBeanoStepTwoFormData) => {
    props.onSubmit(data, totals)
  }

  const {
    totalExpendituresDollars,
    grossBeanoReceiptsDollars,
    charityNetProfitOrLoss,
    totalNetProfitOrLossDollars
  } = totals

  return (
    <>
      <FormCard
        title={'Schedule 1: Beano & Charity Game Tickets Income Statement'}
        currentStep={FORM_STEP}
        totalSteps={ANNUAL_BEANO_NUM_STEPS}
      >
        <div className={FORM_ID}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <div className={getBlockClass(FORM_ID, 'container')}>
                <AnnualBeanoGameReceipts
                  grossBeanoReceiptsDollars={grossBeanoReceiptsDollars}
                />
                <GrossBeanoReceipts
                  grossBeanoReceiptsDollars={grossBeanoReceiptsDollars}
                />
                <AnnualBeanoExpenditures
                  grossBeanoReceiptsDollars={grossBeanoReceiptsDollars}
                  totalExpendituresDollars={totalExpendituresDollars}
                />
                <NetProfitOrLoss
                  grossReceiptsDollars={grossBeanoReceiptsDollars}
                  totalExpendituresDollars={totalExpendituresDollars}
                  beano
                />
                <AnnualBeanoCharityGame
                  charityNetProfitOrLossDollars={charityNetProfitOrLoss}
                />
                <TotalNetProfitOrLoss
                  totalNetProfitOrLossDollars={totalNetProfitOrLossDollars}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          backOffice={props.backOffice}
          currentStep={FORM_STEP}
          totalSteps={ANNUAL_BEANO_NUM_STEPS}
          formId={FORM_ID}
          onBackClick={() => {
            handleSubmit(methods.getValues())
            props.onBackClick()
          }}
        />
      )}
    </>
  )
}
