import * as React from 'react'
import { FormSubsection } from '@Components/form-subsection/form-subsection-ui'
import { useFormContextPlus } from '@Hooks/use-form-plus'
import { getDollarString } from '@Helpers/currency'
import { ReadOnlyField } from '@Components/read-only-field/read-only-field-ui'
import { StaticCardInputs } from '@Components/weekly-beano-form/step-2/card-inputs/card-inputs-ui'
import {
  WeeklyBeanoStepTwoField,
  WeeklyBeanoStepTwoFormData
} from '../constants'
import {
  ADMISSIONS_INPUT_CLASS,
  getAdmissionsTotal,
  ROOT_CLASS,
  X_REGULAR_CLASS
} from './constants'
import '../styles.scss'
import './styles.scss'

export const AdmissionsCards = React.memo(() => {
  const { watch } = useFormContextPlus<WeeklyBeanoStepTwoFormData>()
  const numCards = watch(WeeklyBeanoStepTwoField.admissionsNumCards)
  const pricePerCard = watch(WeeklyBeanoStepTwoField.admissionsCardPrice)
  const numXCards = watch(WeeklyBeanoStepTwoField.admissionsNumCardsXRegular)
  const pricePerXCard = watch(
    WeeklyBeanoStepTwoField.admissionsCardPriceXRegular
  )
  const total = getDollarString(
    getAdmissionsTotal(numCards, pricePerCard, numXCards, pricePerXCard)
  )
  return (
    <FormSubsection type="primary">
      <h3>1. Admission Cards</h3>
      <div className={ROOT_CLASS}>
        <div className={ADMISSIONS_INPUT_CLASS}>
          <StaticCardInputs
            numCardsfieldName={WeeklyBeanoStepTwoField.admissionsNumCards}
            pricePerCardFieldName={WeeklyBeanoStepTwoField.admissionsCardPrice}
          />
          <p className={X_REGULAR_CLASS}>+ X-Regular</p>
          <StaticCardInputs
            numCardsfieldName={
              WeeklyBeanoStepTwoField.admissionsNumCardsXRegular
            }
            pricePerCardFieldName={
              WeeklyBeanoStepTwoField.admissionsCardPriceXRegular
            }
          />
        </div>
        <ReadOnlyField label="Total" value={total} alignRight />
      </div>
    </FormSubsection>
  )
})
