import * as React from 'react'
import { FormProvider, useForm } from '@Hooks/use-form-plus'
import { FormCard } from '@Components/form-card/form-card-ui'
import { ReactHookFormHelpers } from '@Services/react-hook-form/helpers'
import { FormTraversalButtons } from '@Components/form-traversal-buttons/form-traversal-buttons-ui'
import { FORM_SCHEDULE_ONE, WEEKLY_BEANO_NUM_STEPS } from '../constants'
import { getBlockClass } from '@Helpers/components'
import { FormStepProps } from '@Components/form-general/types'
import { NetProfitOrLoss } from '@Components/form-general/net-profit-or-loss/net-profit-or-loss-ui'
import { TaxPaid } from './tax-paid/tax-paid-ui'
import { SummaryOfBeanoPrizes } from './summary-of-beano-prizes/summary-of-beano-prizes-ui'
import { OtherExpenses } from './other-expenses/other-expenses-ui'
import { calculateWeeklyBeanoStepThreeTotals } from './helpers'
import {
  BASE_ROOT_CLASS,
  getWeeklyBeanoThreeDefaultFormData,
  WeeklyBeanoStepThreeFormData,
  WeeklyBeanoStepThreeTotals
} from './constants'
import './styles.scss'

export type Props = FormStepProps<
  WeeklyBeanoStepThreeFormData,
  WeeklyBeanoStepThreeTotals
> & {
  date: string
  grossBeanoReceiptsInDollars: number
  mslcTaxPaidInDollars: number
  progressiveOneProceedsInDollars: number
  progressiveTwoProceedsInDollars: number
  numWinnerTakeAllSections: number
}

const FORM_STEP = 3
const FORM_ID = BASE_ROOT_CLASS

export const WeeklyBeanoStepThree = (props: Props) => {
  const methods = useForm<WeeklyBeanoStepThreeFormData>(
    ReactHookFormHelpers.getDefaultUseFormOptions<WeeklyBeanoStepThreeFormData>(
      {
        defaultValues: getWeeklyBeanoThreeDefaultFormData(
          props.numWinnerTakeAllSections,
          props.initialValues
        )
      }
    )
  )
  const totals = calculateWeeklyBeanoStepThreeTotals(
    methods.watch(),
    props.mslcTaxPaidInDollars,
    props.grossBeanoReceiptsInDollars
  )

  const handleSubmit = async (data: WeeklyBeanoStepThreeFormData) => {
    await props.onSubmit(data, totals)
  }

  return (
    <>
      <FormCard
        title={FORM_SCHEDULE_ONE}
        currentStep={FORM_STEP}
        totalSteps={WEEKLY_BEANO_NUM_STEPS}
      >
        <div className={BASE_ROOT_CLASS}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} id={FORM_ID}>
              <h2 className={getBlockClass(BASE_ROOT_CLASS, 'header')}>
                B. Expenditures (Show only payments actually made)
              </h2>
              <div className={getBlockClass(BASE_ROOT_CLASS, 'container')}>
                <TaxPaid mslcTaxPaidInDollars={props.mslcTaxPaidInDollars} />
                <SummaryOfBeanoPrizes
                  date={props.date}
                  numWinnerTakeAllSections={props.numWinnerTakeAllSections}
                  progressiveOneProceedsInDollars={
                    props.progressiveOneProceedsInDollars
                  }
                  progressiveTwoProceedsInDollars={
                    props.progressiveTwoProceedsInDollars
                  }
                  beanoGrandTotalCashPrizesInDollars={
                    totals.beanoGrandTotalCashPrizesInDollars
                  }
                />
                <OtherExpenses
                  totalExpenditures={totals.totalBeanoExpendituresInDollars}
                />
              </div>
            </form>
            <NetProfitOrLoss
              grossReceiptsDollars={props.grossBeanoReceiptsInDollars}
              totalExpendituresDollars={totals.totalBeanoExpendituresInDollars}
              beano
            />
          </FormProvider>
        </div>
      </FormCard>
      {!props.printMode && (
        <FormTraversalButtons
          currentStep={FORM_STEP}
          totalSteps={WEEKLY_BEANO_NUM_STEPS}
          formId={FORM_ID}
          nextDisabled={!methods.formState.isValid}
          onBackClick={async () => {
            await handleSubmit(methods.watch())
            props.onBackClick()
          }}
          backOffice={props.backOffice}
        />
      )}
    </>
  )
}
