import * as React from 'react'
import { ModalInfo, ModalInfoProps } from '@Components/modal-info/modal-info-ui'

export type UseInfoModalProps = Pick<
  ModalInfoProps,
  'header' | 'ariaLabel' | 'body'
>

export const useInfoModal = (props: UseInfoModalProps) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)
  const InfoModal = (
    <ModalInfo
      isOpen={modalOpen}
      ariaLabel={props.ariaLabel}
      handleClose={closeModal}
      header={props.header}
      body={props.body}
      buttonText="Okay"
      onButtonClick={closeModal}
    />
  )
  return {
    InfoModal,
    closeModal,
    openModal
  }
}
