import { RaffleFormAction, RaffleFormActions } from './actions'
import { RaffleFormState, RaffleFormStep, RaffleTotals } from './shape'

export const RaffleFormReducer = <Step extends RaffleFormStep>(
  state: RaffleFormState,
  action: RaffleFormAction<Step>
): RaffleFormState => {
  switch (action.type) {
    case RaffleFormActions.UPDATE_FORM_STATE:
      return {
        ...state,
        totals: getRaffleTotals(state, action),
        [action.step]: action.payload,
        currentStep: state.currentStep + 1
      }
    case RaffleFormActions.CHANGE_FORM_STEP:
      return {
        ...state,
        currentStep: action.step
      }
    default:
      return state
  }
}

const getRaffleTotals = <Step extends RaffleFormStep>(
  state: RaffleFormState,
  action: RaffleFormAction<Step>
): RaffleTotals =>
  'totals' in action
    ? {
        ...state.totals,
        ...action.totals
      }
    : state.totals
